import React, { memo, useContext } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  // Checkbox,
  // FormControlLabel,
  IconButton,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";

import { Cancel, Check } from "@mui/icons-material";

import { useState } from "react";

import AuthContext from "../../../../Context/Auth/AuthContext";
import { replaceCaracteres, trimPropsItem } from "../../../../config/const";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const RowAgregar = (props) => {
  const { setagregar, socket } = props;
  const [item, setitem] = useState({
    fpagoNombre: "",
    fpagoDescripcion: "",
    fpagoCodigo: "0",
    fpagoPorcentaje: "",
    fpagoEmpresa: "",
    fpagoFecReg: "",
    fpagoUser: "",
  });

  const classes = useRowStyles();
  //gql eliminar user

  const { usuario } = useContext(AuthContext);
  const handleAgregar = async () => {
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          tabla: "formapago",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          fpagoUser: usuario.usuario,
          fpagoEmpresa: usuario.rucempresa,
        })
      );
    } catch (error) {
       
    }
  };
  const botonDisabled = () => {
    if (
      item.fpagoNombre.trim() === "" ||
      item.fpagoDescripcion.trim() === "" ||
      item.fpagoCodigo.trim() === "" ||
      item.fpagoPorcentaje.trim() === ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell></TableCell> {/* boton */}
        <TableCell>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <IconButton
              className={classes.margin}
              onClick={() => {
                setagregar(false);
                setitem({
                  fpagoNombre: "",
                  fpagoDescripcion: "",
                  fpagoCodigo: "0",
                  fpagoPorcentaje: "0",
                  fpagoEmpresa: "",
                  fpagoFecReg: "",
                  fpagoUser: "",
                });
              }}
            >
              <Cancel fontSize="small" color="secondary" />
            </IconButton>
            <IconButton
              color={
                botonDisabled
                  ? botonDisabled()
                    ? "inherit"
                    : "secondary"
                  : "secondary"
              }
              disabled={botonDisabled()}
              className={classes.margin}
              onClick={() => {
                handleAgregar();

                setagregar(false);
                setitem({
                  fpagoNombre: "",
                  fpagoDescripcion: "",
                  fpagoCodigo: "0",
                  fpagoPorcentaje: "0",
                  fpagoEmpresa: "",
                  fpagoFecReg: "",
                  fpagoUser: "",
                });
              }}
            >
              <Check fontSize="small" />
            </IconButton>{" "}
            {/* <FormControlLabel
              className={classes.margin}
              onChange={(e) => {
                setitem({
                  ...item,
                  secuEstado: e.target.checked ? "ACTIVA" : "INACTIVA",
                });
              }}
              control={
                <Checkbox
                  value="ACTIVO"
                  className={classes.margin}
                  checked={
                    item["secuEstado"] === "ACTIVA" ||
                    item["secuEstado"] === "ACTIVO"
                      ? true
                      : false
                  }
                  color="secondary"
                />
              }
            /> */}
          </Box>
        </TableCell>
        {/* las demas columnas */}
        <TableCell fontSize="small" padding="none">
          <TextField
            value={item.fpagoNombre}
            onChange={(e) => {
              const res = replaceCaracteres(e.target.value);
              setitem({
                ...item,
                fpagoNombre: res,
              });
            }}
            helperText="Agregando"
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell fontSize="small" padding="none">
          <TextField
            value={item.fpagoDescripcion}
            onChange={(e) => {
              const res = replaceCaracteres(e.target.value);
              setitem({
                ...item,
                fpagoDescripcion: res,
              });
            }}
            helperText="Agregando"
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell fontSize="small" padding="none">
          <TextField
            type="number"
            value={item.fpagoCodigo}
            onChange={(e) => {
              const res = replaceCaracteres(e.target.value);
              setitem({
                ...item,
                fpagoCodigo: res,
              });
            }}
            helperText="Agregando"
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell fontSize="small" padding="none">
          <TextField
            type="number"
            value={item.fpagoPorcentaje}
            onChange={(e) => {
              const res = replaceCaracteres(e.target.value);
              setitem({
                ...item,
                fpagoPorcentaje: res,
              });
            }}
            helperText="Agregando"
            size="small"
            fullWidth
          />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregar);
