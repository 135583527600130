export const objectDefault = {
  //   chat_id: "",
  chat_type: "GROUP",
  chat_name: "",
  chat_user: "",
  chat_FecReg: "",
};

export const columns = [
  {
    id: "chat_name",
    label: "name ",
    minWidth: 25,
    tipo: "string",
    propiedad: "chat_name",
  },

  {
    id: "chat_user",
    label: "user ",
    minWidth: 25,
    tipo: "string",
    propiedad: "chat_user",
  },

  {
    id: "chat_FecReg",
    label: "FecReg ",
    minWidth: 25,
    tipo: "string",
    propiedad: "chat_FecReg",
  },
];
