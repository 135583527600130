import { createContext } from "react";
import { useSocket } from "../../hooks/useSocket";

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const { socket, online } = useSocket("https://whatsappbot.neitor.com/");
  return (
    <SocketContext.Provider value={{ empresaSocket: socket, online }}>
      {children}
    </SocketContext.Provider>
  );
};
