import React from "react";
import {
  Button,
  Tooltip,
  IconButton,
  Checkbox,
  FormControlLabel,
  Badge,
  Box,
} from "@mui/material";

const Botones = (props) => {
  const { botones } = props;

  return (
    <>
      {botones.map((itemBoton) => {
        const {
          tooltip,
          funcion,
          disabled,
          Icon,
          color,
          id,
          ocultar,
          tipo,
          variante,
          size,
          texto,
          sx,
          estado,
          submit,
          badgeColor = "primary",
        } = itemBoton;
        if (ocultar) {
          return null;
        }

        return (
          <>
            {tipo === "icono" ? (
              <>
                {texto ? (
                  <Badge
                    badgeContent={texto}
                    color={badgeColor}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <Box>
                      <Tooltip
                        title={tooltip}
                        key={id}
                        followCursor
                        placement="top-start"
                      >
                        <IconButton
                          sx={sx}
                          disabled={disabled}
                          onClick={() => funcion()}
                          type={submit ? "submit" : "button"}
                          onSubmit={(e) => {
                            e?.preventDefault();
                            funcion();
                          }}
                        >
                          <Icon
                            disabled={disabled}
                            color={disabled ? "inherit" : color}
                            fontSize={size}
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Badge>
                ) : (
                  <Tooltip
                    title={tooltip}
                    key={id}
                    followCursor
                    placement="top-start"
                  >
                    <IconButton
                      sx={sx}
                      disabled={disabled}
                      onClick={() => funcion()}
                      type={submit ? "submit" : "button"}
                      onSubmit={(e) => {
                        e.preventDefault();
                        funcion(e);
                      }}
                    >
                      <Icon
                        disabled={disabled}
                        color={disabled ? "inherit" : color}
                        fontSize={size}
                      />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            ) : null}
            {tipo === "boton" ? (
              <Tooltip title={tooltip} key={id}>
                <Button
                  type={submit ? "submit" : "button"}
                  onSubmit={() => funcion()}
                  sx={sx}
                  size={size}
                  variant={variante}
                  color={color}
                  disabled={disabled}
                  startIcon={<Icon />}
                  onClick={() => funcion()}
                >
                  {texto}
                </Button>
              </Tooltip>
            ) : null}

            {tipo === "check" ? (
              <Tooltip
                title={tooltip}
                key={id}
                followCursor
                placement="top-start"
              >
                <FormControlLabel
                  sx={{ marginLeft: ".5rem" }}
                  control={
                    <Checkbox
                      disabled={disabled}
                      onChange={(e) => funcion(e)}
                      sx={sx}
                      size={size}
                      value="ACTIVO"
                      checked={estado}
                      color={color}
                    />
                  }
                />
              </Tooltip>
            ) : null}
          </>
        );
      })}
    </>
  );
};

export default Botones;
