import React, { memo, useContext } from "react";
import { useState } from "react";
import clienteAxios from "../../../../config/axios";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";

// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  MenuItem,
  FormControl,
  Autocomplete,
} from "@mui/material";
import { Cancel, Check } from "@mui/icons-material";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));
const RowAgregar = (props) => {
  const { setagregar, setitem, item } = props;
  const [itemRow, setItemRow] = useState({
    detTipo: "",
    detCodigo: "",
    detBasImponible: "",
    detImpuesto: "",
    detPorcentaje: "",
    detValor: "",
  });
  const [data, setdata] = useState([]);
  const { mostrarAlerta } = useContext(AlertaContext);
  const botonDisabled = () => {
    if (
      itemRow.detTipo === "" ||
      itemRow.detCodigo === "" ||
      itemRow.detBasImponible === "" ||
      itemRow.detImpuesto === "" ||
      itemRow.detPorcentaje === "" ||
      itemRow.detValor === ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  const classes = useRowStyles();
  //gql eliminar user

  const handleAgregar = async () => {
    try {
      const retenciones = [itemRow, ...item.retenciones];

      setitem({
        ...item,
        retenciones,
      });
    } catch (error) {}
  };
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <IconButton
              className={classes.margin}
              onClick={() => {
                setagregar(false);
                setItemRow({
                  detTipo: "",
                  detCodigo: "",
                  detBasImponible: "",
                  detImpuesto: "",
                  detPorcentaje: "",
                  detValor: "",
                });
              }}
            >
              <Cancel fontSize="small" color="secondary" />
            </IconButton>

            <IconButton
              className={classes.margin}
              disabled={botonDisabled()}
              color={botonDisabled() ? "secondary" : "inherit"}
              onClick={() => {
                handleAgregar();

                setagregar(false);
                setItemRow({
                  fecReg: 0,
                  user: "",
                  nombre: "",
                  estado: "ACTIVO",
                });
              }}
            >
              <Check fontSize="small" />
            </IconButton>
          </Box>
        </TableCell>
        {/* las demas columnas */}
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          <TextField
            id=""
            value={itemRow.detTipo}
            onChange={(e) =>
              setItemRow({
                ...itemRow,
                detTipo: e.target.value,
              })
            }
            size="small"
            fullWidth
            helperText="Agregando"
            select
            InputLabelProps={{ shrink: true }}
            SelectProps={{ displayEmpty: true }}
          >
            <MenuItem value="">Seleccione</MenuItem>
            {["FACTURA", "NOTA DEBITO", "LIQ. COMPRA"].map((itemRow) => {
              return <MenuItem value={itemRow}>{itemRow} </MenuItem>;
            })}
          </TextField>
        </TableCell>{" "}
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          <TextField
            id=""
            value={itemRow.detImpuesto}
            onChange={(e) => {
              setItemRow({
                ...itemRow,
                detImpuesto: e.target.value,
                detCodigo: "",
                detPorcentaje: "",
                detValor: "",
              });
              setdata([]);
            }}
            size="small"
            fullWidth
            helperText="Agregando"
            select
            InputLabelProps={{ shrink: true }}
            SelectProps={{ displayEmpty: true }}
          >
            <MenuItem value="">Seleccione</MenuItem>
            {["RENTA", "IVA", "ISD"].map((itemRow) => {
              return <MenuItem value={itemRow}>{itemRow} </MenuItem>;
            })}
          </TextField>
        </TableCell>{" "}
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {" "}
          <FormControl fullWidth style={{ minWidth: 250 }}>
            <Autocomplete
              options={data.map((item) => {
                return (
                  item.porCodigo +
                  "/+/" +
                  item.porPorcentaje +
                  "/+/" +
                  item.porConcepto
                );
              })}
              getOptionLabel={(option) => {
                const options = option.split("/+/");
                if (!option[2]) {
                  return "";
                } else {
                  return `${options[0]} - ${options[1]}% - ${options[2]}`;
                }
              }}
              value={
                itemRow.detCodigo === "" ||
                itemRow.detPorcentaje === "" ||
                itemRow.detValor === ""
                  ? ""
                  : itemRow.detCodigo +
                    "/+/" +
                    itemRow.detPorcentaje +
                    "/+/" +
                    itemRow.detValor
              }
              paperprops
              onChange={async (event, newValue) => {
                if (newValue) {
                  const options = newValue.split("/+/");

                  const renta = options[1] === "" ? 0 : Number(options[1]);
                  const imponible =
                    itemRow.detBasImponible === ""
                      ? 0
                      : Number(itemRow.detBasImponible);

                  const resultado = (imponible / 100) * renta;

                  setItemRow({
                    ...itemRow,
                    detCodigo: options[0],
                    detPorcentaje: options[1],
                    detValor: resultado.toFixed(2),
                  });
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  required
                  fullWidth
                  onChange={async (e) => {
                    try {
                      const res = await clienteAxios.get(
                        `/porcentajesRetenciones/filtro/0?impuesto=${itemRow.detImpuesto}&codigo=${e.target.value}`
                      );
                      if (res.data.msg === "No hay registro") {
                        return;
                      }

                      setdata(res.data.data);
                    } catch (error) {
                      mostrarAlerta(error.response.data.msg, "error");
                    }
                  }}
                  helperText="Agregando"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          </FormControl>
          {/* <TextField
            type="number"
            id=""
            value={itemRow.detCodigo}
            onChange={(e) =>
              setItemRow({
                ...itemRow,
                detCodigo: e.target.value,
              })
            }
            size="small"
            fullWidth
            helperText="Agregando"
          /> */}
        </TableCell>{" "}
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          <TextField
            id=""
            type="number"
            value={itemRow.detPorcentaje}
            onChange={(e) => {
              const renta = e.target.value === "" ? 0 : Number(e.target.value);
              const imponible =
                itemRow.detBasImponible === ""
                  ? 0
                  : Number(itemRow.detBasImponible);

              const resultado = (imponible / 100) * renta;

              setItemRow({
                ...itemRow,
                detPorcentaje: e.target.value,
                detValor: resultado.toFixed(2),
              });
            }}
            size="small"
            fullWidth
            helperText="Agregando"
          />
        </TableCell>{" "}
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          <TextField
            type="number"
            id=""
            value={itemRow.detBasImponible}
            onChange={async (e) => {
              const renta =
                itemRow.detPorcentaje === ""
                  ? 0
                  : Number(itemRow.detPorcentaje);
              const imponible =
                e.target.value === "" ? 0 : Number(e.target.value);

              const resultado = (imponible / 100) * renta;

              setItemRow({
                ...itemRow,
                detBasImponible: e.target.value,
                detValor: resultado.toFixed(2),
              });
            }}
            size="small"
            fullWidth
            helperText="Agregando"
          />
        </TableCell>{" "}
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          <TextField
            id=""
            value={itemRow.detValor}
            onChange={(e) =>
              setItemRow({
                ...itemRow,
                detValor: e.target.value,
              })
            }
            size="small"
            fullWidth
            helperText="Agregando"
            disabled
          />
        </TableCell>{" "}
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregar);
