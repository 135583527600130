import React, { memo, useContext } from "react";

import { useState } from "react";

// import ConfirmacionDialog from "../../Dialog/ConfirmacionDialog";

import ConfirmacionDialog from "../../../../components/Extra/ConfirmacionDialog";

import { withRouter } from "react-router";
import ButtonsAction from "./ButtonsAction";
import AuthContext from "../../../../Context/Auth/AuthContext";
import { tienePermisoRuta } from "../../../../config/validaciones";
import MenuContext from "../../../../Context/Menu/MenuContext";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import {
  permisoDenagado,
  replaceCaracteres,
  trimPropsItem,
} from "../../../../config/const";

// MATERIAL
import { makeStyles } from "@mui/styles";
import { TableCell, TableRow, TextField } from "@mui/material";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  celda: {
    fontSize: ".6rem !important",
  },
  hover: {
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const Row = (props) => {
  const {
    arrayExport,
    setarrayExport,
    row,
    index,
    campoExport,
    socket,
  } = props;
  const { rows: rowsMenu } = useContext(MenuContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext);
  const classes = useRowStyles();
  const [item, setitem] = useState({ ...row });
  const [editar, seteditar] = useState(false);
  const handleEliminar = async (_id) => {
    if (
      !tienePermisoRuta(
        rowsMenu,
        props.location.pathname,
        usuario.rol,
        "eliminar"
      )
    ) {
      return mostrarAlerta(permisoDenagado, "error");
    }
    try {
      socket.current.emit("client:eliminarData", {
        tabla: "cuadrecaja",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        cuadUser: usuario.usuario,
        cuadEmpresa: usuario.rucempresa,
        cuadId: row.cuadId,
      });
    } catch (error) {}
  };
  const funcionEdit = () => {
    if (
      !tienePermisoRuta(
        rowsMenu,
        props.location.pathname,
        usuario.rol,
        "editar"
      )
    ) {
      return mostrarAlerta(permisoDenagado, "error");
    }
    setitem({ ...row });
    seteditar(true);
  };

  const funcCancel = () => {
    setitem({ ...row });
    seteditar(false);
  };
  const funcSubmit = () => {
    try {
      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          tabla: "cuadrecaja",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          cuadUser: usuario.usuario,
          cuadEmpresa: usuario.rucempresa,
        })
      );
      seteditar(false);
    } catch (error) {}
  };
  const botonDisabled = () => {
    if (
      item.cuadMoneda1c === "0" &&
      item.cuadMoneda5c === "0" &&
      item.cuadMoneda10c === "0" &&
      item.cuadMoneda25c === "0" &&
      item.cuadMoneda50c === "0" &&
      item.cuadMoneda1d === "0" &&
      item.cuadMoneda5d === "0" &&
      item.cuadMoneda10d === "0" &&
      item.cuadMoneda20d === "0" &&
      item.cuadMoneda50d === "0" &&
      item.cuadMoneda100d === "0"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo={`¿Esta seguro de eliminar este registro: ${row.catNombre}?`}
        contenido={`La categoría ${row.catNombre} será eliminada, no podrá ser recuperada.`}
        _id={row._id}
        funcion={handleEliminar}
      />

      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          row={row}
          setopenConfirmDialog={setopenConfirmDialog}
          funcionEdit={funcionEdit}
          ocultarEditar={false}
          ocultarDelete={false}
          mostrarEstado={false}
          campoExport={campoExport}
          campoEstado="cajaEstado"
          editar={editar}
          funcCancel={funcCancel}
          funcSubmit={funcSubmit}
          botonDisabled={botonDisabled}
          item={item}
          setitem={setitem}
        />
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              disabled
              id="cuadUsuario"
              inputProps={{ style: { textAlign: "center" } }}
              value={item.cuadUsuario}
              onChange={(e) => {
                const res = replaceCaracteres(e.target.value);
                setitem({
                  ...item,
                  cuadUsuario: res,
                });
              }}
              size="small"
              helperText="Editando"
              fullWidth
            />
          ) : (
            `${row.cuadUsuario}`
          )}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              disabled
              id="cuadfecha"
              inputProps={{ style: { textAlign: "center" } }}
              value={item.cuadfecha}
              onChange={(e) => {
                const res = replaceCaracteres(e.target.value);
                setitem({
                  ...item,
                  cuadfecha: res,
                });
              }}
              size="small"
              helperText="Editando"
              fullWidth
            />
          ) : (
            `${row.cuadfecha}`
          )}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              id="cuadMoneda1c"
              type="number"
              inputProps={{ style: { textAlign: "center" } }}
              value={item.cuadMoneda1c}
              onChange={(e) => {
                const res = replaceCaracteres(e.target.value);
                setitem({
                  ...item,
                  cuadMoneda1c: res,
                });
              }}
              size="small"
              helperText="Editando"
              fullWidth
            />
          ) : (
            `${row.cuadMoneda1c}`
          )}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              id="cuadMoneda5c"
              type="number"
              inputProps={{ style: { textAlign: "center" } }}
              value={item.cuadMoneda5c}
              onChange={(e) => {
                const res = replaceCaracteres(e.target.value);
                setitem({
                  ...item,
                  cuadMoneda5c: res,
                });
              }}
              size="small"
              helperText="Editando"
              fullWidth
            />
          ) : (
            `${row.cuadMoneda5c}`
          )}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              type="number"
              id="cuadMoneda10c"
              inputProps={{ style: { textAlign: "center" } }}
              value={item.cuadMoneda10c}
              onChange={(e) => {
                const res = replaceCaracteres(e.target.value);
                setitem({
                  ...item,
                  cuadMoneda10c: res,
                });
              }}
              size="small"
              helperText="Editando"
              fullWidth
            />
          ) : (
            `${row.cuadMoneda10c}`
          )}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              type="number"
              id="cuadMoneda25c"
              inputProps={{ style: { textAlign: "center" } }}
              value={item.cuadMoneda25c}
              onChange={(e) => {
                const res = replaceCaracteres(e.target.value);
                setitem({
                  ...item,
                  cuadMoneda25c: res,
                });
              }}
              size="small"
              helperText="Editando"
              fullWidth
            />
          ) : (
            `${row.cuadMoneda25c}`
          )}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              id="cuadMoneda50c"
              type="number"
              inputProps={{ style: { textAlign: "center" } }}
              value={item.cuadMoneda50c}
              onChange={(e) => {
                const res = replaceCaracteres(e.target.value);
                setitem({
                  ...item,
                  cuadMoneda50c: res,
                });
              }}
              size="small"
              helperText="Editando"
              fullWidth
            />
          ) : (
            `${row.cuadMoneda50c}`
          )}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              id="cuadMoneda1d"
              type="number"
              inputProps={{ style: { textAlign: "center" } }}
              value={item.cuadMoneda1d}
              onChange={(e) => {
                const res = replaceCaracteres(e.target.value);
                setitem({
                  ...item,
                  cuadMoneda1d: res,
                });
              }}
              size="small"
              helperText="Editando"
              fullWidth
            />
          ) : (
            `${row.cuadMoneda1d}`
          )}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              id="cuadMoneda5d"
              type="number"
              inputProps={{ style: { textAlign: "center" } }}
              value={item.cuadMoneda5d}
              onChange={(e) => {
                const res = replaceCaracteres(e.target.value);
                setitem({
                  ...item,
                  cuadMoneda5d: res,
                });
              }}
              size="small"
              helperText="Editando"
              fullWidth
            />
          ) : (
            `${row.cuadMoneda5d}`
          )}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              id="cuadMoneda10d"
              type="number"
              inputProps={{ style: { textAlign: "center" } }}
              value={item.cuadMoneda10d}
              onChange={(e) => {
                const res = replaceCaracteres(e.target.value);
                setitem({
                  ...item,
                  cuadMoneda10d: res,
                });
              }}
              size="small"
              helperText="Editando"
              fullWidth
            />
          ) : (
            `${row.cuadMoneda10d}`
          )}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              id="cuadMoneda20d"
              type="number"
              inputProps={{ style: { textAlign: "center" } }}
              value={item.cuadMoneda20d}
              onChange={(e) => {
                const res = replaceCaracteres(e.target.value);
                setitem({
                  ...item,
                  cuadMoneda20d: res,
                });
              }}
              size="small"
              helperText="Editando"
              fullWidth
            />
          ) : (
            `${row.cuadMoneda20d}`
          )}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              type="number"
              id="cuadMoneda50d"
              inputProps={{ style: { textAlign: "center" } }}
              value={item.cuadMoneda50d}
              onChange={(e) => {
                const res = replaceCaracteres(e.target.value);
                setitem({
                  ...item,
                  cuadMoneda50d: res,
                });
              }}
              size="small"
              helperText="Editando"
              fullWidth
            />
          ) : (
            `${row.cuadMoneda50d}`
          )}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              type="number"
              id="cuadMoneda100d"
              inputProps={{ style: { textAlign: "center" } }}
              value={item.cuadMoneda100d}
              onChange={(e) => {
                const res = replaceCaracteres(e.target.value);
                setitem({
                  ...item,
                  cuadMoneda100d: res,
                });
              }}
              size="small"
              helperText="Editando"
              fullWidth
            />
          ) : (
            `${row.cuadMoneda100d}`
          )}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              disabled
              id="cuadTotal"
              inputProps={{ style: { textAlign: "center" } }}
              value={item.cuadTotal}
              onChange={(e) => {
                const res = replaceCaracteres(e.target.value);
                setitem({
                  ...item,
                  cuadTotal: res,
                });
              }}
              size="small"
              helperText="Editando"
              fullWidth
            />
          ) : (
            `${row.cuadTotal}`
          )}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              disabled
              id="cuadTotalCaja"
              inputProps={{ style: { textAlign: "center" } }}
              value={item.cuadTotalCaja}
              onChange={(e) => {
                const res = replaceCaracteres(e.target.value);
                setitem({
                  ...item,
                  cuadTotalCaja: res,
                });
              }}
              size="small"
              helperText="Editando"
              fullWidth
            />
          ) : (
            `${row.cuadTotalCaja}`
          )}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              disabled
              id="cuadDiferencia"
              inputProps={{ style: { textAlign: "center" } }}
              value={item.cuadDiferencia}
              onChange={(e) => {
                const res = replaceCaracteres(e.target.value);
                setitem({
                  ...item,
                  cuadDiferencia: res,
                });
              }}
              size="small"
              helperText="Editando"
              fullWidth
            />
          ) : (
            `${row.cuadDiferencia}`
          )}
        </TableCell>

        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {new Date(row.cuadFecReg).toLocaleString("es-ES", {
            weekday: "short",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour: "2-digit",
          })}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
