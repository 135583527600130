import React, { memo, useContext } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Collapse,
  Divider,
  TableCell,
  TableRow,
} from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../../../components/Extra/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "./ButtonsAction";
import AuthContext from "../../../../Context/Auth/AuthContext";
import HistorialClinica from "../../HistorialClinica/HistorialClinica";
import CarnetVacunas from "../../CarnetVacunas/CarnetVacunas";
import Recetas from "../../Recetas/Recetas";
import Hospitalizacion from "../../Hospitalizacion/Hospitalizacion";
import Peluqueria from "../../Peluqueria/Peluqueria";
import clienteAxios from "../../../../config/axios";
import Examenes from "../../Examenes/Examenes";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import CallNombrePersona from "../../../components/Tablas/CallNombrePersona";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  hover: {
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  celda: {
    fontSize: ".7rem !important",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const Row = (props) => {
  const {
    arrayExport,
    setarrayExport,
    row,
    index,
    campoExport,
    // puedeExportar,
    desdeOtroLugar,
    seteditar,
    setopen,
    changeObjetoActivoState,
    changeObjetoActivoStatePropietario,
    setopenPropietarioModal,
    socket,
    tienePermiso,
    alertaPermiso,
    permisoHistorialClinica,
    permisoCarnetVacunas,
    permisoRecetas,
    permisoHospitalizacion,
    permisoPeluqueria,
    permisoExamenes,
    eliminadoEstado,
  } = props;
  const { usuario } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const classes = useRowStyles();

  const handleEliminar = async (_id) => {
    if (!tienePermiso("eliminar")) {
      return alertaPermiso("eliminar");
    }
    try {
      socket.current.emit("client:eliminarData", {
        nombre: usuario.nombre,
        tabla: "mascota",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        mascUser: usuario.usuario,
        mascEmpresa: usuario.rucempresa,
        mascId: row.mascId,
      });
    } catch (error) {}
  };
  const funcionEdit = () => {
    setopen(true);
    seteditar(true);
    changeObjetoActivoState({ ...row, restaurado: eliminadoEstado });
  };
  const funcionEditPropietario = async () => {
    try {
      const res = await clienteAxios(`/proveedores/${row.mascPerId}`);
      if (res.data.data.length === 0) {
        return mostrarAlerta(
          "Hubo un error en la consulta del propietario",
          "error"
        );
      }
      changeObjetoActivoStatePropietario(res.data.data[0]);
      setopenPropietarioModal(true);
      // setopen(true);
      // seteditar(true);
      // changeObjetoActivoState(row);
    } catch (error) {
      mostrarAlerta("Hubo un error", "error");
    }
  };

  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const puedeExportar = arrayExport.some((item) => {
    return item[campoExport] === row[campoExport];
  });

  const [seccion, setseccion] = useState({
    historiaClinicaSeccion: false,
    vacunasSeccion: false,
    recetasSeccion: false,
    hospitalizacionSeccion: false,
    peluqueriaSeccion: false,
    examenSeccion: false,
  });
  const {
    historiaClinicaSeccion,
    vacunasSeccion,
    recetasSeccion,
    hospitalizacionSeccion,
    peluqueriaSeccion,
    examenSeccion,
  } = seccion;
  // eslint-disable-next-line
  const [tab, settab] = useState("");
  const [expandir, setexpandir] = useState(false);
  // eslint-disable-next-line
  const [arrayExportHijos, setarrayExportHijos] = useState([]);
  const [desdeOtroLugarMascota] = useState(true);
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo={`¿Esta seguro de eliminar este registro?`}
        _id={row._id}
        funcion={handleEliminar}
      />
      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          eliminadoEstado={eliminadoEstado}
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          row={row}
          setopenConfirmDialog={setopenConfirmDialog}
          funcionEdit={funcionEdit}
          ocultarEditar={false}
          ocultarDelete={false}
          mostrarEstado={false}
          campoExport={campoExport}
          campoEstado=""
          puedeExportar={puedeExportar}
          desdeOtroLugar={desdeOtroLugar}
          expandir={expandir}
          setexpandir={setexpandir}
          funcionEditPropietario={funcionEditPropietario}
        />
        <>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {row.mascPerNombre}
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {row.mascId}
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {row.mascMicroChip}
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {row.mascCaracter}
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {row.mascNombre}
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {row.mascEspecie}
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {row.mascRaza}
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {row.mascSexo}
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {row.mascColor}
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {row.mascEdad}
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {row.mascFechaNacimiento}
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {row.mascAlimento}
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            style={{
              color:
                row.mascEstado === "Vivo"
                  ? "green"
                  : //rojo tomate
                    "#ffa726",
              fontWeight: "bold",
            }}
            className={classes.celda}
          >
            {row.mascEstado}
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {row?.nombre_medico_tratante}
          </TableCell>
          <CallNombrePersona user={row.mascUser} />
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {new Date(row.mascFecUpd).toLocaleString("es-ES", {
              weekday: "short",
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour: "2-digit",
            })}
          </TableCell>
        </>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
          }}
          colSpan={21}
        >
          <Collapse in={expandir} timeout="auto" unmountOnExit>
            <Box
              mt={1}
              display="flex"
              justifyContent="space-between"
              alignItems=" stretch"
              width="100%"
            >
              <Box>
                <>
                  {permisoHistorialClinica === "SI" ? (
                    <Button
                      size="small"
                      variant={historiaClinicaSeccion ? "contained" : null}
                      onClick={async () => {
                        setseccion({ historiaClinicaSeccion: true });
                        settab("");
                        setarrayExportHijos([]);
                      }}
                      disableElevation
                      style={{
                        padding: ".5rem",
                        borderRadius: "15px 15px  0px 0px",
                      }}
                      color="primary"
                    >
                      Historia Clinica
                    </Button>
                  ) : null}

                  {permisoCarnetVacunas === "SI" ? (
                    <Button
                      size="small"
                      variant={vacunasSeccion ? "contained" : null}
                      onClick={async () => {
                        setseccion({ vacunasSeccion: true });
                        settab("");
                        setarrayExportHijos([]);
                      }}
                      disableElevation
                      style={{
                        padding: ".5rem",
                        borderRadius: "15px 15px  0px 0px",
                      }}
                      color="primary"
                    >
                      Vacunas
                    </Button>
                  ) : null}
                  {permisoRecetas === "SI" ? (
                    <Button
                      size="small"
                      variant={recetasSeccion ? "contained" : null}
                      onClick={async () => {
                        setseccion({ recetasSeccion: true });
                        settab("");
                        setarrayExportHijos([]);
                      }}
                      disableElevation
                      style={{
                        padding: ".5rem",
                        borderRadius: "15px 15px  0px 0px",
                      }}
                      color="primary"
                    >
                      Recetas
                    </Button>
                  ) : null}
                  {permisoHospitalizacion === "SI" ? (
                    <Button
                      size="small"
                      variant={hospitalizacionSeccion ? "contained" : null}
                      onClick={async () => {
                        setseccion({ hospitalizacionSeccion: true });
                        settab("");
                        setarrayExportHijos([]);
                      }}
                      disableElevation
                      style={{
                        padding: ".5rem",
                        borderRadius: "15px 15px  0px 0px",
                      }}
                      color="primary"
                    >
                      Hospitalizacion
                    </Button>
                  ) : null}
                  {permisoPeluqueria === "SI" ? (
                    <Button
                      size="small"
                      variant={peluqueriaSeccion ? "contained" : null}
                      onClick={async () => {
                        setseccion({ peluqueriaSeccion: true });
                        settab("");
                        setarrayExportHijos([]);
                      }}
                      disableElevation
                      style={{
                        padding: ".5rem",
                        borderRadius: "15px 15px  0px 0px",
                      }}
                      color="primary"
                    >
                      Peluqueria
                    </Button>
                  ) : null}
                  {permisoExamenes === "SI" ? (
                    <Button
                      size="small"
                      variant={examenSeccion ? "contained" : null}
                      onClick={async () => {
                        setseccion({ examenSeccion: true });
                        settab("");
                        setarrayExportHijos([]);
                      }}
                      disableElevation
                      style={{
                        padding: ".5rem",
                        borderRadius: "15px 15px  0px 0px",
                      }}
                      color="primary"
                    >
                      Examen
                    </Button>
                  ) : null}
                </>
              </Box>
            </Box>
            <Divider style={{ display: "block", width: "100%" }} />
            <Box p={2}>
              {historiaClinicaSeccion ? (
                <HistorialClinica
                  idmascota={row.mascId}
                  settab={settab}
                  setarrayExportHijos={setarrayExportHijos}
                  desdeOtroLugarMascota={desdeOtroLugarMascota}
                  puedeExportar={puedeExportar}
                  itemRelacionadoPadre={row}
                />
              ) : null}
              {vacunasSeccion ? (
                <CarnetVacunas
                  idmascota={row.mascId}
                  settab={settab}
                  setarrayExportHijos={setarrayExportHijos}
                  desdeOtroLugarMascota={desdeOtroLugarMascota}
                  puedeExportar={puedeExportar}
                  itemRelacionadoPadre={row}
                />
              ) : null}
              {recetasSeccion ? (
                <Recetas
                  idmascota={row.mascId}
                  settab={settab}
                  setarrayExportHijos={setarrayExportHijos}
                  desdeOtroLugarMascota={desdeOtroLugarMascota}
                  puedeExportar={puedeExportar}
                  itemRelacionadoPadre={row}
                />
              ) : null}
              {hospitalizacionSeccion ? (
                <Hospitalizacion
                  idmascota={row.mascId}
                  settab={settab}
                  setarrayExportHijos={setarrayExportHijos}
                  desdeOtroLugarMascota={desdeOtroLugarMascota}
                  puedeExportar={puedeExportar}
                  itemRelacionadoPadre={row}
                />
              ) : null}
              {peluqueriaSeccion ? (
                <Peluqueria
                  idmascota={row.mascId}
                  settab={settab}
                  setarrayExportHijos={setarrayExportHijos}
                  desdeOtroLugarMascota={desdeOtroLugarMascota}
                  puedeExportar={puedeExportar}
                  itemRelacionadoPadre={row}
                />
              ) : null}
              {examenSeccion ? (
                <Examenes
                  idmascota={row.mascId}
                  settab={settab}
                  setarrayExportHijos={setarrayExportHijos}
                  desdeOtroLugarMascota={desdeOtroLugarMascota}
                  puedeExportar={puedeExportar}
                  itemRelacionadoPadre={row}
                />
              ) : null}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
