import React, { useContext, useState, useEffect, memo } from "react";
import { makeStyles } from "@mui/styles";
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  Typography,
  TableRow,
} from "@mui/material";

import { withRouter } from "react-router-dom";
// import Spinner from "../../Spinner/Spinner";

import Row from "./Row";
import ToolBarTable from "./ToolBarTable";

import RowAgregar from "./RowAgregar";

import AuthContext from "../../../../Context/Auth/AuthContext";
import clienteAxios from "../../../../config/axios";
import Cargando from "../../../../components/Extra/Cargando";
import TablaCabecera from "../../../../components/Extra/TablaCabecera";
import { usePermiso } from "../../../../hooks/usePermiso";
const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 600,
  },
});
const TablaAsignaturas = (props) => {
  const { alertaPermiso, tienePermiso } = usePermiso("CuentasXCobrar");
  const {
    agregar,
    setagregar,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    rows,
    search,
    input,
    orden,
    setinput,
    setorden,
    cargando,
    setcargando,
    fullBuscador,
    // STATE,
    getDataState,
    remplazarRowsState,
    obtenerState,
    pagination,
    columns,
    addRowsState,
    // export
    arrayExport,
    setarrayExport,
    campoExport,
    socket,
  } = props;

  const {
    usuario: { rucempresa },
  } = useContext(AuthContext);
  const [bancos, setbancos] = useState(null);
  useEffect(() => {
    if (bancos === null) {
      const getDataState = async () => {
        const res = await clienteAxios.get("/bancos/filtro/0");

        setbancos(res.data.data);
      };
      getDataState();
    } // eslint-disable-next-line
  }, []);
  //columnas y rows de la tabla

  // eslint-disable-next-line
  const clases = useStyles();
  // eslint-disable-next-line

  // eslint-disable-next-line
  // eslint-disable-next-line

  // eslint-disable-next-line

  //clases
  const classes = useStyles();
  const reducer = (previousValue, currentValue) =>
    Number(previousValue) + Number(currentValue);

  const handleChangePage = async (event, newPage) => {
    if (newPage * rowsPerPage + rowsPerPage > rows.length) {
      setcargando(true);
      const res = await getDataState(
        rowsPerPage,
        newPage,
        search,
        input.id,
        orden,
        JSON.stringify(fullBuscador),
        rucempresa
      );

      const data = res.data.data.results;
      setcargando(false);
      const resultado = data.filter((row) => {
        const existe = rows.some((item, index) => {
          return row.ccId === item.ccId;
        });

        return !existe;
      });

      addRowsState(resultado);
    }

    setPage(newPage);
  };

  // eslint-disable-next-line

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    if (page === 0) {
      if (event.target.value > rows.length) {
        obtenerState(
          event.target.value,
          0,
          search,
          input.id,
          orden,
          JSON.stringify(fullBuscador),
          rucempresa
        );
      }
    }
  };
  const funcionOrdenar = async (column) => {
    setcargando(true);
    await remplazarRowsState(
      rowsPerPage,
      0,
      search,
      column.id,
      !orden,
      JSON.stringify(fullBuscador),
      rucempresa
    );
    setPage(0);
    setinput(column);
    setorden(!orden);
    setcargando(false);
  };
  const funcionReload = async () => {
    try {
      setcargando(true);
      setRowsPerPage(10);
      setPage(0);
      await obtenerState(
        10,
        0,
        search,
        input.id,
        orden,
        JSON.stringify(fullBuscador),
        rucempresa
      );
      setcargando(false);
    } catch (error) {}
  };

  useEffect(() => {
    //eslint-disable-next-line

    obtenerState(
      10,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa
    );

    // eslint-disable-next-line
  }, []);
  //si no hay usuarios //rows no cargar el componente
  if (!rows) {
    return <Cargando titulo="Cargando" />;
  }
  if (!pagination) {
    return <Cargando titulo="Cargando" />;
  }
  if (!bancos) {
    return <Cargando titulo="Cargando Bancos" />;
  }
  return (
    <Paper className={classes.root}>
      <ToolBarTable
        search={search}
        titulo={"Cuentas Por Cobrar"}
        arrayExport={arrayExport}
        columns={columns}
        expandible={false}
        campoExport={campoExport}
        tabla="cuentasporcobrar"
        funcionReload={funcionReload}
        pagination={pagination}
        rowsPerPage={rowsPerPage}
        rows={rows}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        fullBuscador={fullBuscador}
        socket={socket}
        saldoExport={
          arrayExport.length === 0
            ? "SALDO: $000"
            : `SALDO: $ ${arrayExport
                .map((row) => {
                  return Number(row.ccSaldo);
                })
                .reduce(reducer)
                .toFixed(2)}`
        }
        alertaPermiso={alertaPermiso}
        tienePermiso={tienePermiso}
      />
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" id="tabla" size="small">
          {/* <ModalCarnet setopen={setopen} onChangeCarnetActivo={onChangeCarnetActivo}/> */}
          <TableHead>
            <TablaCabecera
              rows={rows}
              setarrayExport={setarrayExport}
              columns={columns}
              habilitarOrdenar={true}
              //={}nue//={}vo
              input={input}
              orden={orden}
              funcionOrdenar={funcionOrdenar}
            />
          </TableHead>
          <TableBody>
            {agregar ? (
              <RowAgregar setagregar={setagregar} socket={socket} />
            ) : null}
            {cargando ? (
              <TableCell
                size="small"
                align="center"
                colSpan={columns.length + 2}
              >
                <Cargando titulo="Cargando" />
              </TableCell>
            ) : (
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <Row
                      arrayExport={arrayExport}
                      setarrayExport={setarrayExport}
                      row={row}
                      index={index}
                      campoExport={campoExport}
                      key={row.ccId}
                      bancos={bancos}
                      socket={socket}
                      alertaPermiso={alertaPermiso}
                      tienePermiso={tienePermiso}
                    />
                  );
                })
            )}
            <TableRow>
              <TableCell
                padding="none"
                size="small"
                align="center"
                className={classes.celda}
              ></TableCell>
              <TableCell
                padding="none"
                size="small"
                align="center"
                className={classes.celda}
              ></TableCell>
              <TableCell
                padding="none"
                size="small"
                align="center"
                className={classes.celda}
              ></TableCell>
              <TableCell
                padding="none"
                size="small"
                align="center"
                className={classes.celda}
              ></TableCell>
              <TableCell
                padding="none"
                size="small"
                align="center"
                className={classes.celda}
              ></TableCell>
              <TableCell
                padding="none"
                size="small"
                align="center"
                className={classes.celda}
              ></TableCell>
              <TableCell
                padding="none"
                size="small"
                align="center"
                className={classes.celda}
              ></TableCell>

              <TableCell
                padding="none"
                size="small"
                align="center"
                className={classes.celda}
              ></TableCell>

              <TableCell
                padding="none"
                size="small"
                align="center"
                className={classes.celda}
              >
                <Typography
                  variant="body2"
                  color="secondary"
                  style={{ fontWeight: "bold", padding: ".3rem" }}
                  component={"span"}
                >
                  $
                  {rows.length === 0
                    ? null
                    : rows
                        .map((row) => {
                          const existe = arrayExport.some(
                            (itemE) => itemE[campoExport] === row[campoExport]
                          );

                          if (existe) {
                            return row.ccEstado === "ANULADA"
                              ? 0
                              : Number(row.ccValorFactura);
                          }
                          return 0;
                        })
                        .reduce(reducer)
                        .toFixed(2)}
                </Typography>
                /
                <Typography
                  variant="body2"
                  color="primary"
                  style={{ fontWeight: "bold", padding: ".3rem" }}
                  component={"span"}
                >
                  $
                  {rows.length === 0
                    ? null
                    : rows
                        .map((row) => {
                          return row.ccEstado === "ANULADA"
                            ? 0
                            : Number(row.ccValorFactura);
                        })
                        .reduce(reducer)
                        .toFixed(2)}
                </Typography>
              </TableCell>
              <TableCell
                padding="none"
                size="small"
                align="center"
                className={classes.celda}
              >
                <Typography
                  variant="body2"
                  color="primary"
                  style={{ fontWeight: "bold", padding: ".3rem" }}
                >
                    <Typography
                  variant="body2"
                  color="secondary"
                  style={{ fontWeight: "bold", padding: ".3rem" }}
                  component={"span"}
                >
                  $
                  {rows.length === 0
                    ? null
                    : rows
                        .map((row) => {
                          const existe = arrayExport.some(
                            (itemE) => itemE[campoExport] === row[campoExport]
                          );

                          if (existe) {
                            return row.ccEstado === "ANULADA"
                              ? 0
                              : Number(row.ccValorRetencion);
                          }
                          return 0;
                        })
                        .reduce(reducer)
                        .toFixed(2)}
                </Typography>
                /
                <Typography
                  variant="body2"
                  color="primary"
                  style={{ fontWeight: "bold", padding: ".3rem" }}
                  component={"span"}
                >
                  $
                  {rows.length === 0
                    ? null
                    : rows
                        .map((row) => {
                          return row.ccEstado === "ANULADA"
                            ? 0
                            : Number(row.ccValorRetencion);
                        })
                        .reduce(reducer)
                        .toFixed(2)}
                </Typography>
                </Typography>
              </TableCell>
              <TableCell
                padding="none"
                size="small"
                align="center"
                className={classes.celda}
              >
           <Typography
                  variant="body2"
                  color="secondary"
                  style={{ fontWeight: "bold", padding: ".3rem" }}
                  component={"span"}
                >
                  $
                  {rows.length === 0
                    ? null
                    : rows
                        .map((row) => {
                          const existe = arrayExport.some(
                            (itemE) => itemE[campoExport] === row[campoExport]
                          );

                          if (existe) {
                            return row.ccEstado === "ANULADA"
                              ? 0
                              : Number(row.ccValorAPagar);
                          }
                          return 0;
                        })
                        .reduce(reducer)
                        .toFixed(2)}
                </Typography>
                /
                <Typography
                  variant="body2"
                  color="primary"
                  style={{ fontWeight: "bold", padding: ".3rem" }}
                  component={"span"}
                >
                  $
                  {rows.length === 0
                    ? null
                    : rows
                        .map((row) => {
                          return row.ccEstado === "ANULADA"
                            ? 0
                            : Number(row.ccValorAPagar);
                        })
                        .reduce(reducer)
                        .toFixed(2)}
                </Typography>
              </TableCell>
              <TableCell
                padding="none"
                size="small"
                align="center"
                className={classes.celda}
              >
                <Typography
                  variant="body2"
                  color="secondary"
                  style={{ fontWeight: "bold", padding: ".3rem" }}
                  component={"span"}
                >
                  $
                  {rows.length === 0
                    ? null
                    : rows
                        .map((row) => {
                          const existe = arrayExport.some(
                            (itemE) => itemE[campoExport] === row[campoExport]
                          );

                          if (existe) {
                            return row.ccEstado === "ANULADA"
                              ? 0
                              : Number(row.ccAbono);
                          }
                          return 0;
                        })
                        .reduce(reducer)
                        .toFixed(2)}
                </Typography>
                /
                <Typography
                  variant="body2"
                  color="primary"
                  style={{ fontWeight: "bold", padding: ".3rem" }}
                  component={"span"}
                >
                  $
                  {rows.length === 0
                    ? null
                    : rows
                        .map((row) => {
                          return row.ccEstado === "ANULADA"
                            ? 0
                            : Number(row.ccAbono);
                        })
                        .reduce(reducer)
                        .toFixed(2)}
                </Typography>
              </TableCell>
              <TableCell
                padding="none"
                size="small"
                align="center"
                className={classes.celda}
              >
                <Typography
                  variant="body2"
                  color="secondary"
                  style={{ fontWeight: "bold", padding: ".3rem" }}
                  component={"span"}
                >
                  $
                  {rows.length === 0
                    ? null
                    : rows
                        .map((row) => {
                          const existe = arrayExport.some(
                            (itemE) => itemE[campoExport] === row[campoExport]
                          );

                          if (existe) {
                            return row.ccEstado === "ANULADA"
                              ? 0
                              : Number(row.ccSaldo);
                          }
                          return 0;
                        })
                        .reduce(reducer)
                        .toFixed(2)}
                </Typography>
                /
                <Typography
                  variant="body2"
                  color="primary"
                  style={{ fontWeight: "bold", padding: ".3rem" }}
                  component={"span"}
                >
                  $
                  {rows.length === 0
                    ? null
                    : rows
                        .map((row) => {
                          return row.ccEstado === "ANULADA"
                            ? 0
                            : Number(row.ccSaldo);
                        })
                        .reduce(reducer)
                        .toFixed(2)}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
export default memo(withRouter(TablaAsignaturas));
