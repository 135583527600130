import React, { memo, useContext } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

import { useState } from "react";
import { withRouter } from "react-router";
import AuthContext from "../../../Context/Auth/AuthContext";
import {
  Cancel,
  Check,
  Close,
  CopyAll,
  Create,
  DeleteForever,
  PictureAsPdf,
  Receipt,
  Restore,
} from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../../styles/stylesRows";
import { usePermiso } from "../../../hooks/usePermiso";
import { serverphp, trimPropsItem } from "../../../config/const";
import ConfirmacionDialog from "../../components/Dialogs/ConfirmacionDialog";
import ButtonsAction from "../../components/Tablas/ButtonsAction";
import clienteAxios from "../../../config/axios";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import CollapseCell from "../../components/Celdas/CollapseCell";

const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const {
    arrayExport,
    setarrayExport,
    row,
    index,
    campoExport,
    seteditar,
    setopen,
    changeObjetoActivoState,
    socket,
    seteditarFactura,
    changeObjetoActivoStateFactura,
    setopenFactura,
    // ObjectoActivoFactura,
  } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    row.editado === true
      ? classes.celdaEditado
      : row.nuevo === true
      ? classes.celdaNuevo
      : row.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;
  // HOOK DE PERMISO
  const { tienePermiso, alertaPermiso } = usePermiso("Produccion");
  // CONTEXT
  const { usuario } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const [openDialogLeidos, setopenDialogLeidos] = useState(false);
  // STATE`
  const [celComprimida, setcelComprimida] = useState({});
  // ELIMINAR EL REGISTRO
  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        tabla: "produccion",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        prodUser: usuario.usuario,
        prodEmpresa: usuario.rucempresa,
        [campoExport]: row[campoExport],
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };
  // FUNCION PARA EDITAR, ABRE EL MODAL COLOCA EN EDITAR EL MODAL Y CAMBIA EL OBJETO ACTIVO
  const funcionEdit = () => {
    setopen(true);
    seteditar(true);
    changeObjetoActivoState(row);
  };
  const funcRecuperar = () => {
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...row,

          tabla: "produccion",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          prodUser: usuario.usuario + "/+/" + row[campoExport],
          prodEmpresa: usuario.rucempresa,
          [campoExport]: row[campoExport],
        })
      );

      seteditar(false);
    } catch (error) {}
  };
  return (
    <React.Fragment>
      {openDialogLeidos ? (
        <Dialog
          open={openDialogLeidos}
          onClose={() => setopenDialogLeidos(false)}
          scroll={"paper"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <Box>
            <DialogTitle
              id="scroll-dialog-title"
              sx={{ padding: ".5rem" }}
              align="center"
            >
              Leidos
            </DialogTitle>
            <DialogContent dividers sx={{}}>
              {row.conLeidos.map((item, index) => {
                return (
                  <DialogContentText
                    id="scroll-dialog-description"
                    tabIndex={-1}
                    mb={1}
                  >
                    <Typography variant="body1" color="primary">
                      {index + 1} - {item.docnumero} {item.nombres}
                    </Typography>
                    <Typography variant="body2" color="secondary">
                      {item.fecha} - {item.rol.join(" - ")}
                    </Typography>
                  </DialogContentText>
                );
              })}
            </DialogContent>
            <DialogActions>
              <Button fullWidth color="primary" variant="contained">
                OK
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      ) : null}

      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow className={classes.root}>
        {/* boton */}
        <ButtonsAction
          claseCelda={claseCelda}
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          campoExport={campoExport}
          row={row}
          botones={[
            {
              tooltip: "Recuperar",
              texto: "",
              funcion: () => {
                if (!tienePermiso("agregar")) {
                  return alertaPermiso("agregar");
                }
                funcRecuperar(true);
              },
              disabled: usuario.usuario !== row.prodUser.split("/+/")[0],
              Icon: Restore,
              color: "success",
              id: 3,
              ocultar: !row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Editar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("editar")) {
                  return alertaPermiso("editar");
                }
                funcionEdit();
              },
              disabled: false,
              Icon: Create,
              color: "secondary",
              id: 1,
              ocultar: row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Copiar Registro",
              texto: "",
              funcion: () => {
                setopen(true);
                changeObjetoActivoState(row);
              },
              disabled: false,
              Icon: CopyAll,
              color: "primary",
              id: 1,
              ocultar: row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "PDF",
              texto: "",
              funcion: () => {
                window.open(
                  `${serverphp}reportes/ordenticket.php?codigo=${row.prodId}&empresa=${row.prodEmpresa}`
                );
              },
              disabled: false,
              Icon: PictureAsPdf,
              color: "error",
              id: 2,
              ocultar: row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Eliminar",
              texto: "",
              funcion: () => {
                if (!tienePermiso("eliminar")) {
                  return alertaPermiso("eliminar");
                }
                setopenConfirmDialog(true);
              },
              disabled: false,
              Icon: DeleteForever,
              color: "error",
              id: 3,
              ocultar: true,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip:
                row.prodIdVenta === 0 ? "Convertir a Factura" : "Factura",
              texto:
                row.prodIdVenta === 0 ? (
                  <Close fontSize="small" color="error" />
                ) : (
                  <Check fontSize="small" color="primary" />
                ),
              funcion: async () => {
                try {
                  if (row.prodIdVenta === 0) {
                    const facturaData = await clienteAxios.post(
                      "/ventas/produccion",
                      {
                        ...row,
                      }
                    );
                    changeObjetoActivoStateFactura(facturaData.data.data);
                    setopenFactura(true);
                  } else {
                    const resFactura = await clienteAxios.get(
                      `/ventas/${row.prodIdVenta}`
                    );

                    seteditarFactura(true);

                    changeObjetoActivoStateFactura({
                      ...resFactura.data.data[0],
                    });
                    return setopenFactura(true);
                  }
                } catch (error) {
                  mostrarAlerta("Hubo un error", "error");
                }
              },
              disabled: false,
              Icon: Receipt,
              color: "secondary",
              id: 3,
              ocultar: row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
              badgeColor: "default",
            },
          ]}
        />

        <>
          <TableCell size="small" align="center" className={claseCelda}>
            {row.prodId}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {row.prodFechaEntrega}
          </TableCell>
          <TableCell size="small" align="left" className={claseCelda}>
            {row.prodPerDocumentoCliente + " " + row.prodPerNombreCliente}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {row.prodColaboradores
              .map((item) => {
                return item.perDocNumero + " - " + item.perNombre;
              })
              .join(", ")}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {row.prodFormaPago}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {row.prodCredito}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {row.prodAbono}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {row.prodValor}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {row.prodSaldo}
          </TableCell>
          <CollapseCell
            celComprimida={celComprimida}
            setcelComprimida={setcelComprimida}
            row={row}
            propiedad={"prodDetalle"}
            claseCelda={claseCelda}
          />

          <TableCell
            size="small"
            align="center"
            className={claseCelda}
            sx={{
              color:
                row.prodProceso === "ENTREGADO"
                  ? //rojo tomate
                    "green"
                  : row.prodProceso === "ANULADO"
                  ? //verde
                    "red"
                  : row.prodProceso === "EN PROCESO"
                  ? "orange"
                  : "#ffa726",
              fontWeight: "bold",
            }}
          >
            {row.prodProceso}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {row.prodUser.split("/+/")[0]}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {new Date(row.prodFecUpd).toLocaleString("es-ES", {
              weekday: "short",
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour: "2-digit",
            })}
          </TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
