import React, { memo, useContext, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { TableCell, TableRow } from "@mui/material";

import { useState } from "react";

// import ConfirmacionDialog from "../../Dialog/ConfirmacionDialog";

import { withRouter } from "react-router";
import ButtonsAction from "./ButtonsAction";
import AuthContext from "../../../../Context/Auth/AuthContext";
import ConfirmacionDialog from "../../../../components/Extra/ConfirmacionDialog";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  celda: {
    fontSize: ".7rem !important",
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const Row = (props) => {
  const {
    seteditar,
    arrayExport,
    setarrayExport,
    row,
    index,
    setopen,
    campoExport,
    changeObjetoActivoState,
    socket,
  } = props;

  const { usuario } = useContext(AuthContext);
  const classes = useRowStyles();

  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        tabla: "empresa",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        empUser: usuario.usuario,
        empEmpresa: usuario.rucempresa,
        empId: row.empId,
      });
    } catch (error) {}
  };
  const funcionEdit = () => {
    changeObjetoActivoState({
      ...row,
    });
    setopen(true);
    seteditar(true);
  };

  useEffect(() => {}, []);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo={`¿Esta seguro de eliminar este registro: ${row.empNombre}?`}
        contenido={`La empresa   ${row.empNombre}, una vez borrado no podra ser recuperado`}
        _id={row._id}
        funcion={handleEliminar}
      />

      <TableRow
        className={classes.root}
        selected={index % 2 === 0 ? true : false}
      >
        {/* boton */}
        <ButtonsAction
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          row={row}
          setopenConfirmDialog={setopenConfirmDialog}
          funcionEdit={funcionEdit}
          ocultarEditar={false}
          ocultarDelete={true}
          mostrarEstado={true}
          campoExport={campoExport}
          campoEstado="empEstado"
        />
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.empRuc}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="left"
          className={classes.celda}
        >
          {row.empNombre}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="left"
          className={classes.celda}
        >
          {row.empRepresentante}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="left"
          className={classes.celda}
        >
          {row.empComercial}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.empObligado}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="left"
          className={classes.celda}
        >
          {row.empDireccion}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.empTelefono}
        </TableCell>

        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.empIva}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.empIce}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
