import React, { memo, useContext } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";

import { Cancel, Check } from "@mui/icons-material";

import { useState } from "react";

import AuthContext from "../../../../Context/Auth/AuthContext";
import { replaceCaracteres, trimPropsItem } from "../../../../config/const";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
  celda: {
    fontSize: ".7rem !important",
  },
}));

const RowAgregar = (props) => {
  const { setagregar, socket } = props;
  const [item, setitem] = useState({
    bodNombre: "",
    bodDireccion: "",
    bodCelular: "",
    bodEmail: "",
    bodResponsable: "",
    bodEmpresa: "",
    bodEstado: "ACTIVO",
    bodUser: "",
  });
  const classes = useRowStyles();
  //gql eliminar user

  const { usuario } = useContext(AuthContext);
  const handleAgregar = async () => {
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          tabla: "bodega",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          bodUser: usuario.usuario,
          bodEmpresa: usuario.rucempresa,
        })
      );
    } catch (error) {
       
    }
  };
  const botonDisabled = () => {
    if (
      item.bodNombre === "" ||
      item.bodDireccion === "" ||
      item.bodCelular === "" ||
      item.bodEmail === ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell></TableCell> {/* boton */}
        <TableCell>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <IconButton
              className={classes.margin}
              onClick={() => {
                setagregar(false);
                setitem({
                  bodNombre: "",
                  bodDireccion: "",
                  bodCelular: "",
                  bodEmail: "",
                  bodResponsable: "",
                  bodEmpresa: "",
                  bodEstado: "ACTIVO",
                  bodUser: "",
                });
              }}
            >
              <Cancel fontSize="small" color="secondary" />
            </IconButton>
            <IconButton
              color={
                botonDisabled
                  ? botonDisabled()
                    ? "inherit"
                    : "secondary"
                  : "secondary"
              }
              disabled={botonDisabled()}
              className={classes.margin}
              onClick={() => {
                handleAgregar();

                setagregar(false);
                setitem({
                  bodNombre: "",
                  bodDireccion: "",
                  bodCelular: "",
                  bodEmail: "",
                  bodResponsable: "",
                  bodEmpresa: "",
                  bodEstado: "ACTIVO",
                  bodUser: "",
                });
              }}
            >
              <Check fontSize="small" />
            </IconButton>{" "}
            <FormControlLabel
              className={classes.margin}
              onChange={(e) => {
                setitem({
                  ...item,
                  bodEstado: e.target.checked ? "ACTIVA" : "INACTIVA",
                });
              }}
              control={
                <Checkbox
                  value="ACTIVO"
                  className={classes.margin}
                  checked={
                    item["bodEstado"] === "ACTIVA" ||
                    item["bodEstado"] === "ACTIVO"
                      ? true
                      : false
                  }
                  color="secondary"
                />
              }
            />
          </Box>
        </TableCell>
        {/* las demas columnas */}
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {" "}
          <TextField
            id=""
            value={item.bodNombre}
            onChange={(e) => {
              const res = replaceCaracteres(e.target.value);
              setitem({
                ...item,
                bodNombre: res,
              });
            }}
            helperText="Agregando"
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {" "}
          <TextField
            id=""
            value={item.bodDireccion}
            onChange={(e) => {
              const res = replaceCaracteres(e.target.value);
              setitem({
                ...item,
                bodDireccion: res,
              });
            }}
            helperText="Agregando"
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {" "}
          <TextField
            id=""
            value={item.bodCelular}
            onChange={(e) => {
              const res = replaceCaracteres(e.target.value);
              setitem({
                ...item,
                bodCelular: res,
              });
            }}
            helperText="Agregando"
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {" "}
          <TextField
            id=""
            value={item.bodEmail}
            onChange={(e) => {
              const res = replaceCaracteres(e.target.value);
              setitem({
                ...item,
                bodEmail: res,
              });
            }}
            helperText="Agregando"
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {" "}
          <TextField
            id=""
            value={item.bodResponsable}
            onChange={(e) => {
              const res = replaceCaracteres(e.target.value);
              setitem({
                ...item,
                bodResponsable: res,
              });
            }}
            helperText="Agregando"
            size="small"
            fullWidth
          />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregar);
