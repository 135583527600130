import React, { memo, useContext } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Typography,
  Table,
  TableBody,
} from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../../../components/Extra/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "./ButtonsAction";
import AuthContext from "../../../../Context/Auth/AuthContext";
import { tienePermisoRuta } from "../../../../config/validaciones";
import MenuContext from "../../../../Context/Menu/MenuContext";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import { permisoDenagado, trimPropsItem } from "../../../../config/const";
import { AddCircle } from "@mui/icons-material";
import RowItem from "./RowItems/RowItem";
import RowAgregarItem from "./RowItems/RowAgregarItem";
import TablaCabecera from "./TablaCabecera";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  hover: {
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  celda: {
    fontSize: ".7rem !important",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const Row = (props) => {
  const {
    arrayExport,
    setarrayExport,
    row,
    index,
    campoExport,
    puedeExportar,
    desdeOtroLugar,
    socket,
    tienePermiso,
    alertaPermiso,
  } = props;
  const [agregarItemLista, setagregarItemLista] = useState(false);
  const [expandir, setexpandir] = useState(false);
  const { usuario } = useContext(AuthContext);
  const { rows: rowsMenu } = useContext(MenuContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const [item, setitem] = useState(row);
  const [editar, seteditar] = useState(false);
  const classes = useRowStyles();

  const handleEliminar = async (_id) => {
    if (!tienePermiso("eliminar")) {
      return alertaPermiso("eliminar");
    }

    try {
      socket.current.emit("client:eliminarData", {
        nombre: usuario.nombre,
        tabla: "alimento",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        aliUser: usuario.usuario,
        aliEmpresa: usuario.rucempresa,
        aliId: row.aliId,
      });
    } catch (error) {}
  };

  const handleChange = (e) => {
    setitem({
      ...item,
      [e.target.name]: e.target.value,
    });
  };
  const funcionEdit = () => {
    if (!tienePermiso("editar")) {
      return alertaPermiso("editar");
    }
    if (
      !tienePermisoRuta(
        rowsMenu,
        props.location.pathname,
        usuario.rol,
        "editar"
      )
    ) {
      return mostrarAlerta(permisoDenagado, "error");
    }
    seteditar(true);
  };

  const funcCancel = () => {
    setitem(row);
    seteditar(false);
  };
  const funcSubmit = () => {
    try {
      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          tabla: "alimento",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          aliUser: `${row.aliUser.split(" ** ")[0]} ** ${usuario.usuario}`,
          aliEmpresa: usuario.rucempresa,
        })
      );
      seteditar(false);
    } catch (error) {}
  };
  const botonDisabled = () => {
    if (item.aliNombre === "") {
      return true;
    } else {
      return false;
    }
  };
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo={`¿Esta seguro de eliminar este registro?`}
        _id={row._id}
        funcion={handleEliminar}
      />
      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          row={row}
          setopenConfirmDialog={setopenConfirmDialog}
          funcionEdit={funcionEdit}
          ocultarEditar={false}
          ocultarDelete={false}
          mostrarEstado={false}
          campoExport={campoExport}
          editar={editar}
          campoEstado=""
          puedeExportar={puedeExportar}
          desdeOtroLugar={desdeOtroLugar}
          funcCancel={funcCancel}
          funcSubmit={funcSubmit}
          botonDisabled={botonDisabled}
          mostrarEspandir={true}
          setexpandir={setexpandir}
          expandir={expandir}
        />
        {editar ? (
          <>
            {" "}
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                value={item.aliNombre}
                name="aliNombre"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
          </>
        ) : (
          <>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {row.aliNombre}
            </TableCell>
          </>
        )}
      </TableRow>{" "}
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={21}>
          <Collapse in={expandir} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="body1" gutterBottom component="span">
                  Alimentos
                </Typography>
                <IconButton
                  size="small"
                  aria-label=""
                  color="secondary"
                  onClick={() => {
                    setagregarItemLista(true);
                  }}
                >
                  <AddCircle />
                </IconButton>
              </Box>
              <Table size="small" aria-label="purchases">
                <TablaCabecera
                  columns={[
                    {
                      id: "nombre",
                      align: "center",
                      minWidth: 50,
                      colSpan: 1,
                      label: "nombre",
                    },
                  ]}
                />
                <TableBody>
                  {agregarItemLista ? (
                    <RowAgregarItem
                      item={item}
                      setitem={setitem}
                      setagregarItemLista={setagregarItemLista}
                      socket={socket}
                    />
                  ) : null}
                  {item.aliTipo.map((consigna, index) => {
                    return (
                      <RowItem
                        consigna={consigna}
                        item={item}
                        setitem={setitem}
                        index={index}
                        key={index}
                        socket={socket}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
