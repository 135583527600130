import React, { memo, useContext } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";

import { Cancel, Check } from "@mui/icons-material";

import { useState } from "react";

import AuthContext from "../../../../Context/Auth/AuthContext";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";

import { replaceCaracteres, trimPropsItem } from "../../../../config/const";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const RowAgregar = (props) => {
  const { setagregar, llamadoDeOtroLugar, setopenMT, socket } = props;
  const [item, setitem] = useState({
    catNombre: "",
    catDetalle: "",
    catEstado: "ACTIVA",
    catTipo: "",
    catEmpresa: "",
    catUser: "admin",
    Todos: "",
  });

  const { mostrarAlerta } = useContext(AlertaContext);
  const classes = useRowStyles();
  //gql eliminar user

  const { usuario } = useContext(AuthContext);
  const handleAgregar = async () => {
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          tabla: "categoria",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          catUser: usuario.usuario,
          catEmpresa: usuario.rucempresa,
          llamadoDeOtroLugar,
        })
      );
      if (llamadoDeOtroLugar) {
        // changeGuardado(true);
        setopenMT(false);
      }
    } catch (error) {
      mostrarAlerta("Hubo un error", "error");
    }
  };
  const botonDisabled = () => {
    if (item.catNombre === "") {
      return true;
    } else {
      return false;
    }
  };
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell></TableCell> {/* boton */}
        <TableCell>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <IconButton
              className={classes.margin}
              onClick={() => {
                setagregar(false);
                setitem({
                  catNombre: "",
                  catDetalle: "",
                  catEstado: "ACTIVA",
                  catTipo: "",
                  catEmpresa: "",
                  catUser: "admin",
                  Todos: "",
                });
              }}
            >
              <Cancel fontSize="small" color="secondary" />
            </IconButton>
            <IconButton
              color={
                botonDisabled
                  ? botonDisabled()
                    ? "inherit"
                    : "secondary"
                  : "secondary"
              }
              disabled={botonDisabled()}
              className={classes.margin}
              onClick={() => {
                handleAgregar();

                setagregar(false);
                setitem({
                  catNombre: "",
                  catDetalle: "",
                  catEstado: "ACTIVA",
                  catTipo: "",
                  catEmpresa: "",
                  catUser: "admin",
                  Todos: "",
                });
              }}
            >
              <Check fontSize="small" />
            </IconButton>{" "}
            <FormControlLabel
              className={classes.margin}
              onChange={(e) => {
                setitem({
                  ...item,
                  catEstado: e.target.checked ? "ACTIVA" : "INACTIVA",
                });
              }}
              control={
                <Checkbox
                  value="ACTIVO"
                  className={classes.margin}
                  checked={
                    item["catEstado"] === "ACTIVA" ||
                    item["catEstado"] === "ACTIVO"
                      ? true
                      : false
                  }
                  color="secondary"
                />
              }
            />
          </Box>
        </TableCell>
        {/* las demas columnas */}
        <TableCell fontSize="small" padding="none">
          <TextField
            id=""
            value={item.catNombre}
            onChange={(e) => {
              const res = replaceCaracteres(e.target.value);
              setitem({
                ...item,
                catNombre: res,
              });
            }}
            helperText="Agregando"
            size="small"
            fullWidth
          />
        </TableCell>{" "}
        <TableCell fontSize="small" padding="none">
          <TextField
            id=""
            value={item.catTipo}
            onChange={(e) => {
              const res = replaceCaracteres(e.target.value);
              setitem({
                ...item,
                catTipo: res,
              });
            }}
            helperText="Agregando"
            size="small"
            fullWidth
            select
            InputLabelProps={{ shrink: true }}
            SelectProps={{ displayEmpty: true }}
          >
            <MenuItem value="">Seleccione Tipo</MenuItem>
            {["BIEN", "SERVICIO", "BIEN-CARNET"].map((item) => {
              return <MenuItem value={item}>{item} </MenuItem>;
            })}
          </TextField>{" "}
        </TableCell>{" "}
        <TableCell fontSize="small" padding="none">
          <TextField
            id=""
            value={item.catDetalle}
            onChange={(e) => {
              const res = replaceCaracteres(e.target.value);
              setitem({
                ...item,
                catDetalle: res,
              });
            }}
            helperText="Agregando"
            size="small"
            fullWidth
          />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregar);
