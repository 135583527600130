import React, { useState, memo } from "react";

import { withRouter } from "react-router-dom";

import Row from "./Row";

// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Box,
  Typography,
  Collapse,
} from "@mui/material";
import TablaCabecera from "../../../components/Extra/TablaCabecera";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
     maxHeight: 600,
  },
  margin: {
    margin: theme.spacing(1),

    width: "45%",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: theme.spacing(1),
      width: "95%",
    },
  },
}));

const permisos = [
  {
    id: "nombre",
    label: "nombre",
    minWidth: 150,
  },
  {
    id: "tutoriales",
    label: "Tutoriales",
    minWidth: 150,
  },
];
const TablaCategorias = (props) => {
  const { setopen, rows } = props;

  const [rol] = useState("ADMIN");
  const [expand, setOpen] = React.useState({});
  const handleClick = (e, a) => {
    setOpen({
      ...expand,
      [e]: !a,
    });
  };

  //clases
  const classes = useStyles();

  return (
    <>
      {" "}
      <Paper className={classes.root}>
        {/* <ModalAgregarAcreditacion
        haSidoAceptado={haSidoAceptado}
        sethaSidoAceptado={sethaSidoAceptado}
        expand={expand}
        setopen={setopen}
      /> */}

        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table" id="tabla" size="small">
            {/* <ModalCarnet setopen={setopen} onChangeCarnetActivo={onChangeCarnetActivo}/> */}
            <TableHead>
              <TablaCabecera columns={permisos} />
            </TableHead>{" "}
            <TableBody>
              <>
                {rows
                  .sort((a, b) => {
                    return a.orden - b.orden;
                  })
                  .map((categoria, indexCategoria) => {
                    // validacion categoria
                    if (categoria.sidInfo.tipo !== "categoria") {
                      return null;
                    }
                    return (
                      <>
                        <Row
                          tipo="categoria"
                          rol={rol}
                          key={categoria.id}
                          row={categoria}
                          index={indexCategoria}
                          setopen={setopen}
                          expandir={handleClick}
                          expand={expand}
                          expandible={true}
                        />{" "}
                        <TableRow>
                          <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                            colSpan={8}
                          >
                            <Collapse
                              in={expand[categoria.sidInfo.name]}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Box margin={1}>
                                <Typography
                                  variant="body1"
                                  gutterBottom
                                  component="div"
                                >
                                  SubCategoria: {categoria.sidInfo.name}
                                </Typography>{" "}
                                <Table size="small" aria-label="purchases">
                                  <TablaCabecera columns={permisos} />
                                  <TableBody>
                                    {rows
                                      .sort((a, b) => {
                                        return a.orden - b.orden;
                                      })
                                      .map(
                                        (subcategoria, indexSubCategoria) => {
                                          // validacion subcategiria
                                          if (
                                            subcategoria.sidInfo.tipo !==
                                            "subCategoria"
                                          ) {
                                            return null;
                                          }
                                          if (
                                            categoria.sidInfo.path !==
                                            subcategoria.sidInfo.categoria
                                          ) {
                                            return null;
                                          }
                                          //subctegoria expandible
                                          if (subcategoria.sidInfo.expandible) {
                                            return (
                                              <>
                                                <Row
                                                  tipo="subcategoria"
                                                  rol={rol}
                                                  key={indexSubCategoria}
                                                  row={subcategoria}
                                                  index={indexSubCategoria}
                                                  setopen={setopen}
                                                  expandir={handleClick}
                                                  expand={expand}
                                                />{" "}
                                                <TableRow>
                                                  <TableCell
                                                    style={{
                                                      paddingBottom: 0,
                                                      paddingTop: 0,
                                                    }}
                                                    colSpan={8}
                                                  >
                                                    <Collapse
                                                      in={
                                                        expand[
                                                          subcategoria.sidInfo
                                                            .name
                                                        ]
                                                      }
                                                      timeout="auto"
                                                      unmountOnExit
                                                    >
                                                      <Box margin={1}>
                                                        <Typography
                                                          variant="body1"
                                                          gutterBottom
                                                          component="div"
                                                        >
                                                          Sub - SubCategoria:{" "}
                                                          {
                                                            subcategoria.sidInfo
                                                              .name
                                                          }
                                                        </Typography>{" "}
                                                        <Table
                                                          size="small"
                                                          aria-label="purchases"
                                                        >
                                                          <TablaCabecera
                                                            columns={permisos}
                                                          />
                                                          <TableBody>
                                                            {" "}
                                                            {rows
                                                              .sort((a, b) => {
                                                                return (
                                                                  a.orden -
                                                                  b.orden
                                                                );
                                                              })
                                                              .map(
                                                                (
                                                                  subsubCategoria,
                                                                  indexSubCategoria
                                                                ) => {
                                                                  // validacion subcategiria
                                                                  if (
                                                                    subsubCategoria
                                                                      .sidInfo
                                                                      .tipo !==
                                                                    "subsubCategoria"
                                                                  ) {
                                                                    return null;
                                                                  }

                                                                  if (
                                                                    subsubCategoria
                                                                      .sidInfo
                                                                      .subcategoria !==
                                                                    subcategoria
                                                                      .sidInfo
                                                                      .path
                                                                  ) {
                                                                    return null;
                                                                  }

                                                                  return (
                                                                    <>
                                                                      <Row
                                                                        tipo="subsubCategoria"
                                                                        rol={
                                                                          rol
                                                                        }
                                                                        key={
                                                                          indexSubCategoria
                                                                        }
                                                                        row={
                                                                          subsubCategoria
                                                                        }
                                                                        index={
                                                                          indexSubCategoria
                                                                        }
                                                                        setopen={
                                                                          setopen
                                                                        }
                                                                        expandir={
                                                                          handleClick
                                                                        }
                                                                        expand={
                                                                          expand
                                                                        }
                                                                      />
                                                                    </>
                                                                  );
                                                                }
                                                              )}
                                                          </TableBody>
                                                        </Table>
                                                      </Box>
                                                    </Collapse>
                                                  </TableCell>
                                                </TableRow>
                                              </>
                                            );
                                          }

                                          //subcateoria no expandible
                                          return (
                                            <Row
                                              tipo="subcategoria"
                                              rol={rol}
                                              key={indexSubCategoria}
                                              row={subcategoria}
                                              index={indexSubCategoria}
                                              setopen={setopen}
                                              expandir={handleClick}
                                              expand={expand}
                                            />
                                          );
                                        }
                                      )}
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
              </>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};
export default memo(withRouter(TablaCategorias));
