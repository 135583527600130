import React, { memo, useContext } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  FormControl,
  Popper,
  Autocomplete,
} from "@mui/material";

import { Cancel, Check } from "@mui/icons-material";

import { useState } from "react";
import clienteAxios from "../../../../config/axios";
import AuthContext from "../../../../Context/Auth/AuthContext";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));
const RowAgregarBodega = (props) => {
  const { usuario } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const { setagregar, setitem, item } = props;
  const [productosData, setproductosData] = useState([]);
  const [itemRow, setItemRow] = useState({
    item: "",
    costo: "",
    cantidad: "",
    valor: "",
    descripcion: "",
    marca: "",
    observacion: "",
  });
  const botonDisabled = () => {
    if (
      itemRow.item === "" ||
      itemRow.costo === "" ||
      itemRow.cantidad === "" ||
      itemRow.valor === "" ||
      itemRow.descripcion === "" ||
      itemRow.marca === "" ||
      itemRow.observacion === ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  const PopperMy = function (props) {
    return (
      <Popper {...props} style={useRowStyles.popper} placement="bottom-start" />
    );
  };
  const classes = useRowStyles();
  //gql eliminar user

  const handleAgregar = async () => {
    try {
      const asitecEquiposInstalados = [
        itemRow,

        ...item.asitecEquiposInstalados,
      ];

      setitem({
        ...item,
        asitecEquiposInstalados,
      });
    } catch (error) {}
  };
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <IconButton
              className={classes.margin}
              onClick={() => {
                setagregar(false);
                setItemRow({
                  item: "",
                  costo: "",
                  cantidad: "",
                  valor: "",
                  descripcion: "",
                  marca: "",
                  observacion: "",
                });
              }}
            >
              <Cancel fontSize="small" color="secondary" />
            </IconButton>
            <IconButton
              className={classes.margin}
              disabled={botonDisabled()}
              color={botonDisabled() ? "secondary" : "inherit"}
              onClick={() => {
                handleAgregar();

                setagregar(false);
                setItemRow({
                  item: "",
                  costo: "",
                  cantidad: "",
                  valor: "",
                  descripcion: "",
                  marca: "",
                  observacion: "",
                });
              }}
            >
              <Check fontSize="small" />
            </IconButton>{" "}
            {/* <FormControlLabel
              className={classes.margin}
              onChange={(e) => {
                setitem({
                  ...item,
                  ["ëstado"]: e.target.checked ? "ACTIVA" : "INACTIVA",
                });
              }}
              control={
                <Checkbox
                  value="ACTIVA"
                  className={classes.margin}
                  checked={
                    item["ëstado"] === "ACTIVA" || item["ëstado"] === "ACTIVA"
                      ? true
                      : false
                  }
                  color="secondary"
                />
              }
            /> */}
          </Box>
        </TableCell>
        {/* las demas columnas */}

        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          <FormControl fullWidth>
            <Autocomplete
              PopperComponent={PopperMy}
              options={productosData.map((item) => {
                return (
                  item.invNombre +
                  "/+/" +
                  item.invSerie +
                  "/+/" +
                  item.invStock +
                  "/+/" +
                  item.invId
                );
              })}
              props
              getOptionLabel={(option) => {
                return option
                  .replace("/+/", " ** ")
                  .replace("/+/", " ** ")
                  .split("/+/")[0];
              }}
              id="controlled-demo"
              disableClearable={true}
              value={itemRow.item}
              onChange={async (event, newValue) => {
                if (newValue) {
                  const options = newValue.split("/+/");
                  const productoF = productosData.find(
                    (productoItem) => productoItem.invId === Number(options[3])
                  );

                  setItemRow({
                    ...itemRow,
                    item: productoF.invNombre,
                  });
                } else {
                  setItemRow({
                    ...itemRow,
                  });
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          fontSize: "1px",
                          maxHeight: 48 * 4.5 + 8,
                          width: 5000,
                        },
                      },
                    },
                  }}
                  style={{ fontSize: "2px" }}
                  required
                  fullWidth
                  onChange={async (e) => {
                    setItemRow({
                      ...itemRow,
                      descripcion: e.target.value,
                    });
                    try {
                      if (e.target.value.length < 3) {
                        return;
                      }
                      const res = await clienteAxios.get(
                        `/inventario/filtro/0?empresa=${usuario.rucempresa}&search=${e.target.value}`
                      );

                      // invDescripcion;

                      if (!res.data.data) {
                        return mostrarAlerta(res.data.msg, "error");
                      }

                      setproductosData(res.data.data);
                    } catch (error) {}
                  }}
                  helperText="Agregando"
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
            />
          </FormControl>
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          <TextField
            value={itemRow.costo}
            onChange={(e) =>
              setItemRow({
                ...itemRow,
                costo: e.target.value,
              })
            }
            size="small"
            fullWidth
            helperText="Agregando"
          />
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          <TextField
            value={itemRow.cantidad}
            onChange={(e) =>
              setItemRow({
                ...itemRow,
                cantidad: e.target.value,
              })
            }
            size="small"
            fullWidth
            helperText="Agregando"
          />
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          <TextField
            value={itemRow.valor}
            onChange={(e) =>
              setItemRow({
                ...itemRow,
                valor: e.target.value,
              })
            }
            size="small"
            fullWidth
            helperText="Agregando"
          />
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          <TextField
            value={itemRow.descripcion}
            onChange={(e) =>
              setItemRow({
                ...itemRow,
                descripcion: e.target.value,
              })
            }
            size="small"
            fullWidth
            helperText="Agregando"
          />
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          <TextField
            value={itemRow.marca}
            onChange={(e) =>
              setItemRow({
                ...itemRow,
                marca: e.target.value,
              })
            }
            size="small"
            fullWidth
            helperText="Agregando"
          />
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          <TextField
            value={itemRow.observacion}
            onChange={(e) =>
              setItemRow({
                ...itemRow,
                observacion: e.target.value,
              })
            }
            size="small"
            fullWidth
            helperText="Agregando"
          />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregarBodega);
