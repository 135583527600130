import { makeStyles } from "@mui/styles";
import {
  Badge,
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  TableCell,
  Tooltip,
} from "@mui/material";
import {
  Cancel,
  Check,
  Close,
  CopyAll,
  Create,
  Delete,
  Email,
  PictureAsPdf,
  Receipt,
  Restore,
  WhatsApp,
} from "@mui/icons-material";
import React, { memo, useContext } from "react";
import { withRouter } from "react-router";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import { tienePermisoRuta } from "../../../../config/validaciones";
import AuthContext from "../../../../Context/Auth/AuthContext";
import MenuContext from "../../../../Context/Menu/MenuContext";
import { permisoDenagado, serverphpvet } from "../../../../config/const";
import { ModalContactoPlantillaW } from "../../../components/Modal/ModalContactoPlantillaW";
import { useState } from "react";
import clienteAxios from "../../../../config/axios";
import ModalFactura from "../../../Ventas/Facturas/Componentes/Modal";
import Categorias from "../../../Configuración/Categorias/Categorias";
import Subcategorias from "../../../Configuración/Subcategorias/Subcategorias";

import ModalInventario from "../../../Configuración/Inventario/Componentes/Modal";
import ModalProveedor from "../../../Compras/Proveedores/Personas_Contable/Componentes/Modal";
import ModalTraido from "../../../Produccion/ModalTraido";
import { v4 } from "uuid";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const ButtonsAction = (props) => {
  const {
    arrayExport,
    botonDisabled,
    campoEstado,
    campoExport,
    desdeOtroLugar,
    editar,
    eliminadoEstado,
    funcCancel,
    funcionCopiar,
    funcionEdit,
    funcSubmit,
    mostrarEstado,
    ocultarDelete,
    ocultarEditar,
    puedeExportar,
    row,
    setarrayExport,
    setopenConfirmDialog,
    setopenModalEmail,
    socket,
  } = props;
  const classes = useRowStyles();
  const { usuario } = useContext(AuthContext);
  const { rows } = useContext(MenuContext);
  const { mostrarAlerta } = useContext(AlertaContext);

  const [
    openModalContactoPlantillaW,
    setopenModalContactoPlantillaW,
  ] = useState(false);
  const contactoDefault = {
    medioComunicacion: "Whatsapp",
    option: "Hospitalizacion",
    idRegistro: row.hospId,
    contacto: [],
    contenidoMensaje: "",
  };
  const [newContacto, setNewContacto] = useState(contactoDefault);

  // sub-categoria
  const [openSubCategoria, setopenSubCategoria] = useState(false);
  // categoria
  const [openCategoria, setopenCategoria] = useState(false);
  // Inventario
  const [OpenProveedorModalI, setOpenProveedorModalI] = useState(false);
  const [openInventario, setopenInventario] = useState(false);
  // Proveedor
  const [editarInventario, seteditarInventario] = useState(false);
  const [OpenProveedor, setOpenProveedor] = useState(false);
  const [editarProveedor, seteditarProveedor] = useState(false);
  // FACTURA
  const [openFactura, setopenFactura] = useState(false);
  const [editarFactura, seteditarFactura] = useState(false);
  // INVENTARIO
  const [ObjectoActivoInventario, setObjetoActivoInventario] = useState({
    invSerie: "0",
    invTipo: "",
    invNombre: "",
    invDescripcion: "",
    invCosto1: "",
    invCosto2: "",
    invCosto3: "",
    invStock: "",
    invIva: "SI",
    invIncluyeIva: "SI",
    invEstado: "ACTIVA",
    invBodegas: [],
    invprecios: [],
    invProveedor: "",
    invCategoria: "",
    invSubCategoria: "",
    invUnidad: "",
    invMarca: "",
    invSubsidio: "0",
    invPlanCuenta: "",
    invEmpresa: "",
    invUser: "admin",
    invFecReg: "",
    Todos: "",
  });
  const changeObjetoActivoStateInventario = (item) => {
    setObjetoActivoInventario(item);
    // dispatch({
    //   type: CHANGE_ACTIVO,
    //   payload: item,
    // });
  };

  // Proveedor
  const [ObjectoActivoProveedor, setObjetoActivoProveedor] = useState({
    perISPContratos: [],
    perNombreComercial: "",
    perEmpresa: [],
    perPais: "",
    perProvincia: "",
    perCanton: "",
    perTipoProveedor: "BIENES",
    perTiempoCredito: "0",
    perDocTipo: "",
    perDocNumero: "",
    perPerfil: [],
    perNombre: "",
    perDireccion: "",
    perObligado: "NO",
    perCredito: "NO",
    perTelefono: "0",
    perCelular: ["+593"],
    perEstado: "ACTIVO",
    perObsevacion: "",
    perEmail: ["sin@sincorreo.com"],
    perOtros: ["ZZZ9999"],
    perNickname: "",
    perUser: "",
    perFecReg: "",
    perFoto: "",
    perFecContrato: "",
    perFecInstalacion: "",
    perDiaCobro: "1",
    perTipoPlan: "",
    perNombrePlan: "",
    perValorPlan: "",
    perServicioAdicional: "0",
    perDescuentos: "0",
    perFacturaAutomatica: "NO",
    perVendedor1: {
      perId: "",
      perDocNumero: "",
      perNombre: "",
    },
    perVendedor2: {
      perId: "",
      perDocNumero: "",
      perNombre: "",
    },
    perObservacionIsp: "",
    perTipoDocumento: "NOTA",
    perTecnico1: {
      perId: "",
      perDocNumero: "",
      perNombre: "",
    },
    perTecnico2: {
      perId: "",
      perDocNumero: "",
      perNombre: "",
    },
    perMegas: "",
    perUbicacion: {
      longitud: "",
      latitud: "",
    },
    perDocumento: "",
    perGenero: "M",
    perRecomendacion: "OTROS",
    perFecNacimiento: "",
    perEspecialidad: "",
    perTitulo: "",
    perSenescyt: "",
    perPersonal: "INTERNO",
  });
  const changeObjetoActivoStateProveedor = (item) => {
    setObjetoActivoProveedor(item);
    // dispatch({
    //   type: CHANGE_ACTIVO,
    //   payload: item,
    // });
  };
  // FACTURA DATA
  const [ObjectoActivoFactura, setObjetoActivoFactura] = useState({
    venEmpRegimen: "",
    venProductosAntiguos: [],
    optionDocumento: "",
    venTotalRetencion: "0.00",
    venOption: "1",
    venTipoDocumento: "N",
    venIdCliente: "1",
    venRucCliente: "9999999999999",
    venTipoDocuCliente: "RUC",
    venNomCliente: "CONSUMIDOR FINAL",
    venEmailCliente: ["sin@sincorreo.com"],
    venTelfCliente: "0000000001",
    venCeluCliente: "0000000001",
    venDirCliente: "s/n",
    venEmpRuc: "",
    venEmpNombre: "",
    venEmpComercial: "",
    venEmpDireccion: "",
    venEmpTelefono: "",
    venEmpEmail: "",
    venEmpObligado: "",
    venFormaPago: "TRANSFERENCIA",
    venNumero: "0",
    venFacturaCredito: "NO",
    venDias: "0",
    venAbono: "0",
    venDescPorcentaje: "0",
    venOtrosDetalles: "",
    venObservacion: "",
    venSubTotal12: "",
    venSubtotal0: "",
    venDescuento: "",
    venSubTotal: "",
    venTotalIva: "",
    venTotal: "0.00",
    venCostoProduccion: "",
    venUser: "",
    venFechaFactura: `${new Date().getFullYear()}-${(
      "0" +
      (new Date().getMonth() + 1)
    ).slice(-2)}-${("0" + new Date().getDate()).slice(-2)}`,
    venNumFactura: "",
    venNumFacturaAnterior: "",
    venAutorizacion: "0",
    venFechaAutorizacion: "",
    venErrorAutorizacion: "NO",
    venEstado: "ACTIVA",
    venEnvio: "NO",
    fechaSustentoFactura: "",
    venEmpresa: "",
    venProductos: [],
  });
  const changeObjetoActivoStateFactura = (item) => {
    setObjetoActivoFactura(item);
    // dispatch({
    //   type: CHANGE_ACTIVO,
    //   payload: item,
    // });
  };

  return (
    <>
      <ModalTraido
        open={openCategoria}
        setopen={setopenCategoria}
        titulo="Categoría"
        Componente={Categorias}
        socket={socket}
      />
      <ModalTraido
        open={openSubCategoria}
        setopen={setopenSubCategoria}
        titulo="Sub Categoría"
        Componente={Subcategorias}
        socket={socket}
      />
      <ModalProveedor
        estadoRequest={"PROVEEDOR"}
        open={OpenProveedorModalI}
        setopen={setOpenProveedorModalI}
        editar={editarProveedor}
        seteditar={seteditarProveedor}
        socket={socket}
        ObjectoActivo={ObjectoActivoProveedor}
        changeObjetoActivoState={changeObjetoActivoStateProveedor}
      />
      <ModalProveedor
        estadoRequest={"CLIENTE"}
        open={OpenProveedor}
        setopen={setOpenProveedor}
        editar={editarProveedor}
        seteditar={seteditarProveedor}
        socket={socket}
        ObjectoActivo={ObjectoActivoProveedor}
        changeObjetoActivoState={changeObjetoActivoStateProveedor}
      />
      {socket.current ? (
        <ModalInventario
          open={openInventario}
          setopen={setopenInventario}
          editar={editarInventario}
          seteditar={seteditarInventario}
          setopenSubCategoria={setopenSubCategoria}
          setopenCategoria={setopenCategoria}
          setOpenProveedor={setOpenProveedorModalI}
          ObjectoActivo={ObjectoActivoInventario}
          changeObjetoActivoState={changeObjetoActivoStateInventario}
          socket={socket}
          seteditarProveedor={seteditarProveedor}
          changeObjetoActivoStateProveedor={changeObjetoActivoStateProveedor}
        />
      ) : null}
      {socket.current ? (
        <ModalFactura
          estadoRequest={"FACTURAS"}
          editar={editarFactura}
          seteditar={seteditarFactura}
          setopen={setopenFactura}
          open={openFactura}
          setopenInventario={setopenInventario}
          setOpenProveedor={setOpenProveedor}
          ObjectoActivo={ObjectoActivoFactura}
          changeObjetoActivoState={changeObjetoActivoStateFactura}
          socket={socket}
          desdePeluqueria={false}
        />
      ) : null}
      {openModalContactoPlantillaW && (
        <ModalContactoPlantillaW
          personaId={row.hospPerIdPropietario}
          encabezado={[
            {
              label: "Documento Propietario:",
              value: row.hospPerDocNumeroPropietario,
            },
            {
              label: "Propietario:",
              value: row.hospPerNombrePropietario,
            },
            {
              label: "Paciente:",
              value: row.hospMascNombre,
            },
            {
              label: "#Carnet:",
              value: row.hospMascId,
            },
          ]}
          row={row}
          open={openModalContactoPlantillaW}
          setopen={setopenModalContactoPlantillaW}
          newContacto={newContacto}
          setNewContacto={setNewContacto}
          contactoDefault={contactoDefault}
          objetoPlantilla={"empPlantillaHospitalizacion"}
        />
      )}
      {arrayExport ? (
        <TableCell
          size="small"
          padding="checkbox"
          style={{
            borderRight: "1px solid #ccc",
            maxWidth: "1rem",
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="center">
            <FormControlLabel
              className={classes.margin}
              control={
                <Checkbox
                  size="small"
                  disabled={!puedeExportar && desdeOtroLugar}
                  className={classes.margin}
                  checked={arrayExport.some((item) => {
                    return item[campoExport] === row[campoExport];
                  })}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setarrayExport([
                        ...arrayExport,
                        { [campoExport]: row[campoExport] },
                      ]);
                    } else {
                      setarrayExport(
                        arrayExport.filter(
                          (item) => item[campoExport] !== row[campoExport]
                        )
                      );
                    }
                  }}
                  color="secondary"
                />
              }
            />
          </Box>
        </TableCell>
      ) : null}
      <TableCell padding="none" style={{ borderRight: "1px solid #ccc" }}>
        <Box display="flex" alignItems="center">
          {eliminadoEstado ? (
            <>
              <Tooltip title="Recuperar" arrow>
                <IconButton
                  className={classes.margin}
                  onClick={() => {
                    if (
                      !tienePermisoRuta(
                        rows,
                        props.location.pathname,
                        usuario.rol,
                        "editar"
                      )
                    ) {
                      return mostrarAlerta(permisoDenagado, "error");
                    }
                    funcionEdit();
                  }}
                >
                  <Restore color="success" />
                </IconButton>
              </Tooltip>
              <Tooltip title="PDF" arrow>
                <IconButton
                  className={classes.margin}
                  onClick={() => {
                    // Abres la nueva ventas
                    window.open(
                      `${serverphpvet}reportes/hospitalizacion.php?id=${row.hospId}&empresa=${row.hospEmpresa}`,
                      "_blank"
                    );
                  }}
                >
                  <PictureAsPdf color="error" />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <>
              <>
                {editar ? (
                  <>
                    <IconButton
                      className={classes.margin}
                      onClick={() => {
                        funcCancel();
                      }}
                    >
                      <Cancel fontSize="small" color="secondary" />
                    </IconButton>
                    <IconButton
                      className={classes.margin}
                      disabled={botonDisabled ? botonDisabled() : false}
                      onClick={() => {
                        funcSubmit();
                      }}
                    >
                      <Check
                        fontSize="small"
                        color={
                          botonDisabled
                            ? botonDisabled()
                              ? "inherit"
                              : "secondary"
                            : "secondary"
                        }
                      />
                    </IconButton>
                  </>
                ) : (
                  <>
                    {ocultarDelete ? null : (
                      <IconButton
                        className={classes.margin}
                        onClick={() => {
                          if (
                            !tienePermisoRuta(
                              rows,
                              props.location.pathname,
                              usuario.rol,
                              "eliminar"
                            )
                          ) {
                            return mostrarAlerta(permisoDenagado, "error");
                          }
                          setopenConfirmDialog(true);
                        }}
                      >
                        <Delete fontSize="small" color="error" />
                      </IconButton>
                    )}
                    {ocultarEditar ? null : (
                      <IconButton
                        className={classes.margin}
                        onClick={() => {
                          if (
                            !tienePermisoRuta(
                              rows,
                              props.location.pathname,
                              usuario.rol,
                              "editar"
                            )
                          ) {
                            return mostrarAlerta(permisoDenagado, "error");
                          }
                          funcionEdit();
                        }}
                      >
                        <Create color="secondary" />
                      </IconButton>
                    )}
                    <Tooltip title="Copiar" arrow>
                      <IconButton
                        className={classes.margin}
                        onClick={() => {
                          funcionCopiar();
                        }}
                      >
                        <CopyAll color="primary" />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </>
              <Tooltip title="PDF" arrow>
                <IconButton
                  className={classes.margin}
                  onClick={() => {
                    // Abres la nueva ventas
                    window.open(
                      `${serverphpvet}reportes/hospitalizacion.php?id=${row.hospId}&empresa=${row.hospEmpresa}`,
                      "_blank"
                    );
                  }}
                >
                  <PictureAsPdf color="error" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Enviar Correos" arrow>
                <IconButton
                  className={classes.margin}
                  onClick={() => {
                    setopenModalEmail(true);
                  }}
                >
                  <Email color="secondary" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Mensaje whatsapp" arrow>
                <IconButton
                  className={classes.margin}
                  onClick={() => {
                    setopenModalContactoPlantillaW(true);
                  }}
                >
                  <WhatsApp color="success" />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={
                  row.hospIdVenta === 0 ? "Convertir a Factura" : "Factura"
                }
              >
                <Badge
                  badgeContent={
                    row.hospIdVenta === 0 ? (
                      <Close fontSize="small" color="error" />
                    ) : (
                      <Check fontSize="small" color="primary" />
                    )
                  }
                >
                  <IconButton
                    className={classes.margin}
                    color="secondary"
                    onClick={async () => {
                      try {
                        if (row.hospIdVenta === 0) {
                          if (
                            row.hospMedicamentos.medicamentosCabecera.length ===
                            0
                          ) {
                            return mostrarAlerta(
                              "No hay productos en esta hospitalización",
                              "error"
                            );
                          }
                          const listaProductos = row.hospMedicamentos.medicamentosCabecera.map(
                            (item) => ({
                              serie: item?.serie || v4().split('-')[0],
                              nombre: item.medicina,
                              llevaIva: item?.llevaIva || "NO",
                              incluyeIva: item?.incluyeIva || "NO", // NO
                              precio: item?.precio || 0, // 0
                              cantidad: item?.cantidad || 0, // input
                            })
                          );
                          console.log({
                            listaProductos,
                            idCliente: row.hospPerIdPropietario,
                            hospId: row.hospId,
                          });

                          const facturaData = await clienteAxios.post(
                            "ventas/hospitalizacion",
                            {
                              listaProductos,
                              idCliente: row.hospPerIdPropietario,
                              hospId: row.hospId,
                            }
                          );
                          changeObjetoActivoStateFactura({
                            ...facturaData.data.data,
                          });
                          setopenFactura(true);
                        } else {
                          const resFactura = await clienteAxios.get(
                            `/ventas/${row.hospIdVenta}`
                          );
                          console.log({ resFactura });
                          seteditarFactura(true);

                          changeObjetoActivoStateFactura({
                            ...resFactura.data.data[0],
                          });
                          return setopenFactura(true);
                        }
                      } catch (error) {
                        console.log({ error });
                        mostrarAlerta("Hubo un error", "error");
                      }
                    }}
                  >
                    <Receipt />
                  </IconButton>
                </Badge>
              </Tooltip>

              {mostrarEstado ? (
                <FormControlLabel
                  disabled
                  className={classes.margin}
                  control={
                    <Checkbox
                      value="ACTIVO"
                      className={classes.margin}
                      checked={
                        row[campoEstado] === "ACTIVA" ||
                        row[campoEstado] === "ACTIVO"
                          ? true
                          : false
                      }
                      color="primary"
                    />
                  }
                />
              ) : null}
            </>
          )}
        </Box>
      </TableCell>
    </>
  );
};

export default withRouter(memo(ButtonsAction));
