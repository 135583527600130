import React, { useContext } from "react";

// COMPONENTES
import logoNeitor from "../../../assets/img/logo.png";

// CONTEXT
import AuthContext from "../../../Context/Auth/AuthContext";
// METERIAL
import { IconButton, Toolbar, Typography, Box } from "@mui/material";

import { Menu } from "@mui/icons-material";
import NotificactionsButtons from "./NotificactionsButtons";
import { withRouter } from "react-router-dom";
import themeF from "../../../config/themeUser";
import { styled, alpha } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
const NavbarDesktop = (props) => {
  const {
    handleDrawerClose,
    handleDrawerOpen,
    open,
    setbuscador,
    buscador,
  } = props;
  const { usuario } = useContext(AuthContext);
  const theme = themeF(usuario);
  return (
    <Toolbar
      sx={{
        background: `linear-gradient(to left, ${theme.palette.primary.main} 0%, ${theme.palette.primary.light} 20%, ${theme.palette.secondary.light} 40%,  ${theme.palette.secondary.main} 75%, ${theme.palette.secondary.dark} 100%)`,
      }}
    >
      <IconButton
        color="primary"
        aria-label="open drawer"
        edge="start"
        onClick={() => (open ? handleDrawerClose() : handleDrawerOpen())}
        sx={{ mr: 2, color: "white" }}
      >
        <Menu />
      </IconButton>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Box display="flex" justifyContent={"center"} alignItems="center">
          <img
            width={"60"}
            src={logoNeitor}
            alt="Logo Empresa"
            onClick={() => {
              props.history.push("/Contable/Dashboard");
            }}
          />
          {/* <img
            width={"125"}
            src={usuario.logo}
            alt="Logo Empresa"
            onClick={() => {
              props.history.push("/Contable/Dashboard");
            }}
          /> */}
          <Typography
            variant="caption"
            sx={{
              fontWeight: "bold",
              fontSize: "1.3rem",
              fontStyle: "italic",
              ml: 1,
            }}
          >
            {usuario.nomComercial}
          </Typography>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              value={buscador}
              onChange={(e) => setbuscador(e.target.value)}
            />
          </Search>
        </Box>
        <Box display="flex" justifyContent={"center"} alignItems="center">
          <NotificactionsButtons />
        </Box>
      </Box>
    </Toolbar>
  );
};

export default withRouter(NavbarDesktop);
