import React, { memo } from "react";
import { Modal, Box, Divider, Typography } from "@mui/material";
import Draggable from "react-draggable";
import { Cancel } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useModalStyle } from "../../../styles/styleModal";
const getModalStyle = {
  borderRadius: "15px",
  position: "absolute",
  overflow: "scroll",
  maxHeight: "95vh",
};
const ModalLayout = ({ open, defaultActive, titulo, children,id }) => {
  const clases = useModalStyle();

  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle={`#${id}`}>
          <Box display="flex" justifyContent="center">
            <div style={getModalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  style={{ cursor: "-webkit-grab" }}
                  id={id}
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {titulo}
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              {children}
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalLayout);
