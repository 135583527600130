import { FechaExacta } from "../../config/const";

export const columns = [
  {
    id: "prodId",
    label: "#Ord",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "prodId",
    align: "left",
    ocultar: true,
  },
  {
    id: "prodFechaEntrega",
    label: "F. Entrega",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "prodFechaEntrega",
    align: "center",
    ocultar: true,
  },
  {
    id: "prodPerDocumentoCliente",
    label: "Cliente",
    minWidth: 250,
    tipo: "string",
    orden: 1,
    propiedad: "prodPerDocumentoCliente",
    align: "left",
    ocultar: true,
  },
  {
    id: "prodColaboradores",
    label: "Colaborador",
    minWidth: 200,
    tipo: "string",
    orden: 1,
    propiedad: "prodColaboradores",
    align: "center",
    ocultar: true,
  },
  {
    id: "prodFormaPago",
    label: "F. Pago",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "prodFormaPago",
    align: "center",
    ocultar: true,
  },
  {
    id: "prodCredito",
    label: "Credito",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "prodCredito",
    align: "center",
    ocultar: true,
  },
  {
    id: "prodAbono",
    label: "Abono",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "prodAbono",
    align: "center",
    ocultar: true,
  },
  {
    id: "prodValor",
    label: "Valor",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "prodValor",
    align: "center",
    ocultar: true,
  },
  {
    id: "prodSaldo",
    label: "Saldo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "prodSaldo",
    align: "center",
    ocultar: true,
  },
  {
    id: "prodDetalle",
    label: "Detalle",
    minWidth: 200,
    tipo: "string",
    orden: 1,
    propiedad: "prodDetalle",
    align: "center",
    ocultar: true,
  },

  {
    id: "prodProceso",
    label: "Proceso",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "prodProceso",
    align: "center",
    ocultar: true,
  },
  {
    id: "prodUser",
    label: "User",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "prodUser",
    align: "center",
    ocultar: true,
  },
  {
    id: "prodFecUpd",
    label: "F. Upd.",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "prodFecUpd",
    align: "center",
    ocultar: true,
  },
];
export const validacion = (item, setitem, seterror) => {
  const {
    prodPerDocumentoCliente,
    prodColaboradores,
    prodFormaPago,
    prodAbono,
    prodDetalle,
    prodEstado,
    prodProceso,
    prodFechaEntrega,
    prodProductos,
  } = item;
  const validacionProductos =
    prodProductos.length === 0 ||
    prodProductos.some((itemProducto) => validacionHijo(itemProducto));
  if (
    prodPerDocumentoCliente.trim() === "" ||
    prodColaboradores.length === 0 ||
    prodFormaPago.trim() === "" ||
    prodAbono === "" ||
    prodDetalle.trim() === "" ||
    prodEstado.trim() === "" ||
    prodProceso.trim() === "" ||
    prodFechaEntrega.trim() === "" ||
    validacionProductos
  ) {
    if (validacionProductos) {
      setitem((prev) => ({
        ...prev,
        prodProductos: prodProductos.map((itemProducto) => {
          const error = validacionHijo(itemProducto);
          return { ...itemProducto, error };
        }),
      }));
    }
    seterror({
      // PRIMER TAB
      prodPerDocumentoCliente: prodPerDocumentoCliente.trim() === "",
      prodColaboradores: prodColaboradores.length === 0,
      prodFormaPago: prodFormaPago.trim() === "",
      prodAbono: prodAbono === "",
      prodDetalle: prodDetalle.trim() === "",
      prodEstado: prodEstado.trim() === "",
      prodProceso: prodProceso.trim() === "",
      prodFechaEntrega: prodFechaEntrega.trim() === "",
      // SEGUNDO TAB
      prodProductos: validacionProductos,
    });
    return true;
  }
};
export const objectDefault = {
  // prodId: 1,
  prodPerIdCliente: "", //onsumir endpoint cliente igual que factura (obtener valor perId)
  prodPerDocumentoCliente: "", //interno
  prodPerNombreCliente: "", //interno
  prodColaboradores: [],
  prodFormaPago: "EFECTIVO", //["EFECTIVO", "CHEQUE", "TRANSFERENCIA", 'DEPOSITO', "CRUCE"],
  prodCredito: "SI", //interno
  prodAbono: "0", //input
  prodValor: "0", //interno
  prodSaldo: "0", //interno
  prodDetalle: "", // textarea
  prodEstado: "PENDIENTE", // [PENDIENTE, CANCELADO]
  prodProceso: "EN PROCESO", // [PENDIENTE, ENTREGADO, EN PROCESO]
  prodUser: "", //login
  prodEmpresa: "", //login
  prodFecReg: "", //interno
  prodFecUpd: "", //interno
  prodFechaEntrega: FechaExacta, //input
  prodProductos: [], // tabla similar de form factura,
  prodIdVenta: 0,
};

export const columnsProductos = [
  {
    id: "equipo",
    label: "equipo",
    minWidth: 150,
    tipo: "string",
    propiedad: "equipo",
    align: "center",
  },
  {
    id: "detalle",
    label: "detalle",
    minWidth: 200,
    tipo: "string",
    propiedad: "detalle",
    align: "center",
  },
  {
    id: "llevaIva",
    label: "lleva Iva",
    minWidth: 200,
    tipo: "string",
    propiedad: "llevaIva",
    align: "center",
  },
  {
    id: "incluyeIva",
    label: "incluye Iva",
    minWidth: 200,
    tipo: "string",
    propiedad: "incluyeIva",
    align: "center",
  },
  {
    id: "cantidad",
    label: "cantidad",
    minWidth: 20,
    tipo: "string",
    propiedad: "cantidad",
    align: "center",
  },
  {
    id: "valor",
    label: "valor",
    minWidth: 20,
    tipo: "string",
    propiedad: "valor",
    align: "center",
  },
  {
    id: "total",
    label: "total",
    minWidth: 20,
    tipo: "string",
    propiedad: "total",
    align: "center",
  },
];
// DESHABILITAR BOTON
export const validacionHijo = (itemHijo) => {
  return (
    itemHijo.detalle.trim() === "" ||
    itemHijo.cantidad.trim() === "" ||
    itemHijo.valor.trim() === ""
  );
};
export const objectDefaultProducto = {
  order: 1,
  id: "",
  equipo: [],
  detalle: "",
  cantidad: "1",
  valor: "0",
  total: "0",
  llevaIva: "NO",
  incluyeIva: "NO",
};
