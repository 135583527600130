export const columns = [
  {
    id: "mensNombre",
    label: "Nombre",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "mensNombre",
    align: "center",
    ocultar: true,
  },
  {
    id: "mensRecReg",
    label: "Fec. Reg",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "mensRecReg",
    align: "center",
    ocultar: true,
  },
  {
    id: "mensUser",
    label: "User",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "mensUser",
    align: "center",
    ocultar: true,
  },
];

export const objectDefault = {
  // mensId: 1,
  mensNombre: "",
  mensMensaje: "",
  mensUser: "",
  mensEmpresa: "",
  mensRecReg: "",
  mensPerIds: [],
  mensYearMonth: "",
  mensIncluyePropiedadSaldo: 0,
  mensPlantilla: {
    plantilla: "",
    variables: [],
  },
};
