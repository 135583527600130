/*eslint-disable*/

import React, { useState, memo } from "react";
// MODULOS
import { withRouter } from "react-router-dom";

// MATERIAL
import { Typography, Box, IconButton, Tooltip, Badge } from "@mui/material";
import Soporte from "./Soporte";
import { Chat, Help } from "@mui/icons-material";

const Footer = (props) => {
  const [open, setopen] = useState(false);

  return (
    <>
      <Soporte setopen={setopen} open={open} />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={1}
      >
        <Box>
          <Tooltip title="Soporte">
            <IconButton
              onClick={() => setopen(true)}
              variant="text"
              color="secondary"
            >
              <Help />
            </IconButton>
          </Tooltip>
          <Badge badgeContent={1} color="primary">
            <IconButton
              onClick={() => setopen(true)}
              variant="text"
              color="secondary"
            >
              <Chat />
            </IconButton>
          </Badge>
        </Box>
        <Typography
          variant="subtitle2"
          color="primary"
          component={"a"}
          href="https://neitor.com/"
          target="_blank"
        >
          &copy; {new Date().getFullYear()} || IMPULSADO POR NEITOR
        </Typography>
      </Box>
    </>
  );
};

export default memo(withRouter(Footer));
