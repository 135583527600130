import React, { memo, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Email, WhatsApp } from "@mui/icons-material";
import {
  Box,
  Avatar,
  List,
  ListItemAvatar,
  ListItemText,
  DialogTitle,
  Dialog,
  ListItemButton,
} from "@mui/material";
import { withRouter } from "react-router-dom";
import { ModalContacto } from "./ModalContacto";
import { ModalContactoPlantillaW } from "../../../components/Modal/ModalContactoPlantillaW";
const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  avatar2: {
    backgroundColor: theme.palette.secondary.main,
  },
  delete: {
    backgroundColor: theme.palette.error.main,
  },
  bg: {
    backgroundColor: theme.palette.primary.dark,
  },
  success: {
    backgroundColor: theme.palette.success.dark,
  },
  info: {
    backgroundColor: theme.palette.info.dark,
  },
}));

const ExportDialog = (props) => {
  const classes = useStyles();
  const { open, setopen, row } = props;
  const [openModalContacto, setopenModalContacto] = useState(false);
  const [
    openModalContactoPlantillaW,
    setopenModalContactoPlantillaW,
  ] = useState(false);
  const contactoDefault = {
    medioComunicacion: "Whatsapp",
    option: "Venta",
    idRegistro: row.venId,
    contacto: [],
    contenidoMensaje: "",
  };
  const [newContacto, setNewContacto] = useState(contactoDefault);
  return (
    <Dialog
      onClose={() => setopen(false)}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      {openModalContacto && (
        <ModalContacto
          row={row}
          open={openModalContacto}
          setopen={setopenModalContacto}
          newContacto={newContacto}
          setNewContacto={setNewContacto}
          contactoDefault={contactoDefault}
        />
      )}
      {openModalContactoPlantillaW && (
        <ModalContactoPlantillaW
          encabezado={[
            { label: "Ruc Cliente:", value: row.venRucCliente },
            {
              label: "Cliente:",
              value: row.venNomCliente,
            },
          ]}
          row={row}
          open={openModalContactoPlantillaW}
          setopen={setopenModalContactoPlantillaW}
          newContacto={newContacto}
          setNewContacto={setNewContacto}
          contactoDefault={contactoDefault}
          objetoPlantilla={"empPlantillaVenta"}
        />
      )}
      <Box padding={2}>
        <DialogTitle id="simple-dialog-title">Acciones.</DialogTitle>
        <List>
          <ListItemButton
            onClick={() => {
              setopenModalContactoPlantillaW(true);
              setNewContacto({
                ...newContacto,
                medioComunicacion: "Whatsapp",
                contacto: row.venCeluCliente,
              });
            }}
          >
            <ListItemAvatar>
              <Avatar className={classes.success}>
                <WhatsApp />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Whatsapp" />
          </ListItemButton>
          {/* <ListItemButton
            onClick={() => {
              setopenModalContacto(true);
              setNewContacto({
                ...newContacto,
                medioComunicacion: "Telegram",
                contacto: row.venCeluCliente,
              });
            }}
          >
            <ListItemAvatar>
              <Avatar className={classes.info}>
                <Send />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Telegram" />
          </ListItemButton> */}
          {/* <ListItemButton
            onClick={() => {
              setopenModalContacto(true);
              setNewContacto({
                ...newContacto,
                medioComunicacion: "SMS",
                contacto: row.venCeluCliente,
              });
            }}
          >
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <Sms />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="SMS" />
          </ListItemButton> */}
          <ListItemButton
            onClick={() => {
              setopenModalContacto(true);
              setNewContacto({
                ...newContacto,
                medioComunicacion: "Correo",
                contacto: row.venEmailCliente,
              });
            }}
          >
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <Email />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Correo" />
          </ListItemButton>
        </List>
      </Box>
    </Dialog>
  );
};

export default memo(withRouter(ExportDialog));
