import React, { memo, useEffect } from "react";
import { useState } from "react";
import { withRouter } from "react-router";
import ConfirmacionDialog from "../../../../../../components/Extra/ConfirmacionDialog";

// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { Cancel, Check, Create } from "@mui/icons-material";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  celda: {
    fontSize: ".7rem !important",
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "50px",
  },
}));

const RowArma = (props) => {
  const { row, index, item, setitem, deshabilitarbotones } = props;
  const horas = [
    "h0",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "h7",
    "h8",
    "h9",
    "h10",
    "h11",
    "h12",
    "h13",
    "h14",
    "h15",
    "h16",
    "h17",
    "h18",
    "h19",
    "h20",
    "h21",
    "h22",
    "h23",
  ];

  const [itemCabecera, setitemCabecera] = useState(
    item.hospParametros.parametrosCabecera.find((itemmedicamentosCabecera) => {
      return itemmedicamentosCabecera.order === row.order;
    })
  );
  const [validos, setvalidos] = useState([]);
  useEffect(() => {
    const itemCabeceraEffect = item.hospParametros.parametrosCabecera.find(
      (itemmedicamentosCabecera) => {
        return itemmedicamentosCabecera.order === row.order;
      }
    );
    let nuevoComienzo = horas
      .map((item, index) => index)
      .slice(Number(itemCabeceraEffect.inicio));

    let arregloFinal = [];
    let index = nuevoComienzo[0];
    for (; index < 24; index = index + Number(itemCabeceraEffect.frecuencia)) {
      arregloFinal.push(index);
    }
    setvalidos(arregloFinal);

    setitemCabecera(itemCabeceraEffect);
    // eslint-disable-next-line
  }, [item.hospParametros.parametrosCabecera]);

  const [itemRow, setItemRow] = useState(row);
  useEffect(() => {
    setItemRow(row);
    // eslint-disable-next-line
  }, [item.hospParametros.parametrosHorario]);
  const classes = useRowStyles();
  const handleChange = (e) => {
    setItemRow({ ...itemRow, [e.target.name]: e.target.value });
  };
  const handleEliminar = async (_id) => {
    return;
  };
  const funcionEdit = () => {
    seteditar(true);
  };
  const [editar, seteditar] = useState(false);
  const funcCancel = () => {
    setItemRow(row);
    seteditar(false);
  };
  const botonDisabled = () => {
    if (itemRow.nombre === "" || itemRow.valor === "") {
      return true;
    } else {
      return false;
    }
  };
  const funcSubmit = () => {
    setitem({
      ...item,
      hospParametros: {
        ...item.hospParametros,
        parametrosHorario: item.hospParametros.parametrosHorario.map(
          (itemFiltermedicamentosHorario, itemIndex) => {
            if (itemRow.order === itemFiltermedicamentosHorario.order) {
              return itemRow;
            } else {
              return itemFiltermedicamentosHorario;
            }
          }
        ),
      },
    });
    seteditar(false);
  };
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  if (!itemCabecera) {
    return null;
  }
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo="¿Esta seguro que quiere eliminar este Registro?"
        _id={itemRow._id}
        funcion={handleEliminar}
      />

      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}

        <TableCell
          size="small"
          padding="checkbox"
          style={{
            borderRight: "1px solid #ccc",
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="center">
            {editar ? (
              <>
                <Tooltip title="Cancelar">
                  <IconButton
                    size="small"
                    onClick={() => {
                      funcCancel();
                    }}
                  >
                    <Cancel fontSize="small" color="secondary" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Confirmar">
                  <IconButton
                    size="small"
                    disabled={botonDisabled ? botonDisabled() : false}
                    onClick={() => {
                      funcSubmit();
                    }}
                  >
                    <Check
                      fontSize="small"
                      color={
                        botonDisabled
                          ? botonDisabled()
                            ? "inherit"
                            : "secondary"
                          : "secondary"
                      }
                    />
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              <>
                <Tooltip title="Editar">
                  <IconButton
                    size="small"
                    color={deshabilitarbotones ? "inherit" : "secondary"}
                    disabled={deshabilitarbotones}
                    onClick={() => {
                      funcionEdit();
                    }}
                  >
                    <Create />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Box>
        </TableCell>

        {editar ? (
          <>
            <TableCell
              checkbox
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                type={"date"}
                name="fecha"
                value={itemRow.fecha}
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell
              checkbox
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                disabled
                name="nameParametrosHorarios"
                value={itemRow.nameParametrosHorarios}
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
            {horas.map((itemHora, index) => {
              const existe = validos.some((valido) => valido === index);
              return (
                <TableCell
                  checkbox
                  size="small"
                  align="center"
                  className={classes.celda}
                >
                  {Number(itemCabecera.inicio) > index || !existe ? (
                    " -- "
                  ) : (
                    <TextField
                      name={itemHora}
                      disabled={Number(itemCabecera.inicio) > index || !existe}
                      value={itemRow[itemHora]}
                      onChange={(e) => handleChange(e)}
                      size="small"
                      fullWidth
                    />
                  )}
                </TableCell>
              );
            })}
          </>
        ) : (
          <>
            <TableCell
              checkbox
              size="small"
              align="center"
              className={classes.celda}
            >
              {itemRow.fecha}
            </TableCell>
            <TableCell
              checkbox
              size="small"
              align="center"
              className={classes.celda}
            >
              {itemRow.nameParametrosHorarios}
            </TableCell>
            {horas.map((itemHora, index) => {
              const existe = validos.some((valido) => valido === index);
              return (
                <TableCell
                  checkbox
                  size="small"
                  align="center"
                  className={classes.celda}
                >
                  {Number(itemCabecera.inicio) > index || !existe
                    ? " -- "
                    : itemRow[itemHora]}
                </TableCell>
              );
            })}
          </>
        )}
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(RowArma));
