export const columns = [
  {
    id: "lugNombre",
    label: "Nombre",
    minWidth: 150,
    tipo: "string",
    orden: 1,
    propiedad: "lugNombre",
    align: "center",
    ocultar: true,
  },
  {
    id: "maqDetalle",
    label: "Detalle",
    minWidth: 150,
    tipo: "string",
    orden: 1,
    propiedad: "maqDetalle",
    align: "center",
    ocultar: true,
  },

  {
    id: "maqFecReg",
    label: "Fec. Reg",
    minWidth: 150,
    tipo: "string",
    orden: 1,
    propiedad: "maqFecReg",
    align: "center",
    ocultar: true,
  },
  {
    id: "maqUser",
    label: "User",
    minWidth: 150,
    tipo: "string",
    orden: 1,
    propiedad: "maqUser",
    align: "center",
    ocultar: true,
  },
];

export const objectDefault = {
  // "maqId": 1,
  maqNombre: "",
  maqDetalle: "",
  maqUser: "",
  maqEmpresa: "",
  maqFecReg: "",
  maqFecUpd: "",
};
