import React, { useState, useContext, useEffect, memo } from "react";
import Draggable from "react-draggable";
// eslint-disable-next-line
import AlertaContext from "../../../../Context/Alerta/AlertaContext";

import AuthContext from "../../../../Context/Auth/AuthContext";

import TablaCabecera from "../../../../components/Extra/TablaCabecera";
import ToolBarTable from "../../../../components/Extra/ToolBarTable";
import RowRetencion from "./RowRetencion";
import RowAgregarRetencion from "./RowAgregarRetencion";
import { Autocomplete, InputAdornment } from "@mui/material";
import clienteAxios from "../../../../config/axios";
import ConfirmacionDialog from "../../../../components/Extra/ConfirmacionDialog";
import { replaceCaracteres, trimPropsItem } from "../../../../config/const";
import { isNum } from "../../../../config/validaciones";
import RowContabilidad from "./Contabilidad/RowContabilidad";
import RowAgregarContabilidad from "./Contabilidad/RowAgregarContabilidad";
// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Box,
  Button,
  TextField,
  Divider,
  Typography,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TablePagination,
  FormControl,
  TableCell,
  TableRow,
} from "@mui/material";
import { Cancel, Create, AddCircleOutlineTwoTone } from "@mui/icons-material";

function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",

    width: "60%",
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    marginTop: "5vh",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginTop: "2vh",
    },
  },
  margin: {
    margin: theme.spacing(1),
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  ocultar: {
    display: "none",
  },
  inputPadding: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0),
    fontSize: "1rem",
  },
  formD: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "45%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "95%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
    },
  },
  large: {
    width: theme.spacing(13),
    height: theme.spacing(13),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&:hover": {
      opacity: ".7",
    },
  },

  center: {
    justifyContent: "center",
    alignItems: "center",
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
}));

const ModalAsistencia = (props) => {
  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext);

  const [contaEliminados, setcontaEliminados] = useState([]);
  const [openConfirmacionDialog, setopenConfirmacionDialog] = useState(false);
  //props
  const {
    open,
    setopen,
    editar,
    seteditar,
    seteditarProveedor,
    setopenProveedor,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
    changeObjetoActivoStateProveedor,
    ObjectoActivoProveedor,
  } = props;
  const [planDeCuentas, setplanDeCuentas] = useState(null);
  const [item, setitem] = useState(ObjectoActivo);
  const [contabilidadData, setcontabilidadData] = useState(null);
  const [observacionContabilidad, setobservacionContabilidad] = useState("");
  const [crearProveedor, setcrearProveedor] = useState(false);
  // TABLA
  const [agregandoPagoAdicional, setagregandoPagoAdicional] = useState(false);
  const [pagePagoAdicional, setPagePagoAdicional] = React.useState(0);
  const [
    rowsPerPagePagoAdicional,
    setRowsPerPagePagoAdicional,
  ] = React.useState(10);
  const handleChangePagePagoAdicional = async (event, newPage) => {
    setPagePagoAdicional(newPage);
  };

  const handleChangeRowsPerPagePagoAdicional = (event) => {
    setRowsPerPagePagoAdicional(+event.target.value);
    setPagePagoAdicional(0);
  };

  const [cargando, setcargando] = useState(false);
  const [data, setdata] = useState([]);
  const [fpagosData, setfpagosData] = useState(null);
  const [tributarioData, settributarioData] = useState(null);
  const {
    // Codigo,
    tipoCompra,
    // credito,
    // dias,
    // envio,
    estado,
    fDocumento,
    factura,
    fechaFactura,
    // fechaAutorizacion,
    fPago,
    nomProveedor,
    // obligado,
    observacion,
    // rDocumento,
    // retencion,
    rucProveedor,
    valorFactura,
    // valTotalRetencion ,
    retenciones,
    // errorAutorizacion,
    identificacionReceptor,
    inventario,

    // fPagoCodigo,
    // fPagoDescripcion,
    // susTributarioCodigo,
    susTributario,
  } = item;
  const [error, seterror] = useState({
    Codigo: false,
    tipoCompra: false,
    credito: false,
    dias: false,
    envio: false,
    estado: false,
    fDocumento: false,
    factura: false,
    fechaFactura: false,
    fechaAutorizacion: false,
    fPago: false,
    nomProveedor: false,
    obligado: false,
    observacion: false,
    rDocumento: false,
    retencion: false,
    rucProveedor: false,
    valorFactura: false,
    valTotalRetencion: false,
    errorAutorizacion: false,
  });

  useEffect(() => {
    const getcontabilidadData = async () => {
      if (!open) {
        return;
      }
      try {
        const rescontabilidadData = await clienteAxios.get(
          `/contabilidad/${ObjectoActivo.compId ? ObjectoActivo.compId : "0"}`
        );

        setcontabilidadData(rescontabilidadData.data.data);
      } catch (error) {
        setcontabilidadData([]);
      }
    };
    if (!contabilidadData) {
      getcontabilidadData();
    }

    if (editar && ObjectoActivo.fechaFactura !== "") {
      return setitem({
        ...ObjectoActivo,
        fechaFactura: new Date(ObjectoActivo.fechaFactura)
          .toISOString()
          .toString(),
      });
    } else {
      setitem({
        ...ObjectoActivo,
        estado: "GENERAR RETENCION",
        identificacionReceptor: usuario.codigo,
      });
    }

    // eslint-disable-next-line
  }, [ObjectoActivo, open]);
  useEffect(() => {
    const getfpagosData = async () => {
      try {
        const resfpagosData = await clienteAxios.get("/formaPagos/filtro/0");

        setfpagosData(resfpagosData.data.data);
      } catch (error) {
        setfpagosData([]);
      }
    };

    const gettributarioData = async () => {
      try {
        const restributarioData = await clienteAxios(
          "/sustentotributario/filtro/0"
        );

        settributarioData(restributarioData.data.data);
      } catch (error) {
        settributarioData([]);
      }
    };
    const getplanDeCuentas = async () => {
      try {
        const resplanDeCuentas = await clienteAxios.get(
          `plancuentas/tipo/?tipo=${"MOVIMIENTO"}`
        );

        setplanDeCuentas(resplanDeCuentas.data.data);
      } catch (error) {
        setplanDeCuentas([]);
      }
    };

    if (!fpagosData) {
      getfpagosData();
    }
    if (!tributarioData) {
      gettributarioData();
    }
    if (!planDeCuentas) {
      getplanDeCuentas();
    }

    // eslint-disable-next-line
  }, []);

  // const [ItemOtroLugarProveedor, setItemOtroLugarProveedor] = useState({});
  const [itemSocket, setitemSocket] = useState({
    tipo: "",
    itemSocketData: {},
  });

  useEffect(() => {
    socket.current.on("server:guardadoExitoso", (data) => {
      setitemSocket({
        tipo: "agregar",
        itemSocketData: data,
      });
    });
    socket.current.on("server:actualizadoExitoso", (data) => {
      setitemSocket({
        tipo: "editar",
        itemSocketData: data,
      });
    });

    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const { itemSocketData, tipo } = itemSocket;

    if (!open) {
      return;
    }

    if (tipo === "") {
      return;
    }

    if (usuario.rucempresa !== itemSocketData.rucempresa) {
      return;
    }

    const funcionAalerta = () => {
      if (itemSocketData.msg === "") {
        return null;
      }
      if (!itemSocketData.msg) {
        return null;
      }
      mostrarAlerta(
        itemSocketData.msg,
        itemSocketData.alert ? itemSocketData.alert : "success"
      );
    };

    if (tipo === "agregar") {
      if (itemSocketData.tabla === "proveedor") {
        if (usuario.usuario === itemSocketData.perUser) {
          setitem({
            ...item,
            rucProveedor: itemSocketData.perDocNumero,
            nomProveedor: itemSocketData.perNombre,
          });
          // setItemOtroLugarProveedor(itemSocketData);
        }
      }
    }

    if (tipo === "editar") {
      if (itemSocketData.tabla === "proveedor") {
        if (usuario.usuario === itemSocketData.perUser) {
          setitem({
            ...item,
            rucProveedor: itemSocketData.perDocNumero,
            nomProveedor: itemSocketData.perNombre,
          });
          // setItemOtroLugarProveedor(itemSocketData);
        }
      }
    }
    funcionAalerta();
    setitemSocket({ tipo: "", itemSocketData: {} });
    // eslint-disable-next-line
  }, [itemSocket]);
  const defaultActive = () => {
    changeObjetoActivoState({
      empRegimen: "",
      rucEmpresa: "",
      rucProveedor: "",
      nomProveedor: "",
      factura: "",
      fDocumento: "",
      fechaFactura: `${new Date().getFullYear()}-${(
        "0" +
        (new Date().getMonth() + 1)
      ).slice(-2)}-${("0" + new Date().getDate()).slice(-2)}`,
      valorFactura: "",
      identificacionReceptor: "",
      tipoCompra: "",
      obligado: "",
      observacion: "",
      credito: "",
      dias: "",
      fPago: "",
      retencion: "",
      rDocumento: "",
      fechaAutorizacion: "",
      errorAutorizacion: "",
      retenciones: [],
      valTotalRetencion: "",
      estado: "GENERAR RETENCION",
      inventario: "NO",
      envio: "NO",
      user: "",
      empRuc: "",
      empNombre: "",
      empComercial: "",
      empDireccion: "",
      empTelefono: "",
      empEmail: "",
      dirProveedor: "",
      emailProveedor: [],
      telProveedor: "",
      celProveedor: "",
      fPagoCodigo: "",
      fPagoDescripcion: "",
      susTributarioCodigo: "",
      susTributario: "",
    });
    setopen(false);
    seteditar(false);
    seterror({
      Codigo: false,
      tipoCompra: false,
      credito: false,
      dias: false,
      envio: false,
      estado: false,
      fDocumento: false,
      factura: false,
      fechaFactura: false,
      fechaAutorizacion: false,
      fPago: false,
      nomProveedor: false,
      obligado: false,
      observacion: false,
      rDocumento: false,
      retencion: false,
      rucProveedor: false,
      valorFactura: false,
      valTotalRetencion: false,
    });
    setseccionActive({
      datosSeccion: true,
      retencionSeccion: false,
    });
    setcargando(false);
    setcontabilidadData(null);

    setobservacionContabilidad("");
    setcontaEliminados([]);
    setcrearProveedor(false);
  };

  const handleChange = (e) => {
    const res = replaceCaracteres(e.target.value);
    setitem({
      ...item,
      [e.target.name]: res,
    });
  };

  //hook de estilos
  const clases = useStyles();
  //definiendo los estilos del modal

  const [modalStyle] = useState(getModalStyle);
  // obteniendo los años

  const [seccionActive, setseccionActive] = useState({
    datosSeccion: true,
    retencionSeccion: false,
  });
  const { datosSeccion, retencionSeccion, datosContabilidad } = seccionActive;
  const [agregandoRetencion, setagregandoRetencion] = useState(false);
  const handleCrear = async () => {
    if (
      Number(
        contabilidadData.length === 0
          ? 0
          : contabilidadData
              .map((row) => {
                return Number(row.contaDebe);
              })
              .reduce(reducer)
              .toFixed(2)
      ) !==
      Number(
        contabilidadData.length === 0
          ? 0
          : contabilidadData
              .map((row) => {
                return Number(row.contaHaber);
              })
              .reduce(reducer)
              .toFixed(2)
      )
    ) {
      return mostrarAlerta(
        "DEBE Y HABER de CONTABILIDAD deben coincidir",
        "error"
      );
    }

    if (
      tipoCompra === "" ||
      rucProveedor === "" ||
      nomProveedor === "" ||
      nomProveedor === "" ||
      fPago === "" ||
      identificacionReceptor === "" ||
      fechaFactura === "" ||
      valorFactura === "" ||
      factura === "" ||
      factura.length !== 17 ||
      fDocumento === "" ||
      inventario === ""
    ) {
      return seterror({
        tipoCompra: tipoCompra === "" ? true : false,
        rucProveedor: rucProveedor === "" ? true : false,
        nomProveedor: nomProveedor === "" ? true : false,
        fPago: fPago === "" ? true : false,
        identificacionReceptor: identificacionReceptor === "" ? true : false,
        fechaFactura: fechaFactura === "" ? true : false,
        valorFactura: valorFactura === "" ? true : false,
        factura: factura === "" || factura.length !== 17 ? true : false,
        fDocumento: fDocumento === "" ? true : false,
        inventario: inventario === "" ? true : false,
      });
    }
    if (
      estado === "GASTOS PERSONALES" ||
      estado === "ANULADA" ||
      estado === "PROCESADO"
    ) {
    } else {
      if (item.retenciones.length === 0) {
        return mostrarAlerta("Ingrese al menos una retención", "error");
      }
    }
    try {
      setcargando(true);
      // await clienteAxios.post(`/contabilidad`, {
      //   data_actual: contabilidadData,
      //   data_antigua: [],
      // });

      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          valTotalRetencion:
            item.retenciones.length === 0
              ? 0
              : item.retenciones
                  .map((row) => {
                    return Number(row.detValor);
                  })
                  .reduce(reducer)
                  .toFixed(2),
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          user: usuario.usuario,
          rucEmpresa: usuario.rucempresa,
          tabla: "compra",
        })
      );

      return defaultActive();
    } catch (error) {
      setcargando(false);
      return mostrarAlerta("Hubo un error", "error");
    }
  };
  const handleEditar = async () => {
    if (
      Number(
        contabilidadData.length === 0
          ? 0
          : contabilidadData
              .map((row) => {
                return Number(row.contaDebe);
              })
              .reduce(reducer)
              .toFixed(2)
      ) !==
      Number(
        contabilidadData.length === 0
          ? 0
          : contabilidadData
              .map((row) => {
                return Number(row.contaHaber);
              })
              .reduce(reducer)
              .toFixed(2)
      )
    ) {
      return mostrarAlerta(
        "DEBE Y HABER de CONTABILIDAD deben coincidir",
        "error"
      );
    }

    if (
      tipoCompra === "" ||
      rucProveedor === "" ||
      nomProveedor === "" ||
      nomProveedor === "" ||
      fPago === "" ||
      identificacionReceptor === "" ||
      fechaFactura === "" ||
      valorFactura === "" ||
      factura === "" ||
      factura.length !== 17 ||
      fDocumento === "" ||
      inventario === ""
    ) {
      return seterror({
        tipoCompra: tipoCompra === "" ? true : false,
        rucProveedor: rucProveedor === "" ? true : false,
        nomProveedor: nomProveedor === "" ? true : false,
        fPago: fPago === "" ? true : false,
        identificacionReceptor: identificacionReceptor === "" ? true : false,
        fechaFactura: fechaFactura === "" ? true : false,
        valorFactura: valorFactura === "" ? true : false,
        factura: factura === "" || factura.length !== 17 ? true : false,
        fDocumento: fDocumento === "" ? true : false,
        inventario: inventario === "" ? true : false,
      });
    }

    if (
      estado === "GASTOS PERSONALES" ||
      estado === "ANULADA" ||
      estado === "PROCESADO"
    ) {
    } else {
      if (item.retenciones.length === 0) {
        return mostrarAlerta("Ingrese al menos una retención", "error");
      }
    }

    try {
      setcargando(true);
      // const res = await clienteAxios.post(`/contabilidad`, {
      //   data_actual: contabilidadData,
      //   data_antigua: contaEliminados,
      // });

      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          valTotalRetencion:
            item.retenciones.length === 0
              ? 0
              : item.retenciones
                  .map((row) => {
                    return Number(row.detValor);
                  })
                  .reduce(reducer)
                  .toFixed(2),
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          user: usuario.usuario,
          rucEmpresa: usuario.rucempresa,
          tabla: "compra",
        })
      );

      // mostrarAlerta(res.data.msg, "success");
      return defaultActive();
    } catch (error) {
      setcargando(false);
      return mostrarAlerta("Hubo un error", "error");
    }
  };
  const funcion1 = () => {
    if (editar) {
      handleEditar();
    } else {
      handleCrear();
    }
  };
  const [page, setPage] = React.useState(0);
  //filas por pagina
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
  };

  // eslint-disable-next-line

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const reducer = (previousValue, currentValue) =>
    Number(previousValue) + Number(currentValue);

  if (!open) {
    return null;
  }

  if (!fpagosData) {
    return null;
  }
  if (!tributarioData) {
    return null;
  }
  if (!contabilidadData) {
    return null;
  }
  if (!planDeCuentas) {
    return null;
  }
  return (
    <>
      <ConfirmacionDialog
        open={openConfirmacionDialog}
        setopen={setopenConfirmacionDialog}
        titulo={
          editar && item.estado === "ANULADA"
            ? "¿DESEA ANULAR ESTA COMPRA?"
            : item.estado === "GENERAR RETENCION"
            ? "¿GENERAR XML DE ESTA COMPRA?"
            : item.estado === "SIN AUTORIZAR"
            ? "¿AUTORIZAR ESTA COMPRA?"
            : item.estado === "AUTORIZADO"
            ? "¿ESTA COMPRA YA SE ENCUENTRA AUTORIZADA?"
            : item.estado === "IMPORTADO"
            ? "¿GUARDAR CON ESTADO IMPORTADO?"
            : item.estado === "PROCESADO"
            ? "¿GUARDAR COMPRA CON ESTADO PROCESADO?"
            : item.estado === "GASTOS PERSONALES"
            ? "¿GUARDAR COMPRA COMO GASTOS PERSONALES?"
            : `GUARDAR COMO ${item.estado}`
        }
        funcion1={funcion1}
        funcion2={funcion1}
        funcion3={funcion1}
        funcion4={funcion1}
        texto1={"SI"}
        texto2={""}
        texto3={""}
        texto4={""}
        deshabilitarBoton1={false}
        deshabilitarBoton2={true}
        deshabilitarBoton3={true}
        deshabilitarBoton4={true}
        NotaCredito={ObjectoActivo.venTipoDocumento === "NC"}
        tripleOpcion={true}
        categoria={"success"}
      />

      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <Draggable handle="#handle">
            <Box display="flex" justifyContent="center">
              <div style={modalStyle} className={clases.paper}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                  style={{ cursor: "-webkit-grab" }}
                >
                  <Typography
                    id="handle"
                    variant="button"
                    color="initial"
                    align="center"
                  >
                    {editar ? "Editar" : "Agregar"} Compra.
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="estado"
                      label="Estado"
                      value={estado}
                      name="estado"
                      error={error.estado}
                      onChange={(e) => handleChange(e)}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="">Seleccione</MenuItem>
                      {editar
                        ? ObjectoActivo.estado === "AUTORIZADO"
                          ? ["AUTORIZADO", "ANULADA"].map((item) => {
                              return <MenuItem value={item}>{item} </MenuItem>;
                            })
                          : ObjectoActivo.estado === "SIN AUTORIZAR"
                          ? ["SIN AUTORIZAR", "GENERAR RETENCION"].map(
                              (item) => {
                                return (
                                  <MenuItem value={item}>{item} </MenuItem>
                                );
                              }
                            )
                          : ObjectoActivo.estado === "ANULADA"
                          ? ["ANULADA"].map((item) => {
                              return <MenuItem value={item}>{item} </MenuItem>;
                            })
                          : ObjectoActivo.estado === "PROCESADO"
                          ? ["PROCESADO"].map((item) => {
                              return <MenuItem value={item}>{item} </MenuItem>;
                            })
                          : ObjectoActivo.estado === "GASTOS PERSONALES"
                          ? ["GASTOS PERSONALES"].map((item) => {
                              return <MenuItem value={item}>{item} </MenuItem>;
                            })
                          : ObjectoActivo.estado === "IMPORTADO"
                          ? [
                              "IMPORTADO",
                              "GENERAR RETENCION",
                              "PROCESADO",
                              "GASTOS PERSONALES",
                            ].map((item) => {
                              return <MenuItem value={item}>{item} </MenuItem>;
                            })
                          : [
                              "AUTORIZADO",
                              "SIN AUTORIZAR",
                              "GENERAR RETENCION",
                              "IMPORTADO",
                              "PROCESADO",
                              "GASTOS PERSONALES",
                            ].map((item) => {
                              return <MenuItem value={item}>{item} </MenuItem>;
                            })
                        : [
                            "GENERAR RETENCION",
                            "PROCESADO",
                            "GASTOS PERSONALES",
                          ].map((item) => {
                            return <MenuItem value={item}>{item} </MenuItem>;
                          })}
                    </TextField>
                    <IconButton
                      style={{ margin: "0px" }}
                      onClick={() => {
                        defaultActive();
                      }}
                    >
                      <Cancel color="secondary" />
                    </IconButton>
                  </Box>
                </Box>
                <Divider />
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems=" stretch"
                  width="100%"
                >
                  <Box>
                    <Button
                      variant={datosSeccion ? "contained" : null}
                      disableElevation
                      style={{ padding: "8px" }}
                      color="secondary"
                      onClick={() => setseccionActive({ datosSeccion: true })}
                    >
                      Datos principales
                    </Button>
                    <Button
                      variant={retencionSeccion ? "contained" : null}
                      disableElevation
                      style={{ padding: "8px" }}
                      color="secondary"
                      onClick={() =>
                        setseccionActive({ retencionSeccion: true })
                      }
                    >
                      Retención
                    </Button>
                    <Button
                      variant={datosContabilidad ? "contained" : null}
                      disableElevation
                      style={{ padding: "8px" }}
                      color="secondary"
                      onClick={() =>
                        setseccionActive({ datosContabilidad: true })
                      }
                    >
                      Contabilidad
                    </Button>
                  </Box>
                </Box>
                <Divider />
                <Box
                  display="flex"
                  flexWrap="wrap"
                  alignItems="flex-end"
                  borderRadius="0px 50px 50px 0px"
                  p={1}
                >
                  {datosSeccion ? (
                    <>
                      <TextField
                        inputProps={{ className: clases.inputPadding }}
                        className={clases.formD}
                        disabled={
                          ObjectoActivo.estado === "AUTORIZADO" && editar
                        }
                        id="tipoCompra"
                        label="Tipo compra"
                        value={tipoCompra}
                        name="tipoCompra"
                        error={error.tipoCompra}
                        onChange={(e) => handleChange(e)}
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ displayEmpty: true }}
                      >
                        <MenuItem value="">
                          Seleccione un Tipo de Compra
                        </MenuItem>
                        {["BIENES", "SERVICIOS", "BIENES / SERVICIOS"].map(
                          (item) => {
                            return <MenuItem value={item}>{item} </MenuItem>;
                          }
                        )}
                      </TextField>
                      {editar ? (
                        <TextField
                          InputProps={{
                            endAdornment: (
                              <InputAdornment>
                                {crearProveedor ? (
                                  <IconButton
                                    size="small"
                                    onClick={async () => {
                                      seteditarProveedor(false);
                                      setopenProveedor(true);
                                      changeObjetoActivoStateProveedor({
                                        ...ObjectoActivoProveedor,
                                        perDocNumero: rucProveedor,
                                        perNombre: nomProveedor,
                                      });
                                    }}
                                  >
                                    <AddCircleOutlineTwoTone color="primary" />
                                  </IconButton>
                                ) : null}{" "}
                                <IconButton
                                  onClick={async () => {
                                    try {
                                      const res = await clienteAxios.get(
                                        `/proveedores/filtro/0?empresa=${
                                          usuario.rucempresa
                                        }&search=${rucProveedor}&estado=${"PROVEEDOR"}&tabla=compras&edicion=true`
                                      );

                                      if (!res.data.data[0]) {
                                        setcrearProveedor(true);
                                        return mostrarAlerta(
                                          "Hubo un error",
                                          "error"
                                        );
                                      }

                                      await changeObjetoActivoStateProveedor(
                                        res.data.data[0]
                                      );
                                      seteditarProveedor(true);
                                      setopenProveedor(true);
                                    } catch (error) {
                                      setcrearProveedor(true);
                                      return mostrarAlerta(
                                        "Hubo un error",
                                        "error"
                                      );
                                    }
                                  }}
                                >
                                  <Create color="secondary" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          disabled={editar}
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                          id="rucProveedor"
                          label="Ruc proveedor"
                          value={rucProveedor}
                          name="rucProveedor"
                          error={error.rucProveedor}
                          onChange={(e) => handleChange(e)}
                        />
                      ) : (
                        <FormControl fullWidth className={clases.formD}>
                          <Autocomplete
                            options={data.map((item) => {
                              return item.perDocNumero + "/+/" + item.perNombre;
                            })}
                            getOptionLabel={(option) => {
                              return option.replace("/+/", " ");
                            }}
                            id="controlled-demo"
                            value={rucProveedor}
                            paperprops
                            onChange={async (event, newValue) => {
                              if (newValue) {
                                const options = newValue.split("/+/");

                                setitem({
                                  ...item,
                                  rucProveedor: options[0],
                                  nomProveedor: options[1],
                                });
                              } else {
                                setitem({
                                  ...item,
                                  rucProveedor: "",
                                  nomProveedor: "",
                                });
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                required
                                error={error.rucProveedor}
                                label="Ruc proveedor"
                                fullWidth
                                onChange={async (e) => {
                                  if (e.target.value === "") {
                                    return mostrarAlerta(
                                      "Ingrese un documento",
                                      "error"
                                    );
                                  }
                                  try {
                                    if (e.target.value.length < 3) {
                                      return;
                                    }
                                    const res = await clienteAxios.get(
                                      `/proveedores/filtro/0?empresa=${
                                        usuario.rucempresa
                                      }&search=${
                                        e.target.value
                                      }&estado=${"PROVEEDOR"}&tabla=compras`
                                    );

                                    if (!res.data.data) {
                                      return mostrarAlerta(
                                        res.data.msg,
                                        "error"
                                      );
                                    }

                                    setdata(res.data.data);
                                  } catch (error) {}
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {!editar ? (
                                        <IconButton
                                          size="small"
                                          onClick={async () => {
                                            seteditarProveedor(false);
                                            setopenProveedor(true);
                                          }}
                                        >
                                          <AddCircleOutlineTwoTone color="primary" />
                                        </IconButton>
                                      ) : null}
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      )}
                      <TextField
                        disabled={true}
                        inputProps={{ className: clases.inputPadding }}
                        className={clases.formD}
                        id="nomProveedor"
                        label="Proveedor"
                        value={nomProveedor}
                        name="nomProveedor"
                        error={error.nomProveedor}
                        onChange={(e) => handleChange(e)}
                      />
                      <FormControl fullWidth className={clases.formD}>
                        <Autocomplete
                          options={fpagosData.map((item) => {
                            return (
                              item.fpagoNombre +
                              "/+/" +
                              item.fpagoCodigo +
                              "/+/" +
                              item.fpagoDescripcion
                            );
                          })}
                          getOptionLabel={(option) => {
                            return option.split("/+/")[0];
                          }}
                          id="controlled-demo"
                          value={fPago}
                          paperprops
                          onChange={async (event, newValue) => {
                            if (newValue) {
                              const options = newValue.split("/+/");

                              setitem({
                                ...item,
                                fPago: options[0],
                                fPagoCodigo: options[1],
                                fPagoDescripcion: options[2],
                              });
                              if (
                                options[0] === "EFECTIVO" &&
                                editar &&
                                ObjectoActivo.fPago !== "EFECTIVO"
                              ) {
                                mostrarAlerta(
                                  "Se eliminará el registro cuenta por pagar",
                                  "warning"
                                );
                              }
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              required
                              label="Formas de pago"
                              fullWidth
                              error={error.venRucCliente}
                            />
                          )}
                        />
                      </FormControl>
                      <TextField
                        type="number"
                        disabled
                        inputProps={{ className: clases.inputPadding }}
                        className={clases.formD}
                        id="identificacionReceptor"
                        label="Ruc Empresa"
                        value={identificacionReceptor}
                        name="identificacionReceptor"
                        error={error.identificacionReceptor}
                        onChange={(e) => handleChange(e)}
                      />
                      <TextField
                        disabled={
                          editar &&
                          !(
                            ObjectoActivo.estado === "SIN AUTORIZAR" ||
                            ObjectoActivo.estado === "PROCESADO" ||
                            ObjectoActivo.estado === "GASTOS PERSONALES"
                          )
                        }
                        inputProps={{ className: clases.inputPadding }}
                        className={clases.formD}
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        id="fechaFactura"
                        label="Fecha Factura"
                        value={fechaFactura.substr(0, 10)}
                        name="fechaFactura"
                        error={error.fechaFactura}
                        onChange={(e) => {
                          setitem({
                            ...item,
                            [e.target.name]: new Date(
                              e.target.value
                            ).toISOString(),
                          });
                        }}
                      />
                      <TextField
                        disabled={
                          editar &&
                          !(
                            ObjectoActivo.estado === "SIN AUTORIZAR" ||
                            ObjectoActivo.estado === "PROCESADO" ||
                            ObjectoActivo.estado === "GASTOS PERSONALES"
                          )
                        }
                        inputProps={{ className: clases.inputPadding }}
                        className={clases.formD}
                        type="number"
                        id="valorFactura"
                        label="Valor Factura"
                        value={valorFactura}
                        name="valorFactura"
                        error={error.valorFactura}
                        onChange={(e) => handleChange(e)}
                      />
                      <TextField
                        disabled={
                          editar &&
                          !(
                            ObjectoActivo.estado === "SIN AUTORIZAR" ||
                            ObjectoActivo.estado === "PROCESADO" ||
                            ObjectoActivo.estado === "GASTOS PERSONALES"
                          )
                        }
                        inputProps={{ className: clases.inputPadding }}
                        className={clases.formD}
                        id="factura"
                        label="Factura"
                        value={factura}
                        name="factura"
                        error={error.factura}
                        onChange={(e) => handleChange(e)}
                      />
                      <TextField
                        type="number"
                        inputProps={{ className: clases.inputPadding }}
                        className={clases.formD}
                        id="fDocumento"
                        label="Autorización"
                        value={fDocumento}
                        name="fDocumento"
                        error={error.fDocumento}
                        disabled={
                          editar &&
                          !(
                            ObjectoActivo.estado === "SIN AUTORIZAR" ||
                            ObjectoActivo.estado === "PROCESADO" ||
                            ObjectoActivo.estado === "GASTOS PERSONALES"
                          )
                        }
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      <FormControl fullWidth className={clases.formD}>
                        <Autocomplete
                          options={tributarioData.map((item) => {
                            return item.susCodigo + "/+/" + item.susNombre;
                          })}
                          getOptionLabel={(option) => {
                            return option.replace("/+/", " - ");
                          }}
                          id="controlled-demo"
                          value={
                            (
                              item.susTributarioCodigo +
                              " - " +
                              susTributario
                            ).trim() === "-"
                              ? ""
                              : item.susTributarioCodigo + " - " + susTributario
                          }
                          paperprops
                          onChange={async (event, newValue) => {
                            if (newValue) {
                              const options = newValue.split("/+/");
                              setitem({
                                ...item,
                                susTributario: options[1],
                                susTributarioCodigo: options[0],
                              });
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              required
                              label="Sustento Tributario"
                              fullWidth
                              error={error.venRucCliente}
                            />
                          )}
                        />
                      </FormControl>
                      <TextField
                        inputProps={{ className: clases.inputPadding }}
                        className={clases.formD}
                        id="observacion"
                        label="Observación"
                        value={observacion}
                        name="observacion"
                        error={error.observacion}
                        variant="outlined"
                        multiline
                        rows={3}
                        onChange={(e) => handleChange(e)}
                      />
                    </>
                  ) : null}
                  {retencionSeccion ? (
                    <>
                      <Paper className={clases.root}>
                        <ToolBarTable
                          titulo={"Detalles Retención"}
                          soloTitulo={true}
                          botonAdd={true}
                          deshabilitarbotonAdd={
                            (ObjectoActivo.estado === "AUTORIZADO" ||
                              ObjectoActivo.estado === "ANULADA") &&
                            editar
                          }
                          funcionAdd={() => {
                            setagregandoRetencion(true);

                            // const retenciones = [
                            //   {
                            //     detTipo: "",
                            //     detCodigo: "",
                            //     detBasImponible: "",
                            //     detImpuesto: "",
                            //     detPorcentaje: "",
                            //     detValor: "",
                            //   },
                            //   ...item.retenciones,
                            // ];

                            // setitem({
                            //   ...item,
                            //   retenciones,
                            // });
                          }}
                        />
                        <TableContainer className={clases.container}>
                          <Table
                            stickyHeader
                            aria-label="sticky table"
                            id="tabla"
                            size="small"
                          >
                            <caption
                              style={{
                                padding: "0px",
                                paddingRight: "1rem",
                                paddingLeft: "1rem",
                              }}
                            >
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <TablePagination
                                  labelRowsPerPage="Filas por página"
                                  rowsPerPageOptions={[
                                    10,
                                    20,
                                    50,
                                    100,
                                    500,
                                    1000,
                                  ]}
                                  component="div"
                                  count={item.retenciones.length}
                                  rowsPerPage={rowsPerPage}
                                  page={page}
                                  onPageChange={handleChangePage}
                                  onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                                {/* {item.retenciones.length === 0 ? null : (
                              <>
                                <Typography variant="body1" color="primary">
                                  Imponible total: 
                                  <Typography
                                    variant="body1"
                                    color="textPrimary"
                                    component="span"
                                  >
                                    {item.retenciones
                                      .map((row) => {
                                        return Number(row.detBasImponible);
                                      })
                                      .reduce(reducer)
                                      .toFixed(2)}
                                  </Typography>
                                </Typography>
                              </>
                            )} */}

                                {item.retenciones.length === 0 ||
                                !isNum(valorFactura) ? null : (
                                  <>
                                    <Typography variant="body1" color="primary">
                                      Valor a Cancelar:
                                      <Typography
                                        variant="body1"
                                        color="textPrimary"
                                        component="span"
                                      >
                                        $
                                        {Number(valorFactura) -
                                          item.retenciones
                                            .map((row) => {
                                              return Number(row.detValor);
                                            })
                                            .reduce(reducer)
                                            .toFixed(2)}
                                      </Typography>
                                    </Typography>
                                  </>
                                )}

                                {item.retenciones.length === 0 ? null : (
                                  <>
                                    <Typography variant="body1" color="primary">
                                      Total Ret. :
                                      <Typography
                                        variant="body1"
                                        color="textPrimary"
                                        component="span"
                                      >
                                        $
                                        {item.retenciones
                                          .map((row) => {
                                            return Number(row.detValor);
                                          })
                                          .reduce(reducer)
                                          .toFixed(2)}
                                      </Typography>
                                    </Typography>
                                  </>
                                )}
                              </Box>
                            </caption>
                            {/* <ModalCarnet setopen={setopen} onChangeCarnetActivo={onChangeCarnetActivo}/> */}

                            <TableHead>
                              <TablaCabecera
                                columns={[
                                  {
                                    id: "tipo",
                                    label: "Tipo",
                                    minWidth: 100,
                                    tipo: "string",

                                    propiedad: "tipo",
                                  },
                                  {
                                    id: "impuesto",
                                    label: "Impuesto",
                                    minWidth: 50,
                                    tipo: "string",

                                    propiedad: "impuesto",
                                  },
                                  {
                                    id: "codigo",
                                    label: "Codigo",
                                    minWidth: 150,
                                    tipo: "string",

                                    propiedad: "codigo",
                                  },
                                  {
                                    id: "ret",
                                    label: "Ret %",
                                    minWidth: 50,
                                    tipo: "string",

                                    propiedad: "ret",
                                  },
                                  {
                                    id: "imponible",
                                    label: "B. Imponible",
                                    minWidth: 50,
                                    tipo: "string",

                                    propiedad: "imponible",
                                  },

                                  {
                                    id: "valor",
                                    label: "Valor",
                                    minWidth: 50,
                                    tipo: "string",

                                    propiedad: "valor",
                                  },
                                ]}
                                habilitarOrdenar={false}
                              />
                            </TableHead>

                            <TableBody>
                              {agregandoRetencion ? (
                                <RowAgregarRetencion
                                  setagregar={setagregandoRetencion}
                                  setitem={setitem}
                                  item={item}
                                />
                              ) : null}
                              {retenciones.map((row, index) => {
                                return (
                                  <RowRetencion
                                    deshabilitarbotones={
                                      (ObjectoActivo.estado === "AUTORIZADO" ||
                                        ObjectoActivo.estado === "ANULADA") &&
                                      editar
                                    }
                                    item={item}
                                    setitem={setitem}
                                    row={row}
                                    index={index}
                                    key={index}
                                  />
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    </>
                  ) : null}
                  {datosContabilidad ? (
                    <>
                      <TextField
                        className={clases.formD}
                        label="Observación"
                        value={observacionContabilidad}
                        name="observacionContabilidad"
                        error={error.observacionContabilidad}
                        variant="outlined"
                        onChange={(e) =>
                          setobservacionContabilidad(e.target.value)
                        }
                      />
                      <Paper className={clases.root}>
                        <ToolBarTable
                          titulo={"Contabilidad"}
                          soloTitulo={true}
                          botonAdd={true}
                          deshabilitarbotonAdd={
                            rucProveedor.trim() === "" ||
                            nomProveedor.trim() === "" ||
                            factura.trim() === "" ||
                            fechaFactura === "" ||
                            ((ObjectoActivo.estado === "AUTORIZADO" ||
                              ObjectoActivo.estado === "ANULADA") &&
                              editar)
                          }
                          funcionAdd={() => {
                            setagregandoPagoAdicional(true);
                          }}
                        />
                        <TableContainer className={clases.container}>
                          <Table
                            stickyHeader
                            aria-label="sticky table"
                            id="tabla"
                            size="small"
                          >
                            <caption
                              style={{
                                padding: "0px",
                                paddingRight: "1rem",
                                paddingLeft: "1rem",
                              }}
                            >
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <TablePagination
                                  labelRowsPerPage="Filas por pagina"
                                  rowsPerPageOptions={[
                                    10,
                                    20,
                                    50,
                                    100,
                                    500,
                                    1000,
                                  ]}
                                  component="div"
                                  count={contabilidadData.length}
                                  rowsPerPage={rowsPerPagePagoAdicional}
                                  page={pagePagoAdicional}
                                  onPageChange={handleChangePagePagoAdicional}
                                  onRowsPerPageChange={
                                    handleChangeRowsPerPagePagoAdicional
                                  }
                                />
                              </Box>
                            </caption>

                            <TableHead>
                              <TablaCabecera
                                columns={[
                                  {
                                    id: "Cuenta",
                                    label: "Cuenta",
                                    minWidth: 100,
                                  },
                                  {
                                    id: "CuentaNombre",
                                    label: "Nombre",
                                    minWidth: 100,
                                  },

                                  {
                                    id: "Debe",
                                    label: "Debe $",
                                    minWidth: 50,
                                  },
                                  {
                                    id: "Haber",
                                    label: "Haber $",
                                    minWidth: 50,
                                  },
                                ]}
                                habilitarOrdenar={false}
                              />
                            </TableHead>

                            <TableBody>
                              {agregandoPagoAdicional ? (
                                <RowAgregarContabilidad
                                  observacionContabilidad={
                                    observacionContabilidad
                                  }
                                  planDeCuentas={planDeCuentas}
                                  setplanDeCuentas={setplanDeCuentas}
                                  agregar={agregandoPagoAdicional}
                                  setagregar={setagregandoPagoAdicional}
                                  setcontabilidadData={setcontabilidadData}
                                  contabilidadData={contabilidadData}
                                  setitem={setitem}
                                  item={item}
                                  editar={editar}
                                />
                              ) : null}
                              {contabilidadData
                                .slice(
                                  pagePagoAdicional * rowsPerPagePagoAdicional,
                                  pagePagoAdicional * rowsPerPagePagoAdicional +
                                    rowsPerPagePagoAdicional
                                )
                                .map((row, index) => {
                                  return (
                                    <>
                                      <RowContabilidad
                                        observacionContabilidad={
                                          observacionContabilidad
                                        }
                                        contaEliminados={contaEliminados}
                                        setcontaEliminados={setcontaEliminados}
                                        planDeCuentas={planDeCuentas}
                                        setplanDeCuentas={setplanDeCuentas}
                                        deshabilitarbotones={
                                          (ObjectoActivo.estado ===
                                            "AUTORIZADO" ||
                                            ObjectoActivo.estado ===
                                              "ANULADA") &&
                                          editar
                                        }
                                        agregar={agregandoPagoAdicional}
                                        setagregar={setagregandoPagoAdicional}
                                        setcontabilidadData={
                                          setcontabilidadData
                                        }
                                        contabilidadData={contabilidadData}
                                        setitem={setitem}
                                        item={item}
                                        row={row}
                                        key={row.contaId + index + 1}
                                      />
                                    </>
                                  );
                                })}
                              <TableRow>
                                <TableCell
                                  size="small"
                                  align="center"
                                ></TableCell>
                                <TableCell
                                  size="small"
                                  align="center"
                                ></TableCell>
                                <TableCell
                                  size="small"
                                  align="center"
                                ></TableCell>
                                <TableCell size="small" align="center">
                                  <Typography
                                    variant="body2"
                                    color="primary"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    $
                                    {contabilidadData.length === 0
                                      ? null
                                      : contabilidadData
                                          .map((row) => {
                                            return Number(row.contaDebe);
                                          })
                                          .reduce(reducer)
                                          .toFixed(2)}
                                  </Typography>
                                </TableCell>
                                <TableCell size="small" align="center">
                                  <Typography
                                    variant="body2"
                                    color="primary"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    $
                                    {contabilidadData.length === 0
                                      ? null
                                      : contabilidadData
                                          .map((row) => {
                                            return Number(row.contaHaber);
                                          })
                                          .reduce(reducer)
                                          .toFixed(2)}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    </>
                  ) : null}
                </Box>
                {/* <Divider /> */}
                <Box
                  display="flex"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="flex-end"
                    mt={1}
                  >
                    <Button
                      style={{ width: "30%" }}
                      variant="contained"
                      color="primary"
                      disabled={
                        cargando ||
                        (ObjectoActivo.estado === "AUTORIZADO" &&
                          item.estado !== "ANULADA") ||
                        ObjectoActivo.estado === "ANULADA"
                      }
                      onClick={() => {
                        setopenConfirmacionDialog(true);
                      }}
                    >
                      Guardar
                    </Button>
                  </Box>
                </Box>
              </div>
            </Box>
          </Draggable>
        </Box>
      </Modal>
    </>
  );
};

export default memo(ModalAsistencia);
