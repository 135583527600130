import React, { useState, useContext, useEffect, memo } from "react";
// MODULOS
import Draggable from "react-draggable";

// FUNCIONES
import { replaceCaracteres, trimPropsItem } from "../../../config/const";

// AXIOS
import clienteAxios from "../../../config/axios";
// CONTEXT
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../Context/Auth/AuthContext";
// MATERIAL
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  IconButton,
  TextField,
  FormControl,
  Autocomplete,
  Tooltip,
  MenuItem,
} from "@mui/material";
import { Check, Close, Save } from "@mui/icons-material";
import { useModalStyle } from "../../../styles/styleModal";
import { objectDefault, validacion } from "../data";
import ConfirmacionDialog from "../../components/Dialogs/ConfirmacionDialog";
import CampoDoc from "../../components/Campos/CampoDoc";

function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}

const ModalAnticipos = (props) => {
  //props
  const {
    editar,
    seteditar,
    setopen,
    open,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
  } = props;
  const [item, setitem] = useState(ObjectoActivo);
  const {
    // noti_id,
    noti_dirigido,
    noti_tipo_servicio,
    noti_ciudad,
    noti_sector,
    noti_titulo,
    noti_descripcion,
    // noti_foto,
    noti_mostar_descripcion,
    noti_url,
    noti_fecha_inicio,
    noti_fecha_fin,
    // noti_user,
    // noti_empresa,
    // noti_fecreg,
    // noti_fecupd,
  } = item;
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext);
  //  STATE
  const [openDialogClose, setopenDialogClose] = useState(false);
  const [modificado, setmodificado] = useState(false);

  // SECCION
  const seccionDefault = {
    seccionDatosPrincipales: true,
    seccionProductos: false,
  };
  const [seccionActive, setseccionActive] = useState(seccionDefault);
  const { seccionDatosPrincipales } = seccionActive;

  // DE SIEMPRE
  const [cargando, setcargando] = useState(false);
  const [error, seterror] = useState({});
  const clases = useModalStyle();
  const [modalStyle] = useState(getModalStyle);
  const [ciudadesData, setciudadesData] = useState(null);
  const [sectoresData, setSectoresData] = useState([]);

  const getciudadesData = async () => {
    try {
      const resciudadesData = await clienteAxios.get(`/ciudades/filtro/0`);

      setciudadesData(resciudadesData.data.data);
    } catch (error) {
      setciudadesData([]);
    }
  };
  useEffect(() => {
    if (!ciudadesData) {
      getciudadesData();
    } // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (open) {
      setmodificado(true);
    }
    // eslint-disable-next-line
  }, [item]);
  useEffect(() => {
    setitem(ObjectoActivo);
    setTimeout(() => {
      setmodificado(false);
    }, 500);

    // eslint-disable-next-line
  }, [ObjectoActivo]);

  const defaultActive = (args) => {
    if (args?.eliminarForm) {
      changeObjetoActivoState(objectDefault);
      setitem(objectDefault);
    }
    setopen(false);
    seteditar(false);
    seterror({});
    setcargando(false);
    setseccionActive(seccionDefault);
    setopenDialogClose(false);
    setmodificado(false);
    setSectoresData([]);
  };
  const handleChange = (e) => {
    const res = replaceCaracteres(e.target.value);
    setitem({
      ...item,
      [e.target.name]: res,
    });
  };

  const handleCrear = async () => {
    try {
      if (validacion(item, setitem, seterror)) {
        return mostrarAlerta("Faltan campos obligatorios", "error");
      }
      setcargando(true);

      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          tabla: "noticias",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          noti_user: usuario.usuario,
          noti_empresa: usuario.rucempresa,
        })
      );
      setcargando(false);
      return defaultActive({ eliminarForm: true });
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    try {
      if (validacion(item, setitem, seterror)) {
        return mostrarAlerta("Faltan campos obligatorios", "error");
      }
      setcargando(true);

      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          tabla: "noticias",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          noti_user: usuario.usuario,
          noti_empresa: usuario.rucempresa,
        })
      );
      setcargando(false);
      return defaultActive({ eliminarForm: true });
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleGurdar = () => {
    if (editar) {
      handleEditar();
    } else {
      handleCrear();
    }
  };
  if (!ciudadesData) {
    return null;
  }
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <ConfirmacionDialog
          open={openDialogClose}
          setopen={() => setopenDialogClose(false)}
          titulo={"Desea mantener los cambios?"}
          categoria="warning"
          botones={[
            {
              tooltip: "Mantener datos",
              texto: "SI",
              funcion: () => {
                defaultActive({ eliminarForm: false });
              },
              disabled: false,
              Icon: Check,
              color: "secondary",
              id: 1,
              ocultar: false,
              tipo: "boton",
              variante: "contained",
              size: "medium",
              sx: { marginRight: ".3rem" },
            },
            {
              tooltip: "Vaciar datos",
              texto: "NO",
              funcion: () => {
                defaultActive({ eliminarForm: true });
              },
              disabled: false,
              Icon: Close,
              color: "error",
              id: 2,
              ocultar: false,
              tipo: "boton",
              variante: "contained",
              size: "medium",
              sx: {},
            },
          ]}
        />
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  style={{ cursor: "-webkit-grab" }}
                  id="handle"
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {`${editar ? "Editar" : "Agregar"} noticias.`}
                  <Tooltip title={"Guardar"} followCursor placement="top-start">
                    <IconButton
                      onClick={() => {
                        handleGurdar();
                      }}
                    >
                      <Save color="secondary" />
                    </IconButton>
                  </Tooltip>
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    onClick={() => {
                      if (!modificado) return defaultActive();
                      setopenDialogClose(true);
                    }}
                  >
                    <Close color={modificado ? "error" : "primary"} />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box display="flex" justifyContent="space-between" width="100%">
                <Box display={"flex"} flexWrap={"wrap"}>
                  <Button
                    variant={
                      seccionDatosPrincipales
                        ? "contained"
                        : false
                        ? "outlined"
                        : "null"
                    }
                    color={false ? "error" : "primary"}
                    disableElevation
                    className={clases.buttonTab}
                    size="small"
                    onClick={() =>
                      setseccionActive({ seccionDatosPrincipales: true })
                    }
                  >
                    Datos Principales
                  </Button>
                </Box>
                {/* <FormControlLabel
                  control={
                    <Switch
                      checked={resEstado === "ACTIVA" ? true : false}
                      onChange={(e) => {
                        setitem({
                          ...item,
                          resEstado: e.target.checked ? "ACTIVA" : "INACTIVA",
                        });
                      }}
                      color="primary"
                    />
                  }
                  label="Estado"
                /> */}
              </Box>
              <Divider />
              <Box className={clases.container}>
                {seccionDatosPrincipales && (
                  <>
                    <TextField
                      label="Dirigido"
                      error={error.noti_dirigido}
                      value={noti_dirigido}
                      name="noti_dirigido"
                      onChange={(e) => handleChange(e)}
                      className={clases.formD}
                      size="small"
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      {/* <MenuItem value="">Seleccione.</MenuItem> */}
                      {["CLIENTE", "NO-CLIENTE"].map((item) => {
                        return (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                    <TextField
                      label="Tipo Servicio"
                      error={error.noti_tipo_servicio}
                      value={noti_tipo_servicio}
                      name="noti_tipo_servicio"
                      onChange={(e) => handleChange(e)}
                      className={clases.formD}
                      size="small"
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      {/* <MenuItem value="">Seleccione.</MenuItem> */}
                      {["HOGAR", "TRANSPORTE"].map((item) => {
                        return (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                    <FormControl fullWidth className={clases.formD}>
                      <Autocomplete
                        options={ciudadesData.map((item) => {
                          return item.ciuNombre;
                        })}
                        getOptionLabel={(option) => {
                          return option;
                        }}
                        id="controlled-demo"
                        value={noti_ciudad}
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const itemF = ciudadesData.find(
                              (ciudad) => ciudad.ciuNombre === newValue
                            ); 
                            setSectoresData(itemF.ciuSectores);
                            setitem({
                              ...item,
                              noti_ciudad: newValue,
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            required
                            label="Ciudad"
                            name="perUbicacionContrato"
                            error={error.perUbicacionContrato}
                            fullWidth
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl fullWidth className={clases.formD}>
                      <Autocomplete
                        options={sectoresData.map((item) => {
                          return item.nombre;
                        })}
                        getOptionLabel={(option) => {
                          return option;
                        }}
                        id="controlled-demo"
                        value={noti_sector}
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            setitem({
                              ...item,
                              noti_sector: newValue,
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            required
                            label="Sector"
                            name="perUbicacionContrato"
                            error={error.perUbicacionContrato}
                            fullWidth
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <TextField
                      label="Título"
                      error={error.noti_titulo}
                      value={noti_titulo}
                      name="noti_titulo"
                      onChange={(e) => handleChange(e)}
                      className={clases.formD}
                      size="small"
                    />
                    <CampoDoc
                      disabled={cargando}
                      disabledE={cargando}
                      setcargando={setcargando}
                      label={"Foto"}
                      propiedad={"noti_foto"}
                      item={item}
                      setitem={setitem}
                      extension={"IMAGEN"}
                    />
                    <TextField
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      label="fecha_inicio"
                      error={error.noti_fecha_inicio}
                      value={noti_fecha_inicio}
                      name="noti_fecha_inicio"
                      onChange={(e) => handleChange(e)}
                      className={clases.formD}
                      size="small"
                    />
                    <TextField
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      label="fecha_fin"
                      error={error.noti_fecha_fin}
                      value={noti_fecha_fin}
                      name="noti_fecha_fin"
                      onChange={(e) => handleChange(e)}
                      className={clases.formD}
                      size="small"
                    />
                    <TextField
                      label="Mostrar Descripción"
                      error={error.noti_mostar_descripcion}
                      value={noti_mostar_descripcion}
                      name="noti_mostar_descripcion"
                      onChange={(e) => handleChange(e)}
                      className={clases.formD}
                      size="small"
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      {/* <MenuItem value="">Seleccione.</MenuItem> */}
                      {["SI", "NO"].map((item) => {
                        return (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                    <TextField
                      label="URL"
                      error={error.noti_url}
                      value={noti_url}
                      name="noti_url"
                      onChange={(e) => handleChange(e)}
                      className={clases.formD}
                      size="small"
                    />
                    <TextField
                      label="Descripción"
                      variant="outlined"
                      multiline
                      minRows={2}
                      error={error.noti_descripcion}
                      value={noti_descripcion}
                      name="noti_descripcion"
                      onChange={(e) => handleChange(e)}
                      className={clases.formAllW}
                      size="small"
                    />
                  </>
                )}
              </Box>
              <Divider />
              <Box
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="flex-end"
                  mt={1}
                >
                  <Button
                    style={{ width: "30%" }}
                    variant="contained"
                    color="primary"
                    disabled={cargando}
                    onClick={() => {
                      handleGurdar();
                    }}
                  >
                    Guardar
                  </Button>
                </Box>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalAnticipos);
