import React, { memo, useState, useContext, useEffect, useRef } from "react";

import { Box, Divider } from "@mui/material";

import Tabla from "./Componentes/Tabla";
import Modal from "./Componentes/Modal";
import ModalB from "./Componentes/ModalB";
import BarraTablas from "../../components/BarraTablas/BarraTablas";
import clienteAxios from "../../config/axios";
import AlertaContext from "../../Context/Alerta/AlertaContext";
import AuthContext from "../../Context/Auth/AuthContext";
import io from "socket.io-client";
import { socketUrl } from "../../config/const";

const Proveedores = (props) => {
  const {
    documento,
    settab,
    setarrayExportHijos,
    desdeOtroLugarPersona,
    itemRelacionadoPadre,
  } = props;
  // DATA DEL STATE
  const [rows, setrows] = useState(null);
  const columns = [
    {
      id: "perDocTipo",
      label: "Tipo Doc",
      minWidth: 50,
      tipo: "string",

      propiedad: "perDocTipo",
    },
    {
      id: "perDocNumero",
      label: "Documento",
      minWidth: 50,
      tipo: "string",

      propiedad: "perDocNumero",
    },
    {
      id: "perNombre",
      label: "Nombres",
      minWidth: 50,
      tipo: "string",

      propiedad: "perNombre",
    },
    {
      id: "perISPContratos",
      label: "TOTAL",
      minWidth: 50,
      tipo: "string",

      propiedad: "perISPContratos",
    },
    {
      id: "perISPContratos",
      label: "Contratos",
      minWidth: 50,
      tipo: "string",

      propiedad: "perISPContratos",
    },
    {
      id: "perMovil",
      label: "Movil",
      minWidth: 50,
      tipo: "string",

      propiedad: "perMovil",
    },
    {
      id: "perObligado",
      label: "Obligado",
      minWidth: 50,
      tipo: "string",

      propiedad: "perObligado",
    },
    {
      id: "perEmail",
      label: "Email",
      minWidth: 50,
      tipo: "string",

      propiedad: "perEmail",
    },
    {
      id: "perUser",
      label: "User",
      minWidth: 50,
      tipo: "string",

      propiedad: "perUser",
    },
    {
      id: "asitecFecUpd",
      label: "Fec Upd",
      minWidth: 50,
      tipo: "string",
      propiedad: "asitecFecUpd",
    },
  ];

  const [ObjectoActivo, setObjetoActivo] = useState({
    // asitecId: 1,
    asitecFecha: "",
    asitecTipoServicio: [],
    asitecClienteTipo: "",
    asitecClienteDocumento: "",
    asitecClienteNombres: "",
    asitecClienteDireccion: "",
    asitecClienteCelular: [],
    asitecClienteContrato: "",
    asitecTecnicoDocumento: "",
    asitecTecnicoNombres: "",
    asitecTecnicoDireccion: "",
    asitecTecnicoCelular: [],

    asitecRedWifi: "",
    asitecPoseeRvoltaje: "",
    asitecEquiposInstalados: [],
    asitecCargosAdicionales: [],
    asitecEmpresa: "",
    asitecUser: "",
    asitecFecReg: "",
    Todos: "",
  });
  const [pagination, setpagination] = useState(null);

  // FUNCIONES STATE
  const getDataState = async (
    cantidad,
    page,
    search,
    input,
    orden,
    datos,
    rucempresa
  ) => {
    const res = await clienteAxios.get(
      `/asistenciastecnicas/?cantidad=${cantidad}&page=${page}&search=${
        search ? search : ""
      }&input=${input ? input : ""}&orden=${orden ? orden : ""}&datos=${
        datos ? datos : ""
      }&rucempresa=${rucempresa ? rucempresa : ""}&documento=${
        documento ? documento : ""
      }`
    );

    return res;
  };

  const obtenerState = async (
    cantidad = 10,
    page = 0,
    search,
    input,
    orden,
    datos,
    rucempresa
  ) => {
    const res = await getDataState(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa
    );
    setrows(res.data.data.results);
    setpagination(res.data.data.pagination);
  };
  const addRowsState = async (items) => {
    setrows([...rows, ...items]);
  };
  const remplazarRowsState = async (
    cantidad,
    page,
    search,
    input,
    orden,
    datos,
    rucempresa
  ) => {
    const res = await getDataState(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa
    );
    setrows(res.data.data.results);
    setpagination(res.data.data.pagination);
    // dispatch({
    //   type: OBTENER,
    //   payload: {
    //     results: res.data.data.results,
    //     pagination: res.data.data.pagination,
    //   },
    // });
  };
  const agregarState = async (item, funcionAalerta) => {
    try {
      setrows([item, ...rows]);

      setpagination({
        ...pagination,
        numRows: pagination.numRows + 1,
      });
      funcionAalerta();
    } catch (error) {}
  };

  const editarState = async (item, funcionAalerta) => {
    try {
      const resRows = rows.map((itemR) => {
        if (itemR.asitecId === item.asitecId) {
          return item;
        } else {
          return itemR;
        }
      });

      setrows(resRows);
      funcionAalerta();
    } catch (error) {}
  };

  const eliminarState = async (_id, funcionAalerta) => {
    try {
      const resRows = rows.filter((row) => {
        return row.asitecId !== _id;
      });
      setrows(resRows);
      setpagination({
        ...pagination,
        numRows: pagination.numRows - 1,
      });
      funcionAalerta();
    } catch (error) {}
  };
  const eliminarSeleccionState = async (_ids, funcionAalerta) => {
    const resRows = rows.filter((row) => {
      return !_ids.some((item) => {
        return item === row.asitecId;
      });
    });
    setpagination({
      ...pagination,
      numRows: pagination.numRows - _ids.length,
    });
    setrows(resRows);
  };

  const changeObjetoActivoState = (item) => {
    setObjetoActivo(item);
    // dispatch({
    //   type: CHANGE_ACTIVO,
    //   payload: item,
    // });
  };

  const rowsImportState = (rows) => {
    // dispatch({
    //   type: IMPORTAR_ROWS,
    //   payload: rows,
    // });
    setrows(rows);
  };
  // FIN FUNCIONES STATE
  // FIN DATA STATE

  // FUNCIONES SOCKET
  const [tabla] = useState("asistenciatecnica");
  const [itemSocket, setitemSocket] = useState({
    tipo: "",
    item: {},
  });
  const socket = useRef();
  useEffect(() => {
    // Creates a WebSocket connection
    socket.current = io(socketUrl);
    socket.current.on("server:guardadoExitoso", (data) => {
      setitemSocket({
        tipo: "agregar",
        item: data,
      });
    });
    socket.current.on("server:actualizadoExitoso", (data) => {
      setitemSocket({
        tipo: "editar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitoso", (data) => {
      setitemSocket({
        tipo: "eliminar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitosoSeleccionado", (data) => {
      setitemSocket({
        tipo: "eliminarSeleccion",
        item: data,
      });
    });

    socket.current.on("server:error", (data) => {
      mostrarAlerta(
        data.msg ? data.msg : "Hubo un error",
        data.alert ? data.alert : "error"
      );
    });
    return () => {
      socket.current.disconnect();
    };
    // Destroys the socket reference
    // when the connection is closed
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    socket.current.on("server:guardadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }
      setitemSocket({
        tipo: "agregar",
        item: data,
      });
    });
    socket.current.on("server:actualizadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }

      setitemSocket({
        tipo: "editar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }
      setitemSocket({
        tipo: "eliminar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitosoSeleccionado", (data) => {
      if (data.tabla !== tabla) {
        return;
      }
      setitemSocket({
        tipo: "eliminarSeleccion",
        item: data,
      });
    });

    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const { item, tipo } = itemSocket;

    if (tipo === "") {
      return;
    }
    if (!rows) {
      return;
    }
    if (usuario.rucempresa !== item.rucempresa) {
      return;
    }
    if (item.tabla !== tabla) {
      return;
    }

    if (desdeOtroLugarPersona && item.asitecTecnicoDocumento !== documento) {
      return;
    }
    const funcionAalerta = () => {
      if (item.msg === "") {
        return null;
      }
      if (!item.msg) {
        return null;
      }
      mostrarAlerta(item.msg, item.alert ? item.alert : "success");
    };

    if (tipo === "agregar") {
      agregarState(item);
    }
    if (tipo === "editar") {
      editarState(item);
    }
    if (tipo === "eliminar") {
      eliminarState(item.hcliId);
    }
    if (tipo === "eliminarSeleccion") {
      eliminarSeleccionState(item.lista);
    }
    funcionAalerta();
    setitemSocket({ tipo: "", item: {} });
    // eslint-disable-next-line
  }, [itemSocket]);

  const [open, setopen] = useState(false);
  const [buscando, setbuscando] = useState(false);
  const [editar, seteditar] = useState(false);

  const [search, setsearch] = useState("");
  const [cargando, setcargando] = useState(false);
  const [input, setinput] = useState({
    id: "asitecId",
    label: "ID",
    minWidth: 150,
    tipo: "string",

    propiedad: "asitecId",
  });
  const [arrayExport, setarrayExport] = useState([]);
  const [campoExport] = useState("asitecId");
  useEffect(() => {
    if (desdeOtroLugarPersona) {
      settab("");
      setarrayExportHijos(arrayExport.map((item) => item[campoExport]));
    }
    // eslint-disable-next-line
  }, [arrayExport]);
  const { mostrarAlerta } = useContext(AlertaContext);
  const {
    usuario: { rucempresa },
    usuario,
  } = useContext(AuthContext);
  const [orden, setorden] = useState(false);
  const [openMB, setopenMB] = useState(false);
  const [fullBuscador, setfullBuscador] = useState({
    perFecReg1: "",
    perFecReg2: "",
  });

  //pagina actual
  const [page, setPage] = React.useState(0);
  //filas por pagina
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // input
  //
  const handleChange = (e) => {
    setfullBuscador({ ...fullBuscador, [e.target.name]: e.target.value });
  };
  const funcionB = async () => {
    setcargando(true);
    await remplazarRowsState(
      rowsPerPage,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa
    );
    setPage(0);
    setbuscando(true);
    setopenMB(false);
    setcargando(false);
  };

  const handleSearch = async () => {
    setarrayExport([]);
    setcargando(true);
    await remplazarRowsState(
      rowsPerPage,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa
    );

    setPage(0);
    setcargando(false);
  };
  const funcionDefault = async () => {
    setcargando(true);
    setfullBuscador({
      perFecReg1: "",
      perFecReg2: "",
    });
    await remplazarRowsState(
      rowsPerPage,
      0,
      "",
      input.id,
      orden,
      JSON.stringify({
        perFecReg1: "",
        perFecReg2: "",
      }),
      rucempresa
    );

    setPage(0);
    setsearch("");
    setbuscando(false);
    setcargando(false);
    setopenMB(false);
  };

  return (
    <Box>
      <BarraTablas
        search={search}
        setsearch={setsearch}
        funcion={handleSearch}
        buscando={buscando}
        setbuscando={setbuscando}
        setopen={(boolean) => {
          if (desdeOtroLugarPersona) {
            setObjetoActivo({
              ...ObjectoActivo,
              asitecTecnicoDocumento: itemRelacionadoPadre.perDocNumero,
              asitecTecnicoNombres: itemRelacionadoPadre.perNombre,
              asitecTecnicoDireccion: itemRelacionadoPadre.perDireccion,
              asitecTecnicoCelular: itemRelacionadoPadre.perCelular,
            });
          }
          setopen(boolean);
        }}
        open={open}
        openMB={openMB}
        setopenMB={setopenMB}
        LabelBuscador="Buscar por todos los campos"
        funcionDefault={funcionDefault}
        subirArchivo={false}
        ocultarMigasDePan={desdeOtroLugarPersona}
      />
      <ModalB
        openMB={openMB}
        setopenMB={setopenMB}
        titulo={`Busqueda avanzada Asistencias`}
        columns={columns}
        fullBuscador={fullBuscador}
        setfullBuscador={setfullBuscador}
        handleChange={handleChange}
        funcionB={funcionB}
        buscando={buscando}
        setbuscando={setbuscando}
        funcionDefault={funcionDefault}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems=" stretch"
        width="100%"
      ></Box>
      <Divider />
      <Tabla
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        search={search}
        input={input}
        orden={orden}
        setinput={setinput}
        setorden={setorden}
        seteditar={seteditar}
        setopen={setopen}
        buscando={buscando}
        cargando={cargando}
        setcargando={setcargando}
        fullBuscador={fullBuscador}
        //  STATE
        rows={rows}
        columns={columns}
        ObjectoActivo={ObjectoActivo}
        pagination={pagination}
        // FUNCIONES STATE
        getDataState={getDataState}
        obtenerState={obtenerState}
        addRowsState={addRowsState}
        remplazarRowsState={remplazarRowsState}
        agregarState={agregarState}
        editarState={editarState}
        eliminarState={eliminarState}
        eliminarSeleccionState={eliminarSeleccionState}
        changeObjetoActivoState={changeObjetoActivoState}
        rowsImportState={rowsImportState}
        arrayExport={arrayExport}
        setarrayExport={setarrayExport}
        // puedeExportar={puedeExportar}
        desdeOtroLugarPersona={desdeOtroLugarPersona}
        socket={socket}
      />
      <Modal
        editar={editar}
        seteditar={seteditar}
        setopen={setopen}
        open={open}
        ObjectoActivo={ObjectoActivo}
        changeObjetoActivoState={changeObjetoActivoState}
        socket={socket}
      />
    </Box>
  );
};

export default memo(Proveedores);
