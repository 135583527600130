import React, { memo } from "react";
import { makeStyles } from "@mui/styles";
import { Email, WhatsApp } from "@mui/icons-material";
import {
  Box,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  DialogTitle,
  Dialog,
} from "@mui/material";

import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  WhatsApp: {
    backgroundColor: theme.palette.success.main,
  },
  avatar2: {
    backgroundColor: theme.palette.secondary.main,
  },
  delete: {
    backgroundColor: theme.palette.error.main,
  },
  bg: {
    backgroundColor: theme.palette.primary.dark,
  },
}));
const ExportDialog = (props) => {
  const classes = useStyles();
  const { open, setopen } = props;

  return (
    <Dialog
      onClose={() => setopen(false)}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Box padding={2}>
        <DialogTitle id="simple-dialog-title">Acciones.</DialogTitle>
        <List>
          {" "}
          <ListItem button onClick={() => {}}>
            <ListItemAvatar>
              <Avatar className={classes.WhatsApp}>
                <WhatsApp />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="WhatsApp" />
          </ListItem>{" "}
          <ListItem
            button
            onClick={() => {
              // setopenModalEmail(true);
            }}
          >
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <Email />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Correo" />
          </ListItem>{" "}
        </List>
      </Box>
    </Dialog>
  );
};

export default memo(withRouter(ExportDialog));
