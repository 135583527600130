import { makeStyles } from "@mui/styles";
export const useButonStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
  bg: {
    backgroundColor: theme.palette.secondary.main,
  },
  success: { backgroundColor: theme.palette.success.main },
}));
