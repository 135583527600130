import { lighten } from "@mui/material";
import { makeStyles } from "@mui/styles";
export const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
    height: "2rem",
  },
  hover: {
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  celda: {
    fontSize: ".7rem",
    backgroundColor: lighten(theme.palette.primary.main, 0.9),
    border: `0.5px solid ${lighten(theme.palette.primary.main, 0.6)}`,
    padding: "0rem .2rem",
  },
  celdaSecundario: {
    fontSize: ".7rem",
    border: `0.5px solid ${lighten(theme.palette.primary.main, 0.6)}`,
    padding: "0rem .2rem",
  },
  celdaHeader: {
    fontSize: ".7rem",
    backgroundColor: lighten(theme.palette.secondary.main, 0.75),
    border: `0.5px solid ${lighten(theme.palette.secondary.main, 0.5)}`,
  },
  celdaFooter: {
    fontSize: ".85rem",
    fontWeight: "bold",
    backgroundColor: lighten(theme.palette.secondary.main, 0.8),
    border: `0.5px solid ${lighten(theme.palette.secondary.main, 0.6)}`,
    color: theme.palette.secondary.dark,
  },
  celdaNuevo: {
    fontSize: ".7rem",
    backgroundColor: lighten(theme.palette.success.main, 0.75),
  },
  celdaEditado: {
    fontSize: ".7rem",
    backgroundColor: lighten(theme.palette.info.main, 0.75),
  },
  celdaEliminado: {
    fontSize: ".7rem",
    backgroundColor: lighten(theme.palette.error.main, 0.75),
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

export const butonIconTransition = {
  padding: "4px",
};
