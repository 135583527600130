import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
} from "@mui/material";
import Cargando from "../components/Cargando/Cargando";
import { Doughnut } from "react-chartjs-2";

const reducer = (previousValue, currentValue) =>
  Number(previousValue) + Number(currentValue);
const GraficoDougProductos = ({ titulo, dataGrafico }) => {
  const [data, setdata] = useState(null);
  const [labels, setlabels] = useState([]);
  const [total] = useState(
    Object.values(dataGrafico.productos_mas_vendidos).length === 0
      ? 0
      : Object.values(dataGrafico.productos_mas_vendidos)
          .map((numero) => {
            return Number(numero.inv_num_ventas);
          })
          .reduce(reducer)
          .toFixed(0)
  );
  useEffect(() => {
    const { productos_mas_vendidos } = dataGrafico;

    const labels = productos_mas_vendidos.map((item) => {
      return `${item.invNombre}`;
    });
    const data = productos_mas_vendidos.map((item) => item.inv_num_ventas);
    setlabels(labels);
    console.log(data);
    setdata({
      labels: [],
      datasets: [
        {
          label: "Productos",
          data: data,
          fill: true,
          borderColor: "rgb(75, 192, 192)",
          tension: 0.1,
          backgroundColor: [
            "rgb(255, 99, 132)",
            "rgb(54, 162, 235)",
            "rgb(255, 205, 86)",
            "rgb(255, 205, 186)",
            "rgb(25, 25, 86)",
            "rgb(255, 5, 86)",
          ],
        },
      ],
    });
    // eslint-disable-next-line
  }, []);
  if (!data) {
    return <Cargando titulo="Cargando" />;
  }

  return (
    <Box overflow={"scroll"}>
      <Typography variant="h5" align="center" color="secondary">
        {titulo}
      </Typography>

      <Doughnut
        data={data}
        height={85}
        options={{
          plugins: {
            legend: {
              display: false, // This will hide the default legend.
            },
          },
        }}
      />
      {/* Custom legend */}
      <Grid container justifyContent={"center"}>
        {labels.map((label, index) => (
          <Grid item sx={{ mr: 1 }}>
            <Box display={"flex"} alignItems={"center"} gap={0.5}>
              <Box
                sx={{
                  width: 10,
                  height: 10,
                  backgroundColor: data.datasets[0].backgroundColor[index],
                  borderRadius: "50%",
                }}
              />

              <Typography variant="body1" color="initial">
                {label}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default GraficoDougProductos;
