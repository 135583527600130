import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import {
  Avatar,
  IconButton,
  InputAdornment,
  ListItemAvatar,
  TextField,
  Typography,
  Box,
  Switch,
} from "@mui/material";
import { useContext, useState } from "react";
import {
  Cancel,
  NavigateBefore,
  NavigateNext,
  Refresh,
  Search,
} from "@mui/icons-material";
import AuthContext from "../../../Context/Auth/AuthContext";
import Cargando from "../../components/Cargando/Cargando";
import AlertaContext from "../../../Context/Alerta/AlertaContext";

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function ListPersonas({
  left,
  setLeft,
  right,
  setRight,
  error,
  // PAGINACION,
  estadoRequest,
  setestadoRequest,
  page,
  setPage,
  setcargando,
  paginas,
  onPaginate,
  cargando,
  totalItems,
  isActive,
  setisActive,
  setSearch,
  search,
}) {
  const [buscadorPE, setbuscadorPE] = useState("");
  const [buscadorPD, setbuscadorPD] = useState("");
  const [checked, setChecked] = useState([]);
  const {
    usuario: { empRoles },
    usuario,
  } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);

  const [leftF, setLeftF] = useState([]);
  const [leftB, setleftB] = useState(false);
  const [rightF, setRightF] = useState([]);
  const [rightB, setRightB] = useState(false);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    if (right.concat(leftChecked).length > 100) {
      return mostrarAlerta(
        `Solo se puede enviar mensaje como maximo a ${
          right.concat(leftChecked).length
        } / 100`,
        "error"
      );
    }
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };
  const filtrarPorNombre = (arreglo, subcadena) => {
    return arreglo.filter((objeto) =>
      objeto.perNombre.toLowerCase().includes(subcadena.toLowerCase())
    );
  };

  const customList = (items, left, sinFiltros) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "Todos las personas seleccionadas",
            }}
          />
        }
        title={`${numberOfChecked(items)}/${items.length} seleccionadas / ${
          sinFiltros.length
        } `}
        subheader={
          <>
            {left
              ? `${page + 1}/${Math.max(0, paginas)} - Personas: ${totalItems}`
              : ""}
            {left && (
              <>
                <IconButton
                  onClick={async () => {
                    if (page === 0) {
                      return;
                    }
                    setcargando(true);
                    await onPaginate(
                      { perfil: estadoRequest, page: page - 1 },
                      right
                    );
                    setPage((prev) => prev - 1);
                    setcargando(false);
                  }}
                >
                  <NavigateBefore />
                </IconButton>
                <IconButton
                  onClick={async () => {
                    if (page >= paginas - 1) {
                      return;
                    }
                    setcargando(true);
                    await onPaginate(
                      { perfil: estadoRequest, page: page + 1 },
                      right
                    );
                    setPage((prev) => prev + 1);
                    setcargando(false);
                  }}
                >
                  <NavigateNext />
                </IconButton>
              </>
            )}
          </>
        }
      />
      <Divider />
      <List
        sx={{
          width: "100%",
          height: 230,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value.perId}-label`;

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <ListItemAvatar>
                  <Avatar />
                </ListItemAvatar>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value.perNombre}`} />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid
        item
        sx={12}
        md={12}
        mt={2}
        display={"flex"}
        justifyContent={"center"}
      >
        {error && (
          <Typography variant="subtitle1" color="error">
            Seleccione al menos una persona
          </Typography>
        )}
      </Grid>

      <Grid sx={12} md={6} item width={"100%"}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onPaginate(
              { perfil: estadoRequest, page: 0 },
              right,
              isActive,
              search
            );
          }}
        >
          <TextField
            size="small"
            fullWidth
            label="Buscar personas"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton
               
                    onClick={() => {
                      setSearch("");
                      onPaginate(
                        { perfil: estadoRequest, page: 0 },
                        right,
                        isActive,
                        ""
                      );
                    }}
                    color="error"
                  >
                    <Refresh />
                  </IconButton>
                  <IconButton
                    disabled={search === ""}
                    type="submit"
                    color="primary"
                  >
                    <Search />
                  </IconButton>
                  <Switch  color="secondary"
                    checked={isActive === 1}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setisActive(1);
                        setSearch("");
                        setPage(0);
                        onPaginate(
                          { perfil: estadoRequest, page: 0 },
                          right,
                          1,
                          ""
                        );
                      } else {
                        setisActive(0);
                        setSearch("");
                        setPage(0);
                        onPaginate(
                          { perfil: estadoRequest, page: 0 },
                          right,
                          0,
                          ""
                        );
                      }
                    }}
                    inputProps={{ "aria-label": "" }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </form>
        <Button
          fullWidth
          sx={{ my: 0.5 }}
          variant="outlined"
          size="small"
          onClick={handleCheckedRight}
          disabled={leftChecked.length === 0}
          aria-label="move seleccionado right"
        >
          Seleccionar &gt;
        </Button>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems=" stretch"
          width="100%"
        >
          <Box>
            {empRoles.slice(2).map((itemRol, index) => {
              const rolCliente = usuario.rol.find((item) => item === "CLIENTE");
              if (
                usuario.rol.length === 1 &&
                rolCliente &&
                itemRol !== "CLIENTE"
              ) {
                return null;
              }

              return (
                <Button
                  size="small"
                  key={index}
                  variant={estadoRequest === itemRol ? "contained" : null}
                  onClick={async () => {
                    setcargando(true);
                    onPaginate({ perfil: itemRol, page: 0 }, right);
                    setPage(0);
                    setestadoRequest(itemRol);
                    setcargando(false);
                  }}
                  disableElevation
                  style={{
                    padding: ".5rem",
                    borderRadius: "15px 15px  0px 0px",
                  }}
                  color="primary"
                >
                  {itemRol}
                </Button>
              );
            })}
          </Box>
        </Box>
        {cargando ? <Cargando /> : customList(leftB ? leftF : left, true, left)}
      </Grid>
      <Grid sx={12} md={6} item width={"100%"}>
        <TextField
          size="small"
          fullWidth
          label="Buscar personas"
          value={buscadorPD}
          onChange={(e) => {
            setbuscadorPD(e.target.value);
            if (e.target.value.trim() !== "") {
              setRightF(filtrarPorNombre(right, e.target.value));
              setRightB(true);
            } else {
              setRightB(false);
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton
                  disabled={!rightB}
                  onClick={() => {
                    setbuscadorPD("");
                    setRightB(false);
                  }}
                  color="error"
                >
                  <Cancel />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          fullWidth
          sx={{ my: 0.5 }}
          variant="outlined"
          size="small"
          onClick={handleCheckedLeft}
          disabled={rightChecked.length === 0}
          aria-label="move seleccionado left"
        >
          &lt; Deseleccionar
        </Button>

        {customList(rightB ? rightF : right, false, right)}
      </Grid>
    </Grid>
  );
}
