import React, { memo, useContext } from "react";

import {
  TableCell,
  TableRow,
  TextField,
  IconButton,
  Tooltip,
} from "@mui/material";

import { useState } from "react";

import { withRouter } from "react-router";

import AuthContext from "../../../../Context/Auth/AuthContext";
import {
  Cancel,
  DeleteForever,
  LocationOn,
  Person,
  PersonPin,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import ConfirmacionDialog from "../../../components/Dialogs/ConfirmacionDialog";
import ButtonsAction from "../../../components/Tablas/ButtonsAction";
import { trimPropsItem } from "../../../../config/const";
import { usePermiso } from "../../../../hooks/usePermiso";
import {
  useRowStyles,
  butonIconTransition,
} from "../../../../styles/stylesRows";
import DialogMap from "../../../../components/Extra/DialogMap";
import ImagenCelda from "../../../components/Celdas/ImagenCelda";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import clienteAxios from "../../../../config/axios";
const Row = (props) => {
  // CLASES
  const classes = useRowStyles();

  // PROPS
  const {
    arrayExport,
    setarrayExport,
    row,
    index,
    campoExport,
    socket,
    changeObjetoActivoStatePropietario,
    setopenPropietarioModal,
  } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    row.notVisto === "NO"
      ? classes.celdaEditado
      : row.nuevo === true
      ? classes.celdaNuevo
      : row.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;
  // EDITAR
  const [editar, seteditar] = useState(false);
  // HOOK DE PERMISO
  const { tienePermiso, alertaPermiso } = usePermiso("Monitoreo");
  const [openMap, setopenMap] = useState(false);
  // CONTEXT
  const { usuario } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  // SI SE AGREGA UN ITEM A LA TABLA HIJA
  // COPIA DEL REGISTRO PARA EDITAR
  const [item, setitem] = useState({ ...row });
  // COLUMNAS DE LA TABLA HIJA

  //onChange
  const handleChange = (e) => {
    setitem({ ...item, [e.target.name]: e.target.value });
  };
  // DESHABILITAR BOTON
  const botonDisabled = () => {
    if (true) {
      return true;
    }
  };
  // ELIMINAR EL REGISTRO
  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        tabla: "notificacion",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        notUser: usuario.usuario,
        notEmpresa: usuario.rucempresa,
        [campoExport]: row[campoExport],
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };
  const funcSubmit = (agregando) => {
    try {
      if (agregando) {
        socket.current.emit(
          "client:guardarData",
          trimPropsItem({
            ...row,

            tabla: "notificacion",
            rucempresa: usuario.rucempresa,
            rol: usuario.rol,
            notUser: usuario.usuario + "/+/" + row[campoExport],
            notEmpresa: usuario.rucempresa,
            [campoExport]: row[campoExport],
          })
        );
      } else {
        socket.current.emit(
          "client:actualizarData",
          trimPropsItem({
            ...item,
            tabla: "notificacion",
            rucempresa: usuario.rucempresa,
            rol: usuario.rol,
            notUser: usuario.usuario,
            notEmpresa: usuario.rucempresa,
          })
        );
      }

      seteditar(false);
    } catch (error) {}
  };

  // FUNCION PARA EDITAR, ABRE EL MODAL COLOCA EN EDITAR EL MODAL Y CAMBIA EL OBJETO ACTIVO
  const funcionEdit = () => {
    setitem({ ...row });
    seteditar(true);
  };
  const funcionEditPropietario = async () => {
    try {
      const res = await clienteAxios(`/proveedores/${row.notIdPersona}`);
      if (res.data.data.length === 0) {
        return mostrarAlerta(
          "Hubo un error en la consulta del propietario",
          "error"
        );
      }

      changeObjetoActivoStatePropietario(res.data.data[0]);
      setopenPropietarioModal(true);
      // setopen(true);
      // seteditar(true);
      // changeObjetoActivoState(row);
    } catch (error) {
      console.log({ error });
      mostrarAlerta("Hubo un error", "error");
    }
  };
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow className={classes.root} component={"form"}>
        <ButtonsAction
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          row={row}
          claseCelda={claseCelda}
          botones={[
            // {
            //   tooltip: "Recuperar",
            //   texto: "",
            //   funcion: () => {
            //     if (!tienePermiso("agregar")) {
            //       return alertaPermiso("agregar");
            //     }
            //     funcSubmit(true);
            //   },
            //   disabled: usuario.usuario !== row.notUser.split("/+/")[0],
            //   Icon: Restore,
            //   color: "success",
            //   id: 3,
            //   ocultar: !row.eliminado,
            //   tipo: "icono",
            //   variante: "contained",
            //   size: "small",
            //   sx: butonIconTransition,
            // },
            // {
            //   tooltip: "Eliminar Registro",
            //   texto: "",
            //   funcion: () => {
            //     if (!tienePermiso("eliminar")) {
            //       return alertaPermiso("eliminar");
            //     }
            //     setopenConfirmDialog(true);
            //   },
            //   disabled: false,
            //   Icon: DeleteForever,
            //   color: "error",
            //   id: 1,
            //   ocultar: editar || row.eliminado || true,
            //   tipo: "icono",
            //   variante: "contained",
            //   size: "small",
            //   // css
            //   sx: butonIconTransition,
            // },
            // {
            //   tooltip: "Editar Registro",
            //   texto: "",
            //   funcion: () => {
            //     if (!tienePermiso("editar")) {
            //       return alertaPermiso("editar");
            //     }
            //     funcionEdit();
            //   },
            //   disabled: false,
            //   Icon: Create,
            //   color: "secondary",
            //   id: 2,
            //   ocultar: editar || row.eliminado || true,
            //   tipo: "icono",
            //   variante: "contained",
            //   size: "small",
            //   sx: butonIconTransition,
            // },
            // {
            //   tooltip: "Cancelar",
            //   texto: "",
            //   funcion: () => {
            //     seteditar(false);
            //     setitem({ ...row });
            //     // setItemEdit(itemHijo);
            //   },
            //   disabled: false,
            //   Icon: Cancel,
            //   color: "error",
            //   id: 1,
            //   ocultar: !editar,
            //   tipo: "icono",
            //   variante: "contained",
            //   size: "small",
            //   sx: butonIconTransition,
            // },
            // {
            //   tooltip: "Guardar Edición",
            //   texto: "",
            //   funcion: () => {
            //     funcSubmit();
            //   },
            //   disabled: botonDisabled(),
            //   Icon: Check,
            //   color: "success",
            //   id: 2,
            //   ocultar: !editar,
            //   tipo: "icono",
            //   variante: "contained",
            //   size: "small",
            //   sx: butonIconTransition,
            //   submit: true,
            // },
            // {
            //   tooltip: row.notVisto === "SI" ? "Visto" : "No Visto",
            //   texto: "",
            //   funcion: () => {},
            //   disabled: false,
            //   Icon: row.notVisto === "SI" ? Visibility : VisibilityOff,
            //   color: row.notVisto === "SI" ? "primary" : "secondary",
            //   id: 2,
            //   ocultar: true,
            //   tipo: "icono",
            //   variante: "contained",
            //   size: "small",
            //   sx: "",
            //   submit: false,
            // },
            {
              tooltip: "Mapa",
              texto: "",
              funcion: () => {
                setopenMap(true);
              },
              disabled: false,
              Icon: LocationOn,
              color: "info",
              id: 2,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: "",
              submit: false,
            },
            {
              tooltip: "Persona",
              texto: "",
              funcion: () => {
                funcionEditPropietario();
              },
              disabled: false,
              Icon: PersonPin,
              color: "secondary",
              id: 2,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: "",
              submit: false,
            },
          ]}
        />

        {editar ? (
          <>
            <TableCell size="small" align="center" className={claseCelda}>
              <TextField
                value={item.chat_name}
                name="chat_name"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {item.notUser}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {new Date(item.notFecReg).toLocaleString("es-ES", {
                weekday: "short",
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour: "2-digit",
              })}
            </TableCell>
          </>
        ) : (
          <>
            <DialogMap
              open={openMap}
              setopen={setopenMap}
              campo={"coordenadas"}
              setitem={setitem}
              item={row.notInformacionAdicional}
              editar={false}
            />{" "}
            <TableCell size="small" align="center" className={claseCelda}>
              <Tooltip title={row.notVisto === "SI" ? "Visto" : "No Visto"}>
                <IconButton
                  disabled={row.notIdPerPertenece !== usuario.id}
                  aria-label=""
                  size="small"
                  onClick={() => {
                    socket.current.emit(
                      "client:actualizarData",
                      trimPropsItem({
                        ...row,
                        rucempresa: usuario.rucempresa,
                        rol: usuario.rol,
                        notUser: usuario.usuario,
                        notEmpresa: usuario.rucempresa,
                        tabla: "notificacion-leido",
                      })
                    );
                  }}
                  color={row.notVisto === "SI" ? "primary" : "secondary"}
                >
                  {row.notVisto === "SI" ? (
                    <Visibility size="small" />
                  ) : (
                    <VisibilityOff size="small" />
                  )}
                </IconButton>
              </Tooltip>
            </TableCell>
            <ImagenCelda
              propiedad={row.perFotoCasa}
              array={false}
              claseCelda={claseCelda}
              texto={""}
            />
            <ImagenCelda
              propiedad={row.perFotoVehiculo}
              array={false}
              claseCelda={claseCelda}
              texto={""}
            />
            <TableCell size="small" align="center" className={claseCelda}>
              {row.perDocNumero} {row.perNombre}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {row.notTipo}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {new Date(row.notFecReg).toLocaleString("es-ES", {
                weekday: "short",
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour: "2-digit",
              })}
            </TableCell>
          </>
        )}
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
