import React, { memo, useContext, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  TableCell,
  TableRow,
  TextField,
  Box,
  IconButton,
  FormControl,
  Autocomplete,
} from "@mui/material";

import { useState } from "react";

import { withRouter } from "react-router";

import ConfirmacionDialog from "../../../../../components/Extra/ConfirmacionDialog";

import AuthContext from "../../../../../Context/Auth/AuthContext";
import AlertaContext from "../../../../../Context/Alerta/AlertaContext";
import { Cancel, Check, Create, Delete } from "@mui/icons-material";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  celda: {
    fontSize: ".7rem !important",
  },
  hover: {
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const RowArma = (props) => {
  const {
    contaEliminados,
    setcontaEliminados,
    planDeCuentas,
    setcontabilidadData,
    contabilidadData,
    row,
    index,
  } = props;

  const [itemHijoEdit, setitemHijoEdit] = useState(row);
  const {
    contaTipo,
    contaRuc,
    contaNombre,
    contaFechaDoc,
    contaCuenta,
    contaCuentaNombre,
    contaObservacion,
    contaDebe,
    contaHaber,
    contaDocumento,
  } = itemHijoEdit;
  const { mostrarAlerta } = useContext(AlertaContext);
  const [cargando, setcargando] = useState(false);
  // TABLA

  const { usuario } = useContext(AuthContext);
  const handleChange = (e) => {
    setitemHijoEdit({
      ...itemHijoEdit,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setitemHijoEdit(row);
    // eslint-disable-next-line
  }, [row]);

  const classes = useRowStyles();
  const handleEliminar = async (_id) => {
    try {
      // await clienteAxios.delete(`/contabilidad/${itemHijoEdit.contaId}`);
      const res = contabilidadData.filter((contaItem, contaIndex) => {
        return contaIndex !== index;
      });
      if (itemHijoEdit.contaId !== 0) {
        setcontaEliminados([itemHijoEdit, ...contaEliminados]);
      }
      setcontabilidadData(res);
    } catch (error) {
      mostrarAlerta("Contabilidad: Hubo un error");
    }
  };
  const funcionEdit = () => {
    seteditar(true);
  };
  const [editar, seteditar] = useState(false);
  const funcCancel = () => {
    setitemHijoEdit(row);
    seteditar(false);
  };

  const botonDisabled = () => {
    if (
      contaTipo.trim() === "" ||
      contaFechaDoc.trim() === "" ||
      contaCuenta.trim() === "" ||
      contaDebe.trim() === "" ||
      contaHaber.trim() === ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  const funcSubmit = async () => {
    setcargando(true);
    try {
      const resItem = {
        ...itemHijoEdit,
        contaUser: usuario.usuario,
        contaEmpresa: usuario.rucempresa,
      };

      const mantenimientosDataR = [
        ...contabilidadData.map((contaItem, contaIndex) => {
          if (contaIndex === index) {
            return resItem;
          } else {
            return contaItem;
          }
        }),
      ];

      setcontabilidadData(mantenimientosDataR);
      setcargando(false);
      seteditar(false);
    } catch (error) {
      mostrarAlerta("Hubo un error", "error");
      setcargando(false);
      seteditar(false);
    }
  };
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);

  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo={`¿Esta seguro que quiere eliminar este registro?`}
        _id={itemHijoEdit._id}
        funcion={handleEliminar}
      />
      <TableRow className={classes.root}>
        <TableCell padding="none" style={{ borderRight: "1px solid #ccc" }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center" minWidth={"4rem"}>
              {editar ? (
                <>
                  <IconButton
                    className={classes.margin}
                    disabled={cargando}
                    size="small"
                    onClick={async () => {
                      await funcCancel();
                    }}
                  >
                    <Cancel fontSize="small" color="secondary" />
                  </IconButton>
                  <IconButton
                    className={classes.margin}
                    disabled={botonDisabled() || cargando}
                    size="small"
                    onClick={async () => {
                      await funcSubmit();
                    }}
                  >
                    <Check
                      fontSize="small"
                      color={botonDisabled() ? "inherit" : "secondary"}
                    />
                  </IconButton>
                </>
              ) : (
                <>
                  <IconButton
                    className={classes.margin}
                    size="small"
                    onClick={async () => {
                      await setopenConfirmDialog(true);
                    }}
                  >
                    <Delete fontSize="small" color={"error"} />
                  </IconButton>
                  <IconButton
                    className={classes.margin}
                    size="small"
                    onClick={async () => {
                      await funcionEdit();
                    }}
                  >
                    <Create fontSize="small" color={"secondary"} />
                  </IconButton>
                </>
              )}
            </Box>
          </Box>
        </TableCell>
        {editar ? (
          <>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                size="small"
                fullWidth
                value={contaTipo}
                name="contaTipo"
                onChange={(e) => handleChange(e)}
              />
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                size="small"
                fullWidth
                value={contaRuc}
                name="contaRuc"
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                size="small"
                fullWidth
                value={contaNombre}
                name="contaNombre"
                onChange={(e) => handleChange(e)}
              />
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                size="small"
                fullWidth
                value={contaDocumento}
                name="contaDocumento"
                onChange={(e) => handleChange(e)}
              />
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                type="date"
                size="small"
                fullWidth
                value={contaFechaDoc}
                name="contaFechaDoc"
                onChange={(e) => handleChange(e)}
              />
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <FormControl fullWidth>
                <Autocomplete
                  options={planDeCuentas.map((item) => {
                    return item.plancCodigo + "/+/" + item.plancNombre;
                  })}
                  getOptionLabel={(option) => {
                    return option.replace("/+/", " ");
                  }}
                  id="controlled-demo"
                  value={
                    (contaCuenta + " " + contaCuentaNombre).trim() === ""
                      ? ""
                      : contaCuenta + " " + contaCuentaNombre
                  }
                  paperprops
                  onChange={async (event, newValue) => {
                    if (newValue) {
                      const options = newValue.split("/+/");
                      setitemHijoEdit({
                        ...itemHijoEdit,
                        contaCuenta: options[0],
                        contaCuentaNombre: options[1],
                      });
                    }
                  }}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      fullWidth
                      onChange={async (e) => {
                        if (e.target.value === "") {
                          return mostrarAlerta("Ingrese una cuenta", "error");
                        }
                        try {
                          // setproveedoresData(res.data.data);
                        } catch (error) {}
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                type="number"
                size="small"
                fullWidth
                value={contaDebe}
                name="contaDebe"
                onChange={(e) => handleChange(e)}
              />
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                type="number"
                size="small"
                fullWidth
                value={contaHaber}
                name="contaHaber"
                onChange={(e) => handleChange(e)}
              />
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                size="small"
                fullWidth
                value={contaObservacion}
                name="contaObservacion"
                onChange={(e) => handleChange(e)}
              />
            </TableCell>
          </>
        ) : (
          <>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {contaTipo}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {contaRuc}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {contaNombre}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {contaDocumento}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {contaFechaDoc}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {contaCuenta + " " + contaCuentaNombre}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              ${contaDebe}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              ${contaHaber}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {contaObservacion}
            </TableCell>
          </>
        )}
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(RowArma));
