import React, { useContext, useState, useEffect, memo } from "react";
import { makeStyles } from "@mui/styles";
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
} from "@mui/material";

import { withRouter } from "react-router-dom";
// import Spinner from "../../../Spinner/Spinner";

import Row from "./Row";
import Cargando from "../../../../components/Extra/Cargando";
import ToolBarTable from "./ToolBarTable";
import TablaCabecera from "./TablaCabecera";
import AuthContext from "../../../../Context/Auth/AuthContext";
import { usePermiso } from "../../../../hooks/usePermiso";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 600,
  },
});
const Tabla = (props) => {
  const { tienePermiso, alertaPermiso } = usePermiso("CarnetVacunas");
  const {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    setopen,
    search,
    input,
    orden,
    setinput,
    setorden,
    cargando,
    setcargando,
    fullBuscador,
    //  STATE
    rows,
    columns,
    // ObjectoActivo,
    pagination,
    // FUNCIONES STATE
    getDataState,
    obtenerState,
    addRowsState,
    remplazarRowsState,
    // agregarState,
    // editarState,
    // eliminarState,
    // eliminarSeleccionState,
    changeObjetoActivoState,
    // rowsImportState,
    seteditar,
    arrayExport,
    setarrayExport,
    puedeExportar,
    desdeOtroLugar,
    socket,
    eliminadoEstado,
  } = props;

  const {
    usuario: { rucempresa },
  } = useContext(AuthContext);

  // eslint-disable-next-line
  const clases = useStyles();
  // eslint-disable-next-line
  const reducer = (previousValue, currentValue) =>
    Number(previousValue) + Number(currentValue);

  const [campoExport] = useState("carnId");
  //clases
  const classes = useStyles();

  const handleChangePage = async (event, newPage) => {
    if (newPage * rowsPerPage + rowsPerPage > rows.length) {
      setcargando(true);
      const res = await getDataState(
        rowsPerPage,
        newPage,
        search,
        input.id,
        orden,
        JSON.stringify(fullBuscador),
        rucempresa
      );

      const data = res.data.data.results;

      setcargando(false);
      const resultado = data.filter((row) => {
        const existe = rows.some((item, index) => {
          return row.carnId === item.carnId;
        });

        return !existe;
      });

      addRowsState(resultado);
    }

    setPage(newPage);
  };

  // eslint-disable-next-line

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    obtenerState(
      event.target.value,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa
    );
  };
  const funcionOrdenar = async (column) => {
    setcargando(true);
    await remplazarRowsState(
      rowsPerPage,
      0,
      search,
      column.id,
      !orden,
      JSON.stringify(fullBuscador),
      rucempresa
    );
    setPage(0);
    setinput(column);
    setorden(!orden);
    setcargando(false);
  };
  const funcionReload = async () => {
    setRowsPerPage(10);
    setPage(0);
    await obtenerState(
      10,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa
    );
  };

  useEffect(() => {
    //eslint-disable-next-line

    obtenerState(
      10,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa
    );

    // eslint-disable-next-line
  }, []);

  //si no hay usuarios //rows no cargar el componente
  if (!rows) {
    return <Cargando titulo="Cargando" />;
  }
  if (!pagination) {
    return <Cargando titulo="Cargando" />;
  }

  return (
    <Paper className={classes.root}>
      <ToolBarTable
        search={search}
        titulo={"Carnet Vacuna"}
        arrayExport={arrayExport}
        columns={columns}
        expandible={false}
        campoExport={campoExport}
        tabla="carnetvacuna"
        funcionReload={funcionReload}
        pagination={pagination}
        rowsPerPage={rowsPerPage}
        rows={rows}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        fullBuscador={fullBuscador}
        socket={socket}
        eliminadoEstado={eliminadoEstado}
      />
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" id="tabla" size="small">
          {/* <ModalCarnet setopen={setopen} onChangeCarnetActivo={onChangeCarnetActivo}/> */}
          <TableHead>
            <TablaCabecera
              remplazarRowsState={remplazarRowsState}
              search={search}
              input={input}
              setinput={setinput}
              orden={orden}
              setorden={setorden}
              rowsPerPage={rowsPerPage}
              page={page}
              setPage={setPage}
              setcargando={setcargando}
              rows={rows}
              setarrayExport={setarrayExport}
              columns={columns}
              habilitarOrdenar={true}
              funcionOrdenar={funcionOrdenar}
              puedeExportar={puedeExportar}
              desdeOtroLugar={desdeOtroLugar}
            />
          </TableHead>
          <TableBody>
            {cargando ? (
              <TableCell
                size="small"
                align="center"
                colSpan={columns.length + 2}
              >
                <Cargando titulo="Cargando" />
              </TableCell>
            ) : (
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <Row
                      eliminadoEstado={eliminadoEstado}
                      campoExport={campoExport}
                      rows={rows}
                      arrayExport={arrayExport}
                      setarrayExport={setarrayExport}
                      row={row}
                      index={index}
                      setopen={setopen}
                      key={row.carnId}
                      changeObjetoActivoState={changeObjetoActivoState}
                      puedeExportar={puedeExportar}
                      desdeOtroLugar={desdeOtroLugar}
                      seteditar={seteditar}
                      socket={socket}
                      tienePermiso={tienePermiso}
                      alertaPermiso={alertaPermiso}
                    />
                  );
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
export default memo(withRouter(Tabla));
