import React, { useState, memo } from "react";
import { makeStyles } from "@mui/styles";
import { Modal, Box, Divider, Typography, IconButton } from "@mui/material";
import Draggable from "react-draggable";
import { Cancel } from "@mui/icons-material";

function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",

    width: "55%",
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    marginTop: "5vh",
    [theme.breakpoints.down("md")]: {
      width: "75%",
      marginTop: "2vh",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginTop: "2vh",
    },
  },
  margin: {
    margin: theme.spacing(1),
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  ocultar: {
    display: "none",
  },
  inputPadding: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0),
    fontSize: "1rem",
  },
  formD: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "45%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "95%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
    },
  },
  large: {
    width: theme.spacing(13),
    height: theme.spacing(13),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&:hover": {
      opacity: ".7",
    },
  },

  center: {
    justifyContent: "center",
    alignItems: "center",
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  correo: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "45%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  error: {
    color: "red",
    fontWeight: "bold",
  },
}));

const ModalAsistencia = (props) => {
  const { open, setopen, titulo, Componente } = props;

  //hook de estilos
  const clases = useStyles();
  //definiendo los estilos del modal
  const [modalStyle] = useState(getModalStyle);
  if (!open) {
    return null;
  }
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                style={{ cursor: "-webkit-grab" }}
              >
                <Typography
                  id="handle"
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {titulo}
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      setopen(false);
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>

              <Divider />
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="flex-end"
                borderRadius="0px 50px 50px 0px"
                p={1}
              >
                <Box className={clases.formAllW}>
                  <Componente
                    desdeOtroLugarMascota={true}
                    setopenMT={setopen}
                  />
                </Box>{" "}
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalAsistencia);
