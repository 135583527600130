import React, { memo, useContext, useEffect } from "react";

import { useState } from "react";

import AuthContext from "../../../../../../Context/Auth/AuthContext";

// MATERIAL
import { makeStyles } from "@mui/styles";
import { Box, IconButton, TableCell, TableRow, TextField } from "@mui/material";
import { Cancel, Check, Create, Delete } from "@mui/icons-material";
import ConfirmacionDialog from "../../../../../../components/Extra/ConfirmacionDialog";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
  celda: {
    fontSize: ".7rem",
  },
}));

const RowAgregar = (props) => {
  // eslint-disable-next-line
  const {
    itemTipoAlimento,
    indexAlimento,
    itemAlimento,
    itemPadre,
    setitemPadre,
    // key,
    socket,
    indexPadre,
  } = props;
  const { usuario } = useContext(AuthContext);
  const [itemEdit, setItemEdit] = useState({ ...itemTipoAlimento });
  const [editar, seteditar] = useState(false);

  const classes = useRowStyles();
  //gql eliminar user

  const funcSubmit = () => {
    const itemAlimentoEditConNuevo = {
      ...itemAlimento,
      tiposAlimento: itemAlimento.tiposAlimento.map(
        (itemTipoAlimentoMap, indexitemTipoAlimentoMap) => {
          if (indexitemTipoAlimentoMap === indexAlimento) {
            return itemEdit;
          } else {
            return itemTipoAlimentoMap;
          }
        }
      ),
    };

    const itemPadreNuevo = {
      ...itemPadre,
      espAlimentos: itemPadre.espAlimentos.map((itemAlimentoMap) => {
        if (itemAlimentoMap.nombre === itemAlimento.nombre) {
          return itemAlimentoEditConNuevo;
        } else {
          return itemAlimentoMap;
        }
      }),
    };

    socket.current.emit("client:actualizarData", {
      ...itemPadre,
      tabla: "especie",
      espAlimentos: itemPadreNuevo.espAlimentos,
      rucempresa: usuario.rucempresa,
      rol: usuario.rol,
      espUser: `${itemPadre.espUser.split(" ** ")[0]} ** ${usuario.usuario}`,
      espEmpresa: usuario.rucempresa,
    });
    setitemPadre({
      ...itemPadre,
      espAlimentos: itemPadreNuevo.espAlimentos,
    });
    // socket.current.emit("client:actualizarData", {
    //   ...itemPadre,
    //   tabla: "especie",
    //   espAlimentos: itemPadre.espAlimentos.map((item, itemIndex) => {
    //     if (indexPadre === itemIndex) {
    //       return itemEdit;
    //     } else {
    //       return item;
    //     }
    //   }),
    //   rucempresa: usuario.rucempresa,
    //   rol: usuario.rol,
    //   espUser: usuario.usuario,
    //   espEmpresa: usuario.rucempresa,
    // });
    // setitemPadre({
    //   ...itemPadre,
    //   espAlimentos: itemPadre.espAlimentos.map((item, itemIndex) => {
    //     if (indexPadre === itemIndex) {
    //       return itemEdit;
    //     } else {
    //       return item;
    //     }
    //   }),
    // });
    // seteditar(false);
  };
  const handleChange = (e) => {
    setItemEdit({
      ...itemEdit,
      [e.target.name]: e.target.value,
    });
  };

  const botonDisabled = () => {
    if (itemEdit.nombre === "") {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    setItemEdit({ ...itemTipoAlimento });
    // eslint-disable-next-line
  }, [itemPadre]);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const handleEliminar = async (_id) => {
    const itemAlimentoEditConNuevo = {
      ...itemAlimento,
      tiposAlimento: itemAlimento.tiposAlimento.filter(
        (itemTipoAlimentoMap, indexitemTipoAlimentoMap) => {
          return indexitemTipoAlimentoMap !== indexAlimento;
        }
      ),
    };

    const itemPadreNuevo = {
      ...itemPadre,
      espAlimentos: itemPadre.espAlimentos.map((itemAlimentoMap) => {
        if (itemAlimentoMap.nombre === itemAlimento.nombre) {
          return itemAlimentoEditConNuevo;
        } else {
          return itemAlimentoMap;
        }
      }),
    };

    socket.current.emit("client:actualizarData", {
      ...itemPadre,
      tabla: "especie",
      espAlimentos: itemPadreNuevo.espAlimentos,
      rucempresa: usuario.rucempresa,
      rol: usuario.rol,
      espUser: `${itemPadre.espUser.split(" ** ")[0]} ** ${usuario.usuario}`,
      espEmpresa: usuario.rucempresa,
    });
    setitemPadre({
      ...itemPadre,
      espAlimentos: itemPadreNuevo.espAlimentos,
    });
  };
  return (
    <React.Fragment>
      {" "}
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo="¿Esta seguro que quiere eliminar este Item?"
        _id={indexPadre}
        funcion={handleEliminar}
      />
      <TableRow className={classes.root}>
        <TableCell>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            {editar ? (
              <>
                {" "}
                <IconButton
                  className={classes.margin}
                  onClick={() => {
                    seteditar(false);

                    setItemEdit({ ...itemTipoAlimento });
                  }}
                >
                  <Cancel fontSize="small" color="secondary" />
                </IconButton>
                <IconButton
                  color={
                    botonDisabled
                      ? botonDisabled()
                        ? "inherit"
                        : "secondary"
                      : "secondary"
                  }
                  disabled={botonDisabled()}
                  className={classes.margin}
                  onClick={() => {
                    funcSubmit();

                    seteditar(false);
                    setItemEdit({ ...itemTipoAlimento });
                  }}
                >
                  <Check fontSize="small" />
                </IconButton>{" "}
              </>
            ) : (
              <>
                {" "}
                <IconButton
                  className={classes.margin}
                  onClick={() => {
                    seteditar(true);
                  }}
                >
                  <Create fontSize="small" color={"secondary"} />
                </IconButton>
                <IconButton
                  className={classes.margin}
                  onClick={() => {
                    setopenConfirmDialog(true);
                  }}
                >
                  <Delete fontSize="small" color={"error"} />
                </IconButton>{" "}
              </>
            )}
          </Box>
        </TableCell>

        {/* las demas columnas */}
        {editar ? (
          <>
            {" "}
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              <TextField
                autoFocus
                id=""
                value={itemEdit.nombre}
                name="nombre"
                onChange={(e) => handleChange(e)}
                helperText="Agregando"
                size="small"
                fullWidth
              />
            </TableCell>
          </>
        ) : (
          <>
            {" "}
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              {itemTipoAlimento.nombre}
            </TableCell>
          </>
        )}
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregar);
