export const objectDefault = {
  chat_id: "",
  chat_name: "",
  person_id: "",
  num_documento: "",
  nombres: "",
};

export const columns = [
  {
    id: "chat_name",
    label: "Chat",
    minWidth: 25,
    tipo: "string",
    propiedad: "chat_name",
  },
  {
    id: "num_documento",
    label: "Documento",
    minWidth: 150,
    tipo: "string",
    propiedad: "num_documento",
  },
  {
    id: "nombres",
    label: "Nombres",
    minWidth: 150,
    tipo: "string",
    propiedad: "nombres",
  },
];
