import React, { memo, useContext } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  TableCell,
  TableRow,
  Collapse,
  Divider,
  Typography,
  Table,
  TableBody,
} from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../../components/Extra/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "./ButtonsAction";
import AuthContext from "../../../Context/Auth/AuthContext";
import TablaCabecera from "./TablaCabecera";
import RowItem from "./RowItem/RowItem";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  hover: {
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  celda: {
    fontSize: ".7rem !important",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const Row = (props) => {
  const {
    seteditar,
    arrayExport,
    setarrayExport,
    row,
    index,
    setopen,
    campoExport,
    changeObjetoActivoState,
    puedeExportar,
    desdeOtroLugar,
    socket,
  } = props;

  const { usuario } = useContext(AuthContext);
  const [expandir, setexpandir] = useState(false);

  const classes = useRowStyles();

  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        tabla: "asistenciatecnica",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        asitecUser: usuario.usuario,
        asitecEmpresa: usuario.rucempresa,
        asitecId: row.asitecId,
      });
    } catch (error) {}
  };
  const funcionEdit = () => {
    changeObjetoActivoState({
      ...row,
    });
    setopen(true);
    seteditar(true);
  };

  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo={`¿Esta seguro de eliminar este registro: ${row.perNombre}?`}
        contenido={`El asistenciatecnica ${row.perNombre} será eliminado, no podra ser recuperado`}
        _id={row._id}
        funcion={handleEliminar}
      />
      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          row={row}
          setopenConfirmDialog={setopenConfirmDialog}
          funcionEdit={funcionEdit}
          ocultarEditar={false}
          ocultarDelete={false}
          mostrarEstado={false}
          campoExport={campoExport}
          campoEstado=""
          expandir={expandir}
          setexpandir={setexpandir}
          puedeExportar={puedeExportar}
          desdeOtroLugar={desdeOtroLugar}
        />

        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.asitecClienteDocumento}
          {" - "}
          {row.asitecTecnicoDocumento}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {new Date(row.asitecFecUpd).toLocaleString("es-ES", {
            weekday: "short",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour: "2-digit",
          })}
        </TableCell>
      </TableRow>{" "}
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={21}>
          <Collapse in={expandir} timeout="auto" unmountOnExit>
            <Divider style={{ display: "block", width: "100%" }} />
            <Box>
              <>
                <Box margin={1}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="body1" gutterBottom component="span">
                      Equipos Instalados
                    </Typography>{" "}
                  </Box>
                  <Table size="small" aria-label="purchases">
                    <TablaCabecera
                      ocultarPrimeraColumna={true}
                      columns={[
                        {
                          id: "ITEM",
                          label: "ITEM",
                          minWidth: 100,
                          tipo: "string",

                          propiedad: "ITEM",
                        },
                        {
                          id: "COSTO UNITARIO",
                          label: "COSTO UNITARIO",
                          minWidth: 100,
                          tipo: "string",

                          propiedad: "COSTO UNITARIO",
                        },
                        {
                          id: "CANTIDAD",
                          label: "CANTIDAD",
                          minWidth: 100,
                          tipo: "string",

                          propiedad: "CANTIDAD",
                        },
                        {
                          id: "VALOR",
                          label: "VALOR",
                          minWidth: 100,
                          tipo: "string",

                          propiedad: "VALOR",
                        },
                        {
                          id: "DESCRIPCIÓN",
                          label: "DESCRIPCIÓN",
                          minWidth: 100,
                          tipo: "string",

                          propiedad: "DESCRIPCIÓN",
                        },
                        {
                          id: "MARCA",
                          label: "MARCA",
                          minWidth: 100,
                          tipo: "string",

                          propiedad: "MARCA",
                        },
                        {
                          id: "OBSERVACIONES",
                          label: "OBSERVACIONES",
                          minWidth: 100,
                          tipo: "string",

                          propiedad: "OBSERVACIONES",
                        },
                      ]}
                    />
                    <TableBody>
                      {row.asitecEquiposInstalados.map((item, index) => {
                        return (
                          <RowItem item={item} index={index} key={index} />
                        );
                      })}
                    </TableBody>
                  </Table>
                </Box>
              </>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
