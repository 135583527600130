import Dashboard from "./views/Dashboard/Dashboard";
//AYUDA
import Ayuda from "./views/Ayuda/Ayuda";

//COMPRAS
import Compras from "./views/Compras/Compras/Compras";
import Liquidacion from "./views/Compras/Liquidacion/Liquidacion";
import Retencion from "./views/Compras/Retencion/Retencion";

//VENTAS

import CuentasXCobrar from "./views/Ventas/CuentasCobrar/CuentasXCobrar";
import Facturacion from "./views/Ventas/Facturas/Facturacion";
import NotaDeCredito from "./views/Ventas/NotaDeCredito/NotaDeCredito";
import NotasDebito from "./views/Ventas/NotasDebito/NotasDebito";
//MENU
import Menu from "./views/Menu/Menu";
import Tutoriales from "./views/Tutoriales/Tutoriales";

//Config
import TablaConfiguraciones from "./views/Veterinaria/TablaConfiguraciones/TablaConfiguraciones";
import CamposDinamicos from "./views/Veterinaria/CamposDinamicos/CamposDinamicos";
//parametros
import Subcategorias from "./views/Configuración/Subcategorias/Subcategorias";
import Bodega from "./views/Configuración/Bodega/Bodega";
import Ciudades from "./views/Configuración/Ciudadesxd/Ciudades";
import ChatsGroup from "./views/Configuración/ChatsGroup/ChatsGroup";
import ChatParticipants from "./views/Configuración/ChatParticipants/ChatParticipants";
import Monitoreo from "./views/Configuración/Monitoreo/Monitoreo";
//inventario
import Impresion from "./views/Configuración/Impresion/Impresion";
import Comprobantes from "./views/Configuración/Comprobantes/Comprobantes";
import Inventario from "./views/Configuración/Inventario/Inventario";
import Empresas from "./views/Configuración/Empresas/Empresas";
// PERSONAS
import Personas_Contable from "./views/Compras/Proveedores/Personas_Contable/Personas_Contable";
import Personas_ISP from "./views/Compras/Proveedores/Personas_ISP/Personas_ISP";

import PorcentajeRetenciones from "./views/Configuración/PorcentajeRetenciones/PorcentajeRetenciones";
import Categorias from "./views/Configuración/Categorias/Categorias";
import CuentasPorCobrar from "./views/Compras/CuantasPorCobrar/CuentasPorCobrar";
import Cuentas from "./views/Configuración/Cuentas/Cuentas";
import CajaGeneral from "./views/Caja/CajaGeneral/CajaGeneral";
import CajaChica from "./views/Caja/CajaChica/CajaChica";
import FormasPagos from "./views/Configuración/FormasPagos/FormasPagos";
import SustentosTributarios from "./views/Configuración/SustentosTributarios/SustentosTributarios";
import Planes from "./views/Configuración/Planes/Planes";
import AsistenciasTecnicas from "./views/AsistenciasTecnicas/AsistenciasTecnicas";
import Asientos from "./views/Contabilidad/Asientos/Asientos";

import Contabilidad from "./views/ArchivosCards/Componentes/Contabilidad/Contabilidad";
import Caja from "./views/ArchivosCards/Componentes/Caja/Caja";
import InventarioPadre from "./views/ArchivosCards/Componentes/InventarioPadre/InventarioPadre";
import ComprasPadre from "./views/ArchivosCards/Componentes/ComprasPadre/ComprasPadre";
import VentasPadre from "./views/ArchivosCards/Componentes/VentasPadre/VentasPadre";
import Configuracion from "./views/ArchivosCards/Componentes/Configuracion/Configuracion";
import Veterinaria from "./views/ArchivosCards/Componentes/Veterinaria/Veterinaria";
import Parametros from "./views/ArchivosCards/Componentes/Parametros/VentasPadre";
// VETERINARIA
import Especies from "./views/Veterinaria/Especies/Especies";
import Alimentos from "./views/Veterinaria/Alimentos/Alimentos";
import Sexo from "./views/Veterinaria/Sexo/Sexo";
import Recomendaciones from "./views/Veterinaria/Recomendaciones/Recomendaciones";
import ConsultasMedicas from "./views/Veterinaria/ConsultasMedicas/ConsultasMedicas";
import TipoReservas from "./views/Veterinaria/TipoReservas/TipoReservas";
import Mascotas from "./views/Veterinaria/Mascotas/Mascotas";
import Personas_Veterinaria from "./views/Veterinaria/Personas_Veterinaria/Personas_Veterinaria";
import HistorialClinica from "./views/Veterinaria/HistorialClinica/HistorialClinica";
import CarnetVacunas from "./views/Veterinaria/CarnetVacunas/CarnetVacunas";
import Recetas from "./views/Veterinaria/Recetas/Recetas";
import Hospitalizacion from "./views/Veterinaria/Hospitalizacion/Hospitalizacion";
import Peluqueria from "./views/Veterinaria/Peluqueria/Peluqueria";
import PedidosPeluqueria from "./views/Veterinaria/PedidosPeluqueria/PedidosPeluqueria";
import Examenes from "./views/Veterinaria/Examenes/Examenes";
import ParametrosHospitalizacion from "./views/Veterinaria/ParametrosHospitalizacion/ParametrosHospitalizacion";
import Reservas from "./views/Veterinaria/Reservas/Reservas";
import Produccion from "./views/Produccion/Produccion";
// GASOLINERA
import Personas_Gasolinera from "./views/Gasolinera/Personas_Gasolinera/Personas_Gasolinera";
import Maquinaria from "./views/Maquinaria/Maquinaria";
import Mensajeria from "./views/Mensajeria/Mensajeria";
import Noticias from "./views/Noticias/Noticias";
import Acta_Entrega_Recepcion from "./views/Acta_Entrega_Recepcion/Acta_Entrega_Recepcion";

const dashboardRoutes = [];
export const componentes = {
  Dashboard, //
  Ayuda, //
  Compras, //
  Liquidacion, //
  Retencion, //
  Caja, //
  CuentasXCobrar, //
  Facturacion, //
  NotaDeCredito, //
  NotasDebito, //
  Menu, //
  Empresas, //
  Categorias, //
  Subcategorias, //
  Bodega, //
  Impresion, //
  Comprobantes, //
  Inventario, //
  // PERSONAS
  Personas_Contable, //
  Personas_ISP, //
  PorcentajeRetenciones, //
  CuentasPorCobrar, //
  Cuentas, //
  CajaGeneral, //
  CajaChica, //
  FormasPagos, //
  SustentosTributarios, //
  Planes, //
  AsistenciasTecnicas, //
  Personas_Gasolinera,
  Ciudades, //
  Asientos, //
  Contabilidad, //
  InventarioPadre, //
  ComprasPadre, //
  VentasPadre, //
  Configuracion, //
  Especies, //
  Veterinaria,
  Alimentos, //
  Sexo, //
  Recomendaciones, //
  ConsultasMedicas, //
  TipoReservas, //
  Mascotas, //
  Personas_Veterinaria, //
  HistorialClinica, //
  CarnetVacunas, //
  Recetas, //
  Hospitalizacion, //
  Parametros, //
  Peluqueria, //
  PedidosPeluqueria, //
  ParametrosHospitalizacion, //
  Examenes,
  Tutoriales,
  Reservas,
  TablaConfiguraciones,
  Maquinaria,
  Produccion,
  CamposDinamicos,
  Mensajeria,
  ChatsGroup,
  ChatParticipants,
  Noticias,
  Monitoreo,
  Acta_Entrega_Recepcion,
};

export default dashboardRoutes;
