import { makeStyles } from "@mui/styles";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  TableCell,
  Tooltip,
} from "@mui/material";
import {
  Cancel,
  Check,
  Create,
  Delete,
  ExpandLess,
  ExpandMore,
  PersonPin,
  PictureAsPdf,
  Restore,
  WhatsApp,
} from "@mui/icons-material";
import React, { memo, useContext, useState } from "react";
import { withRouter } from "react-router";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import { tienePermisoRuta } from "../../../../config/validaciones";
import AuthContext from "../../../../Context/Auth/AuthContext";
import MenuContext from "../../../../Context/Menu/MenuContext";
import { permisoDenagado, serverphpvet } from "../../../../config/const";
import { ModalContactoPlantillaW } from "../../../components/Modal/ModalContactoPlantillaW";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const ButtonsAction = (props) => {
  const {
    arrayExport,
    setarrayExport,
    campoExport,
    row,
    setopenConfirmDialog,
    funcionEdit,
    ocultarEditar,
    ocultarDelete,
    mostrarEstado,
    campoEstado,
    editar,
    funcCancel,
    funcSubmit,
    botonDisabled,
    puedeExportar,
    desdeOtroLugar,
    expandir,
    setexpandir,
    funcionEditPropietario,
    eliminadoEstado,
  } = props;
  const classes = useRowStyles();
  const { usuario } = useContext(AuthContext);
  const { rows } = useContext(MenuContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const [
    openModalContactoPlantillaW,
    setopenModalContactoPlantillaW,
  ] = useState(false);
  const contactoDefault = {
    medioComunicacion: "Whatsapp",
    option: "Mascota",
    idRegistro: row.mascId,
    contacto: [],
    contenidoMensaje: "",
  };
  const [newContacto, setNewContacto] = useState(contactoDefault);

  return (
    <>
      {openModalContactoPlantillaW && (
        <ModalContactoPlantillaW
          personaId={row.mascPerId}
          encabezado={[
            { label: "Documento Propietario:", value: row.mascPerDocNumero },
            {
              label: "Propietario:",
              value: row.mascPerNombre,
            },
            {
              label: "Paciente:",
              value: row.mascNombre,
            },
            {
              label: "#Carnet:",
              value: row.mascId,
            },
          ]}
          row={row}
          open={openModalContactoPlantillaW}
          setopen={setopenModalContactoPlantillaW}
          newContacto={newContacto}
          setNewContacto={setNewContacto}
          contactoDefault={contactoDefault}
          objetoPlantilla={"empPlantillaMascota"}
        />
      )}
      {arrayExport ? (
        <TableCell
          size="small"
          padding="checkbox"
          style={{
            borderRight: "1px solid #ccc",
            maxWidth: "1rem",
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="center">
            <FormControlLabel
              className={classes.margin}
              control={
                <Checkbox
                  size="small"
                  disabled={!puedeExportar && desdeOtroLugar}
                  className={classes.margin}
                  checked={arrayExport.some((item) => {
                    return item[campoExport] === row[campoExport];
                  })}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setarrayExport([
                        ...arrayExport,
                        { [campoExport]: row[campoExport] },
                      ]);
                    } else {
                      setarrayExport(
                        arrayExport.filter(
                          (item) => item[campoExport] !== row[campoExport]
                        )
                      );
                    }
                  }}
                  color="secondary"
                />
              }
            />
          </Box>
        </TableCell>
      ) : null}
      <TableCell padding="none" style={{ borderRight: "1px solid #ccc" }}>
        <Box display="flex" alignItems="center">
          {eliminadoEstado ? (
            <>
              <Tooltip title="Recuperar" arrow>
                <IconButton
                  className={classes.margin}
                  onClick={() => {
                    if (
                      !tienePermisoRuta(
                        rows,
                        props.location.pathname,
                        usuario.rol,
                        "editar"
                      )
                    ) {
                      return mostrarAlerta(permisoDenagado, "error");
                    }
                    funcionEdit();
                  }}
                >
                  <Restore color="success" />
                </IconButton>
              </Tooltip>
              <Tooltip title="PDF" arrow>
                <IconButton
                  className={classes.margin}
                  onClick={() => {
                    // Abres la nueva ventas
                    window.open(
                      `${serverphpvet}reportes/historiaClinica.php?id=${row.mascId}&empresa=${row.mascEmpresa}`,
                      "_blank"
                    );
                  }}
                >
                  <PictureAsPdf color="error" />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <>
              <>
                {editar ? (
                  <>
                    <IconButton
                      className={classes.margin}
                      onClick={() => {
                        funcCancel();
                      }}
                    >
                      <Cancel fontSize="small" color="secondary" />
                    </IconButton>
                    <IconButton
                      className={classes.margin}
                      disabled={botonDisabled ? botonDisabled() : false}
                      onClick={() => {
                        funcSubmit();
                      }}
                    >
                      <Check
                        fontSize="small"
                        color={
                          botonDisabled
                            ? botonDisabled()
                              ? "inherit"
                              : "secondary"
                            : "secondary"
                        }
                      />
                    </IconButton>
                  </>
                ) : (
                  <>
                    {ocultarDelete ? null : (
                      <Tooltip title="Eliminar" arrow>
                        <IconButton
                          className={classes.margin}
                          onClick={() => {
                            setopenConfirmDialog(true);
                          }}
                        >
                          <Delete fontSize="small" color={"error"} />
                        </IconButton>
                      </Tooltip>
                    )}
                    {ocultarEditar ? null : (
                      <Tooltip title="Editar" arrow>
                        <IconButton
                          className={classes.margin}
                          onClick={() => {
                            if (
                              !tienePermisoRuta(
                                rows,
                                props.location.pathname,
                                usuario.rol,
                                "editar"
                              )
                            ) {
                              return mostrarAlerta(permisoDenagado, "error");
                            }
                            funcionEdit();
                          }}
                        >
                          <Create color="secondary" />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="Editar Propietario" arrow>
                      <IconButton
                        className={classes.margin}
                        onClick={() => {
                          funcionEditPropietario();
                        }}
                      >
                        <PersonPin color="primary" />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </>
              {expandir ? (
                <IconButton
                  className={classes.margin}
                  onClick={() => {
                    setexpandir(false);
                  }}
                >
                  <ExpandLess color="primary" />
                </IconButton>
              ) : (
                <IconButton
                  className={classes.margin}
                  onClick={() => {
                    setexpandir(true);
                  }}
                >
                  <ExpandMore color="primary" />
                </IconButton>
              )}
              <Tooltip title="PDF" arrow>
                <IconButton
                  className={classes.margin}
                  onClick={() => {
                    // Abres la nueva ventas
                    window.open(
                      `${serverphpvet}reportes/historiaClinica.php?id=${row.mascId}&empresa=${row.mascEmpresa}`,
                      "_blank"
                    );
                  }}
                >
                  <PictureAsPdf color="error" />
                </IconButton>
              </Tooltip>
              <Tooltip title="PDF INFORME MASCOTA" arrow>
                <IconButton
                  className={classes.margin}
                  onClick={() => {
                    // Abres la nueva ventas
                    window.open(
                      `${serverphpvet}reportes/informe_mascota.php?id=${row.mascId}&empresa=${row.mascEmpresa}`,
                      "_blank"
                    );
                  }}
                >
                  <PictureAsPdf color="secondary" />
                </IconButton>
              </Tooltip>

              <Tooltip title="Mensaje whatsapp" arrow>
                <IconButton
                  className={classes.margin}
                  onClick={() => {
                    setopenModalContactoPlantillaW(true);
                  }}
                >
                  <WhatsApp color="success" />
                </IconButton>
              </Tooltip>
              {mostrarEstado ? (
                <FormControlLabel
                  disabled
                  className={classes.margin}
                  control={
                    <Checkbox
                      value="ACTIVO"
                      className={classes.margin}
                      checked={
                        row[campoEstado] === "ACTIVA" ||
                        row[campoEstado] === "ACTIVO"
                          ? true
                          : false
                      }
                      color="primary"
                    />
                  }
                />
              ) : null}
            </>
          )}
        </Box>
      </TableCell>
    </>
  );
};

export default withRouter(memo(ButtonsAction));
