import React, { memo, useContext } from "react";

import {
  Box,
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../../components/Dialogs/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "../../../components/Tablas/ButtonsAction";
import AuthContext from "../../../../Context/Auth/AuthContext";
import {
  Cancel,
  Check,
  DeleteForever,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import {
  butonIconTransition,
  useRowStyles,
} from "../../../../styles/stylesRows";
import TablaCabecera from "../../../components/Tablas/TablaCabecera";
import RowItem from "../Componentes/RowItems/RowItem";
import { columnasHijo } from "../data";
import { useDraggable } from "../../../../hooks/useDraggable";
import { useEffect } from "react";
import { trimPropsItem } from "../../../../config/const";

const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const {
    arrayExport,
    setarrayExport,
    row,
    index,
    campoExport,
    socket,
  } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    row.editado === true
      ? classes.celdaEditado
      : row.nuevo === true
      ? classes.celdaNuevo
      : row.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;
  const [item, setitem] = useState(row);
  const [editado, seteditado] = useState(false);
  useEffect(() => {
    setitem(row);
  }, [row]);

  // CONTEXT
  const { usuario } = useContext(AuthContext);

  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  // EXPANDIR
  const [expandir, setexpandir] = useState(false);
  // COPIA DEL REGISTRO PARA EDITAR
  // GUARDIAS TOTALES

  // ELIMINAR EL REGISTRO
  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        tabla: "tablaColumnas",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        estUser: usuario.usuario,
        estEmpresa: usuario.rucempresa,
        [campoExport]: row[campoExport],
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };
  const { handleDragStart, handleDragEnter, handleDragEnd } = useDraggable();
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow
        className={classes.root}
        selected={index % 2 === 0}
        component={"form"}
      >
        {/* boton */}
        <ButtonsAction
          claseCelda={claseCelda}
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          campoExport={campoExport}
          row={row}
          botones={[
            {
              tooltip: "Expandir",
              texto: row.tablaColumnas.length,
              funcion: () => {
                setexpandir((prev) => !prev);
              },
              disabled: false,
              Icon: expandir ? ExpandLess : ExpandMore,
              color: "secondary",
              id: 3,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Editar",
              texto: "",
              funcion: () => {
                socket.current.emit(
                  "client:actualizarData",
                  trimPropsItem({
                    ...item,
                    rucempresa: usuario.rucempresa,
                    rol: usuario.rol,
                    tabla: "tablaColumnas",
                  })
                );
              },
              disabled: false,
              Icon: Check,
              color: "success",
              id: 3,
              ocultar: !editado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Cancelar",
              texto: "",
              funcion: () => {
                setitem(row);
                seteditado(false);
              },
              disabled: false,
              Icon: Cancel,
              color: "error",
              id: 3,
              ocultar: !editado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
          ]}
        />

        <>
          <TableCell size="small" align="center" className={claseCelda}>
            {row.tablaNombre}
          </TableCell>
        </>
      </TableRow>
      <TableRow>
        {" "}
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={21}>
          <Collapse in={expandir} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Paper>
                <TableContainer>
                  <Table stickyHeader aria-label="sticky table" size="small">
                    <TableHead>
                      <TablaCabecera
                        columns={columnasHijo}
                        habilitarOrdenar={false}
                      />
                    </TableHead>

                    <TableBody
                      component={"div"}
                      style={{ height: "33vh", overflow: "scroll" }}
                    >
                      {item.tablaColumnas
                        .sort((a, b) => a.order - b.order)
                        .map((itemHijo, index) => {
                          return (
                            <RowItem
                              item={item}
                              setitem={setitem}
                              handleDragStart={handleDragStart}
                              handleDragEnter={handleDragEnter}
                              handleDragEnd={handleDragEnd}
                              itemHijo={itemHijo}
                              itemPadre={row}
                              index={index}
                              key={index}
                              socket={socket}
                              editado={editado}
                              seteditado={seteditado}
                            />
                          );
                        })}
                    </TableBody>
                    <TableFooter>
                      <TableRow></TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
