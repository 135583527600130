import React, { memo, useContext, useEffect } from "react";

import { useState } from "react";

import AuthContext from "../../../../../Context/Auth/AuthContext";

// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Badge,
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  AddCircle,
  Cancel,
  Check,
  Create,
  Delete,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import ConfirmacionDialog from "../../../../../components/Extra/ConfirmacionDialog";
import TablaCabecera from "../TablaCabecera";
import RowAgregarItemTipoAlimento from "./RowItemsTiposAlimento/RowAgregarItemTipoAlimento";
import RowItemTipoAlimento from "./RowItemsTiposAlimento/RowItemTipoAlimento";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
  celda: {
    fontSize: ".7rem",
  },
}));

const RowAgregar = (props) => {
  // eslint-disable-next-line
  const { itemAlimento, itemPadre, setitemPadre, indexPadre, socket } = props;
  const { usuario } = useContext(AuthContext);
  const [itemEdit, setItemEdit] = useState({ ...itemAlimento });
  const [editar, seteditar] = useState(false);
  const [expandir, setexpandir] = useState(false);
  const [agregarItemLista, setagregarItemLista] = useState(false);
  const classes = useRowStyles();
  //gql eliminar user

  const funcSubmit = () => {
    socket.current.emit("client:actualizarData", {
      ...itemPadre,
      tabla: "especie",
      espAlimentos: itemPadre.espAlimentos.map((item, itemIndex) => {
        if (indexPadre === itemIndex) {
          return itemEdit;
        } else {
          return item;
        }
      }),
      rucempresa: usuario.rucempresa,
      rol: usuario.rol,
      espUser: `${itemPadre.espUser.split(" ** ")[0]} ** ${usuario.usuario}`,
      espEmpresa: usuario.rucempresa,
    });
    setitemPadre({
      ...itemPadre,
      espAlimentos: itemPadre.espAlimentos.map((item, itemIndex) => {
        if (indexPadre === itemIndex) {
          return itemEdit;
        } else {
          return item;
        }
      }),
    });
    seteditar(false);
  };
  const handleChange = (e) => {
    setItemEdit({
      ...itemEdit,
      [e.target.name]: e.target.value,
    });
  };

  const botonDisabled = () => {
    if (itemEdit.nombre === "") {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    setItemEdit({ ...itemAlimento });
    // eslint-disable-next-line
  }, [itemPadre]);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const handleEliminar = async (_id) => {
    socket.current.emit("client:actualizarData", {
      ...itemPadre,
      tabla: "especie",
      espAlimentos: itemPadre.espAlimentos.filter((item, itemIndex) => {
        return indexPadre !== itemIndex;
      }),
      rucempresa: usuario.rucempresa,
      rol: usuario.rol,
      espUser: `${itemPadre.espUser.split(" ** ")[0]} ** ${usuario.usuario}`,
      espEmpresa: usuario.rucempresa,
    });
    setitemPadre({
      ...itemPadre,
      espAlimentos: itemPadre.espAlimentos.filter((item, itemIndex) => {
        return indexPadre !== itemIndex;
      }),
    });
  };
  return (
    <React.Fragment>
      {" "}
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo="¿Esta seguro que quiere eliminar este Item?"
        _id={indexPadre}
        funcion={handleEliminar}
      />
      <TableRow className={classes.root}>
        <TableCell>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            {editar ? (
              <>
                {" "}
                <IconButton
                  className={classes.margin}
                  onClick={() => {
                    seteditar(false);

                    setItemEdit({ ...itemAlimento });
                  }}
                >
                  <Cancel fontSize="small" color="secondary" />
                </IconButton>
                <IconButton
                  color={
                    botonDisabled
                      ? botonDisabled()
                        ? "inherit"
                        : "secondary"
                      : "secondary"
                  }
                  disabled={botonDisabled()}
                  className={classes.margin}
                  onClick={() => {
                    funcSubmit();

                    seteditar(false);
                    setItemEdit({ ...itemAlimento });
                  }}
                >
                  <Check fontSize="small" />
                </IconButton>{" "}
              </>
            ) : (
              <>
                {" "}
                <IconButton
                  className={classes.margin}
                  onClick={() => {
                    seteditar(true);
                  }}
                >
                  <Create fontSize="small" color={"secondary"} />
                </IconButton>
                <IconButton
                  className={classes.margin}
                  onClick={() => {
                    setopenConfirmDialog(true);
                  }}
                >
                  <Delete fontSize="small" color={"error"} />
                </IconButton>{" "}
                <IconButton
                  className={classes.margin}
                  onClick={() => {
                    setexpandir((prev) => !prev);
                  }}
                >
                  <Badge
                    badgeContent={itemAlimento.tiposAlimento.length}
                    color="secondary"
                  >
                    {expandir ? (
                      <ExpandLess fontSize="small" color={"primary"} />
                    ) : (
                      <ExpandMore fontSize="small" color={"primary"} />
                    )}
                  </Badge>
                </IconButton>
              </>
            )}
          </Box>
        </TableCell>

        {/* las demas columnas */}
        {editar ? (
          <>
            {" "}
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              <TextField
                autoFocus
                id=""
                value={itemEdit.nombre}
                name="nombre"
                onChange={(e) => handleChange(e)}
                helperText="Agregando"
                size="small"
                fullWidth
              />
            </TableCell>
          </>
        ) : (
          <>
            {" "}
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              {itemAlimento.nombre}
            </TableCell>
          </>
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={21}>
          <Collapse in={expandir} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography>Tipos Alimento </Typography>
                <IconButton
                  size="small"
                  aria-label=""
                  color="secondary"
                  onClick={() => {
                    setagregarItemLista(true);
                  }}
                >
                  <AddCircle />
                </IconButton>
              </Box>
              <Table size="small" aria-label="purchases">
                <TablaCabecera
                  columns={[
                    {
                      id: "nombre",
                      align: "center",
                      minWidth: 50,
                      colSpan: 1,
                      label: "nombre",
                    },
                  ]}
                />

                <TableBody>
                  {agregarItemLista ? (
                    <RowAgregarItemTipoAlimento
                      itemPadre={itemPadre}
                      itemAlimento={itemAlimento}
                      setitemPadre={setitemPadre}
                      setagregarItemLista={setagregarItemLista}
                      socket={socket}
                      indexPadre={indexPadre}
                    />
                  ) : null}
                  {itemAlimento.tiposAlimento.map(
                    (itemTipoAlimento, indexAlimento) => {
                      return (
                        <RowItemTipoAlimento
                          itemTipoAlimento={itemTipoAlimento}
                          indexAlimento={indexAlimento}
                          itemAlimento={itemAlimento}
                          itemPadre={itemPadre}
                          setitemPadre={setitemPadre}
                          key={indexAlimento}
                          socket={socket}
                          indexPadre={indexPadre}
                        />
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregar);
