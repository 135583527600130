import React, { memo, useContext, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";

import { Cancel, Check, Create, Delete } from "@mui/icons-material";

import { useState } from "react";

import AuthContext from "../../../../Context/Auth/AuthContext";
import ConfirmacionDialog from "../../../../components/Extra/ConfirmacionDialog";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
  celda: {
    fontSize: ".7rem",
  },
}));

const RowAgregar = (props) => {
  // eslint-disable-next-line
  const {
    bancos,
    cpPago,
    item,
    setitem,
    index,
    socket,
    tienePermiso,
    alertaPermiso,
  } = props;

  const [itemEdit, setItemEdit] = useState({ ...cpPago });
  const [editar, seteditar] = useState(false);
  const classes = useRowStyles();
  //gql eliminar user
  const { usuario } = useContext(AuthContext);
  const handleEditar = async () => {
    if (!tienePermiso("editar")) {
      return alertaPermiso("editar");
    }
    try {
      socket.current.emit("client:actualizarData", {
        ...item,
        cpPagos: item.cpPagos.map((CPPago, indexCPPago) => {
          if (index === indexCPPago) {
            return itemEdit;
          } else {
            return CPPago;
          }
        }),
        tabla: "cuentasporpagar",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        cpUser: usuario.usuario,
        cpEmpresa: usuario.rucempresa,
      });
    } catch (error) {}
  };
  const handleChange = (e) => {
    setItemEdit({
      ...itemEdit,
      [e.target.name]: e.target.value,
    });
  };

  const botonDisabled = () => {
    if (
      itemEdit.cpTipo === "" ||
      itemEdit.cpDeposito === "" ||
      itemEdit.cpValor === "" ||
      itemEdit.cpFechaAbono === ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    setItemEdit({ ...cpPago });
    // eslint-disable-next-line
  }, [item]);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const handleEliminar = () => {
    if (!tienePermiso("eliminar")) {
      return alertaPermiso("eliminar");
    }
    socket.current.emit("client:actualizarData", {
      ...item,
      cpPagos: item.cpPagos.filter((item, indexCPago) => {
        return indexCPago !== index;
      }),
      tabla: "cuentasporpagar",
      rucempresa: usuario.rucempresa,
      rol: usuario.rol,
      cpUser: usuario.usuario,
      cpEmpresa: usuario.rucempresa,
    });
  };
  return (
    <React.Fragment>
      {" "}
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo="¿Esta seguro que quiere eliminar este Item?"
        contenido={`El Item ${cpPago.cpNumero} será eliminado, no podrá ser recuperado.`}
        _id={index}
        funcion={handleEliminar}
      />
      <TableRow className={classes.root}>
        <TableCell>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            {" "}
            <FormControlLabel
              className={classes.margin}
              onChange={(e) => {
                setitem({
                  ...item,
                  cpPagos: item.cpPagos.map((CPPago, indexCPPago) => {
                    if (index === indexCPPago) {
                      return { ...itemEdit, imprimir: e.target.checked };
                    } else {
                      return CPPago;
                    }
                  }),
                });
              }}
              control={
                <Checkbox
                  value="ACTIVO"
                  className={classes.margin}
                  checked={itemEdit.imprimir}
                  color="secondary"
                />
              }
            />
            {editar ? (
              <>
                {" "}
                <IconButton
                  className={classes.margin}
                  onClick={() => {
                    seteditar(false);

                    setItemEdit({ ...cpPago });
                  }}
                >
                  <Cancel fontSize="small" color="secondary" />
                </IconButton>
                <IconButton
                  color={
                    botonDisabled
                      ? botonDisabled()
                        ? "inherit"
                        : "secondary"
                      : "secondary"
                  }
                  disabled={botonDisabled()}
                  className={classes.margin}
                  onClick={() => {
                    handleEditar();

                    seteditar(false);
                    setItemEdit({ ...cpPago });
                  }}
                >
                  <Check fontSize="small" />
                </IconButton>{" "}
              </>
            ) : (
              <>
                {" "}
                <IconButton
                  className={classes.margin}
                  disabled={item.cpEstado === "ANULADA"}
                  color={item.cpEstado === "ANULADA" ? "inherit" : "primary"}
                  onClick={() => {
                    if (!tienePermiso("editar")) {
                      return alertaPermiso("editar");
                    }
                    seteditar(true);
                  }}
                >
                  <Create fontSize="small" />
                </IconButton>
                <IconButton
                  disabled={item.cpEstado === "ANULADA"}
                  className={classes.margin}
                  onClick={() => {
                    setopenConfirmDialog(true);
                  }}
                >
                  <Delete
                    fontSize="small"
                    color={item.cpEstado === "ANULADA" ? "inherit" : "error"}
                  />
                </IconButton>
              </>
            )}{" "}
          </Box>
        </TableCell>

        {/* las demas columnas */}
        {editar ? (
          <>
            {" "}
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              <TextField
                id=""
                value={itemEdit.cpTipo}
                name="cpTipo"
                onChange={(e) => handleChange(e)}
                helperText="Agregando"
                size="small"
                select
                InputLabelProps={{ shrink: true }}
                SelectProps={{ displayEmpty: true }}
                fullWidth
              >
                <MenuItem value="">Seleccione Tipo</MenuItem>
                {[
                  "EFECTIVO",
                  "CHEQUE",
                  "TRANSFERENCIA",
                  "DEPOSITO",
                  "TARJETA",
                  "CRUCE COMPRA",
                  "DONACION",
                ].map((itemTipo, index) => {
                  return (
                    <MenuItem key={index} value={itemTipo}>
                      {itemTipo}{" "}
                    </MenuItem>
                  );
                })}
              </TextField>{" "}
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              <TextField
                id=""
                inputProps={{ style: { textAlign: "center" } }}
                type="number"
                value={itemEdit.cpNumero}
                name="cpNumero"
                onChange={(e) => handleChange(e)}
                helperText="Agregando"
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              <TextField
                id=""
                value={itemEdit.cpBanco}
                name="cpBanco"
                onChange={(e) => handleChange(e)}
                helperText="Agregando"
                size="small"
                select
                InputLabelProps={{ shrink: true }}
                SelectProps={{ displayEmpty: true }}
              >
                <MenuItem value="">Seleccione Banco</MenuItem>
                {bancos.map((cpPago) => {
                  return (
                    <MenuItem key={cpPago.banId} value={cpPago.banNombre}>
                      {cpPago.banNombre}{" "}
                    </MenuItem>
                  );
                })}
              </TextField>{" "}
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              <TextField
                id=""
                value={itemEdit.cpDeposito}
                name="cpDeposito"
                onChange={(e) => handleChange(e)}
                helperText="Agregando"
                size="small"
                select
                InputLabelProps={{ shrink: true }}
                SelectProps={{ displayEmpty: true }}
                fullWidth
              >
                <MenuItem value="">Seleccione Tipo</MenuItem>
                {["SI", "NO"].map((itemcpDeposito, index) => {
                  return (
                    <MenuItem key={index} value={itemcpDeposito}>
                      {itemcpDeposito}{" "}
                    </MenuItem>
                  );
                })}
              </TextField>{" "}
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              <TextField
                id=""
                type="date"
                value={itemEdit.cpFechaAbono}
                name="cpFechaAbono"
                onChange={(e) => handleChange(e)}
                helperText="Agregando"
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              <TextField
                id=""
                inputProps={{ style: { textAlign: "center" } }}
                value={itemEdit.cpValor}
                name="cpValor"
                onChange={(e) => handleChange(e)}
                helperText="Agregando"
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              <TextField
                id=""
                value={itemEdit.cpDetalle}
                name="cpDetalle"
                onChange={(e) => handleChange(e)}
                helperText="Agregando"
                size="small"
                fullWidth
              />
            </TableCell>
          </>
        ) : (
          <>
            {" "}
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              {cpPago.cpTipo}
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              {cpPago.cpNumero}
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              {cpPago.cpBanco}
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              {cpPago.cpDeposito}
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              {cpPago.cpFechaAbono.substr(0, 10)}
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              {Number(cpPago.cpValor).toFixed(2)}
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              {cpPago.cpDetalle}
            </TableCell>
          </>
        )}
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregar);
