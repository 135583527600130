const dosDecimales = (numero) => {
  // Convertir el número a una string con 2 decimales
  let string = numero.toFixed(2);
  // Convertir la string a un número
  let resultado = Number(string);
  // Devolver el resultado
  return resultado;
};

function sumarCantidad(acumulador, valorActual) {
  let venSub0y12 =
    valorActual.llevaIva === "SI"
      ? {
          venSubTotal12: dosDecimales(
            acumulador.venSubTotal12 +
              Number(valorActual.precioSubTotalProducto)
          ),
          venSubtotal0: acumulador.venSubtotal0,
        }
      : {
          venSubTotal12: acumulador.venSubTotal12,
          venSubtotal0: dosDecimales(
            acumulador.venSubtotal0 + Number(valorActual.precioSubTotalProducto)
          ),
        };
  return {
    ...venSub0y12,
    venDescuento:
      Number(acumulador.venDescuento) +
      dosDecimales(Number(valorActual.descuento) * valorActual.cantidad),
    venSubTotal: dosDecimales(
      acumulador.venSubTotal + Number(valorActual.precioSubTotalProducto)
    ),
    venTotalIva: dosDecimales(
      acumulador.venTotalIva + Number(valorActual.valorIva)
    ),
    venTotal: dosDecimales(
      acumulador.venTotal +
        Number(valorActual.precioSubTotalProducto) +
        Number(valorActual.valorIva)
    ),
    venCostoProduccion: dosDecimales(
      acumulador.venTotal + Number(valorActual.costoProduccion)
    ),
  };
}
export const calcularTotales = (productos) => {
  let inicial = {
    venSubTotal12: 0,
    venSubtotal0: 0,
    venDescuento: 0,
    venSubTotal: 0,
    venTotalIva: 0,
    venTotal: 0,
  };
  let resultado = productos.reduce(sumarCantidad, inicial);

  return {
    venSubTotal12: resultado.venSubTotal12,
    venSubtotal0: resultado.venSubtotal0,
    venDescuento: resultado.venDescuento,
    venSubTotal: resultado.venSubTotal,
    venTotalIva: resultado.venTotalIva,
    venTotal: resultado.venTotal,
    venCostoProduccion: resultado.venCostoProduccion,
  };
};
