import React from "react";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  lighten,
  Tooltip,
} from "@mui/material";
import themeF from "../../../../config/themeUser";
import { useContext } from "react";
import AuthContext from "../../../../Context/Auth/AuthContext";
import { hacerIcono } from "../../../../config/const";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
const SubSubCategoria = (props) => {
  const { usuario } = useContext(AuthContext);
  const theme = themeF(usuario);
  const {
    subSubCategoria,
    subCategoria,
    categoria,
    mobile,
    handleDrawerToggle,
    activeRoute,
    open,
  } = props;
  return (
    <Tooltip title={subSubCategoria.sidInfo.name} placement="right-start">
      <ListItem
        disablePadding
        sx={{
          maxHeight: "2.25rem",
          backgroundColor: activeRoute(
            subCategoria.sidInfo.layout +
              categoria.sidInfo.path +
              subCategoria.sidInfo.path +
              subSubCategoria.sidInfo.path
          )
            ? lighten(theme.palette.secondary.main, 0.7)
            : "",
          transitionProperty: "backgroundColor",
          transitionDuration: "1s",
        }}
      >
        <ListItemButton
          onClick={() => {
            if (mobile) handleDrawerToggle();
            props.history.push(
              subCategoria.sidInfo.layout +
                categoria.sidInfo.path +
                subCategoria.sidInfo.path +
                subSubCategoria.sidInfo.path
            );
          }}
          sx={{
            minHeight: 40,
            justifyContent: open ? "initial" : "center",
            px: 3,
            py: 0,
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 1 : "auto",
              justifyContent: "center",
            }}
          >
            {hacerIcono(subSubCategoria.sidInfo.icon, "primary")}
          </ListItemIcon>
          <ListItemText
            primary={subSubCategoria.sidInfo.name}
            primaryTypographyProps={{
              color: "success",
              fontWeight: "bold",
              fontSize: ".8rem",
            }}
            sx={{ opacity: open ? 1 : 0 }}
          />
        </ListItemButton>
      </ListItem>
    </Tooltip>
  );
};

export default withRouter(SubSubCategoria);
