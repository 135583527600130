import React, { memo, useContext, useState } from "react";

import clsx from "clsx";
import { makeStyles } from "@mui/styles";

import { AddCircle, ListRounded, Refresh } from "@mui/icons-material";

import { withRouter } from "react-router-dom";
import {
  Box,
  Button,
  TablePagination,
  TextField,
  Typography,
  lighten,
  IconButton,
  Tooltip,
  Divider,
} from "@mui/material";
import ExportDialog from "../../../../components/Extra/ExportDialog";

import { saveAs } from "file-saver";

import clienteAxios from "../../../../config/axios";
import AuthContext from "../../../../Context/Auth/AuthContext";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
  ocultar: {
    display: "none",
  },
  "MuiTablePagination-toolbar": {
    padding: "none",
  },
}));
const ToolBarTable = (props) => {
  const classes = useToolbarStyles();
  const {
    titulo,
    arrayExport,
    columns,
    expandible,
    search,
    campoExport,
    soloTitulo,
    botonAdd,
    funcionAdd,
    tabla,
    funcionReload,
    pagination,
    rowsPerPage,
    rows,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    deshabilitarbotonAdd,
    //PROPIEDAD DE SOLO TITULO
    etiqueta,
    inputData,
    setinputData,
    funcionInputKey,
    inputRef,
    fullBuscador,
    saldoExport,
    socket,
    tienePermiso,
    alertaPermiso,
  } = props;
  const { usuario } = useContext(AuthContext);
  const [tipo, settipo] = useState("seleccion");
  const [open, setopen] = useState(false);

  const funcPdf = async () => {
    const json = {
      rucempresa: usuario.rucempresa,
      tipo,
      lista: arrayExport.map((itemExport) => itemExport[campoExport]),
      search,
      search_avanzado: fullBuscador,
    };

    const res = await clienteAxios.post(
      `reportes/${tabla}`,
      {
        ...json,
      },
      { responseType: "blob" }
    );
    const pdfBlob = new Blob([res.data], { type: "application/pdf" });
    saveAs(pdfBlob, `${titulo}.pdf`);
    setopen(false);
  };

  return (
    <>
      {soloTitulo ? (
        <Box
          display="flex"
          justifyContent="space-between"
          className={clsx(classes.root, {
            [classes.highlight]: false,
          })}
          alignItems="center"
        >
          {etiqueta ? (
            <TextField
              id="inputData"
              label={etiqueta}
              inputRef={inputRef}
              onKeyPress={async (e) => {
                await funcionInputKey(e);
              }}
              value={inputData}
              name="inputData"
              size="small"
              onChange={(e) => {
                setinputData(e.target.value);
              }}
            />
          ) : null}
          <Button
            style={{ padding: ".0rem" }}
            variant="text"
            color="inherit"
            onClick={async () => {
              if (funcionReload) {
                funcionReload();
              }
            }}
          >
            {titulo}
          </Button>
          {botonAdd ? (
            <IconButton
              aria-label=""
              disabled={deshabilitarbotonAdd}
              color="secondary"
              style={{ padding: ".5rem" }}
              onClick={() => funcionAdd()}
              size="small"
            >
              <AddCircle fontSize="medium" />{" "}
            </IconButton>
          ) : null}
        </Box>
      ) : (
        <>
          <ExportDialog
            tipo={tipo}
            settipo={settipo}
            open={open}
            setopen={setopen}
            funcPdf={funcPdf}
            arrayExport={arrayExport}
            columns={columns}
            titulo={titulo}
            expandible={expandible}
            campoExport={campoExport}
            tabla={tabla}
            socket={socket}
            tienePermiso={tienePermiso}
            alertaPermiso={alertaPermiso}
          />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems={"center"}
            className={clsx(classes.root, {
              [classes.highlight]: false,
            })}
          >
            <Button
              style={{ padding: ".0rem" }}
              variant="text"
              color="inherit"
              onClick={async () => {
                if (funcionReload) {
                  funcionReload();
                }
              }}
              startIcon={<Refresh />}
            >
              ACTUALIZAR
            </Button>

            <Box
              display="flex"
              justifyContent="space-around"
              alignItems={"center"}
            >
              <Typography
                variant="body1"
                color="primary"
                style={{ fontWeight: "bold" }}
              >
                {saldoExport}
              </Typography>
              <TablePagination
                labelRowsPerPage="Filas"
                rowsPerPageOptions={[10, 20, 50, 100, 500, 1000,  ]}
                component="div"
                count={pagination.numRows}
                rowsPerPage={rowsPerPage}
                className={"MuiTablePagination-toolbar"}
                backIconButtonProps={{
                  style: { margin: 0, padding: 0 },
                  size: "small",
                  maxHeight: "25px",
                }}
                nextIconButtonProps={{
                  disabled: pagination.numRows < rows.length,
                  style: { margin: 0, padding: 0 },
                  size: "small",
                  maxHeight: "25px",
                }}
                SelectProps={{
                  style: { margin: 0, padding: 0 },
                  size: "small",
                  inputProps: { style: { margin: 0, padding: 0 } },
                  maxHeight: "25px",
                }}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              {arrayExport ? (
                <Tooltip title="Exportar">
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    disabled={arrayExport.length === 0}
                    onClick={() => setopen(true)}
                    style={{ margin: 0, padding: 0 }}
                  >
                    <ListRounded />
                  </IconButton>
                </Tooltip>
              ) : null}
            </Box>
          </Box>
          <Divider />
        </>
      )}
    </>
  );
};
export default memo(withRouter(ToolBarTable));
