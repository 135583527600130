import React, { useState } from "react";

export const useSimpleTable = (nombreTabla) => {
  const [agregando, setagregando] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
  };

  // eslint-disable-next-line

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return {
    ["agregando" + nombreTabla]: agregando,
    ["setagregando" + nombreTabla]: setagregando,
    ["page" + nombreTabla]: page,
    ["setPage" + nombreTabla]: setPage,
    ["rowsPerPage" + nombreTabla]: rowsPerPage,
    ["setRowsPerPage" + nombreTabla]: setRowsPerPage,
    ["handleChangePage" + nombreTabla]: handleChangePage,
    ["handleChangeRowsPerPage" + nombreTabla]: handleChangeRowsPerPage,
  };
};
