import React, { memo, useContext } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  MenuItem,
} from "@mui/material";

import { Cancel, Check } from "@mui/icons-material";

import { useState } from "react";

import AuthContext from "../../../../Context/Auth/AuthContext";
import { replaceCaracteres, trimPropsItem } from "../../../../config/const";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const RowAgregar = (props) => {
  const { setagregar, socket } = props;
  const [item, setitem] = useState({
    // secuId: 0,
    secuNombre: "",
    secuNum1: "",
    secuNum2: "",
    secuNum3: "",
    secuEstado: "ACTIVO",
    secuEmpresa: "",
    secuUser: "",
    secuFecReg: "",
    Todos: "",
  });

  const classes = useRowStyles();
  //gql eliminar user

  const { usuario } = useContext(AuthContext);
  const handleAgregar = async () => {
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          tabla: "comprobante",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          secuUser: usuario.usuario,
          secuEmpresa: usuario.rucempresa,
        })
      );
    } catch (error) {
       
    }
  };
  const botonDisabled = () => {
    if (item.catNombre === "") {
      return true;
    } else {
      return false;
    }
  };
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell></TableCell> {/* boton */}
        <TableCell>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <IconButton
              className={classes.margin}
              onClick={() => {
                setagregar(false);
                setitem({
                  // secuId: 0,
                  secuNombre: "",
                  secuNum1: "",
                  secuNum2: "",
                  secuNum3: "",
                  secuEstado: "ACTIVO",
                  secuEmpresa: "",
                  secuUser: "",
                  secuFecReg: "",
                  Todos: "",
                });
              }}
            >
              <Cancel fontSize="small" color="secondary" />
            </IconButton>
            <IconButton
              color={
                botonDisabled
                  ? botonDisabled()
                    ? "inherit"
                    : "secondary"
                  : "secondary"
              }
              disabled={botonDisabled()}
              className={classes.margin}
              onClick={() => {
                handleAgregar();

                setagregar(false);
                setitem({
                  // secuId: 0,
                  secuNombre: "",
                  secuNum1: "",
                  secuNum2: "",
                  secuNum3: "",
                  secuEstado: "ACTIVO",
                  secuEmpresa: "",
                  secuUser: "",
                  secuFecReg: "",
                  Todos: "",
                });
              }}
            >
              <Check fontSize="small" />
            </IconButton>{" "}
            <FormControlLabel
              className={classes.margin}
              onChange={(e) => {
                setitem({
                  ...item,
                  secuEstado: e.target.checked ? "ACTIVA" : "INACTIVA",
                });
              }}
              control={
                <Checkbox
                  value="ACTIVO"
                  className={classes.margin}
                  checked={
                    item["secuEstado"] === "ACTIVA" ||
                    item["secuEstado"] === "ACTIVO"
                      ? true
                      : false
                  }
                  color="secondary"
                />
              }
            />
          </Box>
        </TableCell>
        {/* las demas columnas */}
        <TableCell fontSize="small" padding="none">
          <TextField
            id=""
            value={item.secuNombre}
            onChange={(e) => {
              const res = replaceCaracteres(e.target.value);
              setitem({
                ...item,
                secuNombre: res,
              });
            }}
            helperText="Agregando"
            size="small"
            fullWidth
            select
            SelectProps={{
              displayEmpty: true,
            }}
            InputLabelProps={{ shrink: true }}
          >
            <MenuItem value="">Seleccione </MenuItem>;
            {[
              "FACTURA",
              "NOTA DE CREDITO",
              "RETENCION",
              "NOTA DE DEBITO",
              "GUIA DE REMISION",
              "NOTA DE VENTA",
              "PROFORMA",
            ].map((item) => {
              return <MenuItem value={item}>{item}</MenuItem>;
            })}
          </TextField>
        </TableCell>
        <TableCell fontSize="small" padding="none">
          <TextField
            id=""
            type="number"
            value={item.secuNum1}
            onChange={(e) => {
              const res = replaceCaracteres(e.target.value);
              setitem({
                ...item,
                secuNum1: res,
              });
            }}
            helperText="Agregando"
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell fontSize="small" padding="none">
          <TextField
            id=""
            type="number"
            value={item.secuNum2}
            onChange={(e) => {
              const res = replaceCaracteres(e.target.value);
              setitem({
                ...item,
                secuNum2: res,
              });
            }}
            helperText="Agregando"
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell fontSize="small" padding="none">
          <TextField
            id=""
            type="number"
            value={item.secuNum3}
            onChange={(e) => {
              const res = replaceCaracteres(e.target.value);
              setitem({
                ...item,
                secuNum3: res,
              });
            }}
            helperText="Agregando"
            size="small"
            fullWidth
          />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregar);
