import { TableCell, Typography } from "@mui/material";
import React from "react";
import { useContext } from "react";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import clienteAxios from "../../../config/axios";

const CallModalPersona = ({ user, claseCelda }) => {
  const { mostrarAlerta } = useContext(AlertaContext);
  const handleGetNameUser = async (documento) => {
    if (!documento) {
      return;
    }
    try {
      const resUser = await clienteAxios.get(
        `/proveedores/usuario/${documento}`
      );
      mostrarAlerta(resUser.data.data.nombre, "success");
    } catch (error) {
      mostrarAlerta("Hubo un error");
    }
  };
  return (
    <TableCell
      padding="none"
      size="small"
      align="center"
      className={claseCelda}
    >
      <Typography
        color="secondary"
        fontSize={".7rem"}
        fontWeight="bold"
        sx={{
          transitionProperty: "opacity",
          transitionDuration: ".5s",
          ":hover": { opacity: 0.7, cursor: "pointer" },
        }}
        onClick={() => handleGetNameUser(user.split(" ** ")[0])}
        component="span"
      >
        Crea:{" "}
        <Typography color="primary" fontSize={".7rem"} component="span">
          {user.split(" ** ")[0] ? user.split(" ** ")[0] : "-"}
        </Typography>
      </Typography>
      <Typography
        color="secondary"
        fontSize={".7rem"}
        fontWeight="bold"
        sx={{
          marginLeft: ".3rem",
          transitionProperty: "opacity",
          transitionDuration: ".5s",
          ":hover": { opacity: 0.7, cursor: "pointer" },
        }}
        onClick={() => handleGetNameUser(user.split(" ** ")[1])}
        component="span"
      >
        Act:
        <Typography color="primary" fontSize={".7rem"} component="span">
          {user.split(" ** ")[1] ? user.split(" ** ")[1] : "-"}
        </Typography>
      </Typography>
    </TableCell>
  );
};

export default CallModalPersona;
