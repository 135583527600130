import { Box } from "@mui/system";
import React from "react";
import Typography from "@mui/material/Typography";
import * as IconosImport from "@mui/icons-material";
import { withRouter } from "react-router-dom";

const Cards = (props) => {
  const hacerIcono = (icono, color = "inherit") => {
    if (icono === "" || !icono) {
      return;
    }
    const Icono = IconosImport[icono];
    if (!Icono) {
      return null;
    }
    return <Icono color={color} fontSize="large" />;
  };
  const { data, padreFinal } = props;
  if (data.tipo === "categoria") {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          backgroundColor: "#FFFDF9",
          borderRadius: "1rem",
          boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
          transitionProperty: "transform",
          transitionDuration: ".3s",
          ":hover": {
            transform: "scale(1.075)",
          },
          padding: ".8rem 1.2rem",
          minHeight: {
            xs: "7rem",
            sm: "8rem",
            md: "9rem",
            lg: "10rem",
          },
          cursor: "pointer",
        }}
        onClick={() => {
          props.history.push(data.layout + data.path);
        }}
      >
        <Box>
          <Typography
            variant="h5"
            color="secondary"
            align="center"
            fontWeight={"500"}
          >
            {data.name}
          </Typography>
          <Box display={"flex"} justifyContent="center">
            {hacerIcono(data.icon, "secondary")}
          </Box>
        </Box>
        <Typography variant="subtitle2" color="primary" align="center">
          {data.descripcion}
        </Typography>
      </Box>
    );
  }
  if (data.tipo === "subCategoria") {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          backgroundColor: "#FFFDF9",
          borderRadius: "1rem",
          boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
          transitionProperty: "transform",
          transitionDuration: ".3s",
          ":hover": {
            transform: "scale(1.075)",
          },
          padding: ".8rem 1.2rem",
          minHeight: {
            xs: "7rem",
            sm: "8rem",
            md: "9rem",
            lg: "10rem",
          },
          cursor: "pointer",
        }}
        onClick={() => {
          props.history.push(data.layout + data.categoria + data.path);
        }}
      >
        <Box>
          <Typography
            variant="h5"
            color="secondary"
            align="center"
            fontWeight={"500"}
          >
            {data.name}
          </Typography>
          <Box display={"flex"} justifyContent="center">
            {hacerIcono(data.icon, "secondary")}
          </Box>
        </Box>
        <Typography variant="subtitle2" color="primary" align="center">
          {data.descripcion}
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        backgroundColor: "#FFFDF9",
        borderRadius: "1rem",
        boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
        transitionProperty: "transform",
        transitionDuration: ".3s",
        ":hover": {
          transform: "scale(1.075)",
        },
        padding: ".8rem 1.2rem",
        minHeight: {
          xs: "7rem",
          sm: "8rem",
          md: "9rem",
          lg: "10rem",
        },
        cursor: "pointer",
      }}
      onClick={() => {
        props.history.push(
          data.layout +
            padreFinal.sidInfo.categoria +
            padreFinal.sidInfo.path +
            data.path
        );
      }}
    >
      <Box>
        <Typography
          variant="h5"
          color="secondary"
          align="center"
          fontWeight={"500"}
        >
          {data.name}
        </Typography>
        <Box display={"flex"} justifyContent="center">
          {hacerIcono(data.icon, "secondary")}
        </Box>
      </Box>
      <Typography variant="subtitle2" color="primary" align="center">
        {data.descripcion}
      </Typography>
    </Box>
  );
};

export default withRouter(Cards);
