import React, { memo, useContext } from "react";
import { makeStyles } from "@mui/styles";
import { Box, IconButton, TableCell, TableRow } from "@mui/material";

import {
  VideoLibraryOutlined,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";

import { useState } from "react";

import MenuContext from "../../../Context/Menu/MenuContext"; 
import ConfirmacionDialog from "../../../components/Extra/ConfirmacionDialog";
import AuthContext from "../../../Context/Auth/AuthContext";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const Row = (props) => {
  const {
    row,
    index,
    // eslint-disable-next-line
    setopen,
    expandir,
    expand, 
  } = props;
  const { usuario } = useContext(AuthContext); 
  const {   changeCategoriaActiva } = useContext(MenuContext);
  const classes = useRowStyles();
  //gql eliminar usuario

  //gql eliminar usuario
 
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const permisos = (item) => {
    return item.sidInfo.permisos.some(
      (rol) =>
        usuario.rol.some((itemRolUsuario) => itemRolUsuario === rol) &&
        item.sidInfo.categoriaEmpresa.some(
          (itemCatEmp) => itemCatEmp === usuario.empCategoria
        )
    );
  };
  if (!permisos(row)) {
    return null;
  }
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo="¿Esta seguro de que quiere eliminar esta asignatura?"
        contenido={`La asignatura   ${row.sidInfo.name} , una vez borrado no podra ser recuperado`}
        _id={row.sidInfo._id}
        funcion={() => {}}
      />
      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <TableCell
          style={{ borderRight: "1px solid #ccc", paddingRight: "1.5rem" }}
        >
          <Box display="flex" alignItems="center">
            <IconButton
              className={classes.margin}
              aria-label=""
              onClick={() => {
                changeCategoriaActiva(row);
                setopen(true);
              }}
            >
              <VideoLibraryOutlined fontSize="small" color="secondary" />
            </IconButton>
            {row.sidInfo.expandible ? (
              <IconButton
                className={classes.margin}
                onClick={() => {
                  expandir(row.sidInfo.name, Boolean(expand[row.sidInfo.name]));
                }}
              >
                {Boolean(expand[row.sidInfo.name]) ? (
                  <ExpandLess fontSize="small" color="secondary" />
                ) : (
                  <ExpandMore fontSize="small" color="secondary" />
                )}
              </IconButton>
            ) : null}
          </Box>
        </TableCell>
        {/* las demas columnas */}
        <TableCell size="small" align="center">
          {row.sidInfo.name}
        </TableCell>
        <>
          <TableCell size="small" align="center" padding="none">
            {row.sidInfo.tutoriales.length === 0
              ? "No hay tutoriales"
              : row.sidInfo.tutoriales.length}
          </TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(Row);
