import React, { memo, useContext, useState } from "react";

import clsx from "clsx";
import { makeStyles } from "@mui/styles";

import { ListRounded, Refresh } from "@mui/icons-material";

import { withRouter } from "react-router-dom";
import {
  Box,
  Button,
  TablePagination,
  lighten,
  IconButton,
  Tooltip,
  Divider,
} from "@mui/material";
import ExportDialog from "./ExportDialog";

import clienteAxios from "../../../../config/axios";
import { saveAs } from "file-saver";
import AuthContext from "../../../../Context/Auth/AuthContext";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
  ocultar: {
    display: "none",
  },
  "MuiTablePagination-toolbar": {
    padding: "none",
  },
}));
const ToolBarTable = (props) => {
  const classes = useToolbarStyles();
  const {
    search,
    titulo,
    arrayExport,
    columns,
    expandible,
    campoExport,
    tabla,
    funcionReload,
    pagination,
    rowsPerPage,
    rows,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    estadoRequest,
    fullBuscador,
    socket,
  } = props;

  const [tipo, settipo] = useState("seleccion");
  const [open, setopen] = useState(false);
  const {
    usuario: { rucempresa },
  } = useContext(AuthContext);
  const funcPdf = async () => {
    const json = {
      rucempresa,
      tipo,
      search,
      estado: estadoRequest,
      search_avanzado: fullBuscador,
      lista: arrayExport,
    };

    const res = await clienteAxios.post(
      `reportes/${tabla}`,
      {
        ...json,
      },
      { responseType: "blob" }
    );

    const pdfBlob = new Blob([res.data], { type: "application/pdf" });
    saveAs(pdfBlob, `${titulo}.pdf`);
    setopen(false);
  };
  const funcExcel = async () => {
    const json = {
      rucempresa,
      tipo,
      search,
      estado: estadoRequest,
      search_avanzado: fullBuscador,
      lista: arrayExport,
    };

    const res = await clienteAxios.post(`reportes/excel/${tabla}`, json, {
      responseType: "blob",
    });
    const pdfBlob = new Blob([res.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(pdfBlob, `${titulo}.xlsx`);

    setopen(false);
  };
  const funcExcel2 = async () => {
    const json = {
      rucempresa,
      tipo,
      search,
      estado: estadoRequest,
      search_avanzado: fullBuscador,
      lista: arrayExport,
    };

    const res = await clienteAxios.post(`/reportes/excel/recaudaciones`, json, {
      responseType: "blob",
    });
    const pdfBlob = new Blob([res.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(pdfBlob, `Recaudaciones.xlsx`);

    setopen(false);
  };
  return (
    <>
      <ExportDialog
        tipo={tipo}
        settipo={settipo}
        open={open}
        setopen={setopen}
        funcPdf={funcPdf}
        funcExcel={funcExcel}
        arrayExport={arrayExport}
        columns={columns}
        titulo={titulo}
        expandible={expandible}
        campoExport={campoExport}
        tabla={tabla}
        socket={socket}
        funcExcel2={funcExcel2}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        className={clsx(classes.root, {
          [classes.highlight]: false,
        })}
      >
        <Button
          style={{ padding: ".0rem" }}
          variant="text"
          color="inherit"
          onClick={async () => {
            if (funcionReload) {
              funcionReload();
            }
          }}
          startIcon={<Refresh />}
        >
          ACTUALIZAR
        </Button>
        <Box display="flex" justifyContent="space-around">
          <TablePagination
            labelRowsPerPage="Filas"
            rowsPerPageOptions={[10, 20, 50, 100, 500, 1000]}
            component="div"
            count={pagination.numRows}
            rowsPerPage={rowsPerPage}
            className={"MuiTablePagination-toolbar"}
            backIconButtonProps={{
              style: { margin: 0, padding: 0 },
              size: "small",
              maxHeight: "25px",
            }}
            nextIconButtonProps={{
              disabled: pagination.numRows < rows.length,
              style: { margin: 0, padding: 0 },
              size: "small",
              maxHeight: "25px",
            }}
            SelectProps={{
              style: { margin: 0, padding: 0 },
              size: "small",
              inputProps: { style: { margin: 0, padding: 0 } },
              maxHeight: "25px",
            }}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {arrayExport ? (
            <Tooltip title="Exportar">
              <IconButton
                aria-label="delete"
                color="primary"
                disabled={arrayExport.length === 0}
                onClick={() => setopen(true)}
                style={{ margin: 0, padding: 0 }}
              >
                <ListRounded />
              </IconButton>
            </Tooltip>
          ) : null}
        </Box>
      </Box>
      <Divider />
    </>
  );
};
export default memo(withRouter(ToolBarTable));
