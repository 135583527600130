import {
  Box,
  TextField,
  Typography,
  IconButton,
  MenuItem,
  FormControl,
} from "@mui/material";
import { AddCircle } from "@mui/icons-material";

export const PlantillaTab = ({
  propiedadPlantilla,
  empresa,
  setempresa,
  clases,
  arregloPropiedades,
}) => {
  return (
    <>
      <FormControl className={clases.formAllW}>
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle1">Agregar variable:</Typography>
          <IconButton
            aria-label=""
            size="small"
            onClick={() => {
              const getVariableF = (valorActual) => {
                return empresa[propiedadPlantilla].variables.find(
                  (variable) => variable.nombre === valorActual
                );
              };
              const textoPlantilla =
                empresa[propiedadPlantilla].plantilla +
                `{{ ${empresa[propiedadPlantilla].variables.length + 1} }}`;
              let regex = /{{\s*(\d+)\s*}}/g;
              let match;
              let result = [];

              while ((match = regex.exec(textoPlantilla)) !== null) {
                const variableF = getVariableF(match[1]);
                if (variableF) {
                  result.push(variableF);
                } else {
                  result.push({
                    nombre: match[1],
                    campo: "",
                  });
                }
              }
              setempresa({
                ...empresa,
                [propiedadPlantilla]: {
                  variables: result,
                  plantilla: textoPlantilla,
                },
              });
            }}
          >
            <AddCircle fontSize="small" color="secondary" />
          </IconButton>
        </Box>
      </FormControl>

      {empresa[propiedadPlantilla].variables.map((variable, index) => {
        return (
          <TextField
            key={index}
            className={clases.formD}
            value={variable.campo}
            onChange={(e) => {
              setempresa({
                ...empresa,
                [propiedadPlantilla]: {
                  ...empresa[propiedadPlantilla],
                  variables: empresa[propiedadPlantilla].variables.map(
                    (item, indexItem) => {
                      if (indexItem === index) {
                        return {
                          nombre: item.nombre,
                          campo: e.target.value,
                        };
                      } else {
                        return item;
                      }
                    }
                  ),
                },
              });
            }}
            label={`Variable #${variable.nombre}`}
            select
            SelectProps={{
              displayEmpty: true,
            }}
            InputLabelProps={{ shrink: true }}
          >
            <MenuItem value="">Seleccione </MenuItem>;
            {arregloPropiedades.map((item) => {
              return (
                <MenuItem key={item.etiqueta} value={item.propiedad}>
                  {item.etiqueta}
                </MenuItem>
              );
            })}
          </TextField>
        );
      })}

      <TextField
        color="secondary"
        multiline
        onBlur={() => {
          const getVariableF = (valorActual) => {
            return empresa[propiedadPlantilla].variables.find(
              (variable) => variable.nombre === valorActual
            );
          };
          const textoPlantilla = empresa[propiedadPlantilla].plantilla;
          let regex = /{{\s*(\d+)\s*}}/g;
          let match;
          let result = [];

          while ((match = regex.exec(textoPlantilla)) !== null) {
            const variableF = getVariableF(match[1]);
            if (variableF) {
              result.push(variableF);
            } else {
              result.push({
                nombre: match[1],
                campo: "",
              });
            }
          }

          setempresa({
            ...empresa,

            [propiedadPlantilla]: {
              variables: result,
              plantilla: textoPlantilla,
            },
          });
        }}
        minRows={3}
        variant="outlined"
        className={clases.formAllW}
        label="Plantilla"
        value={empresa[propiedadPlantilla].plantilla}
        name="correoResp"
        onChange={(e) => {
          setempresa({
            ...empresa,
            [propiedadPlantilla]: {
              ...empresa[propiedadPlantilla],
              plantilla: e.target.value,
            },
          });
        }}
      />
    </>
  );
};
