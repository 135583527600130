import { useRef } from "react";

export const useDraggable = () => {
  const dragItem = useRef(null);
  const draOverItem = useRef(null);
  const handleDragStart = (index) => {
    dragItem.current = index;
  };
  const handleDragEnter = (index) => {
    draOverItem.current = index;
  };
  const handleDragEnd = (propiedad, array, item, setitem) => {
    let copy = [...array];
    const draggedItemContent = copy.splice(dragItem.current, 1)[0];
    // switch position
    copy.splice(draOverItem.current, 0, draggedItemContent);
    // reset
    dragItem.current = null;
    draOverItem.current = null;
    setitem({
      ...item,
      [propiedad]: copy.map((item, index) => ({
        ...item,
        order: index + 1,
      })),
    });
  };
  return {
    handleDragStart,
    handleDragEnter,
    handleDragEnd,
    dragItem,
    draOverItem,
  };
};
