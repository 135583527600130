import React, { memo, useContext, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
  IconButton,
  Badge,
} from "@mui/material";

import { useState } from "react";

// import ConfirmacionDialog from "../../Dialog/ConfirmacionDialog";

import ConfirmacionDialog from "../../../../components/Extra/ConfirmacionDialog";

import { withRouter } from "react-router";
import ButtonsAction from "./ButtonsAction";
import AuthContext from "../../../../Context/Auth/AuthContext";
import { tienePermisoRuta } from "../../../../config/validaciones";
import MenuContext from "../../../../Context/Menu/MenuContext";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import { permisoDenagado } from "../../../../config/const";
import TablaCabecera from "../../../../components/Extra/TablaCabecera";
import RowItem from "./RowItem";
import { AddCircle } from "@mui/icons-material";
import RowAgregarItem from "./RowAgregarItem";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  celda: {
    fontSize: ".7rem",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const Row = (props) => {
  const reducer = (previousValue, currentValue) =>
    Number(previousValue) + Number(currentValue);
  const {
    arrayExport,
    setarrayExport,
    row,
    index,
    campoExport,
    bancos,
    socket,
    tienePermiso,
    alertaPermiso,
  } = props;

  const { rows: rowsMenu } = useContext(MenuContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext);
  const classes = useRowStyles();
  const [item, setitem] = useState({ ...row });
  const [editar, seteditar] = useState(false);
  const [expandir, setexpandir] = useState(false);
  const [agregar, setagregar] = useState(false);
  useEffect(() => {
    setarrayExport(
      arrayExport.map((itemExp) => {
        if (itemExp.cpId === item.cpId) {
          return item;
        } else {
          return itemExp;
        }
      })
    );
    // eslint-disable-next-line
  }, [item.cpPagos]);
  const handleEliminar = async (_id) => {
    if (!tienePermiso("eliminar")) {
      return alertaPermiso("eliminar");
    }

    if (
      !tienePermisoRuta(
        rowsMenu,
        props.location.pathname,
        usuario.rol,
        "eliminar"
      )
    ) {
      return mostrarAlerta(permisoDenagado, "error");
    }
    try {
      socket.current.emit("client:eliminarData", {
        tabla: "categoria",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        cpUser: usuario.usuario,
        cpEmpresa: usuario.rucempresa,
        cpId: row.cpId,
      });
    } catch (error) {}
  };
  const funcionEdit = () => {
    if (
      !tienePermisoRuta(
        rowsMenu,
        props.location.pathname,
        usuario.rol,
        "editar"
      )
    ) {
      return mostrarAlerta(permisoDenagado, "error");
    }
    seteditar(true);
  };

  const funcCancel = () => {
    setitem({ ...row });
    seteditar(false);
  };
  const funcSubmit = () => {
    try {
      socket.current.emit("client:actualizarData", {
        ...item,
        tabla: "categoria",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        cpUser: usuario.usuario,
        cpEmpresa: usuario.rucempresa,
      });
      seteditar(false);
    } catch (error) {}
  };
  const botonDisabled = () => {
    if (
      item.catNombre === "" ||
      item.catDetalle === "" ||
      item.catEmpresa === ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    setitem(row);
  }, [row]);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo={`¿Esta seguro de eliminar este registro: ${row.porCodigo}?`}
        contenido={`El Código ${row.porCodigo} será eliminado, no podrá ser recuperado.`}
        _id={row._id}
        funcion={handleEliminar}
      />
      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          row={row}
          setopenConfirmDialog={setopenConfirmDialog}
          funcionEdit={funcionEdit}
          ocultarEditar={true}
          ocultarDelete={true}
          mostrarEstado={false}
          campoExport={campoExport}
          campoEstado="catEstado"
          editar={editar}
          funcCancel={funcCancel}
          funcSubmit={funcSubmit}
          botonDisabled={botonDisabled}
          item={item}
          setitem={setitem}
          expandible={true}
          expandir={expandir}
          setexpandir={setexpandir}
        />{" "}
        <TableCell
          padding="none"
          size="small"
          align="left"
          className={classes.celda}
        >
          <Box display={"flex"} alignItems={"center"}>
            <Badge
              badgeContent={item.cpPagos.length}
              color="primary"
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            ></Badge>
            {editar ? (
              <TextField
                id=""
                value={item.cpRucProveedor}
                onChange={(e) =>
                  setitem({
                    ...item,
                    cpRucProveedor: e.target.value,
                  })
                }
                size="small"
                fullWidth
                helperText="Editando"
              />
            ) : (
              `${item.cpRucProveedor} ${item.cpNomProveedor}`
            )}
          </Box>
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              id=""
              value={item.cpFactura}
              onChange={(e) =>
                setitem({
                  ...item,
                  cpFactura: e.target.value,
                })
              }
              size="small"
              fullWidth
              helperText="Editando"
            />
          ) : (
            `${item.cpFactura}`
          )}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              id=""
              value={item.cpFechaFactura}
              onChange={(e) =>
                setitem({
                  ...item,
                  cpFechaFactura: e.target.value,
                })
              }
              size="small"
              fullWidth
              helperText="Editando"
            />
          ) : (
            `${item.cpFechaFactura.substr(0, 10)}`
          )}
        </TableCell>{" "}
        <TableCell
          padding="none"
          size="small"
          align="center"
          style={{
            color:
              row.cpEstado === "PENDIENTE"
                ? //rojo tomate
                  "#ffa726"
                : row.cpEstado === "CANCELADO"
                ? //verde
                  "green"
                : row.cpEstado === "ANULADA"
                ? "#f44336"
                : "#ffa726",
            fontWeight: "bold",
          }}
          className={classes.celda}
        >
          {row.cpEstado}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              id=""
              value={item.cpFechaAbono}
              onChange={(e) =>
                setitem({
                  ...item,
                  cpFechaAbono: e.target.value,
                })
              }
              size="small"
              fullWidth
              helperText="Editando"
            />
          ) : (
            `${item.cpFechaAbono.substr(0, 10)}`
          )}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              id=""
              value={item.cpValorFactura}
              onChange={(e) =>
                setitem({
                  ...item,
                  cpValorFactura: e.target.value,
                })
              }
              size="small"
              fullWidth
              helperText="Editando"
            />
          ) : (
            `${item.cpValorFactura}`
          )}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              id=""
              value={item.cpValorRetencion}
              onChange={(e) =>
                setitem({
                  ...item,
                  cpValorRetencion: e.target.value,
                })
              }
              size="small"
              fullWidth
              helperText="Editando"
            />
          ) : (
            `${item.cpValorRetencion}`
          )}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              id=""
              value={item.cpValorAPagar}
              onChange={(e) =>
                setitem({
                  ...item,
                  cpValorAPagar: e.target.value,
                })
              }
              size="small"
              fullWidth
              helperText="Editando"
            />
          ) : (
            `${item.cpValorAPagar}`
          )}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              id=""
              value={item.cpAbono}
              onChange={(e) =>
                setitem({
                  ...item,
                  cpAbono: e.target.value,
                })
              }
              size="small"
              fullWidth
              helperText="Editando"
            />
          ) : (
            `${item.cpAbono}`
          )}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              id=""
              value={item.cpSaldo}
              onChange={(e) =>
                setitem({
                  ...item,
                  cpSaldo: e.target.value,
                })
              }
              size="small"
              fullWidth
              helperText="Editando"
            />
          ) : (
            `${item.cpSaldo}`
          )}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              id=""
              value={item.cpFecReg}
              onChange={(e) =>
                setitem({
                  ...item,
                  cpFecReg: e.target.value,
                })
              }
              size="small"
              fullWidth
              helperText="Editando"
            />
          ) : (
            `${item.cpFecReg.replace("T", " ").substr(0, 10)}`
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={21}>
          <Collapse in={expandir} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="body1" gutterBottom component="span">
                  Cuentas Por Pagar
                </Typography>{" "}
                <IconButton
                  size="small"
                  disabled={
                    item.cpEstado === "CANCELADO" || item.cpEstado === "ANULADA"
                  }
                  color={
                    item.cpEstado === "CANCELADO" || item.cpEstado === "ANULADA"
                      ? "inherit"
                      : "primary"
                  }
                  aria-label=""
                  onClick={() => {
                    if (!tienePermiso("agregar")) {
                      return alertaPermiso("agregar");
                    }
                    setagregar(true);
                  }}
                >
                  <AddCircle />
                </IconButton>
              </Box>
              <Table size="small" aria-label="purchases">
                <TablaCabecera
                  columns={[
                    {
                      id: "cpTipo",
                      align: "center",
                      minWidth: 50,
                      colSpan: 1,
                      label: "Tipo",
                    },
                    {
                      id: "cpNumero",
                      align: "center",
                      minWidth: 50,
                      colSpan: 1,
                      label: "Número",
                    },
                    {
                      id: "cpBanco",
                      align: "center",
                      minWidth: 50,
                      colSpan: 1,
                      label: "Banco",
                    },
                    {
                      id: "cpDeposito",
                      align: "center",
                      minWidth: 50,
                      colSpan: 1,
                      label: "Deposito",
                    },
                    {
                      id: "cpFechaAbono",
                      align: "center",
                      minWidth: 50,
                      colSpan: 1,
                      label: "Fecha Abono",
                    },
                    {
                      id: "cpValor",
                      align: "center",
                      minWidth: 50,
                      colSpan: 1,
                      label: "Valor",
                    },
                    {
                      id: "cpDetalle",
                      align: "center",
                      minWidth: 50,
                      colSpan: 1,
                      label: "Detalle",
                    },
                  ]}
                />
                <TableBody>
                  {agregar ? (
                    <RowAgregarItem
                      bancos={bancos}
                      item={item}
                      setitem={setitem}
                      setagregar={setagregar}
                      socket={socket}
                    />
                  ) : null}
                  {item.cpPagos.map((cpPago, index) => {
                    return (
                      <RowItem
                        cpPago={cpPago}
                        bancos={bancos}
                        item={item}
                        setitem={setitem}
                        index={index}
                        key={index}
                        socket={socket}
                        tienePermiso={tienePermiso}
                        alertaPermiso={alertaPermiso}
                      />
                    );
                  })}{" "}
                  <TableRow>
                    <TableCell
                      padding="none"
                      size="small"
                      align="center"
                      className={classes.celda}
                    ></TableCell>
                    <TableCell
                      padding="none"
                      size="small"
                      align="center"
                      className={classes.celda}
                    ></TableCell>{" "}
                    <TableCell
                      padding="none"
                      size="small"
                      align="center"
                      className={classes.celda}
                    ></TableCell>
                    <TableCell
                      padding="none"
                      size="small"
                      align="center"
                      className={classes.celda}
                    ></TableCell>
                    <TableCell
                      padding="none"
                      size="small"
                      align="center"
                      className={classes.celda}
                    ></TableCell>
                    <TableCell
                      padding="none"
                      size="small"
                      align="center"
                      className={classes.celda}
                    ></TableCell>
                    <TableCell
                      padding="none"
                      size="small"
                      align="center"
                      className={classes.celda}
                    >
                      <Typography
                        variant="subtitle1"
                        color="textPrimary"
                        style={{ fontSize: ".8rem" }}
                      >
                        {item.cpPagos.length === 0
                          ? null
                          : item.cpPagos
                              .map((row) => {
                                return Number(row.cpValor);
                              })
                              .reduce(reducer)
                              .toFixed(2)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      padding="none"
                      size="small"
                      align="center"
                      className={classes.celda}
                    ></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>{" "}
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
