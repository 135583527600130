import React, { useContext, useEffect, useMemo, useState } from "react";
import Caja from "./Caja";
import { makeStyles } from "@mui/styles";
import { Box, Button, Divider, Grid, Hidden, Typography } from "@mui/material";
import AuthContext from "../../Context/Auth/AuthContext";
import MenuContext from "../../Context/Menu/MenuContext";
import Tooltip from "@mui/material/Tooltip";
import GridCard from "../ArchivosCards/GridCards/Grid";
import clienteAxios from "../../config/axios";
import Cargando from "../components/Cargando/Cargando";
import GraficoLineaHis from "./GraficoLineaHis";
import GraficoLineaReserva from "./GraficoLineaReserva";
// import Reservas from "../Veterinaria/Reservas/Reservas";
import Chart from "./Chart";
import GraficoDougProductos from "./GraficoDougProductos";

// import { secondary } from "../../config/const";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const Dashboard = () => {
  // eslint-disable-next-line
  const { usuario } = useContext(AuthContext);
  // eslint-disable-next-line
  const vet = useMemo(() => usuario.empCategoria === "VETERINARIA", []);
  const [seccionActive, setseccionActive] = useState({
    cardsSeccion: false,
    info: true,
  });
  const { cardsSeccion, info } = seccionActive;
  const tienePermiso = (component) => {
    if (!usuario.permisos_usuario[usuario.rucempresa]) {
      const res = component.sidInfo.permisos.some((itemPermiso) => {
        return usuario.rol.some(
          (itemRolUsuario) => itemRolUsuario === itemPermiso
        );
      });

      return res;
    }
    if (
      !usuario.permisos_usuario[usuario.rucempresa][component.sidInfo.component]
    ) {
      const res = component.sidInfo.permisos.some((itemPermiso) => {
        return usuario.rol.some(
          (itemRolUsuario) => itemRolUsuario === itemPermiso
        );
      });

      return res;
    }
    if (
      usuario.permisos_usuario[usuario.rucempresa][component.sidInfo.component]
        .permisos === 0
    ) {
      const res = component.sidInfo.permisos.some((itemPermiso) => {
        return usuario.rol.some(
          (itemRolUsuario) => itemRolUsuario === itemPermiso
        );
      });

      return res;
    }
    if (
      usuario.permisos_usuario[usuario.rucempresa][component.sidInfo.component]
        .permisos === 1
    ) {
      return true;
    }
    if (
      usuario.permisos_usuario[usuario.rucempresa][component.sidInfo.component]
        .permisos === 2
    ) {
      return false;
    }
  };
  const permisos = (item) => {
    return item.sidInfo.categoriaEmpresa.some(
      (itemCatEmp) => itemCatEmp === usuario.empCategoria
    );
  };

  const { rows } = useContext(MenuContext);
  const classes = useStyles();
  const [cards, setcards] = useState(null);
  useEffect(() => {
    if (!cards) {
      const subsubC = rows.filter((item) => {
        return (
          item.sidInfo.tipo === "subsubCategoria" &&
          item.sidInfo.mostrar === "SI"
        );
      });

      const subC = rows.filter((item) => {
        return (
          item.sidInfo.tipo === "subCategoria" && item.sidInfo.mostrar === "SI"
        );
      });
      const Cat = rows.filter(
        (item) =>
          item.sidInfo.tipo === "categoria" && item.sidInfo.mostrar === "SI"
      );

      setcards([...subsubC, ...subC, ...Cat]);
    } // eslint-disable-next-line
  }, []);
  const [fecha, setfecha] = useState("");

  function tiempoRestante(fecha) {
    const fechaObjeto = new Date(
      Date.parse(fecha) + new Date().getTimezoneOffset() * 60 * 1000
    );
    const ahora = new Date();
    const diferencia = fechaObjeto - ahora;

    if (diferencia <= 24 * 60 * 60 * 1000) {
      const horas = Math.floor(
        (diferencia % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutos = Math.floor((diferencia % (1000 * 60 * 60)) / (1000 * 60));
      return horas + " horas, " + minutos + " minutos";
    } else {
      const dias = Math.floor(diferencia / (1000 * 60 * 60 * 24));
      const horas = Math.floor(
        (diferencia % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutos = Math.floor((diferencia % (1000 * 60 * 60)) / (1000 * 60));
      return dias + " días, " + horas + " horas, " + minutos + " minutos";
    }
  }

  useEffect(() => {
    setfecha(tiempoRestante(usuario.fechaCaducaFirma));
    setInterval(() => {
      setfecha(tiempoRestante(usuario.fechaCaducaFirma));
    }, 60000); // eslint-disable-next-line
  }, []);
  const [graficas_estadisticas, setgraficas_estadisticas] = useState(null);
  useEffect(() => {
    const getgraficas_estadisticas = async () => {
      try {
        const res = await clienteAxios.post("/dashboard", {
          empCategoria: usuario.empCategoria,
        });

        setgraficas_estadisticas(res.data);
      } catch (error) {
        setgraficas_estadisticas(null);
      }
    };
    if (!graficas_estadisticas) {
      getgraficas_estadisticas();
    } // eslint-disable-next-line
  }, []);
  if (!cards) return null;
  if (!graficas_estadisticas) return <Cargando titulo="Cargando Data..." />;
  return (
    <div>
      {/* {
            hisclinicas: [
              { mes: "2023-09", total: 11 },
              { mes: "2023-07", total: 8 },
              { mes: "2023-06", total: 1 },
              { mes: "2023-04", total: 1431 },
            ],
            reservas: [
              { mes: "2023-09", total: 2 },
              { mes: "2023-08", total: 12 },
              { mes: "2023-07", total: 13 },
              { mes: "2023-06", total: 2 },
              { mes: "2023-04", total: 84 },
            ],
      } */}
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.paper}>
          <Hidden smDown>
            <Box display={"flex"} justifyContent="center" width={"100%"}>
              <img
                src={usuario.logo}
                alt="Logo Empresa"
                style={{
                  maxWidth: "8%",
                }}
              />
            </Box>
            {Number(fecha.split(" ")[0]) <= 50 &&
            (fecha.split(" ")[1] !== "Dia" ||
              fecha.split(" ")[1] !== "Dias") ? (
              <Tooltip
                title={new Date(
                  Date.parse(usuario.fechaCaducaFirma) -
                    new Date().getTimezoneOffset() * 60 * 1000
                ).toLocaleString("es-ES", {
                  weekday: "short",
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })}
              >
                <Typography variant="h6" color="textPrimary" align="center">
                  Caducidad Firma: {fecha}
                </Typography>
              </Tooltip>
            ) : null}
          </Hidden>
          <Hidden smUp>
            <Box display="flex" alignItems="center" flexDirection="column">
              <Box display={"flex"} justifyContent="center" width={"100%"}>
                <img
                  src={usuario.logo}
                  alt="Logo Empresa"
                  style={{
                    maxWidth: "30%",
                    padding: "1rem",
                  }}
                />
              </Box>
              {Number(fecha.split(" ")[0]) <= 50 &&
              (fecha.split(" ")[1] !== "Dia" ||
                fecha.split(" ")[1] !== "Dias") ? (
                <Tooltip
                  title={new Date(
                    Date.parse(usuario.fechaCaducaFirma) -
                      new Date().getTimezoneOffset() * 60 * 1000
                  ).toLocaleString("es-ES", {
                    weekday: "short",
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })}
                >
                  <Typography variant="h6" color="textPrimary" align="center">
                    Caducidad Firma: {fecha}
                  </Typography>
                </Tooltip>
              ) : null}
              <Box
                display="flex"
                justifyContent="space-around"
                alignItems="center"
                width="100%"
              ></Box>
            </Box>
          </Hidden>
          <Box display="flex" alignItems=" stretch" width="100%">
            <Box>
              <Button
                variant={info ? "contained" : false ? "outlined" : null}
                disableElevation
                style={{
                  padding: "8px",
                  borderRadius: "20px 20px 0px 0px",
                }}
                color={false ? "error" : "secondary"}
                onClick={() => setseccionActive({ info: true })}
              >
                Datos Generales
              </Button>
              <Button
                variant={cardsSeccion ? "contained" : false ? "outlined" : null}
                disableElevation
                style={{
                  padding: "8px",
                  borderRadius: "20px 20px 0px 0px",
                }}
                color={false ? "error" : "secondary"}
                onClick={() => setseccionActive({ cardsSeccion: true })}
              >
                Categorias
              </Button>
            </Box>
          </Box>
          <Divider />
          {cardsSeccion ? <GridCard /> : null}
        </Grid>
        {info ? (
          <>
            <Grid item xs={12} sm={6} md={6} className={classes.paper}>
              <Grid container>
                {/* Principal */}
                {rows
                  .filter(
                    (route) =>
                      route.sidInfo.tipo === "subCategoria" ||
                      route.sidInfo.tipo === "subsubCategoria"
                  )
                  .map((rutasubsubosub, index, arraySSCOSC) => {
                    if (
                      rutasubsubosub.sidInfo.tipo === "subsubCategoria" &&
                      rutasubsubosub.sidInfo.mostrar === "SI"
                    ) {
                      const subC = arraySSCOSC.find(
                        (itemarraySSCOSC) =>
                          itemarraySSCOSC.sidInfo.path ===
                            rutasubsubosub.sidInfo.subcategoria &&
                          itemarraySSCOSC.sidInfo.tipo === "subCategoria"
                      );
                      if (!subC) {
                        return null;
                      }

                      if (!tienePermiso(rutasubsubosub)) {
                        return null;
                      }
                      if (!permisos(rutasubsubosub)) {
                        return null;
                      }

                      return (
                        <Grid item xs={12} sm={12} md={6}>
                          <Caja
                            key={index}
                            icono={rutasubsubosub.sidInfo.icon}
                            total={rutasubsubosub.total}
                            titulo={rutasubsubosub.sidInfo.name}
                            descripcion={rutasubsubosub.sidInfo.descripcion}
                            enlace={
                              subC.sidInfo.layout +
                              subC.sidInfo.categoria +
                              subC.sidInfo.path +
                              rutasubsubosub.sidInfo.path
                            }
                          />
                        </Grid>
                      );
                    }
                    return null;
                  })}
                {cards
                  .filter((route) => route.sidInfo.tipo === "subCategoria")
                  .map((card, index) => {
                    if (!tienePermiso(card)) {
                      return null;
                    }
                    if (!permisos(card)) {
                      return null;
                    }

                    return (
                      <Grid item xs={12} sm={12} md={6}>
                        <Caja
                          key={index}
                          titulo={card.sidInfo.name}
                          icono={card.sidInfo.icon}
                          total={card.total}
                          descripcion={card.sidInfo.descripcion}
                          enlace={
                            card.sidInfo.layout +
                            card.sidInfo.categoria +
                            card.sidInfo.path
                          }
                        />
                      </Grid>
                    );
                  })}
                {cards
                  .filter(
                    (route) =>
                      route.sidInfo.tipo === "categoria" || !route.sidInfo.tipo
                  )
                  .map((card, index) => {
                    if (!tienePermiso(card)) {
                      return null;
                    }
                    if (!permisos(card)) {
                      return null;
                    }

                    return (
                      <Grid item xs={12} sm={12} md={6}>
                        <Caja
                          key={card.sidId}
                          icono={card.sidInfo.icon}
                          total={card.total}
                          titulo={card.sidInfo.name}
                          descripcion={card.sidInfo.descripcion}
                          enlace={card.sidInfo.layout + card.sidInfo.path}
                        />
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              {vet && (
                <GraficoLineaReserva
                  titulo={"Reservas"}
                  className={classes.paper}
                  dataGrafico={graficas_estadisticas}
                />
              )}
              {vet && (
                <GraficoLineaHis
                  titulo={"Historias Clinicas"}
                  className={classes.paper}
                  dataGrafico={graficas_estadisticas}
                />
              )}
 
              <GraficoDougProductos
                titulo={"Productos Mas vendidos"}
                className={classes.paper}
                dataGrafico={graficas_estadisticas}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              {vet ? null : (
                <Chart
                  titulo={"Transacciones"}
                  className={classes.paper}
                  dataGrafico={graficas_estadisticas}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={6}></Grid>
          </>
        ) : null}
      </Grid>
    </div>
  );
};

export default Dashboard;
