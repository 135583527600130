import React, { useState, useContext, useEffect, memo } from "react";
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  Paper,
  TableContainer,
  Table,
  TablePagination,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import RowContabilidad from "./Contabilidad/RowContabilidad";
import RowAgregarContabilidad from "./Contabilidad/RowAgregarContabilidad";
import Draggable from "react-draggable";
import { Cancel } from "@mui/icons-material";
import { IconButton } from "@mui/material";
// eslint-disable-next-line
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../../Context/Auth/AuthContext";
import { trimPropsItem } from "../../../../config/const";
import ToolBarTable from "./ToolBarTable";
import TablaCabecera from "./TablaCabecera";
import clienteAxios from "../../../../config/axios";

function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "70%",
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    marginTop: "5vh",
    [theme.breakpoints.down("sm")]: { width: "90%", marginTop: "2vh" },
  },
  margin: {
    margin: theme.spacing(1),
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  ocultar: { display: "none" },
  inputPadding: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0),
    fontSize: "1rem",
  },
  formD: {
    flexGrow: 1,
    width: "45%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "95%",
    [theme.breakpoints.down("md")]: { padding: "0", margin: "2px" },
    [theme.breakpoints.down("sm")]: { padding: "0", margin: "2px" },
  },
  large: {
    width: theme.spacing(13),
    height: theme.spacing(13),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&:hover": { opacity: ".7" },
  },
  center: { justifyContent: "center", alignItems: "center" },
  root: { width: "100%", marginTop: theme.spacing(1) },
  correo: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "45%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  error: { color: "red", fontWeight: "bold" },
}));
const ModalAsistencia = (props) => {
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext); //props
  const {
    open,
    setopen,
    editar,
    seteditar,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
  } = props;
  // eslint-disable-next-line
  const [item, setitem] = useState(ObjectoActivo);
  const [codigo, setcodigo] = useState("");
  const [cargando, setcargando] = useState(false);
  const [contabilidadData, setcontabilidadData] = useState([]);
  const [planDeCuentas, setplanDeCuentas] = useState(null);
  const [contaEliminados, setcontaEliminados] = useState([]);
  const reducer = (previousValue, currentValue) =>
    Number(previousValue) + Number(currentValue);
  // const {
  //   contaTipo,
  //   contaRuc,
  //   contaNombre,
  //   contaCuenta,
  //   contaDebe,
  //   contaHaber,
  // } = item;
  // eslint-disable-next-line
  const [error, seterror] = useState({
    contaTipo: false,
    contaRuc: false,
    contaNombre: false,
    contaCuenta: false,
    contaDebe: false,
    contaHaber: false,
  }); // TABLA
  const [agregandoPagoAdicional, setagregandoPagoAdicional] = useState(false);
  const [pagePagoAdicional, setPagePagoAdicional] = React.useState(0);
  const [rowsPerPagePagoAdicional, setRowsPerPagePagoAdicional] =
    React.useState(10);
  const handleChangePagePagoAdicional = async (event, newPage) => {
    setPagePagoAdicional(newPage);
  };
  const handleChangeRowsPerPagePagoAdicional = (event) => {
    setRowsPerPagePagoAdicional(+event.target.value);
    setPagePagoAdicional(0);
  };
  useEffect(() => {
    const getplanDeCuentas = async () => {
      try {
        const resplanDeCuentas = await clienteAxios.get(
          `plancuentas/tipo/?tipo=${"MOVIMIENTO"}`
        );

        setplanDeCuentas(resplanDeCuentas.data.data);
      } catch (error) {
        setplanDeCuentas([]);
      }
    };

    if (!planDeCuentas) {
      getplanDeCuentas();
    }
    if (editar) {
      setcargando(true);
      const getcontabilidadData = async () => {
        try {
          if (ObjectoActivo.contaCodigo === "" || !ObjectoActivo.contaCodigo) {
            mostrarAlerta("Este asiento no tiene relación", "error");
            return defaultActive();
          }
          const res = await clienteAxios.get(
            `/contabilidad/${ObjectoActivo.contaCodigo}`
          );

          setcontabilidadData(res.data.data);
          if (res.data.data.length > 0) {
            setcodigo(res.data.data[0].contaCodigo);
          }
          setcargando(false);
        } catch (error) {
          setcontabilidadData([]);
          setcargando(false);
        }
      };
      getcontabilidadData();
    }
    setitem(ObjectoActivo); // eslint-disable-next-line
  }, [open]);
  const defaultActive = () => {
    changeObjetoActivoState({
      contaCodigo: "",
      contaTipo: "",
      contaRuc: "",
      contaNombre: "",
      contaDocumento: "",
      contaFechaDoc: "",
      contaCuenta: "",
      contaCuentaNombre: "",
      contaObservacion: "",
      contaDebe: "",
      contaHaber: "",
      contaEstado: "",
      contaUser: "",
      contaEmpresa: "",
      contaFecReg: "",
      Todos: "",
    });
    setopen(false);
    seteditar(false);
    seterror({});
    setcontaEliminados([]);
    setcontabilidadData([]);
    setcargando(false);
    setcodigo("");
  };

  //hook de estilos
  const clases = useStyles();
  //definiendo los estilos del modal
  const [modalStyle] = useState(getModalStyle);
  const submit = () => {
    if (true) {
      return false;
    } else {
      return true;
    }
  };

  const handleCrear = async () => {
    try {
      setcargando(true);

      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          tabla: "contabilidad",
          data_actual: contabilidadData,
          data_antigua: [],
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    if (
      (contabilidadData.length === 0
        ? null
        : contabilidadData
            .map((row) => {
              return Number(row.contaDebe);
            })
            .reduce(reducer)
            .toFixed(2)) !==
      (contabilidadData.length === 0
        ? null
        : contabilidadData
            .map((row) => {
              return Number(row.contaHaber);
            })
            .reduce(reducer)
            .toFixed(2))
    ) {
      return mostrarAlerta("DEBE Y HABER NO COINCIDEN", "error");
    }

    try {
      setcargando(true);
      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          tabla: "contabilidad",
          data_actual: contabilidadData,
          data_antigua: contaEliminados,
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  //filas por pagina

  // 1 + 2 + 3 + 4

  if (!open) {
    return null;
  }
  if (!planDeCuentas) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  style={{ cursor: "-webkit-grab" }}
                  id="handle"
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {editar ? "Editar" : "Agregar"}
                  {" Asiento"}
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box> </Box>

                {/* <FormControlLabel
                control={
                  <Switch
                    checked={perEstado === "ACTIVO" ? true : false}
                    onChange={(e) => {
                      setitem({
                        ...item,
                        perEstado: e.target.checked ? "ACTIVO" : "INACTIVO",
                      });
                    }}
                    name="antoine"
                  />
                }
                label="Estado"
              /> */}
              </Box>
              <Divider />
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="flex-end"
                borderRadius="0px 50px 50px 0px"
              >
                <>
                  <Paper className={clases.root}>
                    <ToolBarTable
                      titulo={"Contabilidad"}
                      soloTitulo={true}
                      botonAdd={true}
                      deshabilitarbotonAdd={false}
                      funcionAdd={() => {
                        setagregandoPagoAdicional(true);
                      }}
                    />
                    <TableContainer className={clases.container}>
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        id="tabla"
                        size="small"
                      >
                        <caption
                          style={{
                            padding: "0px",
                            paddingRight: "1rem",
                            paddingLeft: "1rem",
                          }}
                        >
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <TablePagination
                              labelRowsPerPage="Filas por pagina"
                              rowsPerPageOptions={[10, 20, 50, 100, 500, 1000,  ]}
                              component="div"
                              count={contabilidadData.length}
                              rowsPerPage={rowsPerPagePagoAdicional}
                              page={pagePagoAdicional}
                              onPageChange={handleChangePagePagoAdicional}
                              onRowsPerPageChange={
                                handleChangeRowsPerPagePagoAdicional
                              }
                            />
                          </Box>
                        </caption>
                        <TableHead>
                          <TablaCabecera
                            columns={[
                              {
                                id: "contaTipo",
                                label: "Tipo",
                                minWidth: 25,
                                tipo: "string",
                                propiedad: "contaTipo",
                              },
                              {
                                id: "contaRuc",
                                label: "Ruc",
                                minWidth: 25,
                                tipo: "string",
                                propiedad: "contaRuc",
                              },
                              {
                                id: "contaNombre",
                                label: "Nombre",
                                minWidth: 25,
                                tipo: "string",
                                propiedad: "contaNombre",
                              },
                              {
                                id: "contaDocumento",
                                label: "Documento",
                                minWidth: 25,
                                tipo: "string",
                                propiedad: "contaDocumento",
                              },
                              {
                                id: "contaFechaDoc",
                                label: "F. Doc",
                                minWidth: 25,
                                tipo: "string",
                                propiedad: "contaFechaDoc",
                              },
                              {
                                id: "contaCuenta",
                                label: "Cuenta",
                                minWidth: 25,
                                tipo: "string",
                                propiedad: "contaCuenta",
                              },
                              {
                                id: "contaDebe",
                                label: "Debe",
                                minWidth: 25,
                                tipo: "string",
                                propiedad: "contaDebe",
                              },
                              {
                                id: "contaHaber",
                                label: "Haber",
                                minWidth: 25,
                                tipo: "string",
                                propiedad: "contaHaber",
                              },
                              {
                                id: "contaObservacion",
                                label: "Observación",
                                minWidth: 25,
                                tipo: "string",
                                propiedad: "contaObservacion",
                              },
                            ]}
                            habilitarOrdenar={false}
                          />
                        </TableHead>
                        <TableBody>
                          {agregandoPagoAdicional ? (
                            <RowAgregarContabilidad
                              planDeCuentas={planDeCuentas}
                              setagregar={setagregandoPagoAdicional}
                              setcontabilidadData={setcontabilidadData}
                              contabilidadData={contabilidadData}
                              codigo={codigo}
                            />
                          ) : null}
                          {contabilidadData
                            .slice(
                              pagePagoAdicional * rowsPerPagePagoAdicional,
                              pagePagoAdicional * rowsPerPagePagoAdicional +
                                rowsPerPagePagoAdicional
                            )
                            .map((row, index) => {
                              return (
                                <>
                                  {" "}
                                  <RowContabilidad
                                    contaEliminados={contaEliminados}
                                    setcontaEliminados={setcontaEliminados}
                                    planDeCuentas={planDeCuentas}
                                    setcontabilidadData={setcontabilidadData}
                                    contabilidadData={contabilidadData}
                                    row={row}
                                    index={index}
                                    key={row.contaId + index + 1}
                                  />
                                </>
                              );
                            })}
                          <TableRow>
                            <TableCell size="small" align="center"></TableCell>
                            <TableCell size="small" align="center"></TableCell>
                            <TableCell size="small" align="center"></TableCell>
                            <TableCell size="small" align="center"></TableCell>
                            <TableCell size="small" align="center"></TableCell>
                            <TableCell size="small" align="center"></TableCell>
                            <TableCell size="small" align="center"></TableCell>
                            <TableCell size="small" align="center">
                              <Typography
                                variant="body2"
                                color="primary"
                                style={{ fontWeight: "bold" }}
                              >
                                $
                                {contabilidadData.length === 0
                                  ? null
                                  : contabilidadData
                                      .map((row) => {
                                        return Number(row.contaDebe);
                                      })
                                      .reduce(reducer)
                                      .toFixed(2)}
                              </Typography>
                            </TableCell>
                            <TableCell size="small" align="center">
                              <Typography
                                variant="body2"
                                color="primary"
                                style={{ fontWeight: "bold" }}
                              >
                                $
                                {contabilidadData.length === 0
                                  ? null
                                  : contabilidadData
                                      .map((row) => {
                                        return Number(row.contaHaber);
                                      })
                                      .reduce(reducer)
                                      .toFixed(2)}
                              </Typography>
                            </TableCell>{" "}
                            <TableCell size="small" align="center"></TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </>
              </Box>
              {/* <Divider /> */}
              <Box
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="flex-end"
                  mt={1}
                >
                  <Button
                    style={{ width: "30%" }}
                    variant="contained"
                    color="primary"
                    disabled={submit() || cargando}
                    onClick={() => {
                      if (editar) {
                        handleEditar();
                      } else {
                        handleCrear();
                      }
                    }}
                  >
                    Guardar
                  </Button>
                </Box>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalAsistencia);
