import React, { memo } from "react";
import { makeStyles } from "@mui/styles";
import { TableCell, TableRow } from "@mui/material";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
  celda: {
    fontSize: ".7rem",
  },
}));

const RowAgregar = (props) => {
  // eslint-disable-next-line
  const { item } = props;

  const classes = useRowStyles();
  //gql eliminar user

  return (
    <React.Fragment>
      {" "}
      <TableRow className={classes.root}>
        {/* las demas columnas */}

        <>
          <TableCell size="small" align="center" className={classes.celda}>
            {item.item}
          </TableCell>
          <TableCell size="small" align="center" className={classes.celda}>
            {item.costo}
          </TableCell>
          <TableCell size="small" align="center" className={classes.celda}>
            {item.cantidad}
          </TableCell>
          <TableCell size="small" align="center" className={classes.celda}>
            {item.valor}
          </TableCell>
          <TableCell size="small" align="center" className={classes.celda}>
            {item.descripcion}
          </TableCell>
          <TableCell size="small" align="center" className={classes.celda}>
            {item.marca}
          </TableCell>
          <TableCell size="small" align="center" className={classes.celda}>
            {item.observacion}
          </TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregar);
