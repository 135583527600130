export const useCrud = (rows, setrows, campoExport, campoUsuario, usuario) => {
  // AGREGA UN REGISTRO AL STATE
  const agregarState = async (item) => {
    try {
      item.nuevo = true;
      const { editado, eliminado, ...rest } = item;

      setrows(
        [rest, ...rows].filter((itemR) => {
          return (
            Number(item[campoUsuario].split("/+/")[1]) !== itemR[campoExport]
          );
        })
      );
    } catch (error) {}
  };

  // EDITA UN REGISTRO DEL STATE
  const editarState = async (item) => {
    try {
      const resRows = rows.map((itemR) => {
        if (itemR[campoExport] === item[campoExport]) {
          item.editado = true;
          const { nuevo, eliminado, ...rest } = item;
          return rest;
        } else {
          return itemR;
        }
      });
      setrows(resRows);
    } catch (error) {}
  };
  // ELIMINA UN REGISTRO DEL STATE
  const eliminarState = async (_id) => {
    try {
      const resRows = rows.map((itemR) => {
        if (itemR[campoExport] === _id) {
          itemR.eliminado = true;
          itemR[campoUsuario] = usuario.usuario;
          const { nuevo, editado, ...rest } = itemR;
          return rest;
        } else {
          return itemR;
        }
      });

      setrows(resRows);
    } catch (error) {}
  };
  // ELIMINA REGISTROS SELECICONADOS DEL STATE
  const eliminarSeleccionState = async (_ids) => {
    const resRows = rows.map((itemR) => {
      const existe = _ids.some((item) => {
        return item === itemR[campoExport];
      });
      if (existe) {
        itemR.eliminado = true;
        itemR[campoUsuario] = usuario.usuario;
        const { nuevo, editado, ...rest } = itemR;
        return rest;
      } else {
        return itemR;
      }
    });

    setrows(resRows);
  };
  return { agregarState, editarState, eliminarState, eliminarSeleccionState };
};
