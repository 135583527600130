import React, { memo } from "react";
// MATERIAL
import { TableCell, TableRow } from "@mui/material";
import {
  butonIconTransition,
  useRowStyles,
} from "../../../../../styles/stylesRows";
import ButtonsAction from "../../../../components/Tablas/ButtonsAction";
const RowAgregar = (props) => {
  const classes = useRowStyles();
  const {
    itemHijo,
    index,
    item,
    setitem,
    handleDragStart,
    handleDragEnter,
    handleDragEnd,
    seteditado,
  } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  }`;

  return (
    <React.Fragment>
      <TableRow
        className={classes.root}
        component={"form"}
        onDragStart={() => handleDragStart(index)}
        onDragEnter={() => handleDragEnter(index)}
        onDragEnd={(e) => {
          seteditado(true);
          handleDragEnd("tablaColumnas", item.tablaColumnas, item, setitem);
        }}
        onDragOver={(e) => e.preventDefault()}
        draggable
      >
        <ButtonsAction
          claseCelda={claseCelda}
          row={itemHijo}
          botones={[
            {
              tooltip: "Estado",
              texto: "",
              funcion: (e) => {
                seteditado(true);
                setitem({
                  ...item,
                  tablaColumnas: item.tablaColumnas.map((item) => {
                    if (item.label === itemHijo.label) {
                      return { ...item, visible: e.target.checked };
                    } else {
                      return item;
                    }
                  }),
                });
              },
              disabled: false,
              Icon: "",
              color: "primary",
              id: 6,
              ocultar: false,
              tipo: "check",
              estado: itemHijo.visible,
              variante: "",
              size: "small",
              sx: butonIconTransition,
            },
          ]}
        />
        {/* las demas columnas */}
        <>
          <TableCell fontSize="small" align="center" className={claseCelda}>
            {itemHijo.label}
          </TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregar);
