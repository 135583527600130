import React, { memo, useContext } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";

import { Cancel, Check } from "@mui/icons-material";

import { useState } from "react";

import AuthContext from "../../../../Context/Auth/AuthContext";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";

import { replaceCaracteres, trimPropsItem } from "../../../../config/const";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
  celda: {
    fontSize: ".7rem !important",
  },
}));

const RowAgregar = (props) => {
  const { setagregar, categorias, llamadoDeOtroLugar, setopenMT, socket } =
    props;

  const { mostrarAlerta } = useContext(AlertaContext);
  const [item, setitem] = useState({
    subNombre: "",
    subDetalle: "",
    subNomCategoria: "",
    subEstado: "ACTIVA",
    subEmpresa: "",
    subUser: "",
  });

  const classes = useRowStyles();
  //gql eliminar user

  const { usuario } = useContext(AuthContext);
  const handleAgregar = async () => {
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          tabla: "subcategoria",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          subUser: usuario.usuario,
          subEmpresa: usuario.rucempresa,
          llamadoDeOtroLugar: llamadoDeOtroLugar,
        })
      );

      if (llamadoDeOtroLugar) {
        setopenMT(false);
      }
    } catch (error) {
      mostrarAlerta("Hubo un error");
    }
  };
  const botonDisabled = () => {
    if (item.subNombre === "" || item.subNomCategoria === "") {
      return true;
    } else {
      return false;
    }
  };
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell></TableCell> {/* boton */}
        <TableCell>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <IconButton
              className={classes.margin}
              onClick={() => {
                setagregar(false);
                setitem({
                  subNombre: "",
                  subDetalle: "",
                  subNomCategoria: "",
                  subEstado: "ACTIVA",
                  subEmpresa: "",
                  subUser: "",
                });
              }}
            >
              <Cancel fontSize="small" color="secondary" />
            </IconButton>
            <IconButton
              color={
                botonDisabled
                  ? botonDisabled()
                    ? "inherit"
                    : "secondary"
                  : "secondary"
              }
              disabled={botonDisabled()}
              className={classes.margin}
              onClick={() => {
                handleAgregar();

                setagregar(false);
                setitem({
                  subNombre: "",
                  subDetalle: "",
                  subNomCategoria: "",
                  subEstado: "ACTIVA",
                  subEmpresa: "",
                  subUser: "",
                });
              }}
            >
              <Check fontSize="small" />
            </IconButton>{" "}
            <FormControlLabel
              className={classes.margin}
              onChange={(e) => {
                setitem({
                  ...item,
                  subEstado: e.target.checked ? "ACTIVA" : "INACTIVA",
                });
              }}
              control={
                <Checkbox
                  value="ACTIVO"
                  className={classes.margin}
                  checked={
                    item["subEstado"] === "ACTIVA" ||
                    item["subEstado"] === "ACTIVO"
                      ? true
                      : false
                  }
                  color="secondary"
                />
              }
            />
          </Box>
        </TableCell>
        {/* las demas columnas */}
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          <TextField
            id=""
            value={item.subNomCategoria}
            onChange={(e) => {
              const res = replaceCaracteres(e.target.value);
              setitem({
                ...item,
                subNomCategoria: res,
              });
            }}
            helperText="Agregando"
            size="small"
            fullWidth
            select
            InputLabelProps={{ shrink: true }}
            SelectProps={{ displayEmpty: true }}
          >
            <MenuItem value="">Seleccione</MenuItem>
            {categorias.map((categoria, index) => {
              return (
                <MenuItem value={categoria.catNombre} key={index}>
                  {categoria.catNombre}
                </MenuItem>
              );
            })}
          </TextField>
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          <TextField
            id=""
            value={item.subNombre}
            onChange={(e) => {
              const res = replaceCaracteres(e.target.value);
              setitem({
                ...item,
                subNombre: res,
              });
            }}
            helperText="Agregando"
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          <TextField
            id=""
            value={item.subDetalle}
            onChange={(e) => {
              const res = replaceCaracteres(e.target.value);
              setitem({
                ...item,
                subDetalle: res,
              });
            }}
            helperText="Agregando"
            size="small"
            fullWidth
          />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregar);
