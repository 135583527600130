import React, { memo, useContext } from "react";

import {
  Box,
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Button,
  TextField,
  Typography,
  Badge,
} from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../../components/Dialogs/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "../../../components/Tablas/ButtonsAction";
import AuthContext from "../../../../Context/Auth/AuthContext";
import {
  Cancel,
  Check,
  Create,
  DeleteForever,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import {
  butonIconTransition,
  useRowStyles,
} from "../../../../styles/stylesRows";
import { useModalStyle } from "../../../../styles/styleModal";
import TablaCabecera from "../../../components/Tablas/TablaCabecera";
import RowItem from "./RowItems/RowItem";
import { columnasHijo } from "../data";
import { useDraggable } from "../../../../hooks/useDraggable";
import { useEffect } from "react";
import { trimPropsItem } from "../../../../config/const";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import { v4 } from "uuid";

const Row = (props) => {
  // CLASES
  const classesModal = useModalStyle();
  const classes = useRowStyles();
  // PROPS
  const {
    arrayExport,
    setarrayExport,
    row,
    index,
    campoExport,
    socket,
  } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    row.editado === true
      ? classes.celdaEditado
      : row.nuevo === true
      ? classes.celdaNuevo
      : row.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;
  const [item, setitem] = useState(row);
  const [editado, seteditado] = useState(false);
  const [tabActive, settabActive] = useState("");
  const [newTab, setnewTab] = useState("");
  const [tabActual, settabActual] = useState(null);
  const onNewTab = (e) => {
    e.preventDefault();
    if (newTab.length <= 1)
      return mostrarAlerta(
        "El nombre del nuevo tab debe de tener mas de un caracter",
        "error"
      );
    const existe = item.campDatos.some((tab) => tab.nombreTab === newTab);
    if (existe)
      return mostrarAlerta("Ya existe un tab con este nombre", "error");
    seteditado(true);
    setitem({
      ...item,
      campDatos: [
        ...item.campDatos,
        {
          nombreTab: newTab,
          id: v4(),
          campos: [],
        },
      ],
    });
    setnewTab("");
  };

  useEffect(() => {
    setitem(row);
  }, [row]);

  // CONTEXT
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext);

  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const [openCDAceptar, setopenCDAceptar] = useState(false);
  const [openCDCancelar, setopenCDCancelar] = useState(false);
  // EXPANDIR
  const [expandir, setexpandir] = useState(false);
  // COPIA DEL REGISTRO PARA EDITAR
  // GUARDIAS TOTALES

  // ELIMINAR EL REGISTRO
  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        tabla: "camposdinamicos",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        estUser: usuario.usuario,
        estEmpresa: usuario.rucempresa,
        [campoExport]: row[campoExport],
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };
  const { handleDragStart, handleDragEnter, handleDragEnd } = useDraggable();
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <ConfirmacionDialog
        open={openCDAceptar}
        setopen={setopenCDAceptar}
        categoria="warning"
        titulo={`¿Esta seguro de realizar estos cambios?`}
        botones={[
          {
            tooltip: "Se editara el registro",
            texto: "SI",
            funcion: () => {
              setopenCDAceptar(false);
              socket.current.emit(
                "client:actualizarData",
                trimPropsItem({
                  ...item,
                  campDatos: item.campDatos.map((tab) =>
                    tab.id === tabActual.id ? tabActual : tab
                  ),
                  rucempresa: usuario.rucempresa,
                  rol: usuario.rol,
                  tabla: "camposdinamicos",
                })
              );
              seteditado(false);
            },
            disabled: false,
            Icon: Create,
            color: "success",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenCDAceptar(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "error",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <ConfirmacionDialog
        open={openCDCancelar}
        setopen={setopenCDCancelar}
        categoria="warning"
        titulo={`¿Esta seguro de deshacer los cambios?`}
        botones={[
          {
            tooltip: "Se desharán los cambios",
            texto: "SI",
            funcion: () => {
              setopenCDCancelar(false);
              setitem(row);
              seteditado(false);

              try {
                const tabF = row.campDatos.find(
                  (tabItem) => tabItem.id === tabActual.id
                );
                settabActual(tabF);
              } catch (error) {
                settabActive("");
                settabActual(null);
              }
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
        ]}
      />
      <TableRow
        className={classes.root}
        selected={index % 2 === 0}
        component={"form"}
      >
        {/* boton */}
        <ButtonsAction
          claseCelda={claseCelda}
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          campoExport={campoExport}
          row={row}
          botones={[
            {
              tooltip: "Expandir",
              texto: row.campDatos.length,
              funcion: () => {
                setexpandir((prev) => !prev);
              },
              disabled: false,
              Icon: expandir ? ExpandLess : ExpandMore,
              color: "secondary",
              id: 3,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Editar",
              texto: "",
              funcion: () => {
                setopenCDAceptar(true);
              },
              disabled: false,
              Icon: Check,
              color: "success",
              id: 3,
              ocultar: !editado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Cancelar",
              texto: "",
              funcion: () => {
                setopenCDCancelar(true);
              },
              disabled: false,
              Icon: Cancel,
              color: "error",
              id: 3,
              ocultar: !editado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
          ]}
        />

        <>
          <TableCell size="small" align="center" className={claseCelda}>
            {row.campTabla}
          </TableCell>
        </>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={21}>
          <Collapse in={expandir} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Paper>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  width="100%"
                  flexWrap={"wrap"}
                  alignItems={"flex-end"}
                >
                  <form onSubmit={onNewTab}>
                    <TextField
                      label="Nuevo tab"
                      value={newTab}
                      onChange={(e) => {
                        setnewTab(e.target.value);
                      }}
                    />
                  </form>
                  <Box
                    display={"flex"}
                    flexWrap={"wrap"}
                    alignItems={"flex-end"}
                  >
                    {item.campDatos.map((tab) => (
                      <Badge
                        key={tab.id}
                        badgeContent={
                          tabActual?.id === tab.id
                            ? tabActual.campos.length
                            : tab.campos.length
                        }
                        color={"secondary"}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <Button
                          variant={
                            tabActive === tab.id ? "contained" : "outlined"
                          }
                          color={"primary"}
                          disableElevation
                          className={classesModal.buttonTab}
                          size="small"
                          onClick={() => {
                            setitem({
                              ...item,
                              campDatos: item.campDatos.map((tab) =>
                                tab.id === tabActual?.id ? tabActual : tab
                              ),
                            });
                            settabActual(tab);
                            settabActive(tab.id);
                          }}
                        >
                          {tab.nombreTab}
                        </Button>
                      </Badge>
                    ))}
                    <Box>
                      {tabActual && (
                        <>
                          <Typography variant="body2" color="initial">
                            Tab actual: {tabActual.nombreTab}
                          </Typography>
                          <Button
                            color={"secondary"}
                            variant="contained"
                            onClick={() => {
                              seteditado(true);
                              settabActual({
                                ...tabActual,
                                campos: [
                                  {
                                    name: `campo_${tabActual.campos.length +
                                      1}`,
                                    label: `Campo ${tabActual.campos.length +
                                      1}`,
                                    tipo: "TEXTO",
                                    required: "NO",
                                    opciones: [],
                                    order: 1,
                                    id: v4(),
                                  },
                                  ...tabActual.campos.map((campo, index) => ({
                                    ...campo,
                                    order: index + 2,
                                  })),
                                ],
                              });
                            }}
                          >
                            Agregar Campo
                          </Button>
                          <Button
                            color={"error"}
                            variant="contained"
                            onClick={() => {
                              if (tabActual.campos.length > 0) {
                                return mostrarAlerta(
                                  "Es necesario eliminar los campos antes de eliminar el tab",
                                  "error"
                                );
                              }
                              seteditado(true);
                              settabActual(null);

                              setitem({
                                ...item,
                                campDatos: item.campDatos.filter(
                                  (tab) => tab.id !== tabActual.id
                                ),
                              });
                            }}
                          >
                            Eliminar
                          </Button>
                        </>
                      )}
                    </Box>
                  </Box>
                </Box>
                <TableContainer>
                  <Table stickyHeader aria-label="sticky table" size="small">
                    <TableHead>
                      <TablaCabecera
                        columns={columnasHijo}
                        habilitarOrdenar={false}
                      />
                    </TableHead>

                    <TableBody
                      component={"div"}
                      style={{ maxHeight: "33vh", overflow: "scroll" }}
                    >
                      {tabActual &&
                        tabActual.campos
                          .sort((a, b) => a.order - b.order)
                          .map((itemHijo, index) => {
                            return (
                              <RowItem
                                key={itemHijo.id}
                                itemHijo={itemHijo}
                                tabActual={tabActual}
                                settabActual={settabActual}
                                handleDragStart={handleDragStart}
                                handleDragEnter={handleDragEnter}
                                handleDragEnd={handleDragEnd}
                                seteditado={seteditado}
                                index={index}
                              />
                            );
                          })}
                    </TableBody>
                    <TableFooter>
                      <TableRow></TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
