import React, { useContext, useState, useEffect, useMemo } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// CONTEXT
import AuthContext from "../Context/Auth/AuthContext.js";
import AlertaContext from "../Context/Alerta/AlertaContext.js";
import MenuContext from "../Context/Menu/MenuContext.js";

// COMPONENTES
import Footer from "../components/Footer/Footer.js";

import Cargando from "../components/Extra/Cargando.js";
// MATERIAL
import { styled } from "@mui/material/styles";
import {
  AppBar,
  Drawer,
  Box,
  Toolbar,
  // CssBaseline,
  Alert,
} from "@mui/material";
import fondo from "../assets/img/fondo.png";
// import sigeopLogo from "../assets/img/sigeoplogo.png";
import Sidebar from "./Componentes/Sidebar/Sidebar.js";
import NavbarDesktop from "./Componentes/Navbars/NavbarDesktop.js";
import NavbarMobile from "./Componentes/Navbars/NavbarMobile.js";
// MATERIAL
import { ThemeProvider } from "@mui/material/styles";
import themeF from "../config/themeUser";
import theme from "../config/theme.js";
import { componentes } from "../routes";
// import { Alert } from "@mui/material";
// import io from "socket.io-client";
// import { socketUrl } from "../config/const.js";
// import { AddCircle } from "@mui/icons-material";
const drawerWidth = 240;

const AppBarDesktop = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerDesktop = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
export default function MiniDrawer(props) {
  const { window: windowProps } = props;

  const [open, setOpen] = React.useState(true);

  const [alertaNavegador, setalertaNavegador] = useState(false);
  const [buscador, setbuscador] = useState("");
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const { usuario } = useContext(AuthContext);
  const { obtenerCategorias, rows } = useContext(MenuContext);

  const { alerta } = useContext(AlertaContext);
  const empresaheader = localStorage.getItem("empresaheader");
  const usuarioheader = localStorage.getItem("usuarioheader");
  const rolheader = localStorage.getItem("rolheader");

  useEffect(() => {
    if (!rows) {
      obtenerCategorias();
    }
    const navegadorAgente = window.navigator.userAgent;

    if (
      navegadorAgente.indexOf("Chrome") > -1 ||
      navegadorAgente.indexOf("Brave") > -1
    ) {
    } else {
      setalertaNavegador(true);
    }
    // eslint-disable-next-line
  }, [usuario]);

  // ADMIN Y SIDEBAR
  const tienePermiso = (component) => {
    if (!usuario.permisos_usuario[usuario.rucempresa]) {
      const res = component.sidInfo.permisos.some((itemPermiso) => {
        return usuario.rol.some(
          (itemRolUsuario) => itemRolUsuario === itemPermiso
        );
      });

      return res;
    }
    if (
      !usuario.permisos_usuario[usuario.rucempresa][component.sidInfo.component]
    ) {
      const res = component.sidInfo.permisos.some((itemPermiso) => {
        return usuario.rol.some(
          (itemRolUsuario) => itemRolUsuario === itemPermiso
        );
      });

      return res;
    }
    if (
      usuario.permisos_usuario[usuario.rucempresa][component.sidInfo.component]
        .permisos === 0
    ) {
      const res = component.sidInfo.permisos.some((itemPermiso) => {
        return usuario.rol.some(
          (itemRolUsuario) => itemRolUsuario === itemPermiso
        );
      });

      return res;
    }
    if (
      usuario.permisos_usuario[usuario.rucempresa][component.sidInfo.component]
        .permisos === 1
    ) {
      return true;
    }
    if (
      usuario.permisos_usuario[usuario.rucempresa][component.sidInfo.component]
        .permisos === 2
    ) {
      return false;
    }
  };
  const permisos = (item) => {
    return item.sidInfo.categoriaEmpresa.some(
      (itemCatEmp) => itemCatEmp === usuario.empCategoria
    );
  };

  const container =
    windowProps !== undefined ? () => windowProps().document.body : undefined;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const errorFirma = useMemo(() => tiempoRestante(usuario?.fechaCaducaFirma), [
    usuario,
  ]);
  function tiempoRestante(fecha) {
    var fechaObjeto = new Date(
      Date.parse(fecha) + new Date().getTimezoneOffset() * 60 * 1000
    );
    var ahora = new Date();
    var diferencia = fechaObjeto - ahora;

    return diferencia <= 0;
  }

  if (!empresaheader) {
    return <Cargando titulo="CARGANDO HEADERS" />;
  }
  if (!usuarioheader) {
    return <Cargando titulo="CARGANDO HEADERS" />;
  }
  if (!rolheader) {
    return <Cargando titulo="CARGANDO HEADERS" />;
  }
  // initialize and destroy the PerfectScrollbar plugin
  if (!usuario) return <Cargando titulo="CARGANDO USUARIO" />;
  if (!rows) return <Cargando titulo="CARGANDO MENU" />;

  return (
    <>
      <ThemeProvider theme={usuario ? themeF(usuario) : theme}>
        {errorFirma && (
          <Box display="flex" justifyContent={"center"}>
            <Alert
              style={{ position: "fixed", zIndex: "8086" }}
              severity={"error"}
              variant="filled"
            >
              Estimado usuario, su firma digital ha caducado. Por favor,
              renuévela en la sección de configuraciones de la empresa.
              Gracias.
            </Alert>
          </Box>
        )}
        {alertaNavegador ? (
          <Box display="flex" justifyContent={"center"}>
            <Alert
              style={{ position: "fixed", zIndex: "8086" }}
              severity={"error"}
              variant="filled"
              onClose={() => {
                setalertaNavegador(false);
              }}
            >
              Para obtener una mejor experiencia de uso, utilizar el navegador
              Chrome o Brave.
            </Alert>
          </Box>
        ) : null}
        {alerta ? (
          <Box display="flex" justifyContent="center">
            <Alert
              className="animacionAlerta"
              style={{ position: "absolute", zIndex: "8086", top: "5rem" }}
              severity={alerta.categoria}
              variant="filled"
            >
              {alerta.msg}
            </Alert>
          </Box>
        ) : null}
        <Box
          sx={{
            display: "flex",
            backgroundImage: `url(${fondo})`,
            backgroundSize: "100% 100%",
          }}
        >
          {/* <CssBaseline /> */}
          <AppBar
            position="fixed"
            sx={{
              display: { xs: "block", sm: "none" },
            }}
          >
            <NavbarMobile handleDrawerToggle={handleDrawerToggle} open={open} />
          </AppBar>
          <AppBarDesktop
            position="fixed"
            sx={{
              display: { xs: "none", sm: "block" },
            }}
          >
            <NavbarDesktop
              setbuscador={setbuscador}
              buscador={buscador}
              handleDrawerClose={handleDrawerClose}
              handleDrawerOpen={handleDrawerOpen}
              open={open}
            />
          </AppBarDesktop>
          <Box
            component="nav"
            sx={{ flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
          >
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: "block", sm: "none" },
                backgroundColor: "#ccc",
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
            >
              <Sidebar
                buscador={buscador}
                open={true}
                mobile={true}
                handleDrawerToggle={handleDrawerToggle}
              />
            </Drawer>
            <DrawerDesktop
              variant="permanent"
              sx={{
                display: { xs: "none", sm: "block" },
              }}
              open={open}
              PaperProps={{ sx: { backgroundColor: "white" } }}
            >
              <Toolbar />
              <Sidebar open={open} buscador={buscador} />
            </DrawerDesktop>
          </Box>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              pl: 1,
              mt: { xs: 2, sm: 1 },
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              minHeight: "99vh",
              overflow: "scroll",
            }}
          >
            <Box
              sx={{
                minHeight: "90vh",
              }}
            >
              <Toolbar />
              {/* <Box sx={{}}> */}
              <Switch>
                {rows
                  .filter(
                    (route) =>
                      route.sidInfo.tipo === "subCategoria" ||
                      route.sidInfo.tipo === "subsubCategoria"
                  )
                  .map((rutasubsubosub, key, arraySSCOSC) => {
                    if (rutasubsubosub.sidInfo.tipo === "subsubCategoria") {
                      const subC = arraySSCOSC.find(
                        (itemarraySSCOSC) =>
                          itemarraySSCOSC.sidInfo.path ===
                          rutasubsubosub.sidInfo.subcategoria
                      );
                      if (!tienePermiso(rutasubsubosub)) {
                        return null;
                      }
                      if (!permisos(rutasubsubosub)) {
                        return null;
                      }

                      if (!subC) {
                        return null;
                      }

                      return (
                        <Route
                          path={
                            subC.sidInfo.layout +
                            subC.sidInfo.categoria +
                            subC.sidInfo.path +
                            rutasubsubosub.sidInfo.path
                          }
                          component={
                            componentes[rutasubsubosub.sidInfo.component]
                          }
                          key={key}
                        />
                      );
                    }
                    return null;
                  })}
                {rows
                  .filter((route) => route.sidInfo.tipo === "subCategoria")
                  .map((prop, key) => {
                    if (!tienePermiso(prop)) {
                      return null;
                    }
                    if (!permisos(prop)) {
                      return null;
                    }
                    return (
                      <Route
                        path={
                          prop.sidInfo.layout +
                          prop.sidInfo.categoria +
                          prop.sidInfo.path
                        }
                        component={componentes[prop.sidInfo.component]}
                        key={key}
                      />
                    );
                  })}
                {rows
                  .filter(
                    (route) =>
                      route.sidInfo.tipo === "categoria" || !route.sidInfo.tipo
                  )
                  .map((prop, key) => {
                    if (!tienePermiso(prop)) {
                      return null;
                    }
                    if (!permisos(prop)) {
                      return null;
                    }
                    return (
                      <Route
                        path={prop.sidInfo.layout + prop.sidInfo.path}
                        component={componentes[prop.sidInfo.component]}
                        key={key}
                      />
                    );
                  })}
                <Redirect from="/Contable" to="/Contable/Dashboard" />
              </Switch>
            </Box>
            <Footer />
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
}
