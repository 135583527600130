import { FechaExacta } from "../../config/const";

export const columns = [
  {
    id: "actaId",
    label: "#Orden",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "actaId",
    align: "center",
    ocultar: true,
  },
  {
    id: "actaEstado",
    label: "Estado",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "actaEstado",
    align: "center",
    ocultar: true,
  },
  {
    id: "actaDocCliente",
    label: "Cliente",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "actaDocCliente",
    align: "center",
    ocultar: true,
  },
  {
    id: "actaTelCliente",
    label: "Tel",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "actaTelCliente",
    align: "center",
    ocultar: true,
    tipo: "",
  },
  {
    id: "actaTipo",
    label: "Tipo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "actaTipo",
    align: "center",
    ocultar: true,
  },

  {
    id: "actaOpcion",
    label: "Opción",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "actaOpcion",
    align: "center",
    ocultar: true,
  },
  {
    id: "actaServiciosVisita",
    label: "Servicios Visita",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "actaServiciosVisita",
    align: "center",
    ocultar: true,
  },
  {
    id: "actaUser",
    label: "User",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "actaUser",
    align: "center",
    ocultar: true,
  },
  {
    id: "actaFecReg",
    label: "Fec. Reg.",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "actaFecReg",
    align: "center",
    ocultar: true,
  },
];
export const validacion = (item, setitem, seterror) => {
  const { actaDocCliente, actaEntDoc } = item;

  // const validacionProductos =
  //   actaEquiposInstalados.length === 0 ||
  //   actaEquiposInstalados.some((itemProducto) => validacionHijo(itemProducto));
  if (actaDocCliente.trim() === "") {
    // if (validacionProductos) {
    //   setitem((prev) => ({
    //     ...prev,
    //     actaEquiposInstalados: actaEquiposInstalados.map((itemProducto) => {
    //       const error = validacionHijo(itemProducto);
    //       return { ...itemProducto, error };
    //     }),
    //   }));
    // }
    seterror({
      // PRIMER TAB
      actaDocCliente: actaDocCliente.trim() === "",
      // SEGUNDO TAB
      actaEntDoc: actaEntDoc.trim() === "",
    });
    return true;
  }
};
export const objectDefault = {
  // actaId: 1,
  actaIdCliente: 0,
  actaIdPersonaEntrega: 0,
  actaFecha: FechaExacta,
  // actaInstalacionFibra: "EMPRESARIAL",
  // actaVisitaTecnica: "EMPRESARIAL",
  actaServiciosVisita: "TRASLADOS",
  actaOpcion: "Nueva Instalación", // | Visita Técnica
  actaTipo: "HOME", //EMPRESARIAL "CORPORATIVO", "HOME"
  actaSeEncuentraCliente: "SI", // | NO

  actaRedWifi: "",
  actaClave: "",
  actaClienteRegulador: "NO",
  actaConfigRouter: "NO",
  actaCambioPatch: "NO",
  actaCalidadIns: "",
  actaSolucionReq: "MUY BUENO",
  actaRecNombre: "",
  actaRecFirma: "",
  actaEntFirma: "",
  actaUser: "",
  actaEmpresa: "",
  actaFecReg: "",
  actaDocCliente: "",
  actaNombreCliente: "",
  actaDireccionCliente: "",
  actaRecDoc: "",
  actaEntNombre: "",
  actaEntDoc: "",
  actaFotos: [],
  actaEquiposInstalados: [],
  actaCargosAdicionales: [],
  actaTelCliente: [],
  actaEmailCliente: [],
  actaEstado: "PENDIENTE", // ANULADO 'PENDIENTE', 'PROCESADO' ,'EN PROCESO'
  actaValorRouter: "0",
  actaValorPatch: "0",
  actaDetalleEstado: "",
  actaIdVendedor: "",
  actaDocVendedor: "",
  actaNombreVendedor: "",
};

export const columnsEquiposInstalados = [
  {
    id: "equipo",
    label: "equipo",
    minWidth: 150,
    tipo: "string",
    propiedad: "equipo",
    align: "center",
  },
  {
    id: "marca",
    label: "marca",
    minWidth: 150,
    tipo: "string",
    propiedad: "marca",
    align: "center",
  },
  {
    id: "serie",
    label: "serie",
    minWidth: 150,
    tipo: "string",
    propiedad: "serie",
    align: "center",
  },
  {
    id: "observaciones",
    label: "observaciones",
    minWidth: 150,
    tipo: "string",
    propiedad: "observaciones",
    align: "center",
  },
];
// DESHABILITAR BOTON
export const validacionHijo = (itemHijo) => {
  return (
    itemHijo.equipo.trim() === "" ||
    itemHijo.marca.trim() === "" ||
    itemHijo.serie.trim() === "" ||
    itemHijo.observaciones.trim() === ""
  );
};
export const objectDefaultEquiposInstalado = {
  order: 1,
  id: "",
  equipo: "",
  marca: "",
  serie: "",
  observaciones: "",
};

export const columnsCargo = [
  {
    id: "item",
    label: "item",
    minWidth: 150,
    tipo: "string",
    propiedad: "item",
    align: "center",
  },
  {
    id: "costoUnitario",
    label: "Costo Unitario",
    minWidth: 150,
    tipo: "string",
    propiedad: "costoUnitario",
    align: "center",
  },
  {
    id: "cantidad",
    label: "cantidad",
    minWidth: 150,
    tipo: "string",
    propiedad: "cantidad",
    align: "center",
  },
  {
    id: "valor",
    label: "valor",
    minWidth: 150,
    tipo: "string",
    propiedad: "valor",
    align: "center",
  },
  {
    id: "descripcion",
    label: "descripcion",
    minWidth: 150,
    tipo: "string",
    propiedad: "descripcion",
    align: "center",
  },
];
// DESHABILITAR BOTON
export const validacionHijoCargo = (itemHijo) => {
  return (
    itemHijo.item.trim() === "" ||
    itemHijo.costoUnitario === 0 ||
    itemHijo.cantidad < 1 ||
    itemHijo.valor === 0
  );
};
export const objectDefaultCargo = {
  order: 1,
  id: "",
  item: "",
  costoUnitario: 0,
  cantidad: 1,
  valor: "",
  descripcion: "",
};
