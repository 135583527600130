import React, { memo, useContext } from "react";
import { makeStyles } from "@mui/styles";
import { TableCell, TableRow } from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../../../components/Extra/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "./ButtonsAction";
import AuthContext from "../../../../Context/Auth/AuthContext";
import CallNombrePersona from "../../../components/Tablas/CallNombrePersona";
import CollapseCell from "../../../components/Celdas/CollapseCell";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  hover: {
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  celda: {
    fontSize: ".7rem !important",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const Row = (props) => {
  const {
    arrayExport,
    setarrayExport,
    row,
    index,
    campoExport,
    puedeExportar,
    desdeOtroLugar,
    seteditar,
    setopen,
    changeObjetoActivoState,
    socket,
    tienePermiso,
    alertaPermiso,
    eliminadoEstado,
  } = props;
  const { usuario } = useContext(AuthContext);
  const classes = useRowStyles();
  const [celComprimida, setcelComprimida] = useState({});
  const handleEliminar = async (_id) => {
    if (!tienePermiso("eliminar")) {
      return alertaPermiso("eliminar");
    }
    try {
      socket.current.emit("client:eliminarData", {
        nombre: usuario.nombre,
        tabla: "carnetvacuna",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        carnUser: usuario.usuario,
        carnEmpresa: usuario.rucempresa,
        carnId: row.carnId,
      });
    } catch (error) {}
  };
  const funcionEdit = () => {
    setopen(true);
    seteditar(true);
    changeObjetoActivoState({ ...row, restaurado: eliminadoEstado });
  };

  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo={`¿Esta seguro de eliminar este registro?`}
        _id={row._id}
        funcion={handleEliminar}
      />
      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          eliminadoEstado={eliminadoEstado}
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          row={row}
          setopenConfirmDialog={setopenConfirmDialog}
          funcionEdit={funcionEdit}
          ocultarEditar={false}
          ocultarDelete={false}
          mostrarEstado={false}
          campoExport={campoExport}
          campoEstado=""
          puedeExportar={puedeExportar}
          desdeOtroLugar={desdeOtroLugar}
        />
        <>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {row.carnId}
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {row.carnMascNombre}
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {row.carnPeso}
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {row.carnPerNombreVet}
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {row.carnProdTipo}
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {row.carnProdNombre}
          </TableCell>
          <TableCell
            padding="checkbox"
            size="small"
            align="center"
            className={classes.celda}
          >
            {row.carnFecVacuColocacion}
          </TableCell>
          <TableCell
            padding="checkbox"
            size="small"
            align="center"
            className={classes.celda}
            style={{
              color:
                row.carnColor === 1
                  ? //rojo tomate
                    "orange"
                  : row.carnColor === 2
                  ? //verde
                    "green"
                  : row.carnColor === 3
                  ? "red"
                  : "black",
              fontWeight: "bold",
            }}
          >
            {row.carnFecVacuRecolocacion}
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {row.carnDiasVacuRecolocacion}
          </TableCell>{" "}
          <CollapseCell
            celComprimida={celComprimida}
            setcelComprimida={setcelComprimida}
            row={row}
            propiedad={"carnObservacion"}
            claseCelda={classes.celda}
          />
          <TableCell
            padding="none"
            size="small"
            align="center"
            style={{
              color:
                row.carnCorreo === "PENDIENTE"
                  ? //rojo tomate
                    "#ffa726"
                  : row.carnCorreo === "ENVIADO"
                  ? //verde
                    "green"
                  : row.carnCorreo === "ERROR"
                  ? "#f44336"
                  : "#ffa726",
              fontWeight: "bold",
            }}
            className={classes.celda}
          >
            {row.carnCorreo}
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            style={{
              color:
                row.carnMensaje === "PENDIENTE"
                  ? //rojo tomate
                    "#ffa726"
                  : row.carnMensaje === "ENVIADO"
                  ? //verde
                    "green"
                  : row.carnMensaje === "ERROR"
                  ? "#f44336"
                  : "#ffa726",
              fontWeight: "bold",
            }}
            className={classes.celda}
          >
            {row.carnMensaje}
          </TableCell>
          <CallNombrePersona user={row.carnUser} />
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {new Date(row.carnFecUpd).toLocaleString("es-ES", {
              weekday: "short",
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour: "2-digit",
            })}
          </TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
