import React, { memo, useContext, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { MenuItem, TableCell, TableRow, TextField } from "@mui/material";

import { useState } from "react";

// import ConfirmacionDialog from "../../Dialog/ConfirmacionDialog";

import { withRouter } from "react-router";
import ButtonsAction from "./ButtonsAction";

import AlertaContext from "../../../../../Context/Alerta/AlertaContext";
import ConfirmacionDialog from "../../../../../components/Extra/ConfirmacionDialog";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  celda: {
    fontSize: ".7rem !important",
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const RowBodega = (props) => {
  const {
    row,
    index,
    campoExport,
    item,
    setitem,
    deshabilitarbotones,
    bodegaData,
  } = props;
  const [itemRow, setitemRow] = useState(row);
  const { mostrarAlerta } = useContext(AlertaContext);

  useEffect(() => {
    setitemRow(row);
    // eslint-disable-next-line
  }, [item]);
  const classes = useRowStyles();
  const handleEliminar = async (_id) => {
    setitem({
      ...item,
      invBodegas: item.invBodegas.filter((item, itemIndex) => {
        return index !== itemIndex;
      }),
    });
  };
  const funcionEdit = () => {
    seteditar(true);
  };
  const [editar, seteditar] = useState(false);
  const funcCancel = () => {
    setitemRow(row);
    seteditar(false);
  };
  const botonDisabled = () => {
    if (
      itemRow.nomBodega === "" ||
      itemRow.stock === "" ||
      itemRow.maximo === "" ||
      itemRow.minimo === ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  const funcSubmit = () => {
    setitem({
      ...item,
      invBodegas: item.invBodegas.map((item, itemIndex) => {
        if (index === itemIndex) {
          return itemRow;
        } else {
          return item;
        }
      }),
    });
    seteditar(false);
  };
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);

  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo="¿Esta seguro que quiere eliminar esta Bodega?"
        _id={itemRow._id}
        funcion={handleEliminar}
      />

      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          setitem={() => {
            const res = item.invBodegas.find((itemBodega, itemIndex) => {
              return itemBodega.estado === "ACTIVA" && index !== itemIndex;
            });

            if (res) {
              return mostrarAlerta("Ya hay una bodega ACTIVA", "error");
            }
            setitem({
              ...item,
              invBodegas: item.invBodegas.map((item, itemIndex) => {
                if (index === itemIndex) {
                  return {
                    ...itemRow,
                    estado:
                      itemRow.estado === "INACTIVA" ? "ACTIVA" : "INACTIVA",
                  };
                } else {
                  return item;
                }
              }),
            });
          }}
          item={itemRow}
          setopenConfirmDialog={setopenConfirmDialog}
          funcionEdit={funcionEdit}
          ocultarEditar={false}
          ocultarDelete={false}
          mostrarEstado={true}
          campoExport={campoExport}
          campoEstado="estado"
          funcCancel={funcCancel}
          funcSubmit={funcSubmit}
          editar={editar}
          deshabilitarbotones={deshabilitarbotones}
          botonDisabled={botonDisabled}
        />
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              id="bodId"
              value={itemRow.bodId}
              name="bodId"
              onChange={(e) => {
                if (e.target.value === "") {
                  return setitemRow({
                    ...itemRow,
                    bodId: "",
                    nomBodega: "",
                  });
                }
                const itemR = bodegaData.find(
                  (itemBodega) => itemBodega.bodId === e.target.value
                );

                setitemRow({
                  ...itemRow,
                  bodId: e.target.value,
                  nomBodega: itemR.bodNombre,
                });
              }}
              size="small"
              fullWidth
              helperText="Agregando"
              select
              InputLabelProps={{ shrink: true }}
              SelectProps={{ displayEmpty: true }}
            >
              <MenuItem value="">Seleccione</MenuItem>
              {bodegaData.map((itemRow) => {
                return (
                  <MenuItem value={itemRow.bodId}>
                    {itemRow.bodNombre}{" "}
                  </MenuItem>
                );
              })}
            </TextField>
          ) : (
            row.nomBodega
          )}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              id=""
              type="number"
              value={itemRow.stock}
              onChange={(e) =>
                setitemRow({
                  ...itemRow,
                  stock: e.target.value,
                })
              }
              size="small"
              fullWidth
              helperText="Agregando"
            />
          ) : (
            row.stock
          )}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              id=""
              type="number"
              value={itemRow.maximo}
              onChange={(e) =>
                setitemRow({
                  ...itemRow,
                  maximo: e.target.value,
                })
              }
              size="small"
              fullWidth
              helperText="Agregando"
            />
          ) : (
            row.maximo
          )}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              id=""
              type="number"
              value={itemRow.minimo}
              onChange={(e) =>
                setitemRow({
                  ...itemRow,
                  minimo: e.target.value,
                })
              }
              size="small"
              fullWidth
              helperText="Agregando"
            />
          ) : (
            row.minimo
          )}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(RowBodega));
