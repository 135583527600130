import React, { useState, memo, useContext } from "react";

import { withRouter } from "react-router-dom";

import Row from "./Row";

// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableBody,
  Box,
  Typography,
  Collapse,
  TextField,
  MenuItem,
  Button,
  Divider,
} from "@mui/material";
import TablaCabecera from "../../../components/Extra/TablaCabecera";
import AuthContext from "../../../Context/Auth/AuthContext";
import PersonasVeterinaria from "../../Veterinaria/Personas_Veterinaria/Personas_Veterinaria";
import PersonasContable from "../../Compras/Proveedores/Personas_Contable/Personas_Contable";
import PersonasGasolinera from "../../Gasolinera/Personas_Gasolinera/Personas_Gasolinera";
import { useTheme } from "@emotion/react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import clienteAxios from "../../../config/axios";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import MenuContext from "../../../Context/Menu/MenuContext";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 600,
  },
  margin: {
    margin: theme.spacing(1),

    width: "45%",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: theme.spacing(1),
      width: "95%",
    },
  },
}));

const TablaCategorias = (props) => {
  const { setopen, rows, buscando, buscados } = props;
  const {
    usuario: { empRoles },
  } = useContext(AuthContext);
  const { rows: menuRows } = useContext(MenuContext);
  const [seccion, setseccion] = useState({
    permisosPorRol: true,
    permisosUsario: false,
  });
  const { mostrarAlerta } = useContext(AlertaContext);
  const [expandirPersonas, setexpandirPersonas] = useState(true);
  const [expandirMenu, setexpandirMenu] = useState(true);
  const { permisosPorRol, permisosUsario } = seccion;
  const [personasSeleccionadas, setpersonasSeleccionadas] = useState([]);
  const [cargando, setcargando] = useState(false);
  const PersonasVeterinariaData = menuRows.find((itemMenu) => {
    return itemMenu.sidInfo.component === "Personas_Veterinaria";
  });
  const PersonasContableData = menuRows.find((itemMenu) => {
    return itemMenu.sidInfo.component === "Personas_Contable";
  });
  const PersonasGasolineraData = menuRows.find((itemMenu) => {
    return itemMenu.sidInfo.component === "Personas_Gasolinera";
  });
 
  const handlePermisoIndividual = async (opcion, tipoPermiso, componente) => {
    if (personasSeleccionadas.length === 0) {
      return mostrarAlerta("Seleccione al menos a un usuario", "error");
    }
    try {
      setcargando(true);
      const lista = personasSeleccionadas.map((item) => item.perId);
      const nombreComponente = componente.sidInfo.component;

      await clienteAxios.put("/roles/permisos_usuarios/0", {
        lista,
        nombreComponente,
        tipoPermiso,
        opcion,
      });

      setcargando(false);
    } catch (error) {
      mostrarAlerta("Hubo un error", "error");
    }
  };
  const permisos = [
    {
      id: "nombre",
      label: permisosPorRol ? `nombre` : `nombre: D/SI/NO`,
      minWidth: 150,
    },
    {
      id: "agregar",
      label: permisosPorRol ? "agregar" : "agregar: D/SI/NO",
      minWidth: 150,
    },

    {
      id: "editar",
      label: permisosPorRol ? "editar" : "editar: D/SI/NO",
      minWidth: 150,
    },

    {
      id: "eliminar",
      label: permisosPorRol ? "eliminar" : "eliminar: D/SI/NO",
      minWidth: 150,
    },
    {
      id: "permisos",
      label: permisosPorRol ? "ver" : "ver: D/SI/NO",
      minWidth: 150,
    },
    {
      id: "allData",
      label: permisosPorRol ? "C. completa" : "C. completa: D/SI/NO",
      minWidth: 150,
    },
  ];
  const [rol, setrol] = useState(empRoles[0] ? empRoles[0] : "ADMIN");
  const [rolesSeleccionados, setrolesSeleccionados] = useState([]);
  const [expand, setOpen] = React.useState({});
  const handleClick = (e, a) => {
    setOpen({
      ...expand,
      [e]: !a,
    });
  };

  //clases
  const classes = useStyles();
  const theme = useTheme();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  function getStylesMenuItems(name, permisos, theme) {
    return {
      fontWeight:
        permisos.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
       backgroundColor: permisos.indexOf(name) === -1 ? "white" : theme.palette.primary.main,
    };
  }
  //pagina actual
  const [page, setPage] = React.useState(0);
  //filas por pagina
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // eslint-disable-next-line

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // const VETERINARIA = [
  //   "ADMIN",
  //   "USUARIO",
  //   "CLIENTE",
  //   "PROVEEDOR",
  //   "VETERINARIO",
  // ];
  // const CONTABLE = ["ADMIN", "USUARIO", "CLIENTE", "PROVEEDOR"];
  // const GASOLINERA = ["ADMIN", "USUARIO", "CLIENTE", "PROVEEDOR", "VENDEDOR"];
  // const ISP = [
  //   "ADMIN",
  //   "USUARIO",
  //   "CLIENTE",
  //   "PROVEEDOR",
  //   "TECNICO",
  //   "VENDEDOR",
  // ];

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems=" stretch"
        width="100%"
      >
        <Box>
          <Button
            size="small"
            variant={permisosPorRol ? "contained" : ""}
            onClick={async () => {
              setseccion({ permisosPorRol: true });
            }}
            disableElevation
            style={{
              padding: ".5rem",
              borderRadius: "15px 15px  0px 0px",
            }}
            color="primary"
          >
            Permisos por rol
          </Button>
          {/* <Button
            size="small"
            variant={roles ? "contained" : ""}
            onClick={async () => {
              setseccion({ roles: true });
            }}
            disableElevation
            style={{
              padding: ".5rem",
              borderRadius: "15px 15px  0px 0px",
            }}
            color="primary"
          >
            Agregar Roles
          </Button> */}
          <Button
            size="small"
            variant={permisosUsario ? "contained" : ""}
            onClick={async () => {
              setseccion({ permisosUsario: true });
            }}
            disableElevation
            style={{
              padding: ".5rem",
              borderRadius: "15px 15px  0px 0px",
            }}
            color="primary"
          >
            Permisos Individuales
          </Button>
        </Box>
      </Box>
      <Divider />
      {/* PERMISOS POR ROL */}

      {/* {roles ? (
        <>
          <TextField
            variant="outlined"
            size="small"
            label="Roles"
            value={rolesSeleccionados}
            fullWidth
            onChange={(e) => {
              setrolesSeleccionados(e.target.value);
            }}
            select
            MenuProps={MenuProps}
            // InputLabelProps={{ shrink: true }}
            SelectProps={{
              displayEmpty: true,
              multiple: true,
              renderValue: (selected) => {
                return selected
                  .map((value, index) => {
                    return value;
                  })
                  .join(" -- ");
              },
            }}
          >
            <MenuItem disabled value="">
              Seleccione.
            </MenuItem>
            {empRoles
              .sort((a, b) => a.orden - b.orden)
              .map((item) => {
                return (
                  <MenuItem
                    style={getStylesMenuItems(item, rolesSeleccionados, theme)}
                    value={item}
                  >
                    {item}
                  </MenuItem>
                );
              })}
          </TextField>
 
        </>
      ) : null} */}
      {permisosUsario || permisosPorRol ? (
        <>
          {permisosPorRol ? null : (
            <>
              <Box display={"flex"} justifyContent="center">
                <Button
                  onClick={() => {
                    setexpandirPersonas((res) => !res);
                  }}
                  fullWidth
                  endIcon={
                    expandirPersonas ? (
                      <ExpandLess fontSize="large" />
                    ) : (
                      <ExpandMore fontSize="large" />
                    )
                  }
                  startIcon={
                    expandirPersonas ? (
                      <ExpandLess fontSize="large" />
                    ) : (
                      <ExpandMore fontSize="large" />
                    )
                  }
                >
                  <Typography color={"secondary"} variant="h5" fontWeight={900}>
                    USUARIOS
                  </Typography>
                </Button>
              </Box>
              <Collapse in={expandirPersonas} timeout="auto">
                <TextField
                  variant="outlined"
                  size="small"
                  label="Roles"
                  value={rolesSeleccionados}
                  fullWidth
                  onChange={(e) => {
                    setrolesSeleccionados(e.target.value);
                  }}
                  select
                  MenuProps={MenuProps}
                  // InputLabelProps={{ shrink: true }}
                  SelectProps={{
                    displayEmpty: true,
                    multiple: true,
                    renderValue: (selected) => {
                      return selected
                        .map((value, index) => {
                          return value;
                        })
                        .join(" -- ");
                    },
                  }}
                >
                  <MenuItem disabled value="">
                    Seleccione.
                  </MenuItem>
                  {empRoles
                    .sort((a, b) => a.orden - b.orden)
                    .map((item) => {
                      return (
                        <MenuItem
                          style={getStylesMenuItems(
                            item,
                            rolesSeleccionados,
                            theme
                          )}
                          value={item}
                        >
                          {item}
                        </MenuItem>
                      );
                    })}
                </TextField>
                {PersonasVeterinariaData ? (
                  <PersonasVeterinaria
                    desdeOtroLugarMenu={true}
                    rolesSeleccionados={rolesSeleccionados}
                    setpersonasSeleccionadas={setpersonasSeleccionadas}
                  />
                ) : null}
                {PersonasContableData ? (
                  <PersonasContable
                    desdeOtroLugarMenu={true}
                    rolesSeleccionados={rolesSeleccionados}
                    setpersonasSeleccionadas={setpersonasSeleccionadas}
                  />
                ) : null}
                {PersonasGasolineraData ? (
                  <PersonasGasolinera
                    desdeOtroLugarMenu={true}
                    rolesSeleccionados={rolesSeleccionados}
                    setpersonasSeleccionadas={setpersonasSeleccionadas}
                  />
                ) : null}

                {/*
                 */}
              </Collapse>
              <Divider />
            </>
          )}

          <Box display={"flex"} justifyContent="center">
            <Button
              onClick={() => {
                setexpandirMenu((res) => !res);
              }}
              fullWidth
              endIcon={
                expandirMenu ? (
                  <ExpandLess fontSize="large" />
                ) : (
                  <ExpandMore fontSize="large" />
                )
              }
              startIcon={
                expandirMenu ? (
                  <ExpandLess fontSize="large" />
                ) : (
                  <ExpandMore fontSize="large" />
                )
              }
            >
              <Typography color={"secondary"} variant="h5" fontWeight={900}>
                ASIGNAR PERMISOS
              </Typography>
            </Button>
          </Box>
          {permisosPorRol ? (
            <Collapse in={expandirMenu} timeout="auto" unmountOnExit>
              <Paper className={classes.root}>
                <TableContainer className={classes.container}>
                  {permisosPorRol
                    ? empRoles.map((itemRol, index) => {
                        return (
                          <Button
                            key={index}
                            size="small"
                            variant={rol === itemRol ? "contained" : ""}
                            onClick={async () => {
                              setrol(itemRol);
                            }}
                            disableElevation
                            style={{
                              padding: ".5rem",
                              borderRadius: "15px 15px  0px 0px",
                            }}
                            color="primary"
                          >
                            {itemRol}
                          </Button>
                        );
                      })
                    : null}

                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    id="tabla"
                    size="small"
                  >
                    {/* <ModalCarnet setopen={setopen} onChangeCarnetActivo={onChangeCarnetActivo}/> */}
                    <TableHead>
                      <TablaCabecera columns={permisos} />
                    </TableHead>
                    <TableBody>
                      <>
                        {rows
                          .sort((a, b) => {
                            return a.orden - b.orden;
                          })
                          .map((categoria, indexCategoria) => {
                            // validacion categoria
                            if (categoria.sidInfo.tipo !== "categoria") {
                              return null;
                            }
                            return (
                              <>
                                <Row
                                  cargando={cargando}
                                  handlePermisoIndividual={
                                    handlePermisoIndividual
                                  }
                                  permisosUsario={permisosUsario}
                                  tipo="categoria"
                                  rol={rol}
                                  key={categoria.sidId}
                                  row={categoria}
                                  index={indexCategoria}
                                  setopen={setopen}
                                  expandir={handleClick}
                                  expand={expand}
                                  expandible={true}
                                />
                                <TableRow>
                                  <TableCell
                                    style={{ paddingBottom: 0, paddingTop: 0 }}
                                    colSpan={8}
                                  >
                                    <Collapse
                                      in={expand[categoria.sidInfo.name]}
                                      timeout="auto"
                                      unmountOnExit
                                    >
                                      <Box margin={1}>
                                        <Typography
                                          variant="body1"
                                          gutterBottom
                                          component="div"
                                        >
                                          SubCategoria: {categoria.sidInfo.name}
                                        </Typography>
                                        <Table
                                          size="small"
                                          aria-label="purchases"
                                        >
                                          <TablaCabecera columns={permisos} />
                                          <TableBody>
                                            {rows
                                              .sort((a, b) => {
                                                return a.orden - b.orden;
                                              })
                                              .map(
                                                (
                                                  subcategoria,
                                                  indexSubCategoria
                                                ) => {
                                                  // validacion subcategiria
                                                  if (
                                                    subcategoria.sidInfo
                                                      .tipo !== "subCategoria"
                                                  ) {
                                                    return null;
                                                  }
                                                  if (
                                                    categoria.sidInfo.path !==
                                                    subcategoria.sidInfo
                                                      .categoria
                                                  ) {
                                                    return null;
                                                  }
                                                  //subctegoria expandible
                                                  if (
                                                    subcategoria.sidInfo
                                                      .expandible
                                                  ) {
                                                    return (
                                                      <>
                                                        <Row
                                                          key={
                                                            subcategoria.sidId
                                                          }
                                                          cargando={cargando}
                                                          handlePermisoIndividual={
                                                            handlePermisoIndividual
                                                          }
                                                          permisosUsario={
                                                            permisosUsario
                                                          }
                                                          tipo="subcategoria"
                                                          rol={rol}
                                                          row={subcategoria}
                                                          index={
                                                            indexSubCategoria
                                                          }
                                                          setopen={setopen}
                                                          expandir={handleClick}
                                                          expand={expand}
                                                        />
                                                        <TableRow>
                                                          <TableCell
                                                            style={{
                                                              paddingBottom: 0,
                                                              paddingTop: 0,
                                                            }}
                                                            colSpan={8}
                                                          >
                                                            <Collapse
                                                              in={
                                                                expand[
                                                                  subcategoria
                                                                    .sidInfo
                                                                    .name
                                                                ]
                                                              }
                                                              timeout="auto"
                                                              unmountOnExit
                                                            >
                                                              <Box margin={1}>
                                                                <Typography
                                                                  variant="body1"
                                                                  gutterBottom
                                                                  component="div"
                                                                >
                                                                  Sub -
                                                                  SubCategoria:
                                                                  {
                                                                    subcategoria
                                                                      .sidInfo
                                                                      .name
                                                                  }
                                                                </Typography>
                                                                <Table
                                                                  size="small"
                                                                  aria-label="purchases"
                                                                >
                                                                  <TablaCabecera
                                                                    columns={
                                                                      permisos
                                                                    }
                                                                  />
                                                                  <TableBody>
                                                                    {rows
                                                                      .sort(
                                                                        (
                                                                          a,
                                                                          b
                                                                        ) => {
                                                                          return (
                                                                            a.orden -
                                                                            b.orden
                                                                          );
                                                                        }
                                                                      )
                                                                      .map(
                                                                        (
                                                                          subsubCategoria,
                                                                          indexSubCategoria
                                                                        ) => {
                                                                          // validacion subcategiria
                                                                          if (
                                                                            subsubCategoria
                                                                              .sidInfo
                                                                              .tipo !==
                                                                            "subsubCategoria"
                                                                          ) {
                                                                            return null;
                                                                          }

                                                                          if (
                                                                            subsubCategoria
                                                                              .sidInfo
                                                                              .subcategoria !==
                                                                            subcategoria
                                                                              .sidInfo
                                                                              .path
                                                                          ) {
                                                                            return null;
                                                                          }

                                                                          return (
                                                                            <>
                                                                              <Row
                                                                                key={
                                                                                  subsubCategoria.sidId
                                                                                }
                                                                                cargando={
                                                                                  cargando
                                                                                }
                                                                                handlePermisoIndividual={
                                                                                  handlePermisoIndividual
                                                                                }
                                                                                permisosUsario={
                                                                                  permisosUsario
                                                                                }
                                                                                tipo="subsubCategoria"
                                                                                rol={
                                                                                  rol
                                                                                }
                                                                                row={
                                                                                  subsubCategoria
                                                                                }
                                                                                index={
                                                                                  indexSubCategoria
                                                                                }
                                                                                setopen={
                                                                                  setopen
                                                                                }
                                                                                expandir={
                                                                                  handleClick
                                                                                }
                                                                                expand={
                                                                                  expand
                                                                                }
                                                                              />
                                                                            </>
                                                                          );
                                                                        }
                                                                      )}
                                                                  </TableBody>
                                                                </Table>
                                                              </Box>
                                                            </Collapse>
                                                          </TableCell>
                                                        </TableRow>
                                                      </>
                                                    );
                                                  }

                                                  //subcateoria no expandible
                                                  return (
                                                    <Row
                                                      key={categoria.subId}
                                                      cargando={cargando}
                                                      handlePermisoIndividual={
                                                        handlePermisoIndividual
                                                      }
                                                      permisosUsario={
                                                        permisosUsario
                                                      }
                                                      tipo="subcategoria"
                                                      rol={rol}
                                                      row={subcategoria}
                                                      index={indexSubCategoria}
                                                      setopen={setopen}
                                                      expandir={handleClick}
                                                      expand={expand}
                                                    />
                                                  );
                                                }
                                              )}
                                          </TableBody>
                                        </Table>
                                      </Box>
                                    </Collapse>
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })}
                      </>
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  labelRowsPerPage="Filas por pagina"
                  rowsPerPageOptions={[5, 10, 20, 50, 100]}
                  component="div"
                  count={buscando ? buscados.length : rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Collapse>
          ) : null}
          {permisosUsario ? (
            <Collapse in={expandirMenu} timeout="auto" unmountOnExit>
              <Paper className={classes.root}>
                <TableContainer className={classes.container}>
                  {permisosPorRol
                    ? empRoles.map((itemRol, index) => {
                        return (
                          <Button
                            key={index}
                            size="small"
                            variant={rol === itemRol ? "contained" : ""}
                            onClick={async () => {
                              setrol(itemRol);
                            }}
                            disableElevation
                            style={{
                              padding: ".5rem",
                              borderRadius: "15px 15px  0px 0px",
                            }}
                            color="primary"
                          >
                            {itemRol}
                          </Button>
                        );
                      })
                    : null}

                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    id="tabla"
                    size="small"
                  >
                    {/* <ModalCarnet setopen={setopen} onChangeCarnetActivo={onChangeCarnetActivo}/> */}
                    <TableHead>
                      <TablaCabecera columns={permisos} />
                    </TableHead>
                    <TableBody>
                      <>
                        {rows
                          .sort((a, b) => {
                            return a.orden - b.orden;
                          })
                          .map((categoria, indexCategoria) => {
                            // validacion categoria
                            if (categoria.sidInfo.tipo !== "categoria") {
                              return null;
                            }
                            return (
                              <>
                                <Row
                                  cargando={cargando}
                                  handlePermisoIndividual={
                                    handlePermisoIndividual
                                  }
                                  permisosUsario={permisosUsario}
                                  tipo="categoria"
                                  rol={rol}
                                  key={categoria.sidId}
                                  row={categoria}
                                  index={indexCategoria}
                                  setopen={setopen}
                                  expandir={handleClick}
                                  expand={expand}
                                  expandible={true}
                                />
                                <TableRow>
                                  <TableCell
                                    style={{ paddingBottom: 0, paddingTop: 0 }}
                                    colSpan={8}
                                  >
                                    <Collapse
                                      in={expand[categoria.sidInfo.name]}
                                      timeout="auto"
                                      unmountOnExit
                                    >
                                      <Box margin={1}>
                                        <Typography
                                          variant="body1"
                                          gutterBottom
                                          component="div"
                                        >
                                          SubCategoria: {categoria.sidInfo.name}
                                        </Typography>
                                        <Table
                                          size="small"
                                          aria-label="purchases"
                                        >
                                          <TablaCabecera columns={permisos} />
                                          <TableBody>
                                            {rows
                                              .sort((a, b) => {
                                                return a.orden - b.orden;
                                              })
                                              .map(
                                                (
                                                  subcategoria,
                                                  indexSubCategoria
                                                ) => {
                                                  // validacion subcategiria
                                                  if (
                                                    subcategoria.sidInfo
                                                      .tipo !== "subCategoria"
                                                  ) {
                                                    return null;
                                                  }
                                                  if (
                                                    categoria.sidInfo.path !==
                                                    subcategoria.sidInfo
                                                      .categoria
                                                  ) {
                                                    return null;
                                                  }
                                                  //subctegoria expandible
                                                  if (
                                                    subcategoria.sidInfo
                                                      .expandible
                                                  ) {
                                                    return (
                                                      <>
                                                        <Row
                                                          key={
                                                            subcategoria.sidId
                                                          }
                                                          cargando={cargando}
                                                          handlePermisoIndividual={
                                                            handlePermisoIndividual
                                                          }
                                                          permisosUsario={
                                                            permisosUsario
                                                          }
                                                          tipo="subcategoria"
                                                          rol={rol}
                                                          row={subcategoria}
                                                          index={
                                                            indexSubCategoria
                                                          }
                                                          setopen={setopen}
                                                          expandir={handleClick}
                                                          expand={expand}
                                                        />
                                                        <TableRow>
                                                          <TableCell
                                                            style={{
                                                              paddingBottom: 0,
                                                              paddingTop: 0,
                                                            }}
                                                            colSpan={8}
                                                          >
                                                            <Collapse
                                                              in={
                                                                expand[
                                                                  subcategoria
                                                                    .sidInfo
                                                                    .name
                                                                ]
                                                              }
                                                              timeout="auto"
                                                              unmountOnExit
                                                            >
                                                              <Box margin={1}>
                                                                <Typography
                                                                  variant="body1"
                                                                  gutterBottom
                                                                  component="div"
                                                                >
                                                                  Sub -
                                                                  SubCategoria:
                                                                  {
                                                                    subcategoria
                                                                      .sidInfo
                                                                      .name
                                                                  }
                                                                </Typography>
                                                                <Table
                                                                  size="small"
                                                                  aria-label="purchases"
                                                                >
                                                                  <TablaCabecera
                                                                    columns={
                                                                      permisos
                                                                    }
                                                                  />
                                                                  <TableBody>
                                                                    {rows
                                                                      .sort(
                                                                        (
                                                                          a,
                                                                          b
                                                                        ) => {
                                                                          return (
                                                                            a.orden -
                                                                            b.orden
                                                                          );
                                                                        }
                                                                      )
                                                                      .map(
                                                                        (
                                                                          subsubCategoria,
                                                                          indexSubCategoria
                                                                        ) => {
                                                                          // validacion subcategiria
                                                                          if (
                                                                            subsubCategoria
                                                                              .sidInfo
                                                                              .tipo !==
                                                                            "subsubCategoria"
                                                                          ) {
                                                                            return null;
                                                                          }

                                                                          if (
                                                                            subsubCategoria
                                                                              .sidInfo
                                                                              .subcategoria !==
                                                                            subcategoria
                                                                              .sidInfo
                                                                              .path
                                                                          ) {
                                                                            return null;
                                                                          }

                                                                          return (
                                                                            <>
                                                                              <Row
                                                                                key={
                                                                                  subsubCategoria.sidId
                                                                                }
                                                                                cargando={
                                                                                  cargando
                                                                                }
                                                                                handlePermisoIndividual={
                                                                                  handlePermisoIndividual
                                                                                }
                                                                                permisosUsario={
                                                                                  permisosUsario
                                                                                }
                                                                                tipo="subsubCategoria"
                                                                                rol={
                                                                                  rol
                                                                                }
                                                                                row={
                                                                                  subsubCategoria
                                                                                }
                                                                                index={
                                                                                  indexSubCategoria
                                                                                }
                                                                                setopen={
                                                                                  setopen
                                                                                }
                                                                                expandir={
                                                                                  handleClick
                                                                                }
                                                                                expand={
                                                                                  expand
                                                                                }
                                                                              />
                                                                            </>
                                                                          );
                                                                        }
                                                                      )}
                                                                  </TableBody>
                                                                </Table>
                                                              </Box>
                                                            </Collapse>
                                                          </TableCell>
                                                        </TableRow>
                                                      </>
                                                    );
                                                  }

                                                  //subcateoria no expandible
                                                  return (
                                                    <Row
                                                      key={categoria.subId}
                                                      cargando={cargando}
                                                      handlePermisoIndividual={
                                                        handlePermisoIndividual
                                                      }
                                                      permisosUsario={
                                                        permisosUsario
                                                      }
                                                      tipo="subcategoria"
                                                      rol={rol}
                                                      row={subcategoria}
                                                      index={indexSubCategoria}
                                                      setopen={setopen}
                                                      expandir={handleClick}
                                                      expand={expand}
                                                    />
                                                  );
                                                }
                                              )}
                                          </TableBody>
                                        </Table>
                                      </Box>
                                    </Collapse>
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })}
                      </>
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  labelRowsPerPage="Filas por pagina"
                  rowsPerPageOptions={[5, 10, 20, 50, 100]}
                  component="div"
                  count={buscando ? buscados.length : rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Collapse>
          ) : null}
        </>
      ) : null}
    </>
  );
};
export default memo(withRouter(TablaCategorias));
