import React, { useState, memo } from "react";
import { NavLink, withRouter } from "react-router-dom";
// eslint-disable-next-line

// MATERIAL
import { makeStyles } from "@mui/styles";
import { Typography, Breadcrumbs } from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
const useStyles = makeStyles((theme) => ({
  link: {
    display: "flex",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));

const MigasDePan = (props) => {
  const classes = useStyles();

  const [rutas] = useState({
    path: props.location.pathname.replace("/Contable", ""),
    layout: "/Contable",
  });

  if (!rutas) {
    return null;
  }
  return (
    <Breadcrumbs
      separator={<NavigateNext fontSize="small" />}
      aria-label="breadcrumb"
    >
      <NavLink color="secondary" className={classes.link} to={rutas.layout}>
        {/* <NavigateNext className={classes.icon} /> */}

        <Typography color="secondary" className={classes.link}>
          Dashboard
        </Typography>
      </NavLink>
      {rutas.path.split("/").map((ruta, index) => {
        if (ruta === "") {
          return null;
        }

        return (
          <NavLink
            key={index}
            color="textSecondary"
            className={classes.link}
            to={
              index === 1
                ? `/Contable/${rutas.path.split("/")[1]}`
                : index === 2
                ? `/Contable/${rutas.path.split("/")[1]}/${
                    rutas.path.split("/")[2]
                  }`
                : `/Contable/${rutas.path.split("/")[1]}/${
                    rutas.path.split("/")[2]
                  }/${rutas.path.split("/")[3]}`
            }
          >
            <Typography color="primary" className={classes.link}>
              {ruta}

              {/* {index === 1
                ? `/Contable/${rutas.path.split("/")[1]}`
                : index === 2
                ? `/Contable/${rutas.path.split("/")[1]}/${
                    rutas.path.split("/")[2]
                  }`
                : `/Contable/${rutas.path.split("/")[index]}/${
                    rutas.path.split("/")[index]
                  }`} */}
            </Typography>
          </NavLink>
        );
      })}
    </Breadcrumbs>
  );
};

export default withRouter(memo(MigasDePan));
