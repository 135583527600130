//dependencias
import React, { memo } from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
//
import "./assets/css/material-dashboard-react.css?v=1.9.0";

// core components
import Admin from "./layouts/Admin";

//importando tema
import { ThemeProvider } from "@mui/material/styles";
import theme from "./config/theme";
//coponentes /vistas fuera del admin

import Login from "./views/Auth/Login";

import AuthState from "./Context/Auth/AuthState";
import tokenAuth from "./config/token";
import axiosEmpresa from "./config/axiosEmpresa";
import AlertaState from "./Context/Alerta/AlertaState";

//ruta protegida
import RutaPrivada from "./components/rutas/RutaPrivada";
import MenuState from "./Context/Menu/MenuState";
import "./components/Extra/GlobalCssSlider.css";
import "./normalize.css";
// import { ChatSocketContext } from "./Context/ChatSocket/ChatSocketContext";
//revisar si hay un  token

const token = localStorage.getItem("token");
const empresaheader = localStorage.getItem("empresaheader");
const usuarioheader = localStorage.getItem("usuarioheader");
const rolheader = localStorage.getItem("rolheader");

if (token) {
  tokenAuth(token);
  axiosEmpresa(empresaheader, usuarioheader, rolheader);
}

function App() {
  const hist = createBrowserHistory();

  return (
    <ThemeProvider theme={theme}>
      <AuthState>
        <AlertaState>
          <MenuState>
            <Router history={hist}>
              <Switch>
                <Route path="/login" component={Login} />
                <RutaPrivada path="/Contable" component={Admin} />
                <Redirect from="/" to="/login" />
              </Switch>
            </Router>
          </MenuState>
        </AlertaState>
      </AuthState>
    </ThemeProvider>
  );
}

export default memo(App);
