import React, { memo, useState, useContext, useEffect, useRef } from "react";
import { Box, Button } from "@mui/material";
import Tabla from "./Componentes/Tabla";
import ModalB from "./Componentes/ModalB";
import BarraTablas from "../../../components/BarraTablas/BarraTablas";
import Modal from "./Componentes/Modal";
import AuthContext from "../../../Context/Auth/AuthContext";
import clienteAxios from "../../../config/axios";
import AlertaContext from "../../../Context/Alerta/AlertaContext";

import io from "socket.io-client";
import { socketUrl } from "../../../config/const";
const Categorias = (props) => {
  // const { llamadoDeOtroLugar, setopenMT } = props;
  const {
    idmascota,
    settab,
    setarrayExportHijos,
    desdeOtroLugarMascota,
    // puedeExportar,
    // itemRelacionadoPadre,
  } = props;
  // DATA DEL STATE
  useEffect(() => {
    if (desdeOtroLugarMascota) {
      settab("");
      setarrayExportHijos(arrayExport.map((item) => item[campoExport]));
    }
    // eslint-disable-next-line
  }, [arrayExport]);

  const [rows, setrows] = useState(null);
  const columns = [
    {
      label: "Numero",
      propiedad: "cajaNumero",
      tipo: "numero",
      orden: 2,
    },
    {
      label: "Fecha",
      propiedad: "cajaFecha",
      tipo: "fecha",
      orden: 1,
    },
    {
      label: "Detalle",
      propiedad: "cajaDetalle",
      tipo: "string",
      orden: 3,
    },
    {
      label: "Ingreso",
      propiedad: "cajaIngreso",
      tipo: "numero",
      orden: 2,
    },
    {
      label: "Egreso",
      propiedad: "cajaEgreso",
      tipo: "numero",
      orden: 2,
    },
    {
      label: "Credito",
      propiedad: "cajaCredito",
      tipo: "numero",
      orden: 2,
    },
    {
      label: "Monto",
      propiedad: "cajaMonto",
      tipo: "numero",
      orden: 2,
    },
    {
      orden: 3,
      id: "cajaTipoCaja",
      label: "F. Pago",
      minWidth: 100,
      tipo: "string",
      propiedad: "cajaTipoCaja",
      variante: "autocompleteDataDefault",
      propiedadesMostrar: 1,
      campoData1: "fpagoNombre",
      getData: async () => {
        const res = await clienteAxios.get("/formaPagos/filtro/0");
        return res;
      },
    },
    {
      label: "Doc.",
      propiedad: "cajaTipoDocumento",
      tipo: "string",
      orden: 3,
      variante: "autocompleteComun",
      data: [
        "APERTURA",
        "INGRESO",
        "EGRESO",
        "DEPOSITO",
        "CAJA CHICA",
        "TRANSFERENCIA",
      ],
    },
    {
      label: "Proviene",
      propiedad: "cajaProcedencia",
      tipo: "string",
      orden: 3,
    },
    {
      label: "Autori",
      propiedad: "cajaAutorizacion",
      tipo: "string",
      orden: 3,
    },
    {
      label: "User",
      propiedad: "cajaUser",
      tipo: "string",
      orden: 3,
    },
    {
      label: "Fec Reg",
      propiedad: "cajaFecReg",
      tipo: "string",
      orden: 3,
    },
  ];
  const defaultFullBuscador = () => {
    const json = {};
    columns.forEach((itemPropiedad) => {
      Object.entries({ itemPropiedad }).forEach((item) => {
        if (itemPropiedad.tipo === "string") {
          json[`${item[1].propiedad}`] = "";
        } else if (itemPropiedad.tipo === "numero") {
          json[`${item[1].propiedad}1`] = "";
          json[`${item[1].propiedad}2`] = "";
        } else if (itemPropiedad.tipo === "fecha") {
          json[`${item[1].propiedad}1`] = "";
          json[`${item[1].propiedad}2`] = "";
        }
      });
    });

    return json;
  };
  const [ObjectoActivo, setObjetoActivo] = useState({
    cajaNumero: "",
    cajaFecha: `${new Date().getFullYear()}-${(
      "0" +
      (new Date().getMonth() + 1)
    ).slice(-2)}-${("0" + new Date().getDate()).slice(-2)}`,
    cajaDetalle: "",
    cajaIngreso: "",
    cajaEgreso: "",
    cajaCredito: "",
    cajaMonto: "",
    cajaTipoCaja: "EFECTIVO",
    cajaTipoDocumento: "INGRESO",
    cajaEstado: "ACTIVO",
    cajaProcedencia: "",
    cajaAutorizacion: "",
    cajaEmpresa: "",
    cajaUser: "",
    cajaFecReg: "",
    Todos: "",
  });
  const [pagination, setpagination] = useState(null);
  // FUNCIONES STATE
  const getDataState = async (
    cantidad,
    page,
    search,
    input,
    orden,
    datos,
    rucempresa,
    estado
  ) => {
    const res = await clienteAxios.get(
      `/cajas/?cantidad=${cantidad}&page=${page}&search=${
        search ? search : ""
      }&input=${input ? input : ""}&orden=${orden ? orden : ""}&datos=${
        datos ? datos : ""
      }&rucempresa=${rucempresa ? rucempresa : ""}&estado=${
        estado ? estado : ""
      }`
    );

    return res;
  };

  const obtenerState = async (
    cantidad = 10,
    page = 0,
    search,
    input,
    orden,
    datos,
    rucempresa,
    estado
  ) => {
    const res = await getDataState(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa,
      estado
    );
    setrows(res.data.data.results);
    setpagination(res.data.data.pagination);
  };
  const addRowsState = async (items) => {
    setrows([...rows, ...items]);
  };
  const remplazarRowsState = async (
    cantidad,
    page,
    search,
    input,
    orden,
    datos,
    rucempresa,
    estado
  ) => {
    const res = await getDataState(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa,
      estado
    );
    setrows(res.data.data.results);
    setpagination(res.data.data.pagination);
    // dispatch({
    //   type: OBTENER,
    //   payload: {
    //     results: res.data.data.results,
    //     pagination: res.data.data.pagination,
    //   },
    // });
  };
  const agregarState = async (item) => {
    try {
      setrows([item, ...rows]);

      setpagination({
        ...pagination,
        numRows: pagination.numRows + 1,
      });
    } catch (error) {}
  };

  const editarState = async (item) => {
    try {
      const resRows = rows.map((itemR) => {
        if (itemR.cajaId === item.cajaId) {
          return item;
        } else {
          return itemR;
        }
      });

      setrows(resRows);
    } catch (error) {}
  };

  const eliminarState = async (_id) => {
    try {
      const resRows = rows.filter((row) => {
        return row.cajaId !== _id;
      });
      setrows(resRows);
      setpagination({
        ...pagination,
        numRows: pagination.numRows - 1,
      });
    } catch (error) {}
  };
  const eliminarSeleccionState = async (_ids) => {
    const resRows = rows.filter((row) => {
      return !_ids.some((item) => {
        return item === row.cajaId;
      });
    });
    setpagination({
      ...pagination,
      numRows: pagination.numRows - _ids.length,
    });
    setrows(resRows);
  };

  const changeObjetoActivoState = (item) => {
    setObjetoActivo(item);
    // dispatch({
    //   type: CHANGE_ACTIVO,
    //   payload: item,
    // });
  };

  const rowsImportState = (rows) => {
    // dispatch({
    //   type: IMPORTAR_ROWS,
    //   payload: rows,
    // });
    setrows(rows);
  };
  // FIN FUNCIONES STATE
  // FIN DATA STATE

  // FUNCIONES SOCKET
  const [tabla] = useState("caja");
  const [itemSocket, setitemSocket] = useState({
    tipo: "",
    item: {},
  });
  const socket = useRef();
  useEffect(() => {
    // Creates a WebSocket connection
    socket.current = io(socketUrl);
    socket.current.on("server:guardadoExitoso", (data) => {
      setitemSocket({
        tipo: "agregar",
        item: data,
      });
    });
    socket.current.on("server:actualizadoExitoso", (data) => {
      setitemSocket({
        tipo: "editar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitoso", (data) => {
      setitemSocket({
        tipo: "eliminar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitosoSeleccionado", (data) => {
      setitemSocket({
        tipo: "eliminarSeleccion",
        item: data,
      });
    });

    socket.current.on("server:error", (data) => {
      mostrarAlerta(
        data.msg ? data.msg : "Hubo un error",
        data.alert ? data.alert : "error"
      );
    });
    return () => {
      socket.current.disconnect();
    };
    // Destroys the socket reference
    // when the connection is closed
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    socket.current.on("server:guardadoExitoso", (data) => {
      setitemSocket({
        tipo: "agregar",
        item: data,
      });
    });
    socket.current.on("server:actualizadoExitoso", (data) => {
      setitemSocket({
        tipo: "editar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitoso", (data) => {
      setitemSocket({
        tipo: "eliminar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitosoSeleccionado", (data) => {
      setitemSocket({
        tipo: "eliminarSeleccion",
        item: data,
      });
    });

    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const { item, tipo } = itemSocket;

    if (tipo === "") {
      return;
    }
    if (!rows) {
      return;
    }
    if (usuario.rucempresa !== item.rucempresa) {
      return;
    }
    if (item.tabla !== tabla) {
      return;
    }

    if (desdeOtroLugarMascota && Number(item.hcliMascId) !== idmascota) {
      return;
    }
    const funcionAalerta = () => {
      if (item.msg === "") {
        return null;
      }
      if (!item.msg) {
        return null;
      }
      mostrarAlerta(item.msg, item.alert ? item.alert : "success");
    };

    if (tipo === "agregar") {
      agregarState(item);
    }
    if (tipo === "editar") {
      editarState(item);
    }
    if (tipo === "eliminar") {
      eliminarState(item.cajaId);
    }
    if (tipo === "eliminarSeleccion") {
      eliminarSeleccionState(item.lista);
    }
    funcionAalerta();
    setitemSocket({ tipo: "", item: {} });
    // eslint-disable-next-line
  }, [itemSocket]);
  const { usuario } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const [arrayExport, setarrayExport] = useState([]);
  const [campoExport] = useState("cajaId");
  const [estadoRequest, setestadoRequest] = useState("DIARIA");
  const [buscando, setbuscando] = useState(false);
  const [search, setsearch] = useState("");
  const [cargando, setcargando] = useState(false);
  const [agregar, setagregar] = useState(false);
  const [editar, seteditar] = useState(false);
  const [input, setinput] = useState({
    id: "cajaId",
    label: "ID",
    minWidth: 150,
    tipo: "string",

    propiedad: "cajaId",
  });
  const [orden, setorden] = useState(false);
  const [openMB, setopenMB] = useState(false);
  const [fullBuscador, setfullBuscador] = useState({});
  const {
    usuario: { rucempresa },
  } = useContext(AuthContext);
  //pagina actual
  const [page, setPage] = React.useState(0);
  //filas por pagina
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // input
  //
  const handleChange = (e) => {
    setfullBuscador({ ...fullBuscador, [e.target.name]: e.target.value });
  };
  const funcionB = async () => {
    setcargando(true);
    await remplazarRowsState(
      rowsPerPage,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest
    );
    setPage(0);
    setbuscando(true);
    setopenMB(false);
    setcargando(false);
  };

  const handleSearch = async () => {
    setcargando(true);
    setPage(0);
    await remplazarRowsState(
      rowsPerPage,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest
    );
    setcargando(false);
  };
  useEffect(() => {
    const fullBRes = defaultFullBuscador();

    setfullBuscador(fullBRes);
    // eslint-disable-next-line
  }, []);

  const funcionDefault = async () => {
    setcargando(true);
    const fullBRes = defaultFullBuscador();
    setfullBuscador(fullBRes);
    await remplazarRowsState(
      rowsPerPage,
      0,
      "",
      input.id,
      orden,
      JSON.stringify(fullBRes),
      rucempresa,
      estadoRequest
    );
    setPage(0);
    setsearch("");
    setbuscando(false);
    setcargando(false);
    setopenMB(false);
  };

  return (
    <Box>
      <BarraTablas
        setopen={setagregar}
        ocultarAgregar={false}
        search={search}
        setsearch={setsearch}
        funcion={handleSearch}
        buscando={buscando}
        setbuscando={setbuscando}
        disabled={false}
        setopenMB={setopenMB}
        LabelBuscador={"Buscar"}
        ocultarBuscar={false}
        ocultarMigasDePan={false}
        funcionDefault={funcionDefault}
      />{" "}
      <ModalB
        defaultFullBuscador={defaultFullBuscador}
        openMB={openMB}
        setopenMB={setopenMB}
        titulo={"Busqueda avanzada (Caja General)"}
        fullBuscador={fullBuscador}
        setfullBuscador={setfullBuscador}
        arrayProps={[...columns]}
        handleChange={handleChange}
        funcionB={funcionB}
        buscando={buscando}
        funcionDefault={funcionDefault}
      />{" "}
      <Box>
        <Button
          size="small"
          variant={estadoRequest === "DIARIA" ? "contained" : null}
          onClick={async () => {
            setcargando(true);
            await remplazarRowsState(
              rowsPerPage,
              0,
              "",
              input.id,
              orden,
              JSON.stringify(fullBuscador),
              rucempresa,
              "DIARIA"
            );

            setPage(0);
            setestadoRequest("DIARIA");
            setcargando(false);
          }}
          disableElevation
          style={{ padding: ".5rem", borderRadius: "15px 15px  0px 0px" }}
          color="primary"
        >
          DIARIA
        </Button>
        <Button
          size="small"
          variant={estadoRequest === "GENERAL" ? "contained" : null}
          onClick={async () => {
            setcargando(true);
            await remplazarRowsState(
              rowsPerPage,
              0,
              "",
              input.id,
              orden,
              JSON.stringify(fullBuscador),
              rucempresa,
              "GENERAL"
            );

            setPage(0);
            setestadoRequest("GENERAL");
            setcargando(false);
          }}
          disableElevation
          style={{ padding: ".5rem", borderRadius: "15px 15px  0px 0px" }}
          color="primary"
        >
          GENERAL
        </Button>
      </Box>
      <Tabla
        agregar={agregar}
        setagregar={setagregar}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        rows={rows}
        search={search}
        input={input}
        orden={orden}
        setinput={setinput}
        setorden={setorden}
        cargando={cargando}
        setcargando={setcargando}
        fullBuscador={fullBuscador}
        estadoRequest={estadoRequest}
        seteditar={seteditar}
        // STATE
        addRowsState={addRowsState}
        getDataState={getDataState}
        remplazarRowsState={remplazarRowsState}
        obtenerState={obtenerState}
        pagination={pagination}
        columns={columns}
        ObjectoActivo={ObjectoActivo}
        changeObjetoActivoState={changeObjetoActivoState}
        rowsImportState={rowsImportState}
        // export
        arrayExport={arrayExport}
        setarrayExport={setarrayExport}
        campoExport={campoExport}
        socket={socket}
      />
      <Modal
        open={agregar}
        setopen={setagregar}
        editar={editar}
        seteditar={seteditar}
        ObjectoActivo={ObjectoActivo}
        changeObjetoActivoState={changeObjetoActivoState}
        socket={socket}
      />
    </Box>
  );
};

export default memo(Categorias);
