import React, { memo, useContext, useState } from "react";

import IconBreadcrumbs from "../Extra/MigasDePan";
import AlertaContext from "../../Context/Alerta/AlertaContext";
import { withRouter } from "react-router";
import { permisoDenagado } from "../../config/const";
import { tienePermisoRuta } from "../../config/validaciones";
import AuthContext from "../../Context/Auth/AuthContext";
import MenuContext from "../../Context/Menu/MenuContext";
import DialogOpciones from "./DialogOpciones";
// MATERIAL
import { lightBlue } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Box,
  IconButton,
  TextField,
  CircularProgress,
} from "@mui/material";
import { Add, Backspace, FindInPage, List } from "@mui/icons-material";
const useStyles = makeStyles((theme) => ({
  button: {
    [theme.breakpoints.down("md")]: {
      padding: "5px",
      margin: "2px",
      width: "100%",
    },
    margin: theme.spacing(1),
  },
  margin: {
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "100%",
    },
    margin: theme.spacing(1),
    width: "60%",
  },
  buscadores: {
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "100%",
    },
    flexGrow: 1,
    margin: theme.spacing(1),
    width: "30%",
  },
  buscador: {
    width: "40%",
  },
  root: {
    display: "flex",
    alignItems: "center",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: lightBlue[500],
    "&:hover": {
      backgroundColor: lightBlue[700],
    },
  },
  fabProgress: {
    color: lightBlue[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: lightBlue[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  ocultar: {
    display: "none",
  },
}));
const BarraTablas = (props) => {
  const classes = useStyles();
  const [openDialogOpciones, setopenDialogOpciones] = useState(false);
  const {
    setopen,
    ocultarAgregar,
    search,
    setsearch,
    funcion,
    buscando,
    setbuscando,
    disabled,
    LabelBuscador,
    ocultarMigasDePan,
    funcionDefault,
    ocultarBuscar,
    setopenMB,
    subirArchivoCompras,
    funcArchivo,
    funcArchivoTxtSri,
    funcArchivoXmlSri,
    subirArchivoVentas,
    ats,
    setopenATS,
    ocultarOpciones,
    autorizarPorLote,
    descargarDocumentosDesdeSri,
  } = props;
  // const { usuario } = useContext(AuthContext);
  //gql eliminar usuario

  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext);
  const { rows } = useContext(MenuContext);
  const handleSubmit = (e) => {
    e.preventDefault();

    if (search.trim() === "") {
      return mostrarAlerta("ingrese una busqueda", "error");
    }
    setbuscando(false);
    funcion(search.trim());
    setbuscando(true);
  };

  return (
    <div>
      <DialogOpciones
        open={openDialogOpciones}
        setopen={setopenDialogOpciones}
        ocultarBuscar={ocultarBuscar}
        setopenMB={setopenMB}
        subirArchivoCompras={subirArchivoCompras}
        funcArchivo={funcArchivo}
        funcArchivoTxtSri={funcArchivoTxtSri}
        funcArchivoXmlSri={funcArchivoXmlSri}
        subirArchivoVentas={subirArchivoVentas}
        ats={ats}
        setopenATS={setopenATS}
        autorizarPorLote={autorizarPorLote}
        descargarDocumentosDesdeSri={descargarDocumentosDesdeSri}
      />
      {ocultarMigasDePan ? null : <IconBreadcrumbs />}
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        alignItems="center"
        flexWrap="wrap"
        flexGrow="1"
      >
        <Box
          display="flex"
          justifyContent="flex-start"
          flexWrap="wrap"
          flexGrow="1"
        >
          {ocultarAgregar ? null : (
            <>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={disabled}
                onClick={() => {
                  if (
                    !tienePermisoRuta(
                      rows,
                      props.location.pathname,
                      usuario.rol,
                      "agregar"
                    )
                  ) {
                    return mostrarAlerta(permisoDenagado, "error");
                  }
                  setopen(true);
                }}
                startIcon={
                  <>
                    {disabled && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                    <Add />
                  </>
                }
              >
                Agregar
              </Button>
            </>
          )}
          {ocultarOpciones ? null : (
            <Button
              size="large"
              className={classes.button}
              variant="contained"
              color="secondary"
              startIcon={<List />}
              onClick={() => setopenDialogOpciones(true)}
            >
              Opciones
            </Button>
          )}
        </Box>
        {ocultarBuscar ? null : (
          <form onSubmit={handleSubmit} className={classes.margin}>
            <TextField
              id=""
              label={LabelBuscador ? LabelBuscador : "Buscar"}
              variant="outlined"
              fullWidth
              color="primary"
              value={search}
              onChange={(e) => setsearch(e.target.value)}
              InputProps={{
                endAdornment: (
                  <Box display="flex" justifyContent="space-between">
                    {buscando ? (
                      <IconButton
                        aria-label=""
                        color="secondary"
                        onClick={async () => {
                          await funcionDefault();
                        }}
                      >
                        <Backspace color="error" />
                      </IconButton>
                    ) : null}
                    <IconButton aria-label="" color="secondary" type="submit">
                      <FindInPage />
                    </IconButton>
                  </Box>
                ),
                labelWidth: "600",
              }}
            />
          </form>
        )}
      </Box>
    </div>
  );
};

export default withRouter(memo(BarraTablas));
