import React, { memo, useContext } from "react";
import { makeStyles } from "@mui/styles";
import { Box, IconButton, TableCell, TableRow, TextField } from "@mui/material";

import { Cancel, Check } from "@mui/icons-material";

import { useState } from "react";

import AuthContext from "../../../../Context/Auth/AuthContext";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import { trimPropsItem } from "../../../../config/const";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
  celda: {
    fontSize: ".7rem !important",
  },
}));

const RowAgregar = (props) => {
  const { setagregar, socket, tienePermiso, alertaPermiso } = props;
  const { mostrarAlerta } = useContext(AlertaContext);
  const [item, setitem] = useState({
    espNombre: "", // text
    espRazas: [
      //  array hijos
    ],
    espAlimentos: [],
    espUser: "", // login
    espEmpresa: "", // login
  });
  const classes = useRowStyles();
  //gql eliminar user

  const { usuario } = useContext(AuthContext);
  const handleAgregar = async () => {
    if (!tienePermiso("agregar")) {
      return alertaPermiso("agregar");
    }
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          tabla: "especie",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          espUser: usuario.usuario,
          espEmpresa: usuario.rucempresa,
        })
      );
    } catch (error) {
      mostrarAlerta("Hubo un error");
    }
  };
  const handleChange = (e) => {
    setitem({
      ...item,
      [e.target.name]: e.target.value,
    });
  };
  const botonDisabled = () => {
    if (item.espNombre === "") {
      return true;
    } else {
      return false;
    }
  };
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell></TableCell> {/* boton */}
        <TableCell padding="none" style={{ borderRight: "1px solid #ccc" }}>
          <Box display="flex" alignItems="center">
            <IconButton
              className={classes.margin}
              onClick={() => {
                setagregar(false);
                setitem({
                  espNombre: "", // text
                  espRazas: [
                    //  array hijos
                  ],
                  espAlimentos: [],
                  espUser: "", // login
                  espEmpresa: "", // login
                });
              }}
            >
              <Cancel fontSize="small" color="secondary" />
            </IconButton>
            <IconButton
              color={
                botonDisabled
                  ? botonDisabled()
                    ? "inherit"
                    : "secondary"
                  : "secondary"
              }
              disabled={botonDisabled()}
              className={classes.margin}
              onClick={() => {
                handleAgregar();

                setagregar(false);
                setitem({
                  espNombre: "", // text
                  espRazas: [
                    //  array hijos
                  ],
                  espAlimentos: [],
                  espUser: "", // login
                  espEmpresa: "", // login
                });
              }}
            >
              <Check fontSize="small" />
            </IconButton>
          </Box>
        </TableCell>
        {/* las demas columnas */}
        <>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              value={item.espNombre}
              name="espNombre"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregar);
