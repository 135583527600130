import React, { memo, useContext } from "react";
import { makeStyles } from "@mui/styles";
import { TableCell, TableRow, TextField, MenuItem } from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../../../components/Extra/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "./ButtonsAction";
import AuthContext from "../../../../Context/Auth/AuthContext";
import { tienePermisoRuta } from "../../../../config/validaciones";
import MenuContext from "../../../../Context/Menu/MenuContext";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import { permisoDenagado, trimPropsItem } from "../../../../config/const";
import { useTheme } from "@emotion/react";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  hover: {
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  celda: {
    fontSize: ".7rem !important",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const Row = (props) => {
  const {
    arrayExport,
    setarrayExport,
    row,
    index,
    campoExport,
    puedeExportar,
    desdeOtroLugar,
    socket,
  } = props;
  const { usuario } = useContext(AuthContext);
  const { rows: rowsMenu } = useContext(MenuContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const [item, setitem] = useState(row);
  const [editar, seteditar] = useState(false);
  const classes = useRowStyles();

  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        nombre: usuario.nombre,
        tabla: "tiporeserva",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        tiporesUser: usuario.usuario,
        tiporesEmpresa: usuario.rucempresa,
        tiporesId: row.tiporesId,
      });
    } catch (error) {}
  };

  const handleChange = (e) => {
    setitem({
      ...item,
      [e.target.name]: e.target.value,
    });
  };
  const funcionEdit = () => {
    if (
      !tienePermisoRuta(
        rowsMenu,
        props.location.pathname,
        usuario.rol,
        "editar"
      )
    ) {
      return mostrarAlerta(permisoDenagado, "error");
    }
    seteditar(true);
  };

  const funcCancel = () => {
    setitem(row);
    seteditar(false);
  };
  const funcSubmit = () => {
    try {
      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          tabla: "tiporeserva",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          tiporesUser: `${row.tiporesUser.split(" ** ")[0]} ** ${
            usuario.usuario
          }`,
          tiporesEmpresa: usuario.rucempresa,
        })
      );
      seteditar(false);
    } catch (error) {}
  };
  const botonDisabled = () => {
    if (
      item.tiporesNombre.trim() === "" ||
      item.tiporesHoraDesde.trim() === "" ||
      item.tiporesHoraHasta.trim() === "" ||
      item.tiporesDias.length === 0
    ) {
      return true;
    } else {
      return false;
    }
  };
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const theme = useTheme();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  function getStylesMenuItems(name, permisos, theme) {
    return {
      fontWeight:
        permisos.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
      backgroundColor:
        permisos.indexOf(name) === -1 ? "white" : theme.palette.primary.main,
    };
  }
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo={`¿Esta seguro de eliminar este registro?`}
        _id={row._id}
        funcion={handleEliminar}
      />
      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          row={row}
          setopenConfirmDialog={setopenConfirmDialog}
          funcionEdit={funcionEdit}
          ocultarEditar={false}
          ocultarDelete={false}
          mostrarEstado={false}
          campoExport={campoExport}
          editar={editar}
          campoEstado=""
          puedeExportar={puedeExportar}
          desdeOtroLugar={desdeOtroLugar}
          funcCancel={funcCancel}
          funcSubmit={funcSubmit}
          botonDisabled={botonDisabled}
        />
        {editar ? (
          <>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                value={item.tiporesNombre}
                name="tiporesNombre"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                type="time"
                value={item.tiporesHoraDesde}
                name="tiporesHoraDesde"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                type="time"
                value={item.tiporesHoraHasta}
                name="tiporesHoraHasta"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                type="time"
                value={item.tiporesDias}
                name="tiporesDias"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
                select
                MenuProps={MenuProps}
                SelectProps={{
                  displayEmpty: true,
                  multiple: true,
                  renderValue: (selected) => {
                    return selected
                      .map((value, index) => {
                        return value;
                      })
                      .join(", ");
                  },
                }}
              >
                <MenuItem disabled value="">
                  Seleccione.
                </MenuItem>
                {[
                  "LUNES",
                  "MARTES",
                  "MIERCOLES",
                  "JUEVES",
                  "VIERNES",
                  "SABADO",
                  "DOMINGO",
                ].map((dia) => {
                  return (
                    <MenuItem
                      style={getStylesMenuItems(dia, item.tiporesDias, theme)}
                      value={dia}
                    >
                      {dia}
                    </MenuItem>
                  );
                })}
              </TextField>
            </TableCell>
          </>
        ) : (
          <>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {row.tiporesNombre}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {row.tiporesHoraDesde}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {row.tiporesHoraHasta}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {JSON.parse(row?.tiporesDias).join(", ")}
            </TableCell>
          </>
        )}
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {new Date(row.tiporesFecReg).toLocaleString("es-ES", {
            weekday: "short",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour: "2-digit",
          })}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
