import { makeStyles } from "@mui/styles";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  TableCell,
  Tooltip,
} from "@mui/material";

import {
  Cancel,
  Check,
  Create,
  Delete,
  Email,
  PersonPin,
  PictureAsPdf,
  WhatsApp,
} from "@mui/icons-material";
import React, { memo, useContext } from "react";
import { withRouter } from "react-router";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import { tienePermisoRuta } from "../../../../config/validaciones";
import AuthContext from "../../../../Context/Auth/AuthContext";
import MenuContext from "../../../../Context/Menu/MenuContext";
import { permisoDenagado, serverphpvet } from "../../../../config/const";
import clienteAxios from "../../../../config/axios";
import { ModalContactoPlantillaW } from "../../../components/Modal/ModalContactoPlantillaW";
import { useState } from "react";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const ButtonsAction = (props) => {
  const {
    arrayExport,
    setarrayExport,
    campoExport,
    row,
    setopenConfirmDialog,
    funcionEdit,
    ocultarEditar,
    ocultarDelete,
    mostrarEstado,
    campoEstado,
    editar,
    funcCancel,
    funcSubmit,
    botonDisabled,
    puedeExportar,
    desdeOtroLugar,
    changeObjetoActivoStatePropietario,
    setopenPropietarioModal,
    setopenModalEmail,
  } = props;
  const classes = useRowStyles();
  const { usuario } = useContext(AuthContext);
  const { rows } = useContext(MenuContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const funcionEditPropietario = async () => {
    try {
      const res = await clienteAxios(`/proveedores/${row.resPerId}`);
      if (res.data.data.length === 0) {
        return mostrarAlerta(
          "Hubo un error en la consulta del propietario",
          "error"
        );
      }
      changeObjetoActivoStatePropietario(res.data.data[0]);
      setopenPropietarioModal(true);
      // setopen(true);
      // seteditar(true);
      // changeObjetoActivoState(row);
    } catch (error) {
      mostrarAlerta("Hubo un error", "error");
    }
  };
  const [
    openModalContactoPlantillaW,
    setopenModalContactoPlantillaW,
  ] = useState(false);
  const contactoDefault = {
    medioComunicacion: "Whatsapp",
    option: "Reserva",
    idRegistro: row.resId,
    contacto: [],
    contenidoMensaje: "",
  };
  const [newContacto, setNewContacto] = useState(contactoDefault);

  return (
    <>
      {openModalContactoPlantillaW && (
        <ModalContactoPlantillaW
          personaId={row.resPerId}
          encabezado={[
            { label: "Documento Propietario:", value: row.resPerDocumento },
            {
              label: "Propietario:",
              value: row.resPerNombre,
            },
            {
              label: "Paciente:",
              value: row.resMascNombre,
            },
            {
              label: "#Carnet:",
              value: row.resMascId,
            },
          ]}
          row={row}
          open={openModalContactoPlantillaW}
          setopen={setopenModalContactoPlantillaW}
          newContacto={newContacto}
          setNewContacto={setNewContacto}
          contactoDefault={contactoDefault}
          objetoPlantilla={"empPlantillaReserva"}
        />
      )}
      {arrayExport ? (
        <TableCell
          size="small"
          padding="checkbox"
          style={{
            borderRight: "1px solid #ccc",
            maxWidth: "1rem",
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="center">
            <FormControlLabel
              className={classes.margin}
              control={
                <Checkbox
                  size="small"
                  disabled={!puedeExportar && desdeOtroLugar}
                  className={classes.margin}
                  checked={arrayExport.some((item) => {
                    return item[campoExport] === row[campoExport];
                  })}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setarrayExport([
                        ...arrayExport,
                        { [campoExport]: row[campoExport] },
                      ]);
                    } else {
                      setarrayExport(
                        arrayExport.filter(
                          (item) => item[campoExport] !== row[campoExport]
                        )
                      );
                    }
                  }}
                  color="secondary"
                />
              }
            />
          </Box>
        </TableCell>
      ) : null}
      <TableCell padding="none" style={{ borderRight: "1px solid #ccc" }}>
        <Box display="flex" alignItems="center">
          <>
            {editar ? (
              <>
                <IconButton
                  className={classes.margin}
                  onClick={() => {
                    funcCancel();
                  }}
                >
                  <Cancel fontSize="small" color="secondary" />
                </IconButton>
                <IconButton
                  className={classes.margin}
                  disabled={botonDisabled ? botonDisabled() : false}
                  onClick={() => {
                    funcSubmit();
                  }}
                >
                  <Check
                    fontSize="small"
                    color={
                      botonDisabled
                        ? botonDisabled()
                          ? "inherit"
                          : "secondary"
                        : "secondary"
                    }
                  />
                </IconButton>
              </>
            ) : (
              <>
                {ocultarDelete ? null : (
                  <IconButton
                    className={classes.margin}
                    onClick={() => {
                      if (
                        !tienePermisoRuta(
                          rows,
                          props.location.pathname,
                          usuario.rol,
                          "eliminar"
                        )
                      ) {
                        return mostrarAlerta(permisoDenagado, "error");
                      }
                      setopenConfirmDialog(true);
                    }}
                  >
                    <Delete fontSize="small" color="error" />
                  </IconButton>
                )}
                {ocultarEditar ? null : (
                  <IconButton
                    className={classes.margin}
                    onClick={() => {
                      if (
                        !tienePermisoRuta(
                          rows,
                          props.location.pathname,
                          usuario.rol,
                          "editar"
                        )
                      ) {
                        return mostrarAlerta(permisoDenagado, "error");
                      }
                      funcionEdit();
                    }}
                  >
                    <Create color="secondary" />
                  </IconButton>
                )}
                <Tooltip title="Editar Propietario" arrow>
                  <IconButton
                    disabled={row.resPerId === 0}
                    className={classes.margin}
                    onClick={() => {
                      funcionEditPropietario();
                    }}
                    color="primary"
                  >
                    <PersonPin />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Enviar Correos" arrow>
                  <IconButton
                    className={classes.margin}
                    onClick={() => {
                      setopenModalEmail(true);
                    }}
                  >
                    <Email color="secondary" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="PDF INFORME MASCOTA" arrow>
                  <IconButton
                    className={classes.margin}
                    onClick={() => {
                      // Abres la nueva ventas
                      window.open(
                        `${serverphpvet}reportes/informe_mascota.php?id=${row.resId}&empresa=${row.resEmpresa}&from=reserva`,
                        "_blank"
                      );
                    }}
                  >
                    <PictureAsPdf color="secondary" />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </>
          {/* <Tooltip title="PDF" arrow>
            <IconButton
              className={classes.margin}
              size="small"
              onClick={() => {
                // Abres la nueva ventas
                window.open(
                  `${serverphpvet}reportes/historiaIndividual.php?id=${row.hcliId}&empresa=${row.hcliEmpresa}`,
                  "_blank"
                );
              }}
            >
              <PictureAsPdf color="error" fontSize="small" />
            </IconButton>
          </Tooltip> */}
          {/* <Tooltip title={expandir ? "Contraer" : "Expandir"} arrow>
            <IconButton
              className={classes.margin}
              onClick={() => {
                setexpandir(!expandir);
              }}
            >
              {expandir ? (
                <ExpandLess color="primary" />
              ) : (
                <ExpandMore color="primary" />
              )}
            </IconButton>
          </Tooltip> */}
          <Tooltip title="Mensaje whatsapp" arrow>
            <IconButton
              className={classes.margin}
              onClick={() => {
                setopenModalContactoPlantillaW(true);
              }}
            >
              <WhatsApp color="success" />
            </IconButton>
          </Tooltip>
          {mostrarEstado ? (
            <FormControlLabel
              disabled
              className={classes.margin}
              control={
                <Checkbox
                  value="ACTIVO"
                  className={classes.margin}
                  checked={
                    row[campoEstado] === "ACTIVA" ||
                    row[campoEstado] === "ACTIVO"
                      ? true
                      : false
                  }
                  color="primary"
                />
              }
            />
          ) : null}
        </Box>
      </TableCell>
    </>
  );
};

export default withRouter(memo(ButtonsAction));
