import React, { useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import IconButton from "@mui/material/IconButton";
import {
  ChangeCircle,
  FoodBankTwoTone,
  Image,
  OpenInBrowser,
  Save,
} from "@mui/icons-material";
import { useModalStyle } from "../../../styles/styleModal";
import {
  Box,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  FormControlLabel,
  Radio,
  FormLabel,
  Typography,
  Tooltip,
} from "@mui/material";

export const Firma = ({ setitem, propiedad, valor, label }) => {
  let canvasRef;
  let [cBase64, setBase64] = useState("");
  console.log({ valor });
  const clases = useModalStyle();
  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        width={"100%"}
        alignItems={"center"}
      >
        <Typography variant="body1" color="secondary">
          {label}
          {valor === "" && (cBase64 === "" || cBase64 !== "") && " - Editando"}
        </Typography>
        <Box>
          {valor === "" && cBase64 !== "" ? (
            <Tooltip title="Guardar firma">
              <IconButton
                aria-label=""
                onClick={() => {
                  setitem((prev) => ({ ...prev, [propiedad]: cBase64 }));
                }}
                sx={{ padding: ".3rem" }}
              >
                <Save />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Rehacer firma">
              <IconButton
                color="error"
                onClick={() => {
                  setitem((prev) => ({ ...prev, [propiedad]: "" }));
                  setBase64("");
                }}
                aria-label={`info about ${label}`}
              >
                <ChangeCircle />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
      {valor === "" ? (
        <Box className={clases.formD} sx={{ border: "1px solid #ccc" }}>
          <SignatureCanvas
            ref={(ref) => {
              console.log({ ref });
              return (canvasRef = ref);
            }}
            onEnd={(e) => {
              try {
                console.log(canvasRef.isEmpty());
                const res = canvasRef.toDataURL();
                setBase64(res);
              } catch (error) {
                console.log({ error });
              }
            }}
            canvasProps={{
              className: clases.formAllWidth,
            }}
            clearOnResize={false}
          />
        </Box>
      ) : (
        <>
          <ImageListItem className={clases.formD}>
            <img src={valor} />
          </ImageListItem>
        </>
      )}
    </>
  );
};
