import React, { useState, useContext, useEffect, memo } from "react";
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Box,
  Divider,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Dialog,
} from "@mui/material";

import Draggable from "react-draggable";

import { Cancel, PictureAsPdf, VideoLibrary } from "@mui/icons-material";
// eslint-disable-next-line

import MenuContext from "../../../Context/Menu/MenuContext";

function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",

    overflow: "scroll",
    maxHeight: "80vh",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",

    width: "45%",
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    marginTop: "8vh",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginTop: "2vh",
    },
  },
  formD: {
    flexGrow: 1,
    margin: theme.spacing(0.5),
    width: "45%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "95%",
    [theme.breakpoints.down("md")]: { padding: "0", margin: "2px" },
    [theme.breakpoints.down("sm")]: { padding: "0", margin: "2px" },
  },
  ocultar: {
    display: "none",
  },
}));

const ModalAsignatura = (props) => {
  const {
    categoriaActiva,
    changeCategoriaActiva, // eslint-disable-next-line
  } = useContext(MenuContext);

  // eslint-disable-next-line

  //props
  const { open, setopen } = props;

  const [categoria, setcategoria] = useState(categoriaActiva);
  const { tutoriales, component } = categoria.sidInfo;

  useEffect(() => {
    setcategoria(categoriaActiva);
  }, [categoriaActiva]);

  const defaultActive = () => {
    changeCategoriaActiva({
      sidId: 0,
      sidInfo: {
        orden: 1,
        path: "",
        name: "",
        icon: "",
        component: "",
        layout: "",
        tipo: "",
        ruta: false,
        expandible: true,
        permisos: ["USUARIO"],
        agregar: ["USUARIO"],
        editar: ["USUARIO"],
        ver: ["USUARIO"],
        eliminar: ["USUARIO"],
        impExp: ["USUARIO"],
        categoriaEmpresa: [],
        tutoriales: [],
      },
    });
    setopen(false);
  };

  //hook de estilos
  const clases = useStyles();
  //definiendo los estilos del modal
  const [modalStyle] = useState(getModalStyle);
  const [openDialog, setopenDialog] = useState(false);
  const [urlVideo, seturlVideo] = useState("");
  if (!open) {
    return null;
  }
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={openDialog}
        onClose={() => setopenDialog(false)}
        aria-labelledby="draggable-dialog-title"
      >
        {urlVideo === "" || !openDialog ? null : (
          <iframe
            height="500"
            title="YouTube video player"
            //  https://youtu.be/xcJtL7QggTI
            //
            src={`${urlVideo.split("/")[0]}/${
              urlVideo.split("/")[1]
            }/www.youtube.com/embed/${urlVideo.split("/")[3]}`}
            allowfullscreen="allowfullscreen"
            mozallowfullscreen="mozallowfullscreen"
            msallowfullscreen="msallowfullscreen"
            oallowfullscreen="oallowfullscreen"
            webkitallowfullscreen="webkitallowfullscreen"
          ></iframe>
        )}
      </Dialog>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <Draggable handle="#handle">
            <Box display="flex" justifyContent="center">
              <div style={modalStyle} className={clases.paper}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                  style={{ cursor: "-webkit-grab" }}
                >
                  <Typography
                    id="handle"
                    variant="button"
                    color="initial"
                    align="center"
                  >
                    {component}
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <IconButton
                      aria-label=""
                      style={{ margin: "0px" }}
                      onClick={() => {
                        defaultActive();
                      }}
                    >
                      <Cancel color="secondary" />
                    </IconButton>
                  </Box>
                </Box>
                <Divider />
                <Divider />
                <List
                  sx={{
                    width: "100%",
                    flexGrow: "1",
                  }}
                >
                  {tutoriales.map((tutorial) => {
                    return tutorial.urlVideo.split("/")[2] === "youtu.be" ? (
                      <ListItem
                        component={"button"}
                        onClick={() => {
                          seturlVideo(tutorial.urlVideo);
                          setopenDialog(true);
                        }}
                      >
                        <ListItemAvatar>
                          <VideoLibrary color="secondary" />
                        </ListItemAvatar>

                        <ListItemText
                          primary={tutorial.nombreVideo}
                          secondary={tutorial.descVideo}
                        />
                      </ListItem>
                    ) : (
                      <ListItem
                        component={"button"}
                        onClick={() => {
                          window.open(tutorial.urlVideo);
                        }}
                      >
                        <ListItemAvatar>
                          <PictureAsPdf color="error" />
                        </ListItemAvatar>

                        <ListItemText
                          primary={tutorial.nombreVideo}
                          secondary={tutorial.descVideo}
                        />
                      </ListItem>
                    );
                  })}
                </List>
                <Divider />
                <Divider />
              </div>
            </Box>
          </Draggable>
        </Box>
      </Modal>{" "}
    </>
  );
};

export default memo(ModalAsignatura);
