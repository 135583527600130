import React, { memo, useContext, useState } from "react";
import { makeStyles } from "@mui/styles";
import { DeleteForever, PictureAsPdf, Restore } from "@mui/icons-material";
import {
  Box,
  TextField,
  MenuItem,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  DialogTitle,
  Dialog,
} from "@mui/material";
import AuthContext from "../../../../Context/Auth/AuthContext";
import Cargando from "../../../../components/Extra/Cargando";
import { usePermiso } from "../../../../hooks/usePermiso";
import ConfirmacionDialog from "../../../../components/Extra/ConfirmacionDialog";
const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  delete: {
    backgroundColor: theme.palette.error.main,
  },
  bg: {
    backgroundColor: theme.palette.primary.dark,
  },
  success: {
    backgroundColor: theme.palette.success.main,
  },
}));
const ExportDialog = (props) => {
  const { tienePermiso, alertaPermiso } = usePermiso("Hospitalizacion");
  const classes = useStyles();
  const { usuario } = useContext(AuthContext);
  const {
    open,
    setopen,
    funcPdf,
    arrayExport,
    tipo,
    settipo,
    campoExport,
    tabla,
    socket,
    eliminadoEstado,
  } = props;
  const [cargando, setcargando] = useState(false);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const handleEliminar = async () => {
    if (tienePermiso && !tienePermiso("eliminar")) {
      return alertaPermiso("eliminar");
    }
    await setcargando(true);

    await setTimeout(async () => {
      const lista = arrayExport.map((item) => {
        return item[campoExport];
      });

      socket.current.emit("client:eliminarDataSeleccionado", {
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        tabla,
        lista,
        restaurado: eliminadoEstado,
      });
      setcargando(false);
      setopen(false);
    }, 500);
  };
  return (
    <>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo={
          eliminadoEstado
            ? `Esta seguro de recuperar estos registros`
            : `¿Esta seguro de eliminar estos registros?`
        }
        funcion={handleEliminar}
        categoria={eliminadoEstado ? "success" : null}
      />
      <Dialog
        onClose={() => setopen(false)}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <Box padding={2}>
          <DialogTitle id="simple-dialog-title">Opciones.</DialogTitle>
          <TextField
            id=""
            label=""
            fullWidth
            value={tipo}
            onChange={(e) => {
              settipo(e.target.value);
            }}
            select
          >
            {[
              { value: "seleccion", label: "Seleccion" },
              { value: "todos", label: "Todos" },
            ].map((item) => {
              return <MenuItem value={item.value}>{item.label}</MenuItem>;
            })}
          </TextField>
          {cargando ? (
            <Cargando titulo="Cargando." />
          ) : (
            <>
              {eliminadoEstado ? (
                <List>
                  <ListItem
                    button
                    disabled={cargando}
                    onClick={async () => {
                      setopenConfirmDialog(true);
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar className={classes.success}>
                        <Restore />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Recuperar" />
                  </ListItem>{" "}
                </List>
              ) : (
                <List>
                  <ListItem
                    button
                    disabled={cargando}
                    onClick={async () => {
                      await setcargando(true);

                      await setTimeout(async () => {
                        await funcPdf();

                        setcargando(false);
                        setopen(false);
                      }, 500);
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar className={classes.avatar}>
                        <PictureAsPdf />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Exportar" />
                  </ListItem>{" "}
                  <ListItem
                    button
                    disabled={cargando}
                    onClick={async () => {
                      setopenConfirmDialog(true);
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar className={classes.delete}>
                        <DeleteForever />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Eliminar" />
                  </ListItem>{" "}
                </List>
              )}
            </>
          )}
        </Box>
      </Dialog>{" "}
    </>
  );
};

export default memo(ExportDialog);
