import React, { memo, useContext } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  FormControl,
  Autocomplete,
} from "@mui/material";

import { Cancel, Check } from "@mui/icons-material";

import { useState } from "react";
import AlertaContext from "../../../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../../../Context/Auth/AuthContext";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
  celda: {
    fontSize: ".7rem !important",
  },
}));

const RowAgregar = (props) => {
  const {
    planDeCuentas,
    setagregar,
    setcontabilidadData,
    contabilidadData,
    codigo,
  } = props;
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext);
  const [item, setitem] = useState({
    contaId: 0,
    contaCodigo: "",
    contaTipo: "COMPRA",
    contaRuc: "",
    contaNombre: "",
    contaDocumento: "",
    contaFechaDoc: "",
    contaCuenta: "",
    contaCuentaNombre: "",
    contaObservacion: "",
    contaDebe: "",
    contaHaber: "",
    contaEstado: "ACTIVO",
    contaUser: "",
    contaEmpresa: "",
    contaFecReg: "",
    contaOption: "AJUSTES",
    Todos: "",
  });
  const {
    contaTipo,
    contaRuc,
    contaNombre,
    contaFechaDoc,
    contaCuenta,
    contaCuentaNombre,
    contaObservacion,
    contaDebe,
    contaHaber,
    contaDocumento,
  } = item;
  const classes = useRowStyles();
  //gql eliminar user

  const handleAgregar = async () => {
    try {
      const res = [
        {
          ...item,
          contaEmpresa: usuario.rucempresa,
          contaUser: usuario.usuario,
          contaCodigo: codigo,
        },
        ...contabilidadData,
      ];
      setcontabilidadData(res);
    } catch (error) {
      mostrarAlerta("Hubo un error");
       
    }
  };
  const handleChange = (e) => {
    setitem({
      ...item,
      [e.target.name]: e.target.value,
    });
  };
  const botonDisabled = () => {
    if (
      contaTipo.trim() === "" ||
      contaFechaDoc.trim() === "" ||
      contaCuenta.trim() === "" ||
      contaDebe.trim() === "" ||
      contaHaber.trim() === ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell padding="none" style={{ borderRight: "1px solid #ccc" }}>
          <Box display="flex" alignItems="center">
            <IconButton
              className={classes.margin}
              onClick={() => {
                setagregar(false);
                setitem({
                  contaId: 0,
                  contaCodigo: "",
                  contaTipo: "COMPRA",
                  contaRuc: "",
                  contaNombre: "",
                  contaDocumento: "",
                  contaFechaDoc: "",
                  contaCuenta: "",
                  contaCuentaNombre: "",
                  contaObservacion: "",
                  contaDebe: "",
                  contaHaber: "",
                  contaEstado: "ACTIVO",
                  contaUser: "",
                  contaEmpresa: "",
                  contaFecReg: "",
                  contaOption: "AJUSTES",
                  Todos: "",
                });
              }}
            >
              <Cancel fontSize="small" color="secondary" />
            </IconButton>
            <IconButton
              color={
                botonDisabled
                  ? botonDisabled()
                    ? "inherit"
                    : "secondary"
                  : "secondary"
              }
              disabled={botonDisabled()}
              className={classes.margin}
              onClick={() => {
                handleAgregar();

                setagregar(false);
                setitem({
                  contaId: 0,
                  contaCodigo: "",
                  contaTipo: "COMPRA",
                  contaRuc: "",
                  contaNombre: "",
                  contaDocumento: "",
                  contaFechaDoc: "",
                  contaCuenta: "",
                  contaCuentaNombre: "",
                  contaObservacion: "",
                  contaDebe: "",
                  contaHaber: "",
                  contaEstado: "ACTIVO",
                  contaUser: "",
                  contaEmpresa: "",
                  contaFecReg: "",
                  contaOption: "AJUSTES",
                  Todos: "",
                });
              }}
            >
              <Check fontSize="small" />
            </IconButton>
          </Box>
        </TableCell>
        {/* las demas columnas */}
        <>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              size="small"
              fullWidth
              value={contaTipo}
              name="contaTipo"
              onChange={(e) => handleChange(e)}
            />
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              size="small"
              fullWidth
              value={contaRuc}
              name="contaRuc"
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              size="small"
              fullWidth
              value={contaNombre}
              name="contaNombre"
              onChange={(e) => handleChange(e)}
            />
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              size="small"
              fullWidth
              value={contaDocumento}
              name="contaDocumento"
              onChange={(e) => handleChange(e)}
            />
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              type="date"
              size="small"
              fullWidth
              value={contaFechaDoc}
              name="contaFechaDoc"
              onChange={(e) => handleChange(e)}
            />
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <FormControl fullWidth>
              <Autocomplete
                options={planDeCuentas.map((item) => {
                  return item.plancCodigo + "/+/" + item.plancNombre;
                })}
                getOptionLabel={(option) => {
                  return option.replace("/+/", " ");
                }}
                id="controlled-demo"
                value={
                  (contaCuenta + " " + contaCuentaNombre).trim() === ""
                    ? ""
                    : contaCuenta + " " + contaCuentaNombre
                }
                paperprops
                onChange={async (event, newValue) => {
                  if (newValue) {
                    const options = newValue.split("/+/");
                    setitem({
                      ...item,
                      contaCuenta: options[0],
                      contaCuentaNombre: options[1],
                    });
                  }
                }}
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    fullWidth
                    onChange={async (e) => {
                      if (e.target.value === "") {
                        return mostrarAlerta("Ingrese una cuenta", "error");
                      }
                      try {
                        // setproveedoresData(res.data.data);
                      } catch (error) {}
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              type="number"
              size="small"
              fullWidth
              value={contaDebe}
              name="contaDebe"
              onChange={(e) => handleChange(e)}
            />
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              type="number"
              size="small"
              fullWidth
              value={contaHaber}
              name="contaHaber"
              onChange={(e) => handleChange(e)}
            />
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              size="small"
              fullWidth
              value={contaObservacion}
              name="contaObservacion"
              onChange={(e) => handleChange(e)}
            />
          </TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregar);
