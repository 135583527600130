import * as IconosImport from "@mui/icons-material";
export const secondary = "#263238";
export const serverphp = "https://syscontable.neitor.com/";
export const serverphpvet = "https://sysvet.neitor.com/";
export const socketUrl = "https://testconta.neitor.com/";
export const minFecha = new Date().toISOString().split("T")[0];

export const columnsA = [
  {
    id: "lectivo",
    label: "Lectivo",
    minWidth: 100,
    campo: "lectivo",
  },
  {
    id: "modalidad",
    label: "Modalidad",
    minWidth: 100,
    campo: "modalidad",
  },
  {
    id: "jornada",
    label: "Jornada",
    minWidth: 100,
    campo: "jornada",
  },
  {
    id: "nivel",
    label: "Nivel",
    minWidth: 100,
    campo: "nivel",
  },
  {
    id: "curso",
    label: "Curso",
    minWidth: 100,
    campo: "curso",
  },
  {
    id: "asignatura",
    label: "Asignatura",
    minWidth: 150,
    campo: "nombre",
  },
  {
    id: "especialidad",
    label: "Especialidad",
    minWidth: 100,
    campo: "especialidad",
  },
  {
    id: "horas",
    label: "Horas",
    minWidth: 100,
    campo: "hora",
  },
  {
    id: "paralelo",
    label: "Paralelo",
    minWidth: 100,
    campo: "paralelo",
    array: true,
  },
  {
    id: "total",
    label: "Total",
    minWidth: 100,
    campo: "total",
  },
];

export const fecha = `${new Date().getFullYear()}-${(
  "0" +
  (new Date().getMonth() + 1)
).slice(-2)}-${("0" + new Date().getDate()).slice(-2)}`;

export const permisoDenagado = "No tienes permiso para esta accion";

export const ordenar = (orden, desc) => (a, b) => {
  const { propiedad, campo2, campo3, campo4, tipo, profundidad } = orden;

  if (profundidad === 1) {
    if (tipo === "string") {
      return desc
        ? a[propiedad].localeCompare(b[propiedad])
        : b[propiedad].localeCompare(a[propiedad]);
    } else if (tipo === "numero") {
      return desc ? b[propiedad] - a[propiedad] : a[propiedad] - b[propiedad];
    }
  }
  if (profundidad === 2) {
    if (!a[propiedad][campo2]) {
      return desc ? 1 : -1;
    } else if (!b[propiedad][campo2]) {
      return desc ? -1 : 1;
    }
    if (tipo === "string") {
      return desc
        ? a[propiedad][campo2].localeCompare(b[propiedad][campo2])
        : b[propiedad][campo2].localeCompare(a[propiedad][campo2]);
    } else if (tipo === "numero") {
      return desc
        ? b[propiedad][campo2] - a[propiedad][campo2]
        : a[propiedad][campo2] - b[propiedad][campo2];
    }
  }
  if (profundidad === 3) {
    if (tipo === "string") {
      return desc
        ? a[propiedad][campo2][campo3].localeCompare(
            b[propiedad][campo2][campo3]
          )
        : b[propiedad][campo2][campo3].localeCompare(
            a[propiedad][campo2][campo3]
          );
    } else if (tipo === "numero") {
      return desc ? b[propiedad] - a[propiedad] : b[propiedad] - a[propiedad];
    }
  }
  if (profundidad === 4) {
    if (tipo === "string") {
      return desc
        ? a[propiedad][campo2][campo3][campo4].localeCompare(
            b[propiedad][campo2][campo3][campo4]
          )
        : b[propiedad][campo2][campo3][campo4].localeCompare(
            a[propiedad][campo2][campo3][campo4]
          );
    } else if (tipo === "numero") {
      return desc
        ? b[propiedad][campo2][campo3][campo4] -
            a[propiedad][campo2][campo3][campo4]
        : a[propiedad][campo2][campo3][campo4] -
            b[propiedad][campo2][campo3][campo4];
    }
  }
};

const reemplazarEspacios = (string) => {
  return string.replace(/\s+/g, " ");
};
export const replaceCaracteres = (string) => {
  try {
    let res = string.replace(/(\r\n|\n|\r)/gm, " ");
    res = res.replace(/(["]+)/gm, "");
    res = res.replace(/([']+)/gm, "");
    res = res.replace(/([&]+)/gm, "");
    res = reemplazarEspacios(res);
    return res;
  } catch (error) {}
};

export const trimPropsItem = (item) => {
  const replaceCaracteres = (string) => {
    try {
      let res = string.replace(/(\r\n|\n|\r)/gm, " ");
      res = res.replace(/(["]+)/gm, "");
      res = res.replace(/([']+)/gm, "");
      res = res.replace(/([&]+)/gm, "");
      res = reemplazarEspacios(res);
      res = res.replace(/\s+/g, " ");
      return res;
    } catch (error) {}
  };

  const json = item;
  Object.entries(item).forEach((itemProps) => {
    if (typeof itemProps[1] === "string") {
      const trimString = itemProps[1].trim();
      json[itemProps[0]] = replaceCaracteres(trimString);
    }
  });

  return json;
};
export const hacerIcono = (icono, color = "inherit", fontSize) => {
  if (icono === "" || !icono) {
    return;
  }
  const Icono = IconosImport[icono];
  if (!Icono) {
    return null;
  }
  return <Icono color={color} style={{ fontSize }} />;
};
export const FechaExacta = new Date(
  Date.now() - new Date().getTimezoneOffset() * 60 * 1000
)
  .toISOString()
  .split("T")[0];
export const FechaExactaConMinutos = new Date(
  Date.now() - new Date().getTimezoneOffset() * 60 * 1000
)
  .toISOString()
  .substring(0, 16);
