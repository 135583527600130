import React, { useContext, useEffect, memo } from "react";
import { makeStyles } from "@mui/styles";
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
} from "@mui/material";

import { withRouter } from "react-router-dom";
// import Spinner from "../../../Spinner/Spinner";

import Row from "./Row";
import Cargando from "../../../../../components/Extra/Cargando";
import ToolBarTable from "./ToolBarTable";
import TablaCabecera from "./TablaCabecera";

import AuthContext from "../../../../../Context/Auth/AuthContext";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 600,
  },
});
const Tabla = (props) => {
  const {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    setopen,
    rows,
    seteditar,
    search,
    input,
    orden,
    setinput,
    setorden,
    cargando,
    setcargando,
    fullBuscador,
    estadoRequest,
    // STATE
    addRowsState,
    getDataState,
    remplazarRowsState,
    obtenerState,
    pagination,
    columns,
    // ObjectoActivo,
    changeObjetoActivoState,
    rowsImportState,
    // export
    arrayExport,
    setarrayExport,
    campoExport,
    socket,
    desdeOtroLugarMenu,
    rolesSeleccionados,
  } = props;

  const {
    usuario: { rucempresa },
  } = useContext(AuthContext);
  //columnas y rows de la tabla

  // eslint-disable-next-line
  const clases = useStyles();
  // eslint-disable-next-line

  // eslint-disable-next-line
  // eslint-disable-next-line
  // eslint-disable-next-line

  //clases
  const classes = useStyles();

  const handleChangePage = async (event, newPage) => {
    if (newPage * rowsPerPage + rowsPerPage > rows.length) {
      setcargando(true);
      const res = await getDataState(
        rowsPerPage,
        newPage,
        search,
        input.id,
        orden,
        JSON.stringify(fullBuscador),
        rucempresa,
        estadoRequest
      );

      const data = res.data.data.results;

      setcargando(false);
      const resultado = data.filter((row) => {
        const existe = rows.some((item, index) => {
          return row.perId === item.perId;
        });

        return !existe;
      });

      addRowsState(resultado);
    }

    setPage(newPage);
  };

  // eslint-disable-next-line

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    if (page === 0) {
      if (event.target.value > rows.length) {
        obtenerState(
          event.target.value,
          0,
          search,
          input.id,
          orden,
          JSON.stringify(fullBuscador),
          rucempresa,
          estadoRequest
        );
      }
    }
  };
  const funcionOrdenar = async (column) => {
    setcargando(true);
    await remplazarRowsState(
      rowsPerPage,
      0,
      search,
      column.id,
      !orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest
    );
    setPage(0);
    setinput(column);
    setorden(!orden);
    setcargando(false);
  };
  const funcionReload = async () => {
    setRowsPerPage(10);
    setPage(0);
    await obtenerState(
      10,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest
    );
  };

  useEffect(() => {
    //eslint-disable-next-line

    obtenerState(
      10,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest
    );

    // eslint-disable-next-line
  }, []);
  //si no hay usuarios //rows no cargar el componente
  if (!rows) {
    return <Cargando titulo="Cargando" />;
  }
  if (!pagination) {
    return <Cargando titulo="Cargando" />;
  }

  return (
    <Paper className={classes.root}>
      <ToolBarTable
        search={search}
        titulo={estadoRequest === "CLIENTE" ? "Clientes" : "Proveedores"}
        estadoRequest={estadoRequest}
        arrayExport={arrayExport}
        columns={columns}
        expandible={false}
        campoExport={campoExport}
        tabla="proveedor"
        funcionReload={funcionReload}
        pagination={pagination}
        rowsPerPage={rowsPerPage}
        rows={rows}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        fullBuscador={fullBuscador}
        socket={socket}
        desdeOtroLugarMenu={desdeOtroLugarMenu}
        rolesSeleccionados={rolesSeleccionados}
        rowsImportState={rowsImportState}
      />
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" id="tabla" size="small">
          {/* <ModalCarnet setopen={setopen} onChangeCarnetActivo={onChangeCarnetActivo}/> */}
          <TableHead>
            <TablaCabecera
              remplazarRowsState={remplazarRowsState}
              search={search}
              input={input}
              setinput={setinput}
              orden={orden}
              setorden={setorden}
              rowsPerPage={rowsPerPage}
              page={page}
              setPage={setPage}
              setcargando={setcargando}
              rows={rows}
              setarrayExport={setarrayExport}
              columns={columns}
              habilitarOrdenar={true}
              funcionOrdenar={funcionOrdenar}
            />
          </TableHead>
          <TableBody>
            {cargando ? (
              <TableCell
                size="small"
                align="center"
                colSpan={columns.length + 2}
              >
                <Cargando titulo="Cargando" />
              </TableCell>
            ) : (
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <Row
                      campoExport={campoExport}
                      rows={rows}
                      seteditar={seteditar}
                      arrayExport={arrayExport}
                      setarrayExport={setarrayExport}
                      row={row}
                      index={index}
                      setopen={setopen}
                      key={row.perId}
                      estadoRequest={estadoRequest}
                      changeObjetoActivoState={changeObjetoActivoState}
                      socket={socket}
                      desdeOtroLugarMenu={desdeOtroLugarMenu}
                    />
                  );
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
export default memo(withRouter(Tabla));
