import React, { memo, useContext } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

import { useState } from "react";
import { withRouter } from "react-router";
import AuthContext from "../../../Context/Auth/AuthContext";
import { Cancel, Create, DeleteForever, Restore } from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../../styles/stylesRows";
import { usePermiso } from "../../../hooks/usePermiso";
import { trimPropsItem } from "../../../config/const";
import ConfirmacionDialog from "../../components/Dialogs/ConfirmacionDialog";
import ButtonsAction from "../../components/Tablas/ButtonsAction";

const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const {
    arrayExport,
    setarrayExport,
    row,
    index,
    campoExport,
    seteditar,
    setopen,
    changeObjetoActivoState,
    socket,
    // ObjectoActivoFactura,
  } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    row.editado === true
      ? classes.celdaEditado
      : row.nuevo === true
      ? classes.celdaNuevo
      : row.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;
  // HOOK DE PERMISO
  const { tienePermiso, alertaPermiso } = usePermiso("Noticias");
  // CONTEXT
  const { usuario } = useContext(AuthContext);
  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const [openDialogLeidos, setopenDialogLeidos] = useState(false);
  // ELIMINAR EL REGISTRO
  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        tabla: "noticias",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        noti_user: usuario.usuario,
        noti_empresa: usuario.rucempresa,
        [campoExport]: row[campoExport],
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };
  // FUNCION PARA EDITAR, ABRE EL MODAL COLOCA EN EDITAR EL MODAL Y CAMBIA EL OBJETO ACTIVO
  const funcionEdit = () => {
    setopen(true);
    seteditar(true);
    changeObjetoActivoState(row);
  };
  const funcRecuperar = () => {
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...row,

          tabla: "noticias",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          noti_user: usuario.usuario + "/+/" + row[campoExport],
          noti_empresa: usuario.rucempresa,
          [campoExport]: row[campoExport],
        })
      );

      seteditar(false);
    } catch (error) {}
  };
  return (
    <React.Fragment>
      {openDialogLeidos ? (
        <Dialog
          open={openDialogLeidos}
          onClose={() => setopenDialogLeidos(false)}
          scroll={"paper"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <Box>
            <DialogTitle
              id="scroll-dialog-title"
              sx={{ padding: ".5rem" }}
              align="center"
            >
              Leidos
            </DialogTitle>
            <DialogContent dividers sx={{}}>
              {row.conLeidos.map((item, index) => {
                return (
                  <DialogContentText
                    id="scroll-dialog-description"
                    tabIndex={-1}
                    mb={1}
                  >
                    <Typography variant="body1" color="primary">
                      {index + 1} - {item.docnumero} {item.nombres}
                    </Typography>
                    <Typography variant="body2" color="secondary">
                      {item.fecha} - {item.rol.join(" - ")}
                    </Typography>
                  </DialogContentText>
                );
              })}
            </DialogContent>
            <DialogActions>
              <Button fullWidth color="primary" variant="contained">
                OK
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      ) : null}

      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow className={classes.root}>
        {/* boton */}
        <ButtonsAction
          claseCelda={claseCelda}
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          campoExport={campoExport}
          row={row}
          botones={[
            {
              tooltip: "Recuperar",
              texto: "",
              funcion: () => {
                if (!tienePermiso("agregar")) {
                  return alertaPermiso("agregar");
                }
                funcRecuperar(true);
              },
              disabled: usuario.usuario !== row.noti_user.split("/+/")[0],
              Icon: Restore,
              color: "success",
              id: 3,
              ocultar: !row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Editar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("editar")) {
                  return alertaPermiso("editar");
                }
                funcionEdit();
              },
              disabled: false,
              Icon: Create,
              color: "secondary",
              id: 1,
              ocultar: row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },

            {
              tooltip: "Eliminar",
              texto: "",
              funcion: () => {
                if (!tienePermiso("eliminar")) {
                  return alertaPermiso("eliminar");
                }
                setopenConfirmDialog(true);
              },
              disabled: false,
              Icon: DeleteForever,
              color: "error",
              id: 3,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
          ]}
        />

        <>
          <TableCell size="small" align="center" className={claseCelda}>
            {row.noti_dirigido}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {row.noti_tipo_servicio}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {row.noti_ciudad}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {row.noti_sector}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {row.noti_titulo}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {new Date(row.noti_fecha_inicio).toLocaleString("es-ES", {
              weekday: "short",
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {new Date(row.noti_fecha_fin).toLocaleString("es-ES", {
              weekday: "short",
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {row.noti_user.split("/+/")[0]}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {new Date(row.noti_fecupd).toLocaleString("es-ES", {
              weekday: "short",
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour: "2-digit",
            })}
          </TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
