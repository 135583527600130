import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Box, Typography, Card, Grid } from "@mui/material";
import { hacerIcono } from "../../config/const";
// import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "black",
    "&:hover": {
      color: "black",
      backgroundColor: "#ccc",
    },
  },
  margin: {
    margin: "15px",
  },
  caja: {
    [theme.breakpoints.up("xs")]: {
      flexBasis: "100%",
    },
    [theme.breakpoints.up("md")]: {
      flexBasis: "30%",
    },
    [theme.breakpoints.up("lg")]: {
      flexBasis: "45%",
    },
  },
  titulo: {
    [theme.breakpoints.down("md")]: {
      fontSize: "45px",
    },
  },
  subTitulo: {
    [theme.breakpoints.down("md")]: {
      fontSize: "9px",
    },
  },
}));
const Caja = (props) => {
  const { titulo, descripcion, enlace, icono, total } = props;
  const classes = useStyles();

  return (
    <Box m={1} flexGrow="1" className={classes.caja}>
      <Card sx={{ padding: "1rem" }} className={classes.root}>
        <Link to={enlace} className={classes.root}>
          <Grid container alignItems={"center"}>
            <Grid sx={12} sm={3} justifyContent={"center"}>
              {hacerIcono(icono, "primary", "40px")}
            </Grid>
            <Grid sx={12} sm={9} display={"flex"} justifyContent={"flex-end"}>
              <Box>
                {total ? (
                  <Typography variant="subtitle1" color="primary">
                    {titulo}
                    <Typography variant="subtitle1" color="secondary">
                      {total}
                    </Typography>
                  </Typography>
                ) : (
                  <Typography variant="subtitle1" color="primary">
                    {titulo}
                  </Typography>
                )}

                <Typography variant="subtitle2" component="p">
                  {descripcion}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Link>
      </Card>
    </Box>
  );
};

export default Caja;
