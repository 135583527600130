import React, { memo, useContext, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  TableCell,
  TableRow,
  TextField,
  MenuItem,
  InputAdornment,
} from "@mui/material";

import { useState } from "react";

// import ConfirmacionDialog from "../../Dialog/ConfirmacionDialog";

import ConfirmacionDialog from "../../../../components/Extra/ConfirmacionDialog";

import { withRouter } from "react-router";
import ButtonsAction from "./ButtonsAction";
import AuthContext from "../../../../Context/Auth/AuthContext";
import { tienePermisoRuta } from "../../../../config/validaciones";
import MenuContext from "../../../../Context/Menu/MenuContext";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import {
  permisoDenagado,
  replaceCaracteres,
  trimPropsItem,
} from "../../../../config/const";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  celda: {
    fontSize: ".7rem !important",
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const Row = (props) => {
  const { arrayExport, setarrayExport, row, index, campoExport, socket } =
    props;
  const { rows: rowsMenu } = useContext(MenuContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext);
  const classes = useRowStyles();
  const [item, setitem] = useState({ ...row });
  const [editar, seteditar] = useState(false);
  const handleEliminar = async (_id) => {
    if (
      !tienePermisoRuta(
        rowsMenu,
        props.location.pathname,
        usuario.rol,
        "eliminar"
      )
    ) {
      return mostrarAlerta(permisoDenagado, "error");
    }
    try {
      socket.current.emit("client:eliminarData", {
        tabla: "plan",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        planUser: usuario.usuario,
        planEmpresa: usuario.rucempresa,
        planId: row.planId,
      });
    } catch (error) {}
  };
  const funcionEdit = () => {
    if (
      !tienePermisoRuta(
        rowsMenu,
        props.location.pathname,
        usuario.rol,
        "editar"
      )
    ) {
      return mostrarAlerta(permisoDenagado, "error");
    }
    seteditar(true);
  };

  const funcCancel = () => {
    setitem({ ...row });
    seteditar(false);
  };
  const funcSubmit = () => {
    try {
      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          tabla: "plan",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          planUser: usuario.usuario,
          planEmpresa: usuario.rucempresa,
        })
      );
      seteditar(false);
    } catch (error) {}
  };
  const botonDisabled = () => {
    if (
      item.planTipo.trim() === "" ||
      item.planNombre.trim() === "" ||
      item.planValor.trim() === ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {}, []);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo={`¿Esta seguro de eliminar este registro: ${row.planNombre}?`}
        funcion={handleEliminar}
      />

      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          row={row}
          setopenConfirmDialog={setopenConfirmDialog}
          funcionEdit={funcionEdit}
          ocultarEditar={false}
          ocultarDelete={false}
          mostrarEstado={false}
          campoExport={campoExport}
          campoEstado="secuEstado"
          editar={editar}
          funcCancel={funcCancel}
          funcSubmit={funcSubmit}
          botonDisabled={botonDisabled}
          item={item}
          setitem={setitem}
        />

        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              value={item.planTipo}
              inputProps={{ style: { textAlign: "center" } }}
              onChange={(e) => {
                const res = replaceCaracteres(e.target.value);
                setitem({
                  ...item,
                  planTipo: res,
                });
              }}
              helperText="Agregando"
              size="small"
              fullWidth
              select
              SelectProps={{
                displayEmpty: true,
              }}
              InputLabelProps={{ shrink: true }}
            >
              <MenuItem value="">Seleccione </MenuItem>;
              {["Home", "Empresarial", "Coorporativo"].map((item) => {
                return <MenuItem value={item}>{item}</MenuItem>;
              })}
            </TextField>
          ) : (
            `${item.planTipo}`
          )}
        </TableCell>
        <TableCell size="small" align="left" className={classes.celda}>
          {editar ? (
            <TextField
              value={item.planNombre}
              inputProps={{ style: { textAlign: "center" } }}
              onChange={(e) => {
                const res = replaceCaracteres(e.target.value);
                setitem({
                  ...item,
                  planNombre: res,
                });
              }}
              size="small"
              helperText="Editando"
              fullWidth
            />
          ) : (
            `${item.planNombre}`
          )}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              value={item.planValor}
              inputProps={{ style: { textAlign: "center" } }}
              onChange={(e) => {
                const res = replaceCaracteres(e.target.value);
                setitem({
                  ...item,
                  planValor: res,
                });
              }}
              size="small"
              helperText="Editando"
              fullWidth
            />
          ) : (
            `$${item.planValor}`
          )}
        </TableCell>

        <TableCell
          fontSize="small"
          padding="none"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">MB</InputAdornment>
                ),
              }}
              type="number"
              value={item.planMegas}
              inputProps={{ style: { textAlign: "center" } }}
              onChange={(e) => {
                const res = replaceCaracteres(e.target.value);
                setitem({
                  ...item,
                  planMegas: res,
                });
              }}
              helperText="Agregando"
              size="small"
              fullWidth
            />
          ) : (
            item.planMegas + " MB"
          )}
        </TableCell>
        <TableCell size="small" align="left" className={classes.celda}>
          {editar ? (
            <TextField
              multiline
              rows={1}
              value={item.planObservacion}
              inputProps={{ style: { textAlign: "center" } }}
              onChange={(e) => {
                const res = replaceCaracteres(e.target.value);
                setitem({
                  ...item,
                  planObservacion: res,
                });
              }}
              helperText="Agregando"
              size="small"
              fullWidth
            />
          ) : (
            item.planObservacion
          )}
        </TableCell>
        <TableCell
          fontSize="small"
          padding="none"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              disabled
              value={item.planUser}
              inputProps={{ style: { textAlign: "center" } }}
              onChange={(e) => {
                const res = replaceCaracteres(e.target.value);
                setitem({
                  ...item,
                  planUser: res,
                });
              }}
              helperText="Agregando"
              size="small"
              fullWidth
            />
          ) : (
            item.planUser
          )}
        </TableCell>
        <TableCell
          fontSize="small"
          padding="none"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              disabled
              value={item.planFecReg}
              inputProps={{ style: { textAlign: "center" } }}
              onChange={(e) => {
                const res = replaceCaracteres(e.target.value);
                setitem({
                  ...item,
                  planFecReg: res,
                });
              }}
              helperText="Agregando"
              size="small"
              fullWidth
            />
          ) : (
            new Date(item.planFecReg).toLocaleString("es-ES", {
              weekday: "short",
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour: "2-digit",
            })
          )}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
