import React from 'react'

const Liquidacion = () => {
  return (
    <div>
      Liquidacion
    </div>
  )
}

export default Liquidacion
