import React, { memo, useState } from "react";

// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Autocomplete,
  Box,
  FormControl,
  IconButton,
  Popper,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { Cancel, Check } from "@mui/icons-material";
import { v4 } from "uuid";
import clienteAxios from "../../../../../../config/axios";
import { useContext } from "react";
import AlertaContext from "../../../../../../Context/Alerta/AlertaContext";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));
const RowAgregarArma = (props) => {
  const { mostrarAlerta } = useContext(AlertaContext);
  const { setagregar, setitem, item } = props;
  const [parametrosHData, setparametrosHData] = useState([]);
  const [itemRow, setItemRow] = useState({
    order: "",
    parametro: "",
    dosis: "",
    frecuencia: "1",
    inicio: "0",
  });
  const PopperMy = function (props) {
    return (
      <Popper {...props} style={useRowStyles.popper} placement="bottom-start" />
    );
  };
  const handleChange = (e) => {
    setItemRow({ ...itemRow, [e.target.name]: e.target.value });
  };
  const botonDisabled = () => {
    if (itemRow.nombre === "" || itemRow.cantidad === "") {
      return true;
    } else {
      return false;
    }
  };

  const classes = useRowStyles();
  //gql eliminar user

  const handleAgregar = async () => {
    try {
      const order = v4();
      const hospParametros = {
        parametrosCabecera: [
          {
            ...itemRow,
            order,
          },
          ...item.hospParametros.parametrosCabecera,
        ],
        parametrosHorario: [
          {
            fecha: new Date(
              Date.now() - new Date().getTimezoneOffset() * 60 * 1000
            )
              .toISOString()
              .substr(0, 10),
            nameParametrosHorarios: itemRow.parametro,
            h0: "",
            h1: "",
            h2: "",
            h3: "",
            h4: "",
            h5: "",
            h6: "",
            h7: "",
            h8: "",
            h9: "",
            h10: "",
            h11: "",
            h12: "",
            h13: "",
            h14: "",
            h15: "",
            h16: "",
            h17: "",
            h18: "",
            h19: "",
            h20: "",
            h21: "",
            h22: "",
            h23: "",
            order,
          },
          ...item.hospParametros.parametrosHorario,
        ],
      };

      setitem({
        ...item,
        hospParametros,
      });
    } catch (error) {}
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <IconButton
              className={classes.margin}
              onClick={() => {
                setagregar(false);
                setItemRow({
                  order: "",
                  parametro: "",
                  dosis: "",
                  frecuencia: "1",
                  inicio: "0",
                });
              }}
            >
              <Cancel fontSize="small" color="secondary" />
            </IconButton>
            <IconButton
              className={classes.margin}
              disabled={botonDisabled()}
              color={botonDisabled() ? "secondary" : "inherit"}
              onClick={() => {
                handleAgregar();

                setagregar(false);
                setItemRow({
                  order: "",
                  parametro: "",
                  dosis: "",
                  frecuencia: "1",
                  inicio: "0",
                });
              }}
            >
              <Check fontSize="small" />
            </IconButton>{" "}
          </Box>
        </TableCell>
        {/* las demas columnas */}

        <>
          <TableCell
            checkbox
            size="small"
            align="center"
            className={classes.celda}
          >
            <FormControl fullWidth>
              <Autocomplete
                filterOptions={(x) => x}
                PopperComponent={PopperMy}
                options={parametrosHData.map((item) => {
                  return (
                    item.paramNombre +
                    "/+/" +
                    item.paramValor +
                    "/+/" +
                    item.paramId
                  );
                })}
                getOptionLabel={(option) => {
                  return option.replace("/+/", " ** ").split("/+/")[0];
                }}
                id="controlled-demo"
                value={itemRow.parametro}
                paperprops
                onChange={async (event, newValue) => {
                  if (newValue) {
                    const options = newValue.split("/+/");
                    const itemF = parametrosHData.find((itemPersona) => {
                      return itemPersona.paramId === Number(options[2]);
                    });

                    setItemRow({
                      ...itemRow,
                      parametro: itemF.paramNombre,
                      dosis: itemF.paramValor,
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    fullWidth
                    onChange={async (e) => {
                      if (e.target.value === "") {
                        return;
                      }
                      try {
                        const res = await clienteAxios.get(
                          `/parametros/filtro/0?search=${e.target.value}`
                        );
                        if (!res.data.data) {
                          return mostrarAlerta(res.data.msg, "error");
                        }

                        setparametrosHData(res.data.data);
                      } catch (error) {}
                    }}
                    // InputProps={{
                    //   ...params.InputProps,
                    //   endAdornment: (
                    //     <React.Fragment>
                    //       {!editar ? (
                    //         <IconButton
                    //           size="small"
                    //           aria-label=""
                    //           onClick={async () => {
                    //             seteditarProveedor(false);
                    //             setopenProveedor(true);
                    //           }}
                    //         >
                    //           <AddCircleOutlineTwoTone color="primary" />
                    //         </IconButton>
                    //       ) : null}
                    //       {params.InputProps.endAdornment}
                    //     </React.Fragment>
                    //   ),
                    // }}
                  />
                )}
              />
            </FormControl>
          </TableCell>
          <TableCell
            checkbox
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              name="dosis"
              value={itemRow.dosis}
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>

          <TableCell
            padding="checkbox"
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              type={"number"}
              name="inicio"
              value={itemRow.inicio}
              onChange={(e) => {
                setItemRow({
                  ...itemRow,
                  inicio:
                    e.target.valueAsNumber > 23
                      ? 23
                      : e.target.valueAsNumber < 0
                      ? 0
                      : e.target.valueAsNumber,
                });
              }}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell
            padding="checkbox"
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              type={"number"}
              name="frecuencia"
              value={itemRow.frecuencia}
              onChange={(e) => {
                setItemRow({
                  ...itemRow,
                  frecuencia:
                    e.target.valueAsNumber < 1
                      ? 1
                      : e.target.valueAsNumber > 23
                      ? 23
                      : e.target.valueAsNumber,
                });
              }}
              size="small"
              fullWidth
            />
          </TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregarArma);
