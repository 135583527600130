import React, { memo, useContext, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { TableCell, TableRow, TextField } from "@mui/material";

import { useState } from "react";

// import ConfirmacionDialog from "../../Dialog/ConfirmacionDialog";

import ConfirmacionDialog from "../../../../components/Extra/ConfirmacionDialog";

import { withRouter } from "react-router";
import ButtonsAction from "./ButtonsAction";
import AuthContext from "../../../../Context/Auth/AuthContext";
import { tienePermisoRuta } from "../../../../config/validaciones";
import MenuContext from "../../../../Context/Menu/MenuContext";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import {
  permisoDenagado,
  replaceCaracteres,
  trimPropsItem,
} from "../../../../config/const";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  celda: {
    fontSize: ".7rem !important",
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const Row = (props) => {
  const { arrayExport, setarrayExport, row, index, campoExport, socket } =
    props;
  const { rows: rowsMenu } = useContext(MenuContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext);
  const classes = useRowStyles();
  const [item, setitem] = useState({ ...row });
  const [editar, seteditar] = useState(false);
  const handleEliminar = async (_id) => {
    if (
      !tienePermisoRuta(
        rowsMenu,
        props.location.pathname,
        usuario.rol,
        "eliminar"
      )
    ) {
      return mostrarAlerta(permisoDenagado, "error");
    }
    try {
      socket.current.emit("client:eliminarData", {
        tabla: "formapago",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        fpagoUser: usuario.usuario,
        fpagoEmpresa: usuario.rucempresa,
        fpagoId: row.fpagoId,
      });
    } catch (error) {}
  };
  const funcionEdit = () => {
    if (
      !tienePermisoRuta(
        rowsMenu,
        props.location.pathname,
        usuario.rol,
        "editar"
      )
    ) {
      return mostrarAlerta(permisoDenagado, "error");
    }
    seteditar(true);
  };

  const funcCancel = () => {
    setitem({ ...row });
    seteditar(false);
  };
  const funcSubmit = () => {
    try {
      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          tabla: "formapago",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          fpagoUser: usuario.usuario,
          fpagoEmpresa: usuario.rucempresa,
        })
      );
      seteditar(false);
    } catch (error) {}
  };
  const botonDisabled = () => {
    if (
      item.fpagoNombre.trim() === "" ||
      item.fpagoDescripcion.trim() === "" ||
      item.fpagoCodigo.trim() === "" ||
      item.fpagoPorcentaje.trim() === ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {}, []);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo={`¿Esta seguro de eliminar este registro: ${row.fpagoNombre}?`}
        funcion={handleEliminar}
      />

      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          row={row}
          setopenConfirmDialog={setopenConfirmDialog}
          funcionEdit={funcionEdit}
          ocultarEditar={false}
          ocultarDelete={false}
          mostrarEstado={false}
          campoExport={campoExport}
          campoEstado="secuEstado"
          editar={editar}
          funcCancel={funcCancel}
          funcSubmit={funcSubmit}
          botonDisabled={botonDisabled}
          item={item}
          setitem={setitem}
        />

        <TableCell size="small" align="left" className={classes.celda}>
          {editar ? (
            <TextField
              value={item.fpagoNombre}
              onChange={(e) => {
                const res = replaceCaracteres(e.target.value);
                setitem({
                  ...item,
                  fpagoNombre: res,
                });
              }}
              size="small"
              helperText="Editando"
              fullWidth
            />
          ) : (
            `${row.fpagoNombre}`
          )}
        </TableCell>
        <TableCell size="small" align="left" className={classes.celda}>
          {editar ? (
            <TextField
              value={item.fpagoDescripcion}
              onChange={(e) => {
                const res = replaceCaracteres(e.target.value);
                setitem({
                  ...item,
                  fpagoDescripcion: res,
                });
              }}
              size="small"
              helperText="Editando"
              fullWidth
            />
          ) : (
            `${row.fpagoDescripcion}`
          )}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              type="number"
              value={item.fpagoCodigo}
              onChange={(e) => {
                const res = replaceCaracteres(e.target.value);
                setitem({
                  ...item,
                  fpagoCodigo: res,
                });
              }}
              size="small"
              helperText="Editando"
              fullWidth
            />
          ) : (
            `${row.fpagoCodigo}`
          )}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              type="number"
              value={item.fpagoPorcentaje}
              onChange={(e) => {
                const res = replaceCaracteres(e.target.value);
                setitem({
                  ...item,
                  fpagoPorcentaje: res,
                });
              }}
              size="small"
              helperText="Editando"
              fullWidth
            />
          ) : (
            `${row.fpagoPorcentaje}`
          )}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
