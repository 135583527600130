import React, { memo, useContext } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Autocomplete,
} from "@mui/material";

import { Cancel, Check } from "@mui/icons-material";
import { useState } from "react";

import AlertaContext from "../../../../../Context/Alerta/AlertaContext";
import clienteAxios from "../../../../../config/axios";
import AuthContext from "../../../../../Context/Auth/AuthContext";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  celda: {
    fontSize: ".7rem !important",
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));
const RowAgregarArma = (props) => {
  const [cargando, setcargando] = useState(false);
  const { usuario } = useContext(AuthContext);

  const { mostrarAlerta } = useContext(AlertaContext);
  const { setagregar, item, setPagoAdicionalsData, PagoAdicionalsData } = props;

  const [itemHijoNuevo, setitemHijoNuevo] = useState({
    padiId: 0,
    padiIdCliente: item.perId,
    padiRucCliente: item.perDocNumero,
    padiNomCliente: item.perNombre,
    padiContrato: "",
    padiUbicacionContrato: "",
    padiIva: "SI",
    padiValor: "1",
    padiDetalle: "",
    padiFecCobro: "",
    padiEstado: "ACTIVA",
    padiUser: usuario.usuario,
    padiEmpresa: usuario.rucempresa,
    padiFecReg: "",
    Todos: "",
  });
  const handleChange = (e) => {
    setitemHijoNuevo({
      ...itemHijoNuevo,
      [e.target.name]: e.target.value,
    });
  };
  const botonDisabled = () => {
    if (
      itemHijoNuevo.padiContrato === "" ||
      itemHijoNuevo.padiIva === "" ||
      itemHijoNuevo.padiValor === "" ||
      itemHijoNuevo.padiDetalle === "" ||
      itemHijoNuevo.padiFecCobro === ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  const classes = useRowStyles();
  //gql eliminar user

  const handleAgregar = async () => {
    try {
      setcargando(true);

      const resItem = await clienteAxios.post("/pagosadicionales", {
        ...itemHijoNuevo,
      });

      const mantenimientosDataR = [resItem.data.data, ...PagoAdicionalsData];
      mostrarAlerta("Pago Adicional Creado Correctamente", "success");
      setPagoAdicionalsData(mantenimientosDataR);

      setcargando(false);
    } catch (error) {
      setcargando(false);
    }
  };
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell padding="none" style={{ borderRight: "1px solid #ccc" }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-around"
            ml={1}
            mr={1}
          >
            <IconButton
              className={classes.margin}
              disabled={cargando}
              color={cargando ? "inherit" : "secondary"}
              onClick={() => {
                setagregar(false);
                setitemHijoNuevo({
                  padiId: 0,
                  padiIdCliente: item.perId,
                  padiRucCliente: item.perDocNumero,
                  padiNomCliente: item.perNombre,
                  padiContrato: "",
                  padiUbicacionContrato: "",
                  padiIva: "SI",
                  padiValor: "1",
                  padiDetalle: "",
                  padiFecCobro: "",
                  padiEstado: "ACTIVA",
                  padiUser: usuario.usuario,
                  padiEmpresa: usuario.rucempresa,
                  padiFecReg: "",
                  Todos: "",
                });
              }}
            >
              <Cancel fontSize="small" />
            </IconButton>
            <IconButton
              className={classes.margin}
              disabled={botonDisabled() || cargando}
              color={botonDisabled() || cargando ? "inherit" : "secondary"}
              onClick={async () => {
                await handleAgregar();

                setagregar(false);
                setitemHijoNuevo({
                  padiId: 0,
                  padiIdCliente: item.perId,
                  padiRucCliente: item.perDocNumero,
                  padiNomCliente: item.perNombre,
                  padiContrato: "",
                  padiUbicacionContrato: "",
                  padiIva: "SI",
                  padiValor: "1",
                  padiDetalle: "",
                  padiFecCobro: "",
                  padiEstado: "ACTIVA",
                  padiUser: usuario.usuario,
                  padiEmpresa: usuario.rucempresa,
                  padiFecReg: "",
                  Todos: "",
                });
              }}
            >
              <Check fontSize="small" />
            </IconButton>
            <FormControlLabel
              className={classes.margin}
              onChange={(e) => {
                setitemHijoNuevo({
                  ...itemHijoNuevo,
                  padiEstado: e.target.checked ? "ACTIVA" : "INACTIVA",
                });
              }}
              control={
                <Checkbox
                  disabled
                  value="ACTIVO"
                  className={classes.margin}
                  checked={
                    itemHijoNuevo["padiEstado"] === "ACTIVA" ? true : false
                  }
                  color="primary"
                />
              }
            />
          </Box>
        </TableCell>
        {/* las demas columnas */}

        <>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <FormControl fullWidth>
              <Autocomplete
                autoComplete="false"
                options={Object.entries(item.perISPContratos).map(
                  (itemContrato) => {
                    return itemContrato[1].perNombreContrato;
                  }
                )}
                getOptionLabel={(option) => {
                  return option.replace("/+/", " ** ").replace("/+/", " ** ");
                }}
                value={itemHijoNuevo.padiContrato}
                paperprops
                onChange={async (event, newValue) => {
                  if (newValue) {
                    const itemF = Object.values(item.perISPContratos).find(
                      (itemContrato) => {
                        return itemContrato.perNombreContrato === newValue;
                      }
                    );

                    setitemHijoNuevo({
                      ...itemHijoNuevo,
                      padiContrato: newValue,
                      padiUbicacionContrato: itemF.perUbicacionContrato,
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    required
                    autoComplete={false}
                    fullWidth
                    onChange={async (e) => {}}
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              />
            </FormControl>
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              size="small"
              fullWidth
              value={itemHijoNuevo.padiDetalle}
              name="padiDetalle"
              onChange={(e) => handleChange(e)}
            />
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              type="number"
              size="small"
              fullWidth
              value={itemHijoNuevo.padiValor}
              name="padiValor"
              onChange={(e) => handleChange(e)}
            />
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              size="small"
              fullWidth
              value={itemHijoNuevo.padiIva}
              name="padiIva"
              onChange={(e) => handleChange(e)}
              select
              InputLabelProps={{ shrink: true }}
              SelectProps={{ displayEmpty: true }}
            >
              <MenuItem value="">Seleccione.</MenuItem>
              {["SI", "NO"].map((item) => {
                return <MenuItem value={item}>{item} </MenuItem>;
              })}
            </TextField>
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              type="date"
              size="small"
              fullWidth
              value={itemHijoNuevo.padiFecCobro}
              name="padiFecCobro"
              onChange={(e) => handleChange(e)}
            />
          </TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregarArma);
