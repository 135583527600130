import React, { memo, useEffect } from "react";

import {
  Autocomplete,
  FormControl,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";

import { useState } from "react";
import { withRouter } from "react-router";
import ButtonsAction from "../../../components/Tablas/ButtonsAction";
import {
  Cancel,
  Check,
  Create,
  Delete,
  DeleteForever,
} from "@mui/icons-material";

import {
  butonIconTransition,
  useRowStyles,
} from "../../../../styles/stylesRows";
import ConfirmacionDialog from "../../../components/Dialogs/ConfirmacionDialog";
import { trimPropsItem } from "../../../../config/const";
import { validacionHijo } from "../../data";

const Row = (props) => {
  // PROPS
  const {
    setitemPadre,
    itemPadre,
    rowHijo,
    index,
    handleDragStart,
    handleDragEnter,
    handleDragEnd,
    equipoData,
    editarPadre,
  } = props;
  // CLASES
  const classes = useRowStyles();
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${rowHijo.error === true ? classes.celdaEliminado : ""}`;

  // STATE
  const [editar, seteditar] = useState(false);
  const [itemHijo, setitemHijo] = useState(rowHijo);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  //onChange
  const handleChange = (e) => {
    setitemHijo({ ...itemHijo, [e.target.name]: e.target.value });
  };

  // ELIMINAR EL REGISTRO
  const funcSubmit = () => {
    try {
      const prodProductos = itemPadre.prodProductos.map((item, itemIndex) => {
        const { nuevo, ...rest } = itemHijo;
        if (item.id === itemHijo.id) {
          return trimPropsItem({ ...rest, order: rowHijo.order });
        } else {
          return item;
        }
      });
      setitemPadre({
        ...itemPadre,
        prodProductos,
      });

      seteditar(false);
    } catch (error) {}
  };
  const funcEliminar = () => {
    try {
      setitemPadre({
        ...itemPadre,
        prodProductos: itemPadre.prodProductos.filter(
          (item, indexItem) => item.id !== rowHijo.id
        ),
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };
  const botones = [
    {
      tooltip: "Eliminar",
      texto: "",
      funcion: () => {
        setopenConfirmDialog(true);
      },
      disabled: editarPadre,
      Icon: Delete,
      color: "error",
      id: 1,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
      submit: true,
    },
    {
      tooltip: "Editar",
      texto: "",
      funcion: () => {
        seteditar(true);
        setitemHijo(rowHijo);
      },
      disabled: editarPadre,
      Icon: Create,
      color: "secondary",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: { ...butonIconTransition, padding: "0" },
    },
  ];
  const botonesEditar = [
    {
      tooltip: "Cancelar",
      texto: "",
      funcion: () => {
        if (rowHijo.nuevo) {
          return funcEliminar();
        }
        seteditar(false);
        setitemHijo(rowHijo);
        // setItemEdit(rowHijo);
      },
      disabled: false,
      Icon: Cancel,
      color: "error",
      id: 1,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: { ...butonIconTransition, padding: "0" },
    },
    {
      tooltip: "Guardar",
      texto: "",
      funcion: () => {
        funcSubmit();
      },
      disabled: validacionHijo(itemHijo),
      Icon: Check,
      color: "success",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
      submit: true,
    },
  ];
  useEffect(() => {
    if (rowHijo.nuevo) {
      seteditar(true);
    }
    // eslint-disable-next-line
  }, [rowHijo]);
  return (
    <TableRow
      className={classes.root}
      component={"form"}
      onSubmit={(e) => {
        e.preventDefault();
      }}
      onDragStart={() => handleDragStart(index)}
      onDragEnter={() => {
        handleDragEnter(index);
      }}
      onDragEnd={(e) =>
        handleDragEnd(
          "prodProductos",
          itemPadre.prodProductos,
          itemPadre,
          setitemPadre
        )
      }
      onDragOver={(e) => e.preventDefault()}
      draggable
    >
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              funcEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <ButtonsAction
        claseCelda={claseCelda}
        botones={editar ? botonesEditar : botones}
      />
      {editar ? (
        <>
          <TableCell size="small" align="center" className={claseCelda}>
            <FormControl fullWidth>
              <Autocomplete
                size="small"
                options={equipoData.map((item) => {
                  return item.maqNombre;
                })}
                getOptionLabel={(option) => {
                  return option;
                }}
                value={itemHijo.equipo}
                onChange={async (event, newValue) => {
                  if (newValue) {
                    setitemHijo({ ...itemHijo, equipo: newValue });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              />
            </FormControl>
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            <TextField
              multiline
              rows={3}
              value={itemHijo.detalle}
              name="detalle"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
              autoComplete="false"
            />
          </TableCell>

          <TableCell size="small" align="center" className={claseCelda}>
            <TextField
              value={itemHijo.llevaIva}
              name="llevaIva"
              onChange={(e) => handleChange(e)}
              size="small"
              select
              fullWidth
              InputLabelProps={{ shrink: true }}
              SelectProps={{ displayEmpty: true }}
            >
              <MenuItem disabled value="">
                Seleccione.
              </MenuItem>
              {["SI", "NO"].map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </TextField>
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            <TextField
              value={itemHijo.incluyeIva}
              name="incluyeIva"
              onChange={(e) => handleChange(e)}
              size="small"
              select
              fullWidth
              InputLabelProps={{ shrink: true }}
              SelectProps={{ displayEmpty: true }}
            >
              <MenuItem disabled value="">
                Seleccione.
              </MenuItem>
              {["SI", "NO"].map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </TextField>
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            <TextField
              type="number"
              value={itemHijo.cantidad}
              name="cantidad"
              onChange={(e) => {
                setitemHijo({
                  ...itemHijo,
                  [e.target.name]: e.target.value,
                  total: (e.target.value * itemHijo.valor).toFixed(2),
                });
              }}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            <TextField
              type="number"
              value={itemHijo.valor}
              name="valor"
              onChange={(e) => {
                setitemHijo({
                  ...itemHijo,
                  [e.target.name]: e.target.value,
                  total: (e.target.value * itemHijo.cantidad).toFixed(2),
                });
              }}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {itemHijo.total}
          </TableCell>
        </>
      ) : (
        <>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.equipo}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.detalle}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.llevaIva}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.incluyeIva}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.cantidad}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.valor}
          </TableCell>{" "}
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.total}
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

export default withRouter(memo(Row));
