import React, { memo, useContext } from "react";

import { TableCell, TableRow } from "@mui/material";

import { useState } from "react";
import { withRouter } from "react-router";
import AuthContext from "../../../Context/Auth/AuthContext";
import {
  Cancel,
  CopyAll,
  Create,
  DeleteForever,
  Restore,
  Send,
  Visibility,
  WhatsApp,
} from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../../styles/stylesRows";
import { usePermiso } from "../../../hooks/usePermiso";
import { trimPropsItem } from "../../../config/const";
import ButtonsAction from "../../components/Tablas/ButtonsAction";
import CallNombrePersona from "../../components/Tablas/CallNombrePersona";
import ConfirmacionDialog from "../../components/Dialogs/ConfirmacionDialog";
import clienteAxios from "../../../config/axios";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const {
    arrayExport,
    setarrayExport,
    row,
    index,
    campoExport,
    seteditar,
    setopen,
    changeObjetoActivoState,
    socket,
  } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    row.editado === true
      ? classes.celdaEditado
      : row.nuevo === true
      ? classes.celdaNuevo
      : row.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;
  // HOOK DE PERMISO
  const { tienePermiso, alertaPermiso } = usePermiso("Mensajeria");
  // CONTEXT
  const { usuario } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const [openConfirmDialogW, setopenConfirmDialogW] = useState(false);
  const [cargandoW, setcargandoW] = useState(false);
  // ELIMINAR EL REGISTRO
  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        tabla: "mensajeria_noficacion",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        mensUser: usuario.usuario,
        conEmpresa: usuario.rucempresa,
        [campoExport]: row[campoExport],
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };
  // FUNCION PARA EDITAR, ABRE EL MODAL COLOCA EN EDITAR EL MODAL Y CAMBIA EL OBJETO ACTIVO
  // const funcionEdit = () => {
  //   setopen(true);
  //   seteditar(true);
  //   changeObjetoActivoState(row);
  // };
  const funcRecuperar = () => {
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...row,
          tabla: "mensajeria_noficacion",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          mensUser: usuario.usuario + "/+/" + row[campoExport],
          conEmpresa: usuario.rucempresa,
          [campoExport]: row[campoExport],
        })
      );

      seteditar(false);
    } catch (error) {}
  };
  return (
    <React.Fragment>
      {" "}
      <ConfirmacionDialog
        open={openConfirmDialogW}
        setopen={setopenConfirmDialogW}
        categoria="warning"
        titulo={`¿Esta seguro de enviar este mensaje a todas las personas seleccionadas?`}
        botones={[
          {
            tooltip: "Se enviará el mensaje",
            texto: "SI",
            funcion: async () => {
              try {
                setcargandoW(true);
                const res = await clienteAxios.post(
                  "/mensajeria/notificacion",
                  {
                    mensId: row.mensId,
                  }
                );
                setcargandoW(false);
                setopenConfirmDialogW(false);
                mostrarAlerta(res.data.msg, "success");
              } catch (error) {
                console.log({ error });
                setcargandoW(false);
                mostrarAlerta("Hubo un error", "error");
              }
            },
            disabled: cargandoW,
            Icon: Send,
            color: "success",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialogW(false);
            },
            disabled: cargandoW,
            Icon: Cancel,
            color: "error",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow className={classes.root}>
        {/* boton */}
        <ButtonsAction
          claseCelda={claseCelda}
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          campoExport={campoExport}
          row={row}
          botones={[
            {
              tooltip: "Recuperar",
              texto: "",
              funcion: () => {
                if (!tienePermiso("añadir")) {
                  return alertaPermiso("añadir");
                }
                funcRecuperar(true);
              },
              disabled: usuario.usuario !== row.mensUser.split("/+/")[0],
              Icon: Restore,
              color: "success",
              id: 3,
              ocultar: !row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Ver Registro",
              texto: "",
              funcion: () => {
                setopen(true);
                changeObjetoActivoState({ ...row, ver: true });
              },
              disabled: false,
              Icon: Visibility,
              color: "primary",
              id: 1,
              ocultar: row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },

            {
              tooltip: "Copiar Registro",
              texto: "",
              funcion: () => {
                setopen(true);
                changeObjetoActivoState(row);
              },
              disabled: false,
              Icon: CopyAll,
              color: "secondary",
              id: 1,
              ocultar: row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Editar Registro",
              texto: "",
              funcion: () => {
                setopen(true);
                changeObjetoActivoState(row);
                seteditar(true);
              },
              disabled: false,
              Icon: Create,
              color: "secondary",
              id: 1,
              ocultar: row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Enviar",
              texto: "",
              funcion: async () => {
                setopenConfirmDialogW(true);
              },
              disabled: false,
              Icon: WhatsApp,
              color: "success",
              id: 3,
              ocultar: row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
          ]}
        />

        <>
          <TableCell size="small" align="center" className={claseCelda}>
            {row.mensNombre}
          </TableCell>

          <TableCell size="small" align="center" className={claseCelda}>
            {new Date(row.mensRecReg).toLocaleString("es-ES", {
              weekday: "short",
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour: "2-digit",
            })}
          </TableCell>

          <CallNombrePersona user={row.mensUser} claseCelda={claseCelda} />
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
