import React, { useState, useContext, useEffect, memo } from "react";
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  TextField,
  FormControl,
  Autocomplete,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

import Draggable from "react-draggable";
import { Cancel } from "@mui/icons-material";
import { IconButton } from "@mui/material";
// eslint-disable-next-line
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../../Context/Auth/AuthContext";
import { FechaExactaConMinutos, trimPropsItem } from "../../../../config/const";
import clienteAxios from "../../../../config/axios";
import { usePermiso } from "../../../../hooks/usePermiso";
import { useTheme } from "@emotion/react";
import { addHours } from "date-fns";
function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "55%",
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    marginTop: "5vh",
    [theme.breakpoints.down("sm")]: { width: "90%", marginTop: "2vh" },
  },
  margin: {
    margin: theme.spacing(1),
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  ocultar: { display: "none" },
  inputPadding: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0),
    fontSize: "1rem",
  },
  formD: {
    flexGrow: 1,
    width: "45%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "95%",
    [theme.breakpoints.down("md")]: { padding: "0", margin: "2px" },
    [theme.breakpoints.down("sm")]: { padding: "0", margin: "2px" },
  },

  form30PC: {
    flexGrow: 1,
    width: "30%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "30%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },

  large: {
    width: theme.spacing(13),
    height: theme.spacing(13),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&:hover": { opacity: ".7" },
  },
  center: { justifyContent: "center", alignItems: "center" },
  root: { width: "100%", marginTop: theme.spacing(1) },
  correo: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "45%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  error: { color: "red", fontWeight: "bold" },
}));
const ModalData = (props) => {
  const tema = useTheme();
  const { tienePermiso, alertaPermiso } = usePermiso("Reservas");
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext); //props
  const {
    open,
    setopen,
    editar,
    seteditar,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
  } = props;
  // eslint-disable-next-line
  const [item, setitem] = useState(ObjectoActivo);
  const {
    // resId: 1,
    // resMascId,
    resMascNombre,
    resMascRaza,
    // resPerId,
    resPerDocumento,
    resPerNombre,
    // resPerCelular,
    // resPerEmail,
    resTipoReserva,
    resUltima,
    resPerDocVeterinario,
    resPerNomVeterinario,
    resFecha,
    // resEmpresa,
    // resUser,
    resObservacion,
    // resEnvioCorreo,
    // resEnvioSms,
    resBgColor,
    resFechaFinal,
  } = item;

  // const [personasData, setpersonasData] = useState([]);
  const [tipoReservaData, settipoReservaData] = useState([]);
  const [mascotasData, setmascotasData] = useState([]);
  const [clientesData, setclientesData] = useState([]);
  const [veterinariosData, setveterinariosData] = useState([]);
  const handleChange = (e) => {
    setitem({ ...item, [e.target.name]: e.target.value });
  };
  const [cargando, setcargando] = useState(false);
  const [seccionActive, setseccionActive] = useState({
    clienteSeccion: true,
    reservaSeccion: false,
  });
  const { clienteSeccion, reservaSeccion } = seccionActive;
  const [error, seterror] = useState({});
  const [motivosData, setmotivosData] = useState(null);
  // GET => /api/sexo/filtro/0
  useEffect(() => {
    if (!motivosData) {
      const getresmotivosData = async () => {
        try {
          const resmotivosData = await clienteAxios.get(
            `/consultasmedicas/filtro/0`
          );

          setmotivosData(resmotivosData.data.data);
        } catch (error) {
          setmotivosData([]);
        }
      };
      getresmotivosData();
    }

    setitem(ObjectoActivo); // eslint-disable-next-line
  }, [open]);
  const defaultActive = () => {
    changeObjetoActivoState({
      // resId: 1,
      resMascId: "",
      resMascNombre: "",
      resMascRaza: "",
      resPerId: "",
      resPerDocumento: "",
      resPerNombre: "",
      resPerCelular: [],
      resPerEmail: [],
      resTipoReserva: "",
      resUltima: "NO",
      resPerDocVeterinario: "",
      resPerNomVeterinario: "",
      resPerIdVeterinario: "",
      resFecha: FechaExactaConMinutos,
      resEmpresa: "",
      resUser: "",
      resObservacion: "",
      resEnvioCorreo: "PENDIENTE",
      resEnvioSms: "PENDIENTE",
      resBgColor: tema.palette.primary.main,
      resFechaFinal: new Date(
        Date.parse(addHours(Date.parse(FechaExactaConMinutos), 1)) -
          new Date().getTimezoneOffset() * 60 * 1000
      )
        .toISOString()
        .substring(0, 16),
    });
    setopen(false);
    seteditar(false);
    seterror({});
    setseccionActive({ clienteSeccion: true, reservaSeccion: false });
    setcargando(false);
  };

  //hook de estilos
  const clases = useStyles();
  //definiendo los estilos del modal
  const [modalStyle] = useState(getModalStyle);
  const validacion = () => {
    if (
      resTipoReserva.trim() === "" ||
      resFecha.trim() === "" ||
      resUltima.trim() === "" ||
      resPerDocVeterinario.trim() === "" ||
      resFechaFinal === "" ||
      Date.parse(resFechaFinal) <= Date.parse(resFecha)
    ) {
      seterror({
        resTipoReserva: resTipoReserva.trim() === "" ? true : false,
        resFecha: resFecha.trim() === "" ? true : false,
        resUltima: resUltima.trim() === "" ? true : false,
        resPerDocVeterinario: resPerDocVeterinario.trim() === "" ? true : false,
        resFechaFinal: Date.parse(resFechaFinal) <= Date.parse(resFecha),
      });

      return true;
    } else {
      return false;
    }
  };

  const handleCrear = async () => {
    try {
      setcargando(true);

      // if (archivo !== "") {
      //   const formDataLogos = new FormData();
      //   formDataLogos.append("tipo", "mascotas");
      //   formDataLogos.append("rucempresa", usuario.rucempresa);
      //   formDataLogos.append("archivoAnterior", item.hcliFoto);
      //   formDataLogos.append("archivo", archivo);
      //   resFoto = await clienteAxios.post(
      //     "/upload_delete_multiple_files/upload",
      //     formDataLogos
      //   );
      // }

      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          tabla: "reserva",
          resUser: usuario.usuario,
          resEmpresa: usuario.rucempresa,
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    try {
      setcargando(true);
      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          tabla: "reserva",
          resUser: `${item.resUser.split(" ** ")[0]} ** ${usuario.usuario}`,
          resEmpresa: usuario.rucempresa,
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  //filas por pagina

  // 1 + 2 + 3 + 4

  if (!open) {
    return null;
  }
  if (!motivosData) {
    return null;
  }
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  style={{ cursor: "-webkit-grab" }}
                  id="handle"
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {editar ? "Editar" : "Agregar"}
                  {" Reserva"}
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box>
                  {/* Cliente {resPerDocumento} */}
                  {/* Mascota {resMascNombre} */}
                  {/* Raza {resMascRaza} */}
                  <Button
                    variant={
                      clienteSeccion
                        ? "contained"
                        : error.resPerDocumento ||
                          error.resMascNombre ||
                          error.resMascRaza
                        ? "outlined"
                        : null
                    }
                    disableElevation
                    style={{ padding: "8px" }}
                    color={
                      error.resPerDocumento ||
                      error.resMascNombre ||
                      error.resMascRaza
                        ? "error"
                        : "secondary"
                    }
                    onClick={() => setseccionActive({ clienteSeccion: true })}
                  >
                    Cliente
                  </Button>
                  {/* Reserva {resTipoReserva}   */}
                  {/* Fecha {resFecha}   */}
                  {/* Como la ultima vez {resUltima}   */}
                  {/* Veterinario {resPerDocVeterinario}  */}
                  {/* Observación {resObservacion}  */}

                  <Button
                    variant={
                      reservaSeccion
                        ? "contained"
                        : error.resTipoReserva ||
                          error.resFecha ||
                          error.resUltima ||
                          error.resPerDocVeterinario ||
                          error.resObservacion
                        ? "outlined"
                        : null
                    }
                    disableElevation
                    style={{ padding: "8px" }}
                    color={
                      error.resTipoReserva ||
                      error.resFecha ||
                      error.resUltima ||
                      error.resPerDocVeterinario ||
                      error.resObservacion
                        ? "error"
                        : "secondary"
                    }
                    onClick={() => setseccionActive({ reservaSeccion: true })}
                  >
                    Reserva
                  </Button>
                </Box>
                {/* <FormControlLabel
                control={
                  <Switch
                    checked={perEstado === "ACTIVO" ? true : false}
                    onChange={(e) => {
                      setitem({
                        ...item,
                        perEstado: e.target.checked ? "ACTIVO" : "INACTIVO",
                      });
                    }}
                    name="antoine"
                  />
                }
                label="Estado"
              /> */}
              </Box>
              <Divider />
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="flex-end"
                borderRadius="0px 50px 50px 0px"
                mt={1}
              >
                {clienteSeccion ? (
                  <>
                    {" "}
                    <FormControl fullWidth className={clases.formD}>
                      <Autocomplete
                        filterOptions={(x) => x}
                        options={clientesData.map((item) => {
                          return (
                            item.perDocNumero +
                            "/+/" +
                            item.perNombre +
                            "/+/" +
                            item.perId
                          );
                        })}
                        getOptionLabel={(option) => {
                          return option.replace("/+/", " - ").split("/+/")[0];
                        }}
                        id="controlled-demo"
                        value={
                          (resPerNombre + " " + resPerDocumento).trim() === ""
                            ? ""
                            : resPerNombre + " " + resPerDocumento
                        }
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");
                            const itemF = clientesData.find((itemPersona) => {
                              return itemPersona.perId === Number(options[2]);
                            });

                            setitem({
                              ...item,
                              resMascId: "",
                              resMascNombre: "",
                              resMascRaza: "",
                              resPerId: itemF.perId,
                              resPerNombre: itemF.perNombre,
                              resPerDocumento: itemF.perDocNumero,
                              resPerCelular: itemF.perCelular,
                              resPerEmail: itemF.perEmail,
                            });
                            const res = await clienteAxios.get(
                              `/mascotas/filtro/0?search=${
                                itemF.perNombre
                              }&byCliente=${true}`
                            );

                            if (!res.data.data) {
                              return mostrarAlerta(res.data.msg, "error");
                            }

                            setmascotasData(res.data.data);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            error={error.resMascNombre}
                            label="Cliente"
                            fullWidth
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                return;
                              }
                              try {
                                const res = await clienteAxios.get(
                                  `/proveedores/filtro/0?search=${e.target.value}&estado=CLIENTE`
                                );

                                if (!res.data.data) {
                                  return mostrarAlerta(res.data.msg, "error");
                                }
                                setclientesData(res.data.data);
                              } catch (error) {}
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl fullWidth className={clases.formD}>
                      <Autocomplete
                        filterOptions={(x) => x}
                        options={mascotasData.map((item) => {
                          return (
                            item.mascNombre +
                            "/+/" +
                            item.mascPerNombre +
                            "/+/" +
                            item.mascId
                          );
                        })}
                        getOptionLabel={(option) => {
                          return option.replace("/+/", " - ").split("/+/")[0];
                        }}
                        id="controlled-demo"
                        value={resMascNombre}
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");
                            const itemF = mascotasData.find((itemPersona) => {
                              return itemPersona.mascId === Number(options[2]);
                            });

                            setitem({
                              ...item,
                              resMascId: itemF.mascId,
                              resMascNombre: itemF.mascNombre,
                              resMascRaza: itemF.mascRaza,
                              resPerId: itemF.mascPerId,
                              resPerNombre: itemF.mascPerNombre,
                              resPerDocumento: itemF.mascPerDocNumero,
                              resPerCelular: itemF.mascPerCelular,
                              resPerEmail: itemF.mascPerEmail,
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            error={error.resMascNombre}
                            label="Paciente"
                            fullWidth
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                return;
                              }
                              try {
                                const res = await clienteAxios.get(
                                  `/mascotas/filtro/0?search=${
                                    e.target.value
                                  }&byCliente=${true}`
                                );

                                if (!res.data.data) {
                                  return mostrarAlerta(res.data.msg, "error");
                                }

                                setmascotasData(res.data.data);
                              } catch (error) {}
                            }}
                            // InputProps={{
                            //   ...params.InputProps,
                            //   endAdornment: (
                            //     <React.Fragment>
                            //       {!editar ? (
                            //         <IconButton
                            //           size="small"
                            //           aria-label=""
                            //           onClick={async () => {
                            //             seteditarProveedor(false);
                            //             setopenProveedor(true);
                            //           }}
                            //         >
                            //           <AddCircleOutlineTwoTone color="primary" />
                            //         </IconButton>
                            //       ) : null}
                            //       {params.InputProps.endAdornment}
                            //     </React.Fragment>
                            //   ),
                            // }}
                          />
                        )}
                      />
                    </FormControl>
                    <TextField
                      disabled
                      className={clases.formD}
                      label="Raza"
                      value={resMascRaza}
                      name="resMascRaza"
                      error={error.resMascRaza}
                      onChange={(e) => handleChange(e)}
                    />
                    {/* <TextField
                      disabled
                      className={clases.formD}
                      label="Cliente"
                      value={`${resPerDocumento} ${resPerNombre}`}
                      name="resPerDocumento"
                      error={error.resPerDocumento}
                      onChange={(e) => handleChange(e)}
                    /> */}
                  </>
                ) : null}
                {reservaSeccion ? (
                  <>
                    <FormControl className={clases.formD}>
                      <Autocomplete
                        filterOptions={(x) => x}
                        options={tipoReservaData.map((item) => {
                          return item.tiporesNombre;
                        })}
                        getOptionLabel={(option) => {
                          return option;
                        }}
                        id="controlled-demo"
                        value={resTipoReserva}
                        name={"resTipoReserva"}
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            setitem({
                              ...item,
                              resTipoReserva: newValue,
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            required
                            error={error.resTipoReserva}
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                return;
                              }
                              try {
                                const res = await clienteAxios.get(
                                  "/tiporeservas/filtro/0"
                                );

                                if (!res.data.data) {
                                  return mostrarAlerta(res.data.msg, "error");
                                }

                                settipoReservaData(res.data.data);
                              } catch (error) {}
                            }}
                            label={"Reserva"}
                            fullWidth
                            InputProps={{
                              ...params.InputProps,
                              // endAdornment: (
                              //   <React.Fragment>
                              //     <IconButton
                              //       size="small"

                              //       aria-label=""
                              //       onClick={async () => {
                              //         // seteditarProveedor(false);
                              //         setOpenProveedor(true);
                              //       }}
                              //     >
                              //       <AddCircleOutlineTwoTone color="primary" />
                              //     </IconButton>
                              //     {params.InputProps.endAdornment}
                              //   </React.Fragment>
                              // ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <TextField
                      type={"datetime-local"}
                      InputLabelProps={{ shrink: true }}
                      className={clases.formD}
                      label="Fecha"
                      value={resFecha}
                      name="resFecha"
                      error={error.resFecha}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      type="datetime-local"
                      className={clases.formD}
                      label="Fecha Final"
                      value={resFechaFinal}
                      name="resFechaFinal"
                      error={error.resFechaFinal}
                      onChange={(e) => handleChange(e)}
                    />{" "}
                    <TextField
                      type="color"
                      className={clases.formD}
                      label="BgColor"
                      value={resBgColor}
                      name="resBgColor"
                      error={error.resBgColor}
                      onChange={(e) => handleChange(e)}
                    />
                    <FormControl
                      component="fieldset"
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      error={error.resUltima}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel
                          component="span"
                          color="primary"
                          style={{
                            marginRight: ".2rem",
                            fontWeight: "bold",
                          }}
                          className={error.resUltima ? clases.error : ""}
                        >
                          Como la ultima vez
                        </FormLabel>
                        <RadioGroup
                          onChange={(e) => handleChange(e)}
                          row
                          aria-label="gender"
                          value={resUltima}
                          name="resUltima"
                        >
                          <FormControlLabel
                            value="SI"
                            control={<Radio />}
                            label="SI"
                          />
                          <FormControlLabel
                            value="NO"
                            control={<Radio />}
                            label="NO"
                          />
                        </RadioGroup>
                      </Box>
                    </FormControl>
                    <FormControl fullWidth className={clases.formD}>
                      <Autocomplete
                        filterOptions={(x) => x}
                        options={veterinariosData.map((item) => {
                          return (
                            item.perDocNumero +
                            "/+/" +
                            item.perNombre +
                            "/+/" +
                            item.perId
                          );
                        })}
                        getOptionLabel={(option) => {
                          return option.replace("/+/", " ").split("/+/")[0];
                        }}
                        id="controlled-demo"
                        value={
                          resPerDocVeterinario === ""
                            ? ""
                            : `${resPerDocVeterinario} ${resPerNomVeterinario}`
                        }
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");
                            const itemF = veterinariosData.find(
                              (itemPersona) => {
                                return itemPersona.perId === Number(options[2]);
                              }
                            );
                            setitem({
                              ...item,
                              resPerDocVeterinario: itemF.perDocNumero,
                              resPerNomVeterinario: itemF.perNombre,
                              resPerIdVeterinario: itemF.perId,
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            error={error.resPerDocVeterinario}
                            label="Veterinario"
                            fullWidth
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                return;
                              }
                              try {
                                const res = await clienteAxios.get(
                                  `/proveedores/filtro/0?search=${e.target.value}&estado=VETERINARIO`
                                );
                                if (!res.data.data) {
                                  return mostrarAlerta(res.data.msg, "error");
                                }
                                setveterinariosData(res.data.data);
                              } catch (error) {}
                            }}
                            // InputProps={{
                            //   ...params.InputProps,
                            //   endAdornment: (
                            //     <React.Fragment>
                            //       {!editar ? (
                            //         <IconButton
                            //           size="small"
                            //           aria-label=""
                            //           onClick={async () => {
                            //             seteditarProveedor(false);
                            //             setopenProveedor(true);
                            //           }}
                            //         >
                            //           <AddCircleOutlineTwoTone color="primary" />
                            //         </IconButton>
                            //       ) : null}
                            //       {params.InputProps.endAdornment}
                            //     </React.Fragment>
                            //   ),
                            // }}
                          />
                        )}
                      />
                    </FormControl>
                    <TextField
                      multiline
                      rows={3}
                      variant={"outlined"}
                      className={clases.formAllW}
                      label="Observación"
                      value={resObservacion}
                      name="resObservacion"
                      error={error.resObservacion}
                      onChange={(e) => handleChange(e)}
                    />
                  </>
                ) : null}
              </Box>
              {/* <Divider /> */}{" "}
              <Box
                mt={2}
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                flexWrap={"wrap"}
              >
                <Box
                  className={clases.formD}
                  alignItems="center"
                  display={"flex"}
                  justifyContent="space-around"
                >
                  <Typography
                    className={clases.formD}
                    color="secondary"
                    fontSize={".9rem"}
                    fontWeight="bold"
                  >
                    Crea:{" "}
                    <Typography
                      className={clases.formD}
                      color="primary"
                      fontSize={".9rem"}
                      component="span"
                    >
                      {item.resUser.split(" ** ")[0]}
                    </Typography>
                  </Typography>
                  <Typography
                    className={clases.formD}
                    color="secondary"
                    fontSize={".9rem"}
                    fontWeight="bold"
                  >
                    Act:{" "}
                    <Typography
                      className={clases.formD}
                      color="primary"
                      fontSize={".9rem"}
                      component="span"
                    >
                      {item.resUser.split(" ** ")[1]}
                    </Typography>
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  mt={1}
                  className={clases.formD}
                >
                  <Button
                    className={clases.formD}
                    variant="contained"
                    color="primary"
                    disabled={cargando}
                    onClick={() => {
                      if (validacion()) {
                        return mostrarAlerta(
                          "Faltan campos obligatorios",
                          "error"
                        );
                      }
                      if (editar) {
                        if (!tienePermiso("editar")) {
                          return alertaPermiso("editar");
                        }
                        handleEditar();
                      } else {
                        if (!tienePermiso("agregar")) {
                          return alertaPermiso("agregar");
                        }
                        handleCrear();
                      }
                    }}
                  >
                    Guardar
                  </Button>
                </Box>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalData);
